import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "table-footer"
};
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminElementAdd from "./AdminElementAdd.vue";
import { limitBound } from "@/helpers/text.js";
import CustomPagination from "../../common/CustomPagination.vue";
const perPage = 20;
export default {
  __name: 'AdminRoles',
  setup(__props) {
    const store = useStore();
    const currentPage = ref(1);
    const tableFields = [
    // { key: "id", label: "#" },
    {
      key: "title",
      label: "Название"
    }, {
      key: "btnChange",
      label: "Редактировать",
      class: "text-center"
    }];
    const breadcrumbs = [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }];
    const settingsChange = {
      title: "Редактировать роль",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      texts: {
        title: {
          required: true,
          label: "Название"
        }
      }
    };
    const adminRoles = computed(() => store.getters["administration/adminRoles"]);
    const adminRolesLoading = computed(() => store.getters["administration/adminRolesLoading"]);
    const adminRolesCount = computed(() => store.getters["administration/adminRolesCount"]);
    const updateAdminRoles = payload => store.dispatch("administration/updateAdminRoles", payload);
    const changeAdminRoles = payload => store.dispatch("administration/changeAdminRoles", payload);
    async function changePage(newPage) {
      currentPage.value = limitBound(Number(newPage), 1, adminRolesCount.value / perPage, currentPage.value);
      await loadData();
    }
    async function clickChange(data) {
      await changeAdminRoles(data);
      await loadData();
    }
    async function loadData() {
      await updateAdminRoles({
        page: currentPage.value,
        page_size: perPage
      });
    }
    onMounted(async () => {
      await loadData();
    });
    return (_ctx, _cache) => {
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_table = _resolveComponent("b-table");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_card = _resolveComponent("b-card");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs
      }), _cache[2] || (_cache[2] = _createElementVNode("h1", {
        class: "mb-4"
      }, "Роли пользователей", -1)), _createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0 shadow-sm py-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_table, {
          class: "table-custom",
          responsive: "",
          hover: "",
          small: "",
          "show-empty": "",
          stacked: "md",
          items: adminRoles.value,
          fields: tableFields,
          busy: adminRolesLoading.value
        }, {
          "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
          empty: _withCtx(() => [_createVNode(_component_b_alert, {
            show: "",
            variant: "light",
            class: "text-center"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("img", {
              src: _imports_0,
              alt: "empty",
              class: "mb-4"
            }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
            _: 1
          })]),
          "cell(btnChange)": _withCtx(row => [_withDirectives(_createVNode(AdminElementAdd, {
            idModal: `changeCountry${row.item.id}`,
            settings: settingsChange,
            item: row.item,
            onUpdate: clickChange
          }, null, 8, ["idModal", "item"]), [[_directive_can, _ctx.$perm.change_admin_role_names]])]),
          _: 1
        }, 8, ["items", "busy"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
          class: "d-flex flex-wrap m-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "6",
            xl: "4"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(CustomPagination, {
              totalRows: adminRolesCount.value,
              onOnChangePage: changePage
            }, null, 8, ["totalRows"]), [[_vShow, adminRolesCount.value.value > perPage]])]),
            _: 1
          })]),
          _: 1
        })])]),
        _: 1
      })]);
    };
  }
};