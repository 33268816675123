import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_PartnersList = _resolveComponent("PartnersList");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_PartnersReport = _resolveComponent("PartnersReport");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: $options.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, _toDisplayString($options.title), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "primary",
        onClick: $options.clickCreate
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("Создать ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_bp]]), !$options.isAdminPath ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        class: "ms-3",
        variant: "primary",
        onClick: $options.gotoAutocheck
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Результаты автосверки ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.view_wa_autocheck]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        class: "px-0",
        active: ""
      }, {
        title: _withCtx(() => [_cache[2] || (_cache[2] = _createTextVNode("Деловые партнеры ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.partnersCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_PartnersList)]),
        _: 1
      }), !$options.isAdminPath ? (_openBlock(), _createBlock(_component_b_tab, {
        key: 0,
        title: "Отчёты"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_PartnersReport)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  })]);
}