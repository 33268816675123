import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapGetters } from "vuex";
import { checkPermissions } from "@/auth/auth.js";
import { formatName, fmtNameFull } from "@/helpers/text";
import { deepEqual } from "@/helpers/global";
import Calendar from "@/components/Helpers/Calendar.vue";
import { formatDateObj, formatObjDate, fmtDate, dateObjInit } from "@/helpers/date";
export default {
  name: "AdminWorkersChg",
  components: {
    Calendar
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      default: () => ({})
    },
    idModal: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    fmtNameFull,
    department: null,
    position: "",
    code: "",
    // ТН
    departmentState: null,
    positionState: null,
    codeState: null,
    startDate: {
      ...dateObjInit
    }
  }),
  methods: {
    formatName,
    changeWorker() {
      this.department = this.item.department || null;
      this.position = this.item.position || "";
      this.code = this.item.worker_code || "";
      this.startDate = formatObjDate(this.item.hiring_date);
      this.showModal = true;
    },
    handlerOk(bvModalEvt) {
      if (!this.validateCode() || !this.validatePosition() || !this.validateDepartment()) {
        bvModalEvt.preventDefault();
        return;
      }
      const itemStartDate = formatObjDate(this.item.hiring_date);
      this.$emit("updateWorker", {
        params: {
          department: this.department !== this.item.department ? this.department : null,
          position: this.position !== this.item.position ? this.position : null,
          worker_code: this.code !== this.item.worker_code ? this.code : null,
          hiring_date: deepEqual(itemStartDate, this.startDate) ? null : formatDateObj(this.startDate, fmtDate, null)
        },
        item: this.item
      });
      this.showModal = false;
    },
    validateCode() {
      const validCode = this.code.trim() !== "";
      this.codeState = !validCode ? validCode : null;
      return validCode;
    },
    validatePosition() {
      const validPosition = this.position.trim() !== "";
      this.positionState = !validPosition ? validPosition : null;
      return validPosition;
    },
    validateDepartment() {
      const validDepartment = this.department !== null;
      this.departmentState = !validDepartment ? validDepartment : null;
      return validDepartment;
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departments"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [{
        value: null,
        text: "Выберите подразделение"
      }]);
    },
    disableChange() {
      return !checkPermissions(this.$perm.change_wa_worker);
    }
  }
};