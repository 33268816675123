import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "filter-wrap"
};
const _hoisted_2 = {
  class: "footer mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_nav = _resolveComponent("b-nav");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_form_group, {
    label: "№ декларации"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.number,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filters.number = $event),
      type: "search",
      placeholder: "Номер",
      id: "number",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Сотрудник"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.author,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.filters.author = $event),
      type: "search",
      placeholder: "Фамилия",
      id: "author",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Статус"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.state,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.filters.state = $event),
      options: _ctx.stateOptions
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Подразделение"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.department,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.filters.department = $event),
      type: "search",
      placeholder: "Код подразделения",
      id: "department",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата изменения статуса от..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.dateFrom,
        classCustom: "no-gutters",
        onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.filters.dateFrom = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата изменения статуса до..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.dateUntill,
        classCustom: "no-gutters",
        onChangeValue: _cache[5] || (_cache[5] = $event => _ctx.filters.dateUntill = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Ответственный"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_nav, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.filters.assignee,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.filters.assignee = $event),
        type: "search",
        placeholder: "Фамилия",
        id: "assignee",
        trim: ""
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_checkbox, {
    modelValue: _ctx.filters.new,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.filters.new = $event),
    value: true,
    "unchecked-value": null
  }, {
    default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode(" Только новые ")])),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_button, {
    block: "",
    variant: "primary",
    onClick: $options.clickApplyFilters
  }, {
    default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Применить")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_button, {
    block: "",
    variant: "outline-secondary",
    class: "ms-3",
    onClick: $options.clearFilters
  }, {
    default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("Сбросить фильтры")])),
    _: 1
  }, 8, ["onClick"])])]);
}