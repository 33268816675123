import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { mapActions, mapGetters } from "vuex";
import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import { formatDateObj, fmtDate, formatDate, fmtDateShort, dateObjInit } from "@/helpers/date";
import {
//formatName,
limitBound, NONAME_DOTS,
//fmtNameShort,
MEASURES_NO, innerCheckMeasures, innerCheckReason, selectAll, fmtNameFull } from "@/helpers/text.js";
export default {
  name: "InnerCheckReport",
  components: {
    Calendar,
    UserSelector
  },
  data: () => ({
    innerCheckReason,
    currentPage: 1,
    reportShow: false,
    filters: {},
    departmentSelected: null,
    startDate: {
      ...dateObjInit
    },
    endDate: {
      ...dateObjInit
    },
    perPage: 10,
    gotoPage: 1,
    worker: {},
    fmtNameFull,
    reportType: "department",
    reportTypeOptions: [{
      value: "department",
      text: "Внутренние проверки по подразделениям"
    }, {
      value: "worker",
      text: "Внутренние проверки в отношении работника"
    }]
  }),
  methods: {
    ...mapActions("referenceBooks", ["updateDepartments"]),
    ...mapActions("innerCheck", ["updateReport", "downloadReport"]),
    async clickReport() {
      this.reportShow = true;
      this.filters.state_modified_gte = formatDateObj(this.startDate, fmtDate, null);
      this.filters.state_modified_lte = formatDateObj(this.endDate, fmtDate, null);
      if (this.reportType === "department") {
        delete this.filters.target_worker_id;
        this.filters.department_id = this.departmentSelected;
      } else {
        delete this.filters.department_id;
        this.filters.target_worker_id = this.worker.id;
      }
      await this.updateReport({
        page: 1,
        filters: this.filters
      });
    },
    async clickDownload() {
      this.filters.state_modified_gte = formatDateObj(this.startDate, fmtDate, null);
      this.filters.state_modified_lte = formatDateObj(this.endDate, fmtDate, null);
      if (this.reportType === "department") {
        delete this.filters.target_worker_id;
        this.filters.department_id = this.departmentSelected;
      } else {
        delete this.filters.department_id;
        this.filters.target_worker_id = this.worker.id;
      }
      await this.downloadReport({
        filters: this.filters
      });
      this.$refs.fileDownload.click();
    },
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.reportCount / this.perPage, this.currentPage);
      this.updateReport({
        page: this.currentPage,
        filters: this.filters
      });
    },
    selectedWorker(worker) {
      this.worker = worker;
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    ...mapGetters("innerCheck", ["reportLoading", "reportCount", "reportData", "reportFile"]),
    departmentOptions() {
      return selectAll.concat(Object.keys(this.departments).map(key => ({
        value: this.departments[key],
        text: key
      })));
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateReport({
          page: value,
          filters: this.filters
        });
      }
    },
    tableFields() {
      if (this.reportType === "department") return [{
        key: "index",
        label: "Порядковый номер"
      }, {
        key: "id",
        label: "Номер проверки"
      }, {
        key: "source",
        label: "Основание проверки"
      }, {
        key: "date_planned",
        label: "Срок проведения проверки",
        formatter: value => formatDate(value, fmtDateShort, NONAME_DOTS),
        class: "text-nowrap"
      },
      // {
      //   key: "assignee_detail",
      //   label: "Исполнитель",
      //   formatter: (value) => formatName(value, fmtNameShort),
      //   class: "text-nowrap",
      // },
      // { key: "category_name", label: "Категория" },
      // { key: "subject", label: "Суть" },
      // {
      //   key: "date_actual",
      //   label: "Проведено",
      //   formatter: (value) => formatDate(value, fmtDateShort, NONAME_DOTS),
      //   class: "text-nowrap",
      // },
      // {
      //   key: "violation_detected",
      //   label: "Нарушение",
      //   class: "text-center",
      //   formatter: (value) =>
      //     value ? "Да" : value === null ? NONAME_DOTS : "Нет",
      // },
      // {
      //   key: "date_confirmed",
      //   label: "Утверждено",
      //   formatter: (value) => formatDate(value, fmtDateShort, NONAME_DOTS),
      //   class: "text-nowrap",
      // },
      {
        key: "measures",
        label: "Результаты проверки",
        formatter: value => Array.isArray(value) && value.length > 0 ? value.map(key => innerCheckMeasures[key]).join(", ") : MEASURES_NO
      }];else return [{
        key: "index",
        label: "Порядковый номер"
      }, {
        key: "id",
        label: "Номер проверки"
      }, {
        key: "source",
        label: "Основание проверки"
      }, {
        key: "date_planned",
        label: "Срок проведения проверки",
        formatter: value => formatDate(value, fmtDateShort, NONAME_DOTS),
        class: "text-nowrap"
      },
      // {
      //   key: "assignee_detail",
      //   label: "Исполнитель",
      //   formatter: (value) => formatName(value, fmtNameShort),
      //   class: "text-nowrap",
      // },
      // { key: "category_name", label: "Категория" },
      // { key: "subject", label: "Суть" },
      // {
      //   key: "date_actual",
      //   label: "Проведено",
      //   formatter: (value) => formatDate(value, fmtDateShort, NONAME_DOTS),
      //   class: "text-nowrap",
      // },
      // {
      //   key: "violation_detected",
      //   label: "Нарушение",
      //   class: "text-center",
      //   formatter: (value) =>
      //     value ? "Да" : value === null ? NONAME_DOTS : "Нет",
      // },
      // {
      //   key: "date_confirmed",
      //   label: "Утверждено",
      //   formatter: (value) => formatDate(value, fmtDateShort, NONAME_DOTS),
      //   class: "text-nowrap",
      // },
      {
        key: "measures",
        label: "Результаты проверки",
        formatter: value => Array.isArray(value) && value.length > 0 ? value.map(key => innerCheckMeasures[key]).join(", ") : MEASURES_NO
      }];
    }
  },
  created() {
    this.updateDepartments();
  }
};