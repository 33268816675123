import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "d-inline-block"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "text-danger"
};
const _hoisted_4 = {
  class: "text-danger"
};
const _hoisted_5 = {
  class: "text-danger"
};
const _hoisted_6 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_card_title = _resolveComponent("b-card-title");
  const _component_StringsWithX = _resolveComponent("StringsWithX");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_button, {
    variant: "primary",
    onClick: $options.clickAssign,
    class: "m-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
      icon: "clipboard-list",
      "fixed-width": ""
    }), _cache[7] || (_cache[7] = _createTextVNode(" Сформировать список обучающихся"))]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.showModal = $event),
    id: "modalCourseAssign",
    title: 'Сформировать список обучающихся по программе ' + ($props.item.title || _ctx.CONST_NONAME),
    centered: "",
    size: "xl",
    onOk: $options.handlerOk,
    "ok-title": "Сохранить",
    okDisabled: $options.okDisabled,
    "cancel-title": "Отменить",
    "cancel-variant": "light",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("form", {
      ref: "form-login",
      onSubmit: _cache[5] || (_cache[5] = _withModifiers((...args) => $options.handlerOk && $options.handlerOk(...args), ["stop", "prevent"]))
    }, [!_ctx.departmentsLoading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "12",
        lg: "7",
        class: "py-lg-4"
      }, {
        default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("h5", null, "Даты", -1)), _createVNode(_component_b_form_group, {
          label: "Начало обучения"
        }, {
          default: _withCtx(() => [_createVNode(_component_Calendar, {
            title: "",
            date: _ctx.dateStart,
            classCustom: "no-gutters",
            onChangeValue: _cache[0] || (_cache[0] = $event => _ctx.dateStart = $event)
          }, null, 8, ["date"]), _withDirectives(_createElementVNode("div", _hoisted_3, " «Дата начала обучения» не может быть позднее «Даты окончания обучения» ", 512), [[_vShow, $options.invalidStartDate]])]),
          _: 1
        }), _createVNode(_component_b_form_group, {
          label: "Окончание обучения"
        }, {
          default: _withCtx(() => [_createVNode(_component_Calendar, {
            title: "",
            date: _ctx.dateEnd,
            onChangeValue: _cache[1] || (_cache[1] = $event => $options.changeDateEnd($event)),
            classCustom: "no-gutters"
          }, null, 8, ["date"]), _withDirectives(_createElementVNode("div", _hoisted_4, " «Дата окончания обучения» не может быть ранее даты внесения сведений ", 512), [[_vShow, $options.invalidEndDate]])]),
          _: 1
        }), _createVNode(_Transition, null, {
          default: _withCtx(() => [_ctx.showDeadline ? (_openBlock(), _createBlock(_component_b_form_group, {
            key: 0,
            label: "Дедлайн"
          }, {
            default: _withCtx(() => [_createVNode(_component_Calendar, {
              title: "",
              date: _ctx.deadline,
              classCustom: "no-gutters",
              onChangeValue: _cache[2] || (_cache[2] = $event => _ctx.deadline = $event)
            }, null, 8, ["date"]), _withDirectives(_createElementVNode("div", _hoisted_5, " «Дедлайн» не может быть ранее «Даты начала обучения» и не может быть позднее «Даты окончания обучения» ", 512), [[_vShow, $options.invalidDeadline]])]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _cache[10] || (_cache[10] = _createElementVNode("h5", null, "Организация", -1)), _createVNode(_component_b_form_group, null, {
          default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
            modelValue: _ctx.allOrg,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.allOrg = $event),
            switch: ""
          }, {
            default: _withCtx(() => [_createTextVNode("в целом по организации " + _toDisplayString(_ctx.orgName), 1)]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _cache[11] || (_cache[11] = _createElementVNode("h5", null, "Подразделения", -1)), _createVNode(_component_b_form_group, null, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.depart,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.depart = $event),
            disabled: $options.departOptions.length === 0 || _ctx.allOrg,
            options: $options.departOptions,
            onChange: $options.changeDepart
          }, {
            first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
              value: null,
              disabled: ""
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Выберите подразделение")])),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue", "disabled", "options", "onChange"])]),
          _: 1
        }), _cache[12] || (_cache[12] = _createElementVNode("h5", null, "Работники", -1)), _createVNode(_component_b_form_group, null, {
          default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
            default: _withCtx(() => [_createVNode(_component_UserSelector, {
              onSelected: $options.selectedWorker,
              disabled: _ctx.allOrg,
              fmtName: _ctx.fmtNameFull,
              showInfo: true
            }, null, 8, ["onSelected", "disabled", "fmtName"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "12",
        lg: "5",
        class: "d-flex"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card, {
          "bg-variant": "light",
          "border-variant": "light",
          class: "align-self-stretch w-100"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_card_title, {
            class: "h5 mb-1"
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("Выбрано")])),
            _: 1
          }), _createVNode(_component_b_card_text, null, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_StringsWithX, {
              items: _ctx.departsToAssign,
              onRemoveItem: $options.removeDeparts
            }, null, 8, ["items", "onRemoveItem"]), [[_vShow, !_ctx.allOrg]]), _withDirectives(_createVNode(_component_StringsWithX, {
              items: _ctx.workersToAssign,
              onRemoveItem: $options.removeWorkers
            }, null, 8, ["items", "onRemoveItem"]), [[_vShow, !_ctx.allOrg]]), _withDirectives(_createElementVNode("p", {
              class: "mb-0"
            }, " Все работники " + _toDisplayString(_ctx.orgName), 513), [[_vShow, _ctx.allOrg]])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Загрузка...", -1))]))], 544)]),
    _: 1
  }, 8, ["modelValue", "title", "onOk", "okDisabled"])]);
}