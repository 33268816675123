import theNotification from "@/components/theNotification";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import ProgramAddDescription from "./ProgramAddDescription.vue";
import ProgramAddMaterial from "./ProgramAddMaterial.vue";
import ProgramAddTesting from "./ProgramAddTesting";
import ProgramEvents from "./ProgramEvents.vue";
import StudentsList from "./StudentsList.vue";
import CourseAssign from "../CourseAssign.vue";
import StudentsToAssign from "./StudentsToAssign.vue";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { statesEducProg } from "@/helpers/states";
export default {
  name: "ProgramAddBase",
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  components: {
    theNotification,
    BreadCrumbToHome,
    ProgramAddDescription,
    ProgramAddMaterial,
    CourseAssign,
    ProgramEvents,
    StudentsList,
    StudentsToAssign,
    ProgramAddTesting
  },
  async created() {
    await this.updateCourse(this.msg);
    await this.updateEvents({
      page: 1,
      course_id: this.msg
    });
    await this.updateStudentsList({
      page: 1,
      filters: {
        course_id: this.msg,
        confirmed: true
      }
    });
    await this.updateStudentsToAss({
      page: 1,
      filters: {
        course_id: this.msg,
        confirmed: false
      }
    });
    await this.updateDepartments();
    await this.updateEducationQuest({
      page: 1,
      id: this.msg
    });
  },
  data: () => ({
    links,
    statesEducProg,
    breadcrumbs: [{
      text: "Обучение",
      to: {
        name: "Education"
      }
    }]
  }),
  methods: {
    ...mapActions("education", ["updateCourse", "changeState", "updateEvents", "updateStudentsList", "updateStudentsToAss", "updateEducationQuest"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    async clickChangeState(state) {
      await this.changeState({
        id: this.msg,
        state: state
      });
      await this.updateCourse(this.msg); // обновить курс (возможно он уже недоступен для редактирования)
    }
  },
  computed: {
    ...mapGetters("education", ["getCourse", "courseLoading", "courseEventsCount", "studentsListCount", "studentsToAssCount", "educationQuestCount"]),
    //issues/360
    // Необходимо сделать функционал тестирования не обязательным при:
    // внешнем обучении (онлайн и очно)
    // внутреннем обучении (очно)
    // для онлайн обучения должны быть материалы задача #390
    showBtn2Submitted() {
      return this.getCourse.format === "online" && this.getCourse.materials.length > 0 && (this.getCourse.max_attempts > 0 && this.educationQuestCount > 0 || this.getCourse.edu_type !== "internal") || this.getCourse.format === "offline";
    },
    statePublished() {
      return statesEducProg[this.getCourse.state] === statesEducProg.published;
    },
    stateSubmitted() {
      return statesEducProg[this.getCourse.state] === statesEducProg.submitted;
    },
    stateArchived() {
      return statesEducProg[this.getCourse.state] === statesEducProg.archived;
    }
  }
};