import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: ""
};
const _hoisted_2 = {
  class: "table-header"
};
const _hoisted_3 = {
  class: "text-center text-primary my-2"
};
const _hoisted_4 = {
  class: "table-footer"
};
const _hoisted_5 = {
  class: "mb-2"
};
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { statesEducProg } from "@/helpers/states";
import links from "@/router/links";
import { limitBound, optionsDesc, CONST_NONAME, optionsEducFormat } from "@/helpers/text";
import CourseSidebar from "./CourseSidebar.vue";
import thePersona from "@/components/Helpers/thePersona";
const perPage = 20;
export default {
  __name: 'EducationProgramInner',
  setup(__props) {
    const store = useStore();
    const currentItem = ref({});
    const showModalRemove = ref(false);
    const showSidebar = ref(false);
    const currentPage = ref(1);
    const sortBy = ref(null);
    const sortDesc = ref(true);
    const filters = ref({
      edu_type: "internal",
      course_format: null,
      start_date_gte: null,
      end_date_lte: null,
      owner_department_id: null,
      instructor_search: null
    });
    const optionsSort = ref([{
      value: "owner_department_name",
      text: "по владельцу"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "start_date",
      text: "по дате начала"
    }, {
      value: "end_date",
      text: "по дате окончания"
    }, {
      value: "instructor",
      text: "по тренеру"
    }]);
    const tableFields = ref([{
      key: "index",
      label: "№ п/п",
      class: "text-nowrap"
    }, {
      key: "id",
      label: "UID",
      class: "font-mono"
    }, {
      key: "owner_department_name",
      label: "Подразделение",
      class: "text-nowrap"
    }, {
      key: "title",
      label: "Наименование"
    }, {
      key: "start_dateT",
      label: "Дата начала (план)",
      class: "text-nowrap font-mono"
    }, {
      key: "end_dateT",
      label: "Дата завершения (план)",
      class: "text-nowrap font-mono"
    }, {
      key: "formatT",
      label: "Формат"
    }, {
      key: "instructor",
      label: "Исполнитель",
      class: "text-nowrap"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "pass",
      label: "Результат",
      class: "text-nowrap"
    }, {
      key: "btnChange",
      label: ""
    }, {
      key: "btnRemove",
      label: ""
    }]);
    const cours = computed(() => store.getters["education/cours"]);
    const coursLoading = computed(() => store.getters["education/coursLoading"]);
    const coursCount = computed(() => store.getters["education/coursCount"]);
    const items4table = computed(() => {
      return Array.isArray(cours.value) ? cours.value.map(el => ({
        ...el,
        formatT: optionsEducFormat.find(x => x.value === el.format)?.text,
        start_dateT: formatDate(el.start_date, fmtDateShort),
        end_dateT: formatDate(el.end_date, fmtDateShort),
        stateT: statesEducProg[el.state]
      })) : [];
    });
    const updateCourses = params => {
      store.dispatch("education/updateCourses", params);
    };
    const deleteCourse = item => {
      store.dispatch("education/deleteCourse", item);
    };
    async function sortChanged({
      sortBy,
      sortDesc
    }) {
      currentPage.value = 1;
      updateCourses({
        sortBy,
        sortDesc: sortDesc ? "-" : "",
        filters: filters.value,
        page: 1,
        pageSize: perPage
      });
    }
    const changePage = newPage => {
      currentPage.value = limitBound(Number(newPage), 1, coursCount.value / perPage, currentPage.value);
      updateCourses({
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        page: currentPage.value,
        pageSize: perPage
      });
    };
    const applyFilters = async newFilters => {
      filters.value.course_format = newFilters.format;
      filters.value.start_date_gte = formatDateObj(newFilters.dateFrom, fmtDate, null);
      filters.value.end_date_lte = formatDateObj(newFilters.dateUntill, fmtDate, null);
      filters.value.owner_department_id = newFilters.owner;
      filters.value.instructor_search = newFilters.instructor;
      filters.value.is_new = newFilters.new;
      if (currentPage.value === 1) updateCourses({
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        page: 1,
        pageSize: perPage
      });else currentPage.value = 1;
    };
    function clickRemove(item) {
      currentItem.value = item;
      showModalRemove.value = true;
    }
    async function doRemove() {
      deleteCourse(currentItem.value);
      currentPage.value = 1;
      updateCourses({
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        page: 1,
        pageSize: perPage
      });
    }
    async function searchChanged(search) {
      currentPage.value = 1;
      updateCourses({
        page: currentPage.value,
        searchString: search,
        filters: filters.value,
        pageSize: perPage
      });
    }
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_FilterButton = _resolveComponent("FilterButton");
      const _component_b_row = _resolveComponent("b-row");
      const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_container = _resolveComponent("b-container");
      const _component_b_offcanvas = _resolveComponent("b-offcanvas");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSearch, {
            onOnSearch: searchChanged
          })]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "5"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSort, {
            optionsSort: optionsSort.value,
            optionsDesc: _unref(optionsDesc),
            onOnSort: sortChanged
          }, null, 8, ["optionsSort", "optionsDesc"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "me-auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_FilterButton, {
            onClick: _cache[0] || (_cache[0] = $event => showSidebar.value = true)
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_table, {
        class: "table-custom mb-0",
        hover: "",
        items: items4table.value,
        fields: tableFields.value,
        stacked: "lg",
        small: "",
        responsive: "",
        "show-empty": "",
        busy: coursLoading.value
      }, {
        "cell()": _withCtx(row => [_createElementVNode("span", {
          class: _normalizeClass(row.value ? '' : 'text-gray-400')
        }, _toDisplayString(row.value ? row.value : "—"), 3)]),
        "cell(id)": _withCtx(row => [_createElementVNode("span", {
          class: _normalizeClass(row.item.is_new ? 'd-inline-block font-weight-bold new-item-label' : '')
        }, _toDisplayString(row.value), 3)]),
        "cell(title)": _withCtx(row => [_createElementVNode("span", {
          class: _normalizeClass(row.item.is_new ? 'font-weight-bold' : row.value ? '' : ' text-gray-400')
        }, _toDisplayString(row.value ? row.value : "—"), 3)]),
        "cell(btnChange)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_router_link, {
          to: _unref(links).educationProgramAdd + row.item.id,
          class: "p-0 border-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: "edit",
            "fixed-width": ""
          })]),
          _: 2
        }, 1032, ["to"])), [[_directive_can, _ctx.$perm.change_wa_course]])]),
        "cell(btnRemove)": _withCtx(row => [_createVNode(_component_b_button, {
          variant: "link",
          onClick: $event => clickRemove(row.item),
          class: "p-0 border-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: "trash-alt",
            "fixed-width": ""
          })]),
          _: 2
        }, 1032, ["onClick"])]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
          _: 1
        })]),
        "cell(instructor)": _withCtx(row => [_createVNode(_unref(thePersona), {
          firstname: row.item.internal_instructor_first_name,
          lastname: row.item.internal_instructor_last_name,
          middlename: row.item.internal_instructor_middle_name,
          target: 'user-' + row.item.id
        }, null, 8, ["firstname", "lastname", "middlename", "target"])]),
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
        _: 1
      }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            onOnChangePage: changePage,
            totalRows: coursCount.value
          }, null, 8, ["totalRows"]), [[_vShow, coursCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_offcanvas, {
        modelValue: showSidebar.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showSidebar.value = $event),
        placement: "end",
        "no-header": "",
        backdrop: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-2"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_cache[6] || (_cache[6] = _createElementVNode("h5", {
            class: "d-inline"
          }, "Фильтровать обучение", -1)), _createElementVNode("button", {
            type: "button",
            class: "btn-close float-end",
            "aria-label": "Close",
            onClick: _cache[1] || (_cache[1] = $event => showSidebar.value = false)
          })]), _createVNode(CourseSidebar, {
            onEmitApplyFilters: applyFilters
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_b_modal, {
        title: `Удалить ${currentItem.value.title || _unref(CONST_NONAME)}?`,
        modelValue: showModalRemove.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => showModalRemove.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Удалить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doRemove
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};