import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { optionsIpSelfemployed, profileReadonly } from "@/helpers/profile";
import { ogrnipMask } from "@/helpers/masks";
export default {
  name: "theNineteenth",
  data: () => ({
    ogrnipMask,
    optionsIpSelfemployed,
    ip_selfemployed_state: null,
    ogrnip: null,
    ogrnip_state: null,
    profileObjSaved: {} // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
  }),
  created() {
    this.ogrnip = this.profileObj.ogrnip;
    this.profileObjSaved.ogrnip = this.profileObj.ogrnip;
    this.profileObjSaved.ip_selfemployed = this.profileObj.ip_selfemployed;
  },
  methods: {
    ...mapActions("profile", ["updateProfile"]),
    ...mapActions(["showNotify"]),
    submitData(key) {
      console.log(1, key);
      let value = this.profileObj[key];
      if (key === "ogrnip") {
        value = this.ogrnip;
      }
      if (this.profileObjSaved[key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      console.log(this.ogrnip_state);
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        this.profileObjSaved[key] = this.profileObj[key];
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  },
  watch: {
    ogrnip(val) {
      this.ogrnip = ogrnipMask(val);
    }
  }
};