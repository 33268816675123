import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_2 = {
  key: 1,
  class: "table-responsive my-2"
};
const _hoisted_3 = {
  class: "table table-striped"
};
import InnerCheckFormAttachItem from "./InnerCheckFormAttachItem.vue";
import { computed, ref, toRefs, onMounted } from "vue";
import { useStore } from "vuex";
import { limitBound } from "@/helpers/text.js";
import { statesInnerCheck } from "@/helpers/states";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";

//props
const perPage = 10;
export default {
  __name: 'InnerCheckFormAttachments',
  props: {
    msg: String
  },
  setup(__props) {
    const props = __props;
    const {
      msg
    } = toRefs(props);

    // store
    const store = useStore();
    const innerCheckForm = computed(() => store.getters["innerCheck/innerCheckForm"]);
    const innerCheckFormLoading = computed(() => store.getters["innerCheck/innerCheckFormLoading"]);
    const icAttachList = computed(() => store.getters["innerCheck/icAttachList"]);
    const icAttachListCount = computed(() => store.getters["innerCheck/icAttachListCount"]);
    const icAttachListLoading = computed(() => store.getters["innerCheck/icAttachListLoading"]);
    function updateList() {
      store.dispatch("innerCheck/updateICAttachList", {
        params: {
          page: currentPage.value
        },
        ICId: msg.value
      });
    }
    onMounted(() => {
      updateList();
    });

    // pagination
    const gotoPage = ref(1);
    const currentPage = ref(1);
    const currentPageModel = computed({
      get: () => currentPage.value,
      set: value => {
        currentPage.value = value;
        updateList();
      }
    });
    function changePage() {
      currentPage.value = limitBound(Number(gotoPage.value), 1, icAttachListCount.value / perPage, currentPage.value);
      updateList();
    }
    const readOnly = computed(() => statesInnerCheck[innerCheckForm.value.state] !== statesInnerCheck.draft || innerCheckFormLoading.value);

    // remove
    const currentId = ref(null);
    const currentName = ref("");
    const showModalRemove = ref(false);
    function clickRemove(id, name) {
      currentId.value = id;
      currentName.value = name;
      showModalRemove.value = true;
    }
    async function doRemove() {
      await store.dispatch("innerCheck/deleteICAttach", {
        attachId: currentId.value,
        ICId: msg.value
      });
      currentPage.value = 1;
      updateList();
    }
    return (_ctx, _cache) => {
      const _component_b_pagination = _resolveComponent("b-pagination");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_form_input = _resolveComponent("b-form-input");
      const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_input_group_append = _resolveComponent("b-input-group-append");
      const _component_b_input_group = _resolveComponent("b-input-group");
      const _component_b_form_row = _resolveComponent("b-form-row");
      const _component_b_card_header = _resolveComponent("b-card-header");
      const _component_b_link = _resolveComponent("b-link");
      const _component_b_modal = _resolveComponent("b-modal");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_card_header, {
        "header-class": "py-0 m-0",
        "no-body": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_row, {
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "auto"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
              modelValue: currentPageModel.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => currentPageModel.value = $event),
              "total-rows": icAttachListCount.value,
              "per-page": perPage,
              size: "sm",
              "first-number": "",
              "last-number": "",
              "hide-goto-end-buttons": ""
            }, null, 8, ["modelValue", "total-rows"]), [[_vShow, icAttachListCount.value > perPage]])]),
            _: 1
          }), _createVNode(_component_b_col, {
            cols: "auto",
            class: "m-0 p-0"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
              prepend: "Перейти к",
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_form_input, {
                modelValue: gotoPage.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => gotoPage.value = $event),
                style: {
                  "width": "3em"
                },
                oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
              }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
                default: _withCtx(() => [_createVNode(_component_b_button, {
                  variant: "outline-primary-muted",
                  onClick: changePage,
                  size: "sm"
                }, {
                  default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                    icon: ['far', 'arrow-alt-circle-right'],
                    "fixed-width": ""
                  })]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            }, 512), [[_vShow, icAttachListCount.value > perPage]])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), icAttachListLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[3] || (_cache[3] = [_createElementVNode("span", {
        class: "visually-hidden"
      }, "Загрузка...", -1)]))) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("table", _hoisted_3, [_cache[5] || (_cache[5] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "#"), _createElementVNode("th", null, "Основание"), _createElementVNode("th", null, "Описание"), _createElementVNode("th", null, "Создано"), _createElementVNode("th"), _createElementVNode("th")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(icAttachList.value, (el, index) => {
        return _openBlock(), _createElementBlock("tr", {
          key: index
        }, [_createElementVNode("td", null, _toDisplayString(el.id), 1), _createElementVNode("td", null, [_createVNode(_component_b_link, {
          href: el.document,
          download: el.file_name,
          target: "_blank"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(el.file_name), 1)]),
          _: 2
        }, 1032, ["href", "download"])]), _createElementVNode("td", null, _toDisplayString(el.description), 1), _createElementVNode("td", null, _toDisplayString(_unref(formatDate)(el.created_at, _unref(fmtDateMonthTime))), 1), _createElementVNode("td", null, [_createVNode(InnerCheckFormAttachItem, {
          ICId: _unref(msg),
          item: el,
          addNew: false,
          disabled: readOnly.value,
          onUpdateData: updateList
        }, null, 8, ["ICId", "item", "disabled"])]), _createElementVNode("td", null, [_createVNode(_component_b_button, {
          variant: "link",
          onClick: $event => clickRemove(el.id, el.file_name),
          class: "p-0",
          disabled: readOnly.value
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
            class: "bi bi-trash"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick", "disabled"])])]);
      }), 128))])])])), _createElementVNode("div", null, [_createVNode(InnerCheckFormAttachItem, {
        ICId: _unref(msg),
        item: {
          id: 'new'
        },
        addNew: true,
        disabled: readOnly.value,
        onUpdateData: updateList
      }, null, 8, ["ICId", "disabled"])]), _createVNode(_component_b_modal, {
        title: `Удалить основание - ${currentName.value}?`,
        modelValue: showModalRemove.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showModalRemove.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Удалить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doRemove
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};