import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "awards"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  colspan: "5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "12. Какие Вы имеете награды (государственные, ведомственные и иные официальные)?"
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Наименование награды"), _createElementVNode("th", null, "Дата награждения"), _createElementVNode("th", null, "Кем награждены")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.govAwards, award => {
          return _openBlock(), _createElementBlock("tr", {
            key: award.id,
            class: "award-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_input, {
            modelValue: award.award_name,
            "onUpdate:modelValue": $event => award.award_name = $event,
            onChange: $event => $options.updateAward(award, 'award_name'),
            state: award.award_name_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_Calendar, {
            title: "",
            date: award.award_date,
            disabled: $options.readonly,
            classCustom: "no-gutters",
            state: award.award_date_state,
            onChangeValue: $event => $options.updateAward(award, 'award_date', $event)
          }, null, 8, ["date", "disabled", "state", "onChangeValue"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_input, {
            modelValue: award.award_medium,
            "onUpdate:modelValue": $event => award.award_medium = $event,
            onChange: $event => $options.updateAward(award, 'award_medium'),
            state: award.award_medium_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete",
            variant: "outline",
            onClick: $event => $options.removeRow(award.id)
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_5, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_6, [_createVNode(_component_AddRow, {
          onClicked: $options.addDataEndpoint
        }, null, 8, ["onClicked"])])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}