import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "parties"
};
const _hoisted_2 = {
  class: "name"
};
const _hoisted_3 = {
  class: "tin"
};
const _hoisted_4 = {
  class: "address"
};
const _hoisted_5 = {
  id: "input-list-party-address"
};
const _hoisted_6 = ["value"];
const _hoisted_7 = {
  class: "center manager"
};
const _hoisted_8 = {
  class: "center member"
};
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  colspan: "5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "11. Участие в деятельности политических партий, политических объединений"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_political_parties,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_political_parties = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: $options.submitData,
          state: _ctx.has_political_parties_state,
          class: "w-auto"
        }, null, 8, ["modelValue", "options", "disabled", "onChange", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _withDirectives(_createVNode(_component_b_form_row, {
    class: "mt-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[4] || (_cache[4] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", {
          rowspan: "2"
        }, " Наименование партии, политического объединения "), _createElementVNode("th", {
          rowspan: "2"
        }, "ИНН"), _createElementVNode("th", {
          rowspan: "2"
        }, "Адрес юридического лица"), _createElementVNode("th", {
          colspan: "2"
        }, "Характер участия в деятельности")]), _createElementVNode("tr", null, [_createElementVNode("th", null, "Вхождение в состав органа управления"), _createElementVNode("th", null, "Рядовой член")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parties, party => {
          return _openBlock(), _createElementBlock("tr", {
            key: party.id,
            class: "party-record"
          }, [_createElementVNode("td", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("small", null, "Организационно-правовая форма", -1)), _createVNode(_component_b_form_input, {
            modelValue: party.organization_form,
            "onUpdate:modelValue": $event => party.organization_form = $event,
            onChange: $event => $options.submitDataEndpoint(party, 'organization_form'),
            state: party.organization_form_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _cache[2] || (_cache[2] = _createElementVNode("small", null, "Наименование", -1)), _createVNode(_component_b_form_input, {
            modelValue: party.org_name,
            "onUpdate:modelValue": $event => party.org_name = $event,
            onChange: $event => $options.submitDataEndpoint(party, 'org_name'),
            state: party.org_name_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
            modelValue: party.inn,
            "onUpdate:modelValue": $event => party.inn = $event,
            onChange: $event => $options.submitDataEndpoint(party, 'inn'),
            state: party.inn_state,
            disabled: $options.readonly,
            type: "number",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_group, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: party.address,
              "onUpdate:modelValue": $event => party.address = $event,
              onChange: $event => $options.submitDataEndpoint(party, 'address'),
              state: party.address_state,
              disabled: $options.readonly,
              trim: "",
              list: "input-list-party-address",
              onInput: $event => $options.inputData(party.address)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled", "onInput"]), _createElementVNode("datalist", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataAddress, (address, index) => {
              return _openBlock(), _createElementBlock("option", {
                key: index,
                value: address
              }, _toDisplayString(address), 9, _hoisted_6);
            }), 128))])]),
            _: 2
          }, 1024)]), _createElementVNode("td", _hoisted_7, [_createVNode(_component_b_form_select, {
            modelValue: party.is_board_member,
            "onUpdate:modelValue": $event => party.is_board_member = $event,
            options: _ctx.optionYesNo,
            disabled: $options.readonly,
            onChange: $event => $options.submitDataEndpoint(party, 'is_board_member'),
            state: party.is_board_member_state
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled", "onChange", "state"])]), _createElementVNode("td", _hoisted_8, [_createVNode(_component_b_form_select, {
            modelValue: party.is_common_member,
            "onUpdate:modelValue": $event => party.is_common_member = $event,
            options: _ctx.optionYesNo,
            disabled: $options.readonly,
            onChange: $event => $options.submitDataEndpoint(party, 'is_common_member'),
            state: party.is_common_member_state
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled", "onChange", "state"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(party.id)
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_9, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_10, [_createVNode(_component_AddRow, {
          onClicked: $options.addDataEndpoint
        }, null, 8, ["onClicked"])])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.profileObj.has_political_parties]])]);
}