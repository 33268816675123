import "core-js/modules/es.array.push.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification.vue";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
export default {
  name: "RiskArea",
  components: {
    BreadCrumbToHome,
    theNotification
  },
  async created() {
    this.updateRisk(); // administration
    this.setMatrixPage(1);
    this.setRisksPage(1);
    this.updateMatrix();
    this.updateRisks();
  },
  data: () => ({
    links
  }),
  methods: {
    ...mapActions("risk", ["updateMatrix", "updateRisks", "createMatrix", "setMatrixPage", "setRisksPage", "createRisk"]),
    ...mapActions("administration", ["updateRisk"]),
    async clickCreateMatrix() {
      await this.createMatrix();
      if (this.matrixForm.id) this.$router.push(links.matrixForm + this.matrixForm.id);
    },
    async clickCreateRisk() {
      await this.createRisk();
      if (this.riskForm.id) this.$router.push(links.riskForm + this.riskForm.id);
    }
  },
  computed: {
    ...mapGetters("risk", ["matrixCount", "matrixForm", "risksCount", "riskForm", "riskYear"])
  }
};