import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "ms-2"
};
const _hoisted_2 = {
  class: "ms-2"
};
const _hoisted_3 = {
  class: "ms-2"
};
const _hoisted_4 = {
  class: "ms-2"
};
const _hoisted_5 = {
  class: "ms-2"
};
const _hoisted_6 = {
  class: "mb-2"
};
const _hoisted_7 = {
  class: "ms-2"
};
const _hoisted_8 = {
  class: "ms-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_KnowledgeAssign = _resolveComponent("KnowledgeAssign");
  const _component_b_button = _resolveComponent("b-button");
  const _component_TaskButton = _resolveComponent("TaskButton");
  const _component_b_row = _resolveComponent("b-row");
  const _component_KnowledgeFormDescription = _resolveComponent("KnowledgeFormDescription");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_WorkersToAssign = _resolveComponent("WorkersToAssign");
  const _component_WorkersList = _resolveComponent("WorkersList");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: $options.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      class: "h1"
    }, {
      default: _withCtx(() => [_createTextVNode(" Документ #" + _toDisplayString(_ctx.knowlegeDocument.id), 1)]),
      _: 1
    }), $options.readOnly ? (_openBlock(), _createBlock(_component_b_col, {
      key: 0,
      class: "text-center text-primary my-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Загрузка...", -1))]),
      _: 1
    })) : _createCommentVNode("", true), _ctx.statesKnowledge[_ctx.knowlegeDocument.state] === _ctx.statesKnowledge.published && $options.useAppoint ? _withDirectives((_openBlock(), _createBlock(_component_b_col, {
      key: 1,
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_KnowledgeAssign)]),
      _: 1
    })), [[_directive_can, _ctx.$perm.assign_wa_libmaterial]]) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.stateBtn, (btn, index) => {
      return _openBlock(), _createBlock(_component_b_col, {
        cols: "auto",
        key: index
      }, {
        default: _withCtx(() => [btn.type === 'simple' ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          variant: btn.variant,
          onClick: $event => $options.clickAction(btn, {}),
          disabled: $options.readOnly
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(btn.text), 1)]),
          _: 2
        }, 1032, ["variant", "onClick", "disabled"])), [[_directive_can, btn.perm]]) : _createCommentVNode("", true), btn.type === 'taskBtn' ? _withDirectives((_openBlock(), _createBlock(_component_TaskButton, {
          key: 1,
          idModal: btn.modal,
          settings: btn.settings,
          onAddData: $event => $options.clickAction(btn, $event),
          disabled: $options.readOnly
        }, null, 8, ["idModal", "settings", "onAddData", "disabled"])), [[_directive_can, btn.perm]]) : _createCommentVNode("", true)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }), _createElementVNode("div", null, [_cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "text-muted me-2"
  }, "Статус ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.statesKnowledge[_ctx.knowlegeDocument.state]), 1)]), _createElementVNode("div", null, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "text-muted"
  }, "Создано", -1)), _createElementVNode("span", _hoisted_1, _toDisplayString($options.formatDate(_ctx.knowlegeDocument.created_at, _ctx.fmtDateShort)), 1), _cache[3] || (_cache[3] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Изменено", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString($options.formatDate(_ctx.knowlegeDocument.state_modified_at, _ctx.fmtDateShort)), 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Направлено на рассмотрение", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString($options.formatDate(_ctx.knowlegeDocument.submitted_at, _ctx.fmtDateShort)), 1), _cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Опубликовано", -1)), _createElementVNode("span", _hoisted_4, _toDisplayString($options.formatDate(_ctx.knowlegeDocument.published_at, _ctx.fmtDateShort)), 1), _cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Архивировано", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString($options.formatDate(_ctx.knowlegeDocument.archived_at, _ctx.fmtDateShort)), 1)]), _createElementVNode("div", _hoisted_6, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "text-muted"
  }, "Ответственный", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString($options.formatName(_ctx.knowlegeDocument.responsible_detail, _ctx.fmtNameShort)), 1), _cache[8] || (_cache[8] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Срок ознакомления", -1)), _createElementVNode("span", _hoisted_8, _toDisplayString($options.formatDate(_ctx.knowlegeDocument.deadline, _ctx.fmtDateShort)), 1)]), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        title: "Карточка документа"
      }, {
        default: _withCtx(() => [!_ctx.knowlegeDocumentLoading ? (_openBlock(), _createBlock(_component_b_card_text, {
          key: 0
        }, {
          default: _withCtx(() => [_createVNode(_component_KnowledgeFormDescription)]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }), _ctx.statesKnowledge[_ctx.knowlegeDocument.state] === _ctx.statesKnowledge.published && $options.useAppoint ? (_openBlock(), _createBlock(_component_b_tab, {
        key: 0
      }, {
        title: _withCtx(() => [_cache[9] || (_cache[9] = _createTextVNode(" Список к назначению ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.workersToAssCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_WorkersToAssign)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true), $options.useAppoint ? (_openBlock(), _createBlock(_component_b_tab, {
        key: 1
      }, {
        title: _withCtx(() => [_cache[10] || (_cache[10] = _createTextVNode(" Лист ознакомления ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.workersListCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_WorkersList)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  })])), [[_directive_can, _ctx.$perm.view_wa_libmaterial]]);
}