import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { formatDateObj, fmtDate, formatObjDate, dateObjInit } from "@/helpers/date";
import Calendar from "@/components/Helpers/Calendar.vue";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "GovAwards",
  components: {
    Calendar,
    AddRow
  },
  data: () => ({
    optionYesNo,
    govAwards: [],
    // для разметки
    template: {
      award_name: null,
      award_date: {
        ...dateObjInit
      },
      award_medium: null
    },
    states: {
      award_name_state: null,
      award_date_state: null,
      award_medium_state: null
    },
    govAwardsSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    endpoint: "government-awards",
    // endpoint
    hasAwardsState: null
  }),
  created() {
    this.govAwards = this.profileObj.government_awards ? this.profileObj.government_awards.map(award => ({
      ...award,
      award_name_state: null,
      award_date_state: null,
      award_medium_state: null,
      award_date: formatObjDate(award.award_date)
    })) : [];
    this.govAwardsSaved = this.profileObj.government_awards ? this.profileObj.government_awards.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitData() {
      this.hasAwardsState = false;
      this.updateProfile({
        id: this.profileObj.id,
        has_government_awards: this.profileObj.has_government_awards
      }).then(() => {
        setValueTimeout(this, "hasAwardsState");
      });
    },
    updateAward(award, key, date) {
      const value = ["award_date"].includes(key) ? formatDateObj(date, fmtDate, null) : award[key];
      if (this.govAwardsSaved[award.id][key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      award[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: award.id,
        params: {
          [key]: value
        },
        endpoint: this.endpoint
      }).then(response => {
        this.govAwardsSaved[response.id] = response;
        setValueTimeout(award, state);
      });
    },
    addDataEndpoint() {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        this.govAwardsSaved[response.id] = {
          ...this.template
        };
        this.govAwards.push({
          id: response.id,
          ...this.states,
          ...this.template
        });
      });
    },
    removeRow(id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = this.govAwards.findIndex(award => award.id === id);
        delete this.govAwardsSaved[id];
        this.govAwards.splice(index, 1);
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};