import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "overflow-hidden border-0 shadow-sm"
};
const _hoisted_2 = {
  key: 1,
  class: "ms-2"
};
const _hoisted_3 = ["onClick"];
import { computed } from "vue";
import { useStore } from "vuex";
import { hitsStatus, hitGroups } from "../autochecks";
import links from "@/router/links";

// store

export default {
  __name: 'AutocheckFormList',
  setup(__props) {
    const store = useStore();
    const autochecksHitsList = computed(() => store.getters["administration2/autochecksHitsList"]);
    const autochecksHitsLoading = computed(() => store.getters["administration2/autochecksHitsLoading"]);
    const tableFields = [{
      key: "hit_status",
      label: "Статус",
      formatter: value => hitsStatus[value]
    }, {
      key: "hit_group",
      label: "Субъект",
      formatter: value => value ? hitGroups[value] : "-"
    }, {
      key: "secundum_attribute",
      label: "Информация"
    }, {
      key: "tertium_value",
      label: "Совпавшее значение"
    }, {
      key: "secundum_ids",
      label: "Существующая сущность"
    }, {
      key: "expand",
      label: "Развернуть",
      class: "text-center"
    }];
    function getLink(type, id) {
      switch (type) {
        case "Деловой партнер":
          return links.partnersForm + id;
        case "ЭДКИ":
          return links.declarationForm + id;
        case "Конфликт интересов":
          return links.coiForm + id;
        case "Личный листок":
          return links.profileCard + JSON.stringify({
            profileId: id,
            myProfile: false
          }) + "/print";
        default:
          break;
      }
    }
    return (_ctx, _cache) => {
      const _component_b_link = _resolveComponent("b-link");
      const _component_b_table = _resolveComponent("b-table");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        small: "",
        responsive: "",
        stacked: "md",
        busy: autochecksHitsLoading.value,
        items: autochecksHitsList.value,
        fields: tableFields,
        "show-empty": ""
      }, {
        "cell(secundum_ids)": _withCtx(row => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.secundum_ids, (id, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, [index === 0 || row.item.showDetails ? (_openBlock(), _createBlock(_component_b_link, {
            key: 0,
            to: getLink(row.item.secundum, id)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(row.item.secundum) + " #" + _toDisplayString(id), 1)]),
            _: 2
          }, 1032, ["to"])) : _createCommentVNode("", true), index === 0 && !row.item.showDetails && row.item.secundum_ids.length > 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2, "...")) : _createCommentVNode("", true)]);
        }), 128))]),
        "cell(expand)": _withCtx(row => [row.item.secundum_ids.length > 1 ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(["bi text-primary", row.item.showDetails ? 'bi-chevron-up' : 'bi-chevron-down']),
          role: "button",
          onClick: $event => row.item.showDetails = !row.item.showDetails
        }, null, 10, _hoisted_3)) : _createCommentVNode("", true)]),
        empty: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h5", {
          class: "text-center"
        }, "Данных нет", -1)])),
        _: 1
      }, 8, ["busy", "items"])]);
    };
  }
};