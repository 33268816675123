import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "ms-2"
};
const _hoisted_6 = {
  class: "ms-2"
};
const _hoisted_7 = {
  class: "ms-2"
};
const _hoisted_8 = {
  class: "ms-2"
};
const _hoisted_9 = {
  class: "ms-2"
};
const _hoisted_10 = {
  class: "ms-2"
};
const _hoisted_11 = {
  class: "ms-2"
};
const _hoisted_12 = {
  class: "ms-2"
};
const _hoisted_13 = {
  class: "ms-2"
};
const _hoisted_14 = {
  class: "ms-2"
};
const _hoisted_15 = {
  class: "ms-2"
};
const _hoisted_16 = {
  class: "ms-2"
};
const _hoisted_17 = {
  class: "ms-2"
};
const _hoisted_18 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_19 = {
  key: 1,
  class: "mx-3"
};
const _hoisted_20 = {
  class: "mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_FilterButton = _resolveComponent("FilterButton");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_modal = _resolveComponent("b-modal");
  const _component_b_row = _resolveComponent("b-row");
  const _component_SecurityEventsSidebar = _resolveComponent("SecurityEventsSidebar");
  const _component_b_container = _resolveComponent("b-container");
  const _component_b_offcanvas = _resolveComponent("b-offcanvas");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _cache[25] || (_cache[25] = _createElementVNode("div", {
    class: "d-flex"
  }, [_createElementVNode("h1", {
    class: "mb-4 me-auto"
  }, "Журнал безопасности")], -1)), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm py-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      "header-class": "py-0 m-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "4",
          xl: "2",
          class: "ps-0 mb-3"
        }, {
          default: _withCtx(() => [_createVNode(_component_FilterButton, {
            onClick: _cache[0] || (_cache[0] = $event => _ctx.showSidebar = true)
          })]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.securityEventsCount,
            "per-page": _ctx.perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.securityEventsCount > _ctx.perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.gotoPage,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.gotoPage = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: $options.changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 512), [[_vShow, _ctx.securityEventsCount > _ctx.perPage]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_table, {
      class: "table-custom",
      responsive: "",
      hover: "",
      small: "",
      "show-empty": "",
      stacked: "md",
      items: $options.items4table,
      fields: _ctx.tableFields,
      busy: _ctx.securityEventsLoading
    }, {
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      empty: _withCtx(() => [_createVNode(_component_b_alert, {
        show: "",
        variant: "light",
        class: "text-center"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "empty",
          class: "mb-4"
        }, null, -1), _createElementVNode("h4", null, "Событий нет", -1)])),
        _: 1
      })]),
      "cell()": _withCtx(row => [_createElementVNode("span", {
        role: "button",
        onClick: $event => $options.clickItem(row.item.id)
      }, _toDisplayString(row.value), 9, _hoisted_2)]),
      _: 1
    }, 8, ["items", "fields", "busy"])]),
    _: 1
  }), _createVNode(_component_b_modal, {
    id: "securityEventsItemModal",
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModal = $event),
    title: `Событие журнала безопасности #${_ctx.securityEventsItem.id}`,
    size: "lg",
    centered: "",
    "ok-only": "",
    "ok-title": "Закрыть",
    "ok-variant": "outline-primary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_ctx.securityEventsItemLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[8] || (_cache[8] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", null, [_cache[9] || (_cache[9] = _createElementVNode("span", {
      class: "text-muted"
    }, "Событие", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.securityEventsItem.event_verbose), 1)]), _createElementVNode("div", null, [_cache[10] || (_cache[10] = _createElementVNode("span", {
      class: "text-muted"
    }, "Дата и время события", -1)), _createElementVNode("span", _hoisted_6, _toDisplayString($options.formatDate(_ctx.securityEventsItem.timestamp, _ctx.fmtDateLong)), 1)]), _createElementVNode("div", null, [_cache[11] || (_cache[11] = _createElementVNode("span", {
      class: "text-muted"
    }, "IP", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.securityEventsItem.ip_address), 1)]), _createElementVNode("div", null, [_cache[12] || (_cache[12] = _createElementVNode("span", {
      class: "text-muted"
    }, "Пользователь, выполнившей операцию", -1)), _createElementVNode("span", _hoisted_8, _toDisplayString($options.formatName(_ctx.securityEventsItem.user_detail, _ctx.fmtNameFull)), 1)]), _createElementVNode("div", null, _toDisplayString(_ctx.securityEventsItem.user_detail.login), 1), _createElementVNode("div", null, [_cache[13] || (_cache[13] = _createElementVNode("span", {
      class: "text-muted"
    }, "Пользователь, для которого выполнена операция", -1)), _createElementVNode("span", _hoisted_9, _toDisplayString($options.formatName(_ctx.securityEventsItem.target_user_detail, _ctx.fmtNameFull)), 1)]), _createElementVNode("div", null, _toDisplayString(_ctx.securityEventsItem.target_user_detail.login), 1), _createElementVNode("div", null, [_cache[14] || (_cache[14] = _createElementVNode("span", {
      class: "text-muted"
    }, "Результат операции", -1)), _createElementVNode("span", _hoisted_10, _toDisplayString($options.switchText(_ctx.securityEventsItem.is_success, _ctx.results)), 1)]), _createElementVNode("div", null, [_cache[15] || (_cache[15] = _createElementVNode("span", {
      class: "text-muted"
    }, "Идентификатор сеанса", -1)), _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.securityEventsItem.session_id), 1)]), _withDirectives((_openBlock(), _createElementBlock("div", null, [_cache[16] || (_cache[16] = _createElementVNode("span", {
      class: "text-muted"
    }, "Организация", -1)), _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.securityEventsItem.organization_name), 1)])), [[_directive_can, _ctx.$perm.list_orgs]]), _withDirectives(_createElementVNode("div", null, [_cache[17] || (_cache[17] = _createElementVNode("span", {
      class: "text-muted"
    }, "Причина неудачи операции", -1)), _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.securityEventsItem.fail_reason), 1)], 512), [[_vShow, _ctx.securityEventsItem.fail_reason]]), _withDirectives(_createElementVNode("div", null, [_cache[18] || (_cache[18] = _createElementVNode("span", {
      class: "text-muted"
    }, "Предполагаемый логин", -1)), _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.securityEventsItem.supposed_email), 1)], 512), [[_vShow, _ctx.securityEventsItem.supposed_email]]), _createElementVNode("div", null, [_cache[19] || (_cache[19] = _createElementVNode("span", {
      class: "text-muted"
    }, "Старые роли пользователя", -1)), _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.securityEventsItem.old_roles.join(", ")), 1)]), _createElementVNode("div", null, [_cache[20] || (_cache[20] = _createElementVNode("span", {
      class: "text-muted"
    }, "Текущие роли пользователя", -1)), _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.securityEventsItem.current_roles.join(", ")), 1)]), _createElementVNode("div", null, [_cache[21] || (_cache[21] = _createElementVNode("span", {
      class: "text-muted"
    }, "Примечание", -1)), _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.securityEventsItem.extra_info), 1)])]))]),
    _: 1
  }, 8, ["modelValue", "title"]), _createVNode(_component_b_modal, {
    id: "securitySettingsModal",
    title: "Настройки безопасности",
    size: "xl",
    centered: "",
    "ok-only": "",
    "ok-title": "Закрыть",
    "ok-variant": "outline-primary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_ctx.securityGuideLoading ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(), _createElementBlock("div", _hoisted_19, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.securityGuide.table), ([key, value]) => {
      return _openBlock(), _createBlock(_component_b_row, {
        key: key
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          class: "border py-1"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(key), 1)]),
          _: 2
        }, 1024), _createVNode(_component_b_col, {
          class: "border text-center py-1"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(value), 1)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024);
    }), 128)), _cache[23] || (_cache[23] = _createElementVNode("p", null, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.securityGuide.guide, (el, index) => {
      return _openBlock(), _createElementBlock("p", {
        key: index
      }, _toDisplayString(index + 1) + ". " + _toDisplayString(el), 1);
    }), 128))]))]),
    _: 1
  }), _createVNode(_component_b_offcanvas, {
    modelValue: _ctx.showSidebar,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.showSidebar = $event),
    placement: "end",
    "no-header": "",
    backdrop: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_container, {
      class: "p-2"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_20, [_cache[24] || (_cache[24] = _createElementVNode("h5", {
        class: "d-inline"
      }, "Фильтровать события", -1)), _createElementVNode("button", {
        type: "button",
        class: "btn-close float-end",
        "aria-label": "Close",
        onClick: _cache[4] || (_cache[4] = $event => _ctx.showSidebar = false)
      })]), _createVNode(_component_SecurityEventsSidebar, {
        onEmitApplyFilters: $options.applyFilters
      }, null, 8, ["onEmitApplyFilters"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])])), [[_directive_can, _ctx.$perm.view_security_events]]);
}