import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mb-0"
};
const _hoisted_2 = {
  class: "text-muted mb-2"
};
const _hoisted_3 = {
  class: "text-muted mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_button, {
    variant: "link",
    onClick: $options.changeWorker,
    class: "border-0"
  }, {
    default: _withCtx(() => [_createElementVNode("i", {
      class: _normalizeClass(`bi bi-${$props.settings.iconType}`)
    }, null, 2)]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.showModal = $event),
    id: $props.idModal,
    title: $props.settings.title,
    centered: "",
    onOk: $options.handlerOk,
    "ok-disabled": $options.disableChange,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("p", _hoisted_1, _toDisplayString($options.formatName($props.item, _ctx.fmtNameFull)), 1), _createElementVNode("div", _hoisted_2, _toDisplayString($props.item.organization_name), 1), _withDirectives(_createElementVNode("div", null, [_cache[6] || (_cache[6] = _createElementVNode("div", {
      class: "text-danger"
    }, [_createElementVNode("i", {
      class: "bi bi-exclamation-circle-fill me-2"
    }), _createTextVNode(" У пользователя почта на небезопасном домене ")], -1)), _createElementVNode("div", _hoisted_3, _toDisplayString($props.item.login), 1)], 512), [[_vShow, !$props.item.is_safe_email]]), _createElementVNode("form", {
      ref: "form-login",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers((...args) => $options.handlerOk && $options.handlerOk(...args), ["stop", "prevent"]))
    }, [_createVNode(_component_b_form_group, {
      label: "Табельный номер",
      "label-for": "modal-code"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        id: "modal-code",
        modelValue: _ctx.code,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.code = $event),
        required: "",
        type: "text",
        placeholder: "Укажите табельный номер",
        state: _ctx.codeState,
        onInput: $options.validateCode,
        disabled: $options.disableChange,
        trim: ""
      }, null, 8, ["modelValue", "state", "onInput", "disabled"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Должность",
      "label-for": "modal-position"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        id: "modal-position",
        modelValue: _ctx.position,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.position = $event),
        required: "",
        type: "text",
        placeholder: "Укажите должность",
        state: _ctx.positionState,
        onInput: $options.validatePosition,
        disabled: $options.disableChange,
        trim: ""
      }, null, 8, ["modelValue", "state", "onInput", "disabled"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Подразделение"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_select, {
        modelValue: _ctx.department,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.department = $event),
        options: $options.departmentOptions,
        state: _ctx.departmentState,
        onChange: $options.validateDepartment,
        disabled: $options.disableChange
      }, null, 8, ["modelValue", "options", "state", "onChange", "disabled"])]),
      _: 1
    }), _createVNode(_component_Calendar, {
      title: "Дата приема на работу",
      date: _ctx.startDate,
      disabled: $options.disableChange,
      classCustom: "mt-2",
      onChangeValue: _cache[3] || (_cache[3] = $event => _ctx.startDate = $event)
    }, null, 8, ["date", "disabled"])], 544)]),
    _: 1
  }, 8, ["modelValue", "id", "title", "onOk", "ok-disabled"])]);
}