import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
  class: "h4"
};
const _hoisted_2 = {
  class: "mb-4"
};
const _hoisted_3 = {
  class: "text-primary"
};
const _hoisted_4 = {
  class: "text-success"
};
const _hoisted_5 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_6 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_button_group = _resolveComponent("b-button-group");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_card = _resolveComponent("b-card");
  const _component_CustomSort = _resolveComponent("CustomSort");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_TrafficLight = _resolveComponent("TrafficLight");
  const _component_b_table = _resolveComponent("b-table");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _cache[11] || (_cache[11] = _createElementVNode("h1", {
    class: "sticky-top align-items-center px-4 mb-2 bg-light title-shadow"
  }, " Статистика ", -1)), _createVNode(_component_b_row, {
    class: "py-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      class: "mb-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button_group, {
        class: "mx-1"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periods, (period, index) => {
          return _openBlock(), _createBlock(_component_b_button, {
            variant: period.active ? 'primary' : 'primary-light',
            class: "px-4",
            key: index,
            onClick: $event => $options.clickBtn(_ctx.periods, index, true)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(period.text), 1)]),
            _: 2
          }, 1032, ["variant", "onClick"]);
        }), 128))]),
        _: 1
      }), _createVNode(_component_b_button_group, {
        class: "mx-1"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_button, {
          variant: "primary-light",
          onClick: _cache[0] || (_cache[0] = $event => $options.setData(-1))
        }, {
          default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: "chevron-left",
            "fixed-width": ""
          })]),
          _: 1
        }), _createVNode(_component_b_button, {
          variant: "primary-light",
          class: "period-text-wrap"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($options.periodText), 1)]),
          _: 1
        }), _createVNode(_component_b_button, {
          variant: "primary-light",
          onClick: _cache[1] || (_cache[1] = $event => $options.setData(1))
        }, {
          default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: "chevron-right",
            "fixed-width": ""
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      class: "mb-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button_group, {
        class: "mx-1 btn-group-type-wrap"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types4chart, (el, index) => {
          return _openBlock(), _createBlock(_component_b_button, {
            variant: el.active ? 'primary' : 'primary-light',
            class: "text-left px-4 btn-type-wrap",
            key: index,
            onClick: $event => $options.clickBtn(_ctx.types4chart, index)
          }, {
            default: _withCtx(() => [_createElementVNode("small", null, _toDisplayString(el.text), 1), _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)), _createElementVNode("span", _hoisted_1, _toDisplayString($options.totalCount(index)), 1)]),
            _: 2
          }, 1032, ["variant", "onClick"]);
        }), 128))]),
        _: 1
      }), _createVNode(_component_b_button, {
        variant: "primary",
        class: "btn-generate",
        onClick: $options.clickReport
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Сформировать")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    class: "pb-5"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        class: "overflow-hidden border-0"
      }, {
        default: _withCtx(() => [_createElementVNode("h3", _hoisted_2, [_cache[6] || (_cache[6] = _createTextVNode(" Количество ")), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.currentChart.received), 1), _cache[7] || (_cache[7] = _createTextVNode(" и ")), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentChart.reviewed), 1), _createTextVNode(" " + _toDisplayString(_ctx.currentChart.endText), 1)]), _ctx.adminStatsDeptLoading ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[8] || (_cache[8] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _cache[9] || (_cache[9] = _createElementVNode("canvas", {
          id: "RiskChart"
        }, null, -1))]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _cache[12] || (_cache[12] = _createElementVNode("h3", {
    class: "sticky-top align-items-center px-4 mb-2 bg-light title-shadow"
  }, " Риски ", -1)), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      "header-class": "py-2 m-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "5",
          xl: "4",
          class: "me-auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSort, {
            optionsSort: _ctx.optionsSort,
            optionsDesc: _ctx.optionsDesc,
            onOnSort: $options.sortChanged
          }, null, 8, ["optionsSort", "optionsDesc", "onOnSort"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.adminStatsRiskCount,
            "per-page": _ctx.perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.adminStatsRiskCount > _ctx.perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.gotoPage,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.gotoPage = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: $options.changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 512), [[_vShow, _ctx.adminStatsRiskCount > _ctx.perPage]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_table, {
      class: "table-custom",
      responsive: "",
      hover: "",
      small: "",
      "show-empty": "",
      stacked: "md",
      items: _ctx.adminStatsRisk,
      fields: _ctx.tableFields,
      busy: _ctx.adminStatsRiskLoading,
      "empty-text": "Рисков нет"
    }, {
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[10] || (_cache[10] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + row.index + 1), 1)]),
      "cell(risk_level)": _withCtx(row => [_createVNode(_component_TrafficLight, {
        typeLight: row.value ? 'danger' : row.value === null ? row.value : 'success'
      }, null, 8, ["typeLight"])]),
      _: 1
    }, 8, ["items", "fields", "busy"])]),
    _: 1
  })])), [[_directive_can, _ctx.$perm.access_admin_orgs_stats]]);
}