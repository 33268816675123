import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "mt-4"
};
const _hoisted_2 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_AdminCalendarItem = _resolveComponent("AdminCalendarItem");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_BModal = _resolveComponent("BModal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _cache[4] || (_cache[4] = _createElementVNode("h1", {
    class: "mb-4"
  }, "Справочник «Рабочие и нерабочие дни»", -1)), !_ctx.adminCalendarLoading ? (_openBlock(), _createBlock(_component_b_card, {
    key: 0,
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm p-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.year,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.year = $event),
          options: _ctx.yearOptions,
          onChange: $options.updateYear
        }, null, 8, ["modelValue", "options", "onChange"])]),
        _: 1
      })]),
      _: 1
    }), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (quarter, index) => {
      return _createElementVNode("div", {
        key: index
      }, [_createElementVNode("h3", _hoisted_1, _toDisplayString(quarter) + " квартал", 1), _createVNode(_component_b_row, {
        class: "mt-2"
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i, index) => {
          return _createVNode(_component_b_col, {
            cols: "4",
            class: "text-center mt-4",
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_AdminCalendarItem, {
              year: String(_ctx.year),
              month: String(i + 3 * (quarter - 1)),
              onSelectDate: $options.onSelectDate
            }, null, 8, ["year", "month", "onSelectDate"])]),
            _: 2
          }, 1024);
        }), 64))]),
        _: 2
      }, 1024)]);
    }), 64))]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))])), _createVNode(_component_BModal, {
    id: "madalDateSetting",
    title: _ctx.modalTitle,
    onOk: $options.handlerOk,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    centered: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
          modelValue: _ctx.worker,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.worker = $event),
          size: "lg",
          switch: ""
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.worker ? "Рабочий день" : "Нерабочий день"), 1)]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
          modelValue: _ctx.active,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.active = $event),
          size: "lg",
          switch: ""
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.active ? "Активный" : "Неактивный"), 1)]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["title", "onOk"])]);
}