import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import theNotification from "@/components/theNotification";
import theAttachments from "@/components/Helpers/theAttachments.vue";
import theComments from "@/components/Helpers/theComments.vue";
import theHistory from "@/components/Helpers/theHistory.vue";
import COIFormInfo from "./COIFormInfo.vue";
import COIFormHeader from "./COIFormHeader.vue";
import COIFormSource from "./COIFormSource.vue";
import { mapActions, mapGetters } from "vuex";
import { states } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth.js";
import { formatName, fmtNameShort } from "@/helpers/text";
import { formatDate, fmtDateShort } from "@/helpers/date";
export default {
  name: "COIFormBase",
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  // сюда получаем id coi

  components: {
    BreadCrumbToHome,
    theNotification,
    COIFormInfo,
    COIFormHeader,
    theAttachments,
    theComments,
    theHistory,
    COIFormSource
  },
  data: () => ({
    breadcrumbs: [{
      text: "Декларации",
      to: {
        name: "Declarations"
      }
    }],
    fieldsAttach: [{
      key: "user",
      label: "Работник",
      formatter: value => formatName(value, fmtNameShort)
    }],
    eventFields: [{
      key: "index",
      label: "№"
    }, {
      key: "event",
      label: "Описание"
    }, {
      key: "last_name",
      label: "Работник"
    }, {
      key: "timestamp",
      label: "Дата",
      formatter: value => formatDate(value, fmtDateShort)
    }]
  }),
  async created() {
    this.updateCOIForm(this.msg);
    this.updateCoiDirectory();
    this.updateCOIAttach({
      id: this.msg,
      page: 1
    });
    this.updateCOIComments({
      id: this.msg,
      page: 1
    });
    this.updateCOIEvents({
      coi_id: this.msg,
      page: 1
    });
  },
  methods: {
    ...mapActions("coi", ["updateCoiDirectory", "updateCOIForm", "updateCOIAttach", "createCOIAttach", "deleteCOIAttach", "updateCOIComments", "createCOIComments", "changeCOIComments", "deleteCOIComments", "updateCOIEvents"]),
    async addAttach(file) {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("file_name", file.name);
      await this.createCOIAttach({
        id: this.msg,
        formData
      });
      this.updateCOIAttach({
        id: this.msg,
        page: 1
      });
    },
    async delAttach(idAttach) {
      await this.deleteCOIAttach({
        id: this.msg,
        idAttach
      });
      this.updateCOIAttach({
        id: this.msg,
        page: 1
      });
    },
    setPageAttach(page) {
      this.updateCOIAttach({
        id: this.msg,
        page
      });
    },
    async addComment() {
      await this.createCOIComments({
        id: this.msg,
        body: "новый комментарий"
      });
      this.updateCOIComments({
        id: this.msg,
        page: 1
      });
    },
    changeComment(data) {
      this.changeCOIComments({
        id: this.msg,
        idComment: data.id,
        body: data.name
      });
    },
    async delComment(idComment) {
      await this.deleteCOIComments({
        id: this.msg,
        idComment
      });
      this.updateCOIComments({
        id: this.msg,
        page: 1
      });
    },
    async setPageComment(page) {
      this.updateCOIComments({
        id: this.msg,
        page
      });
    },
    setPageHistory(page) {
      this.updateCOIEvents({
        coi_id: this.msg,
        page
      });
    }
  },
  computed: {
    ...mapGetters("coi", ["coiForm", "coiFormLoading", "coiLoading", "coiAttach", "coiAttachCount", "coiAttachLoading", "coiComments", "coiCommentsCount", "coiCommentsLoading", "coiEvents", "coiEventsCount", "coiEventsLoading"]),
    readOnly() {
      return states[this.coiForm.state] !== states.draft || !checkPermissions(this.$perm.add_wa_coi) || this.coiFormLoading;
    },
    getAttachments() {
      return this.coiAttach.map(el => ({
        name: el.file_name,
        id: el.id,
        date: el.created_at,
        file: el.document,
        user: el.user_detail
      }));
    },
    getComments() {
      return this.coiComments.map(el => ({
        name: el.body,
        id: el.id,
        date: el.created_at,
        user: el.user_detail
      }));
    }
  }
};