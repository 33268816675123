import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "fullnames"
};
const _hoisted_2 = {
  class: "lastname"
};
const _hoisted_3 = {
  class: "firstname"
};
const _hoisted_4 = {
  class: "middlename"
};
const _hoisted_5 = {
  colspan: "3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    label: "Предыдущие фамилия, имя, отчество (если изменялись)"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      class: "w-auto",
      modelValue: _ctx.profileObj.has_old_names,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_old_names = $event),
      disabled: $options.readonly,
      state: _ctx.has_old_names_state,
      options: _ctx.optionYesNo,
      onChange: $options.submitData
    }, null, 8, ["modelValue", "disabled", "state", "options", "onChange"])]),
    _: 1
  }), _ctx.profileObj.has_old_names ? (_openBlock(), _createBlock(_component_b_form_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Фамилия"), _createElementVNode("th", null, "Имя"), _createElementVNode("th", null, "Отчество")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fullNames, fullname => {
      return _openBlock(), _createElementBlock("tr", {
        key: fullname.id,
        class: "old-name-record"
      }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_input, {
        modelValue: fullname.last_name,
        "onUpdate:modelValue": $event => fullname.last_name = $event,
        onChange: $event => $options.submitDataEndpoint(fullname, 'last_name'),
        state: fullname.last_name_state,
        disabled: $options.readonly,
        trim: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
        modelValue: fullname.first_name,
        "onUpdate:modelValue": $event => fullname.first_name = $event,
        onChange: $event => $options.submitDataEndpoint(fullname, 'first_name'),
        state: fullname.first_name_state,
        disabled: $options.readonly,
        trim: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_input, {
        modelValue: fullname.middle_name,
        "onUpdate:modelValue": $event => fullname.middle_name = $event,
        onChange: $event => $options.submitDataEndpoint(fullname, 'middle_name'),
        state: fullname.middle_name_state,
        disabled: $options.readonly,
        trim: ""
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _withDirectives(_createVNode(_component_b_button, {
        size: "sm",
        class: "mb-2 delete",
        variant: "outline",
        onClick: $event => $options.removeDataEndpoint(fullname.id)
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
          class: "bi bi-trash-fill",
          "aria-hidden": "true"
        }, null, -1)])),
        _: 2
      }, 1032, ["onClick"]), [[_vShow, !$options.readonly]])])]);
    }), 128))]), _withDirectives(_createElementVNode("tfoot", null, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_5, [_createVNode(_component_AddRow, {
      onClicked: $options.addDataEndpoint
    }, null, 8, ["onClicked"])])])], 512), [[_vShow, !$options.readonly]])])]),
    _: 1
  })) : _createCommentVNode("", true)]);
}