import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  ref: "form-modal"
};
const _hoisted_2 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createBlock(_component_b_modal, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.modalShow = $event),
    id: "modalPEP",
    title: "Пожалуйста, подтвердите свою личность",
    centered: "",
    "no-close-on-backdrop": "",
    onOk: $options.handlerConfirm,
    onShow: $options.resetModal,
    onHidden: $options.resetModal,
    "ok-title": "Подтвердить",
    "cancel-title": "Отменить",
    "ok-variant": "primary",
    "cancel-variant": "outline-primary",
    "ok-disabled": $options.okModalDisabled
  }, {
    default: _withCtx(() => [_createElementVNode("form", _hoisted_1, [!_ctx.signLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "На почту вам отправлен проверочный код для входа. Проверьте,\n          пожалуйста, свою почту и введите данный код в поле ниже."
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        autofocus: "",
        modelValue: _ctx.code,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.code = $event),
        placeholder: "Введите код",
        trim: ""
      }, null, 8, ["modelValue"]), _ctx.sign !== _ctx.CODE_TO_EMAIL ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(_toDisplayString(_ctx.sign), 1)], 64)) : _createCommentVNode("", true)]),
      _: 1
    })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))]))], 512)]),
    _: 1
  }, 8, ["modelValue", "onOk", "onShow", "onHidden", "ok-disabled"]);
}