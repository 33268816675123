import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "px-4"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_thePersona = _resolveComponent("thePersona");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_table = _resolveComponent("b-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("h5", _hoisted_1, " Перечень работников, прошедших обучение по программе " + _toDisplayString($props.program) + " за период с " + _toDisplayString($options.formatDate($props.filters.date_start_formal_gte, _ctx.fmtDateShort)) + " по " + _toDisplayString($options.formatDate($props.filters.date_end_formal_lte, _ctx.fmtDateShort)), 1), _createVNode(_component_b_card_header, {
    "header-class": "py-0 m-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_row, {
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
          "total-rows": _ctx.reportCount,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.reportCount > _ctx.perPage]])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        }, 512), [[_vShow, _ctx.reportCount > _ctx.perPage]])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_table, {
    class: "table-custom",
    hover: "",
    responsive: "",
    "show-empty": "",
    items: $options.items4table,
    fields: _ctx.tableFields,
    stacked: "md",
    busy: _ctx.reportLoading,
    small: ""
  }, {
    "cell()": _withCtx(row => [_createElementVNode("span", {
      class: _normalizeClass(row.value ? '' : 'text-gray-400')
    }, _toDisplayString(row.value ? row.value : "—"), 3)]),
    "cell(FIO)": _withCtx(row => [_createVNode(_component_thePersona, {
      firstname: row.item.student_first_name,
      lastname: row.item.student_last_name,
      middlename: row.item.student_middle_name,
      target: 'user-' + row.item.id
    }, null, 8, ["firstname", "lastname", "middlename", "target"])]),
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    empty: _withCtx(() => [_createVNode(_component_b_alert, {
      show: "",
      variant: "light",
      class: "text-center"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        alt: "empty",
        class: "mb-4"
      }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
      _: 1
    })]),
    "cell(index)": _withCtx(data => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + data.index + 1), 1)]),
    "cell(result)": _withCtx(row => [_createElementVNode("span", {
      class: _normalizeClass(row.item.is_success ? 'text-success' : row.item.is_success === false ? 'text-danger' : '')
    }, _toDisplayString(row.value !== null ? Math.round(row.value) : "-"), 3)]),
    _: 1
  }, 8, ["items", "fields", "busy"])]);
}