import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow } from "vue";
const _hoisted_1 = {
  class: "d-flex"
};
const _hoisted_2 = {
  key: 0,
  class: "ms-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_nav_item = _resolveComponent("b-nav-item");
  const _component_b_nav = _resolveComponent("b-nav");
  const _component_b_button = _resolveComponent("b-button");
  const _component_DeclarationsList = _resolveComponent("DeclarationsList");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome), _cache[6] || (_cache[6] = _createElementVNode("h1", null, "Декларации", -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_b_nav, {
    pills: "",
    class: "mb-4 mt-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_nav_item, {
      "link-classes": "h3 border-0 me-4",
      active: _ctx.showCOI,
      onClick: _cache[0] || (_cache[0] = $event => _ctx.showCOI = true)
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Декларации конфликтов интересов")])),
      _: 1
    }, 8, ["active"]), _createVNode(_component_b_nav_item, {
      "link-classes": "h3 border-0",
      active: !_ctx.showCOI,
      onClick: _cache[1] || (_cache[1] = $event => _ctx.showCOI = false)
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Декларации подарков")])),
      _: 1
    }, 8, ["active"])]),
    _: 1
  }), _ctx.showCOI ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.tabIndex === 0 ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    variant: "primary",
    onClick: $options.gotoAutocheck
  }, {
    default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Результаты автосверки ")])),
    _: 1
  }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.view_wa_autocheck]]) : _createCommentVNode("", true), _ctx.tabIndex === 1 ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
    key: 1,
    variant: "primary",
    onClick: $options.clickCreateCoi
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Создать ")])),
    _: 1
  }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_coi]]) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]), _withDirectives(_createVNode(_component_DeclarationsList, {
    "declarations-obj": _ctx.declarationsObj,
    onClickTabs: $options.clickTabs
  }, null, 8, ["declarations-obj", "onClickTabs"]), [[_vShow, _ctx.showCOI]]), _withDirectives(_createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm text-center p-5"
  }, null, 512), [[_vShow, !_ctx.showCOI]])]);
}