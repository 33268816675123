import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  "data-hook": "numberDecl",
  class: "mb-4"
};
const _hoisted_2 = {
  class: "need-wrap"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  class: "mb-0"
};
const _hoisted_5 = {
  class: "mb-0"
};
const _hoisted_6 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_SignPEP = _resolveComponent("SignPEP");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_DeclarationFormPartI = _resolveComponent("DeclarationFormPartI");
  const _component_DeclarationFormPartII = _resolveComponent("DeclarationFormPartII");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_theAttachments = _resolveComponent("theAttachments");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_SignPEP, {
    ref: "btnPEP",
    type: _ctx.signTypes.declaration,
    id: $props.msg
  }, null, 8, ["type", "id"]), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createElementVNode("h1", _hoisted_1, "Декларация #" + _toDisplayString($props.msg), 1), _createVNode(_component_b_alert, {
    modelValue: _ctx.showAlert,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showAlert = $event),
    variant: "danger",
    dismissible: ""
  }, {
    default: _withCtx(() => [_cache[2] || (_cache[2] = _createElementVNode("div", null, "Декларация не может быть отправлена", -1)), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.alertTitle), 1)]),
    _: 1
  }, 8, ["modelValue"]), !_ctx.declarationFormLoading ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("p", _hoisted_4, [_cache[3] || (_cache[3] = _createElementVNode("span", {
        class: "text-muted"
      }, "Статус ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.statesDeclarations[_ctx.declarationFormObj.state]), 1)]), _createElementVNode("p", _hoisted_5, [_cache[4] || (_cache[4] = _createElementVNode("span", {
        class: "text-muted"
      }, "Обновлено ", -1)), _createElementVNode("span", null, _toDisplayString($options.formatDate(_ctx.declarationFormObj.created_at, _ctx.fmtDateMonthTime)), 1)])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "primary",
        onClick: $options.signPEP,
        disabled: $options.disabledDeclSend
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Направить на проверку")])),
        _: 1
      }, 8, ["onClick", "disabled"])), [[_directive_can, _ctx.$perm.submit_my_coid]])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "primary",
        to: _ctx.links.printDeclaration + `${JSON.stringify({
          id: $props.msg,
          myDecl: true
        })}`,
        target: "_blank"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Печать декларации")])),
        _: 1
      }, 8, ["to"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_DeclarationFormPartI, {
    user: $options.renderUser
  }, null, 8, ["user"]), _createVNode(_component_b_card, {
    "no-body": "",
    class: "mt-2 overflow-hidden border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, null, {
        title: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Форма")])),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_DeclarationFormPartII, {
            sectionData: _ctx.declarationFormPartII,
            disabledDeclActions: $options.disabledDeclActions,
            onEmitAddCOI: $options.emitAddCOI,
            onEmitDelCOI: $options.emitDelCOI,
            onEmitChangeCOI: $options.emitChangeCOI
          }, null, 8, ["sectionData", "disabledDeclActions", "onEmitAddCOI", "onEmitDelCOI", "onEmitChangeCOI"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[8] || (_cache[8] = _createTextVNode("Вложения ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.declarationFormAttachmentsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_theAttachments, {
            onAddFile: $options.addFile,
            onDelFile: $options.delFile,
            onSetPage: $options.setPage,
            items: $options.getAttachments,
            count: _ctx.declarationFormAttachmentsCount,
            disabledActions: $options.disabledDeclActions,
            busy: _ctx.declarationFormAttachmentsLoading
          }, null, 8, ["onAddFile", "onDelFile", "onSetPage", "items", "count", "disabledActions", "busy"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])), [[_directive_can, _ctx.$perm.view_my_coid]]) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Загрузка...", -1))])), _createVNode(_component_b_modal, {
    title: "Достоверность и полнота информации",
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.showModal = $event),
    "ok-variant": "primary",
    "ok-title": "Подтверждаю",
    "cancel-variant": "danger",
    "cancel-title": "Нет",
    centered: "",
    "footer-class": "p-2",
    onOk: $options.doSend
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.confirmTitle), 1)]),
    _: 1
  }, 8, ["modelValue", "onOk"])]);
}