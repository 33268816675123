import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "abroad"
};
const _hoisted_2 = {
  class: "start-date"
};
const _hoisted_3 = {
  class: "end-date"
};
const _hoisted_4 = {
  class: "country"
};
const _hoisted_5 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_6 = {
  class: "purpose"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  colspan: "5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "8. Пребывание за границей за последние 5 лет \n        (работа, служебная командировка, поездка в составе делегации,\n        туризм)"
      }, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[4] || (_cache[4] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Месяц и год с какого времени"), _createElementVNode("th", null, "Месяц и год по какое время"), _createElementVNode("th", null, "В какой стране"), _createElementVNode("th", null, "Цель пребывания за границей")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visits, visit => {
          return _openBlock(), _createElementBlock("tr", {
            key: visit.id,
            class: "visit-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_select, {
            modelValue: visit.start_month,
            "onUpdate:modelValue": $event => visit.start_month = $event,
            options: _ctx.monthsOptions,
            onChange: $event => $options.submitDataEndpoint(visit, 'start_month'),
            state: visit.start_month_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"]), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)), _createVNode(_component_b_form_select, {
            modelValue: visit.start_year,
            "onUpdate:modelValue": $event => visit.start_year = $event,
            options: $options.visitingYear,
            onChange: $event => $options.submitDataEndpoint(visit, 'start_year'),
            state: visit.start_year_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_select, {
            modelValue: visit.end_month,
            "onUpdate:modelValue": $event => visit.end_month = $event,
            options: _ctx.monthsOptions,
            onChange: $event => $options.submitDataEndpoint(visit, 'end_month'),
            state: visit.end_month_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"]), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)), _createVNode(_component_b_form_select, {
            modelValue: visit.end_year,
            "onUpdate:modelValue": $event => visit.end_year = $event,
            options: $options.visitingYear,
            onChange: $event => $options.submitDataEndpoint(visit, 'end_year'),
            state: visit.end_year_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [!_ctx.countriesLoading ? (_openBlock(), _createBlock(_component_b_form_select, {
            key: 0,
            options: $options.optionsCountry,
            disabled: $options.readonly,
            modelValue: visit.country,
            "onUpdate:modelValue": $event => visit.country = $event,
            onChange: $event => $options.submitDataEndpoint(visit, 'country'),
            state: visit.country_state
          }, null, 8, ["options", "disabled", "modelValue", "onUpdate:modelValue", "onChange", "state"])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))]))]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_b_form_textarea, {
            modelValue: visit.purpose,
            "onUpdate:modelValue": $event => visit.purpose = $event,
            onChange: $event => $options.submitDataEndpoint(visit, 'purpose'),
            state: visit.purpose_state,
            disabled: $options.readonly,
            rows: "3",
            "no-resize": "",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete double-height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(visit.id)
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_7, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_8, [_createVNode(_component_AddRow, {
          onClicked: $options.addDataEndpoint
        }, null, 8, ["onClicked"])])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}