import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { cpReason } from "@/helpers/text";
import { statesCp } from "@/helpers/states";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { option4select } from "@/helpers/global";
export default {
  name: "CPFormSource",
  data: () => ({
    typeSource: null,
    idSource: "",
    tableFields: [{
      key: "index",
      label: "№"
    }, {
      key: "title",
      label: "Источник"
    }, {
      key: "remove",
      label: "Удалить",
      class: "text-center"
    }],
    showModal: false,
    showModalRemove: false,
    titleModalRemove: "",
    item2Remove: {}
  }),
  methods: {
    ...mapActions("cp", ["changeCPForm"]),
    ...mapActions("innerCheck", ["updateInnerCheckForm", "dropICForm"]),
    ...mapActions("coi", ["updateCOIForm", "dropCOIForm"]),
    ...mapActions("hotline", ["updateHlineMsg", "dropHlForm"]),
    ...mapActions("declarationForm", ["fetchDeclarationForm", "dropDeclarationForm"]),
    ...mapActions(["showNotify"]),
    addSource() {
      this.typeSource = null;
      this.idSource = "";
      this.showModal = true;
    },
    async handlerAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      // foundations_coi: "Конфликт интересов",
      // foundations_message: "Сообщение горячей линии",
      // foundations_innercheck: "Внутренняя проверка",
      // foundations_coid: "ЭДКИ",
      if (cpReason[this.typeSource] === cpReason.foundations_coi) {
        await this.dropCOIForm();
        await this.updateCOIForm(this.idSource);
        if (!this.coiForm.id) {
          this.showNotify({
            msg: `Конфликт интересов #${this.idSource} недоступен`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else if (cpReason[this.typeSource] === cpReason.foundations_message) {
        await this.dropHlForm();
        await this.updateHlineMsg(this.idSource);
        if (!this.hlineMsg.id) {
          this.showNotify({
            msg: `Сообщение горячей линии #${this.idSource} недоступно`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else if (cpReason[this.typeSource] === cpReason.foundations_innercheck) {
        await this.dropICForm();
        await this.updateInnerCheckForm(this.idSource);
        if (!this.innerCheckForm.id) {
          this.showNotify({
            msg: `Внутренняя проверка #${this.idSource} недоступна`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else if (cpReason[this.typeSource] === cpReason.foundations_coid) {
        await this.dropDeclarationForm();
        await this.fetchDeclarationForm({
          id: this.idSource,
          myDecl: false
        });
        if (!this.declarationFormObj.id) {
          this.showNotify({
            msg: `ЭДКИ #${this.idSource} недоступна`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else return;
      await this.changeCPForm({
        id: this.cpForm.id,
        params: {
          [this.typeSource]: this.cpForm[this.typeSource].concat(this.idSource)
        }
      });
      this.showModal = false;
    },
    async removeSource(item) {
      this.titleModalRemove = `Удалить источник ${item.title}?`;
      this.item2Remove = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      this.cpForm[this.item2Remove.key].splice(this.item2Remove.index, 1);
      await this.changeCPForm({
        id: this.cpForm.id,
        params: {
          [this.item2Remove.key]: this.cpForm[this.item2Remove.key]
        }
      });
    }
  },
  computed: {
    ...mapGetters("cp", ["cpForm", "cpFormChangeLoading"]),
    ...mapGetters("innerCheck", ["innerCheckForm", "innerCheckFormLoading"]),
    ...mapGetters("coi", ["coiForm", "coiFormLoading"]),
    ...mapGetters("hotline", ["hlineMsg", "hlineMsgLoading"]),
    ...mapGetters("declarationForm", ["declarationFormObj", "declarationFormLoading"]),
    okModalAddDisabled() {
      return !this.typeSource || !this.idSource.trim().length;
    },
    readOnly() {
      return statesCp[this.cpForm.state] !== statesCp.draft || this.cpFormChangeLoading;
    },
    optionsSource() {
      return option4select(cpReason);
    },
    source() {
      return [].concat(this.cpForm.foundations_coi.map((coi, index) => ({
        key: "foundations_coi",
        index,
        link: links.coiForm + coi,
        title: cpReason.foundations_coi + " #" + coi
      })), this.cpForm.foundations_message.map((msg, index) => ({
        key: "foundations_message",
        index,
        link: links.hotlineForm + msg,
        title: cpReason.foundations_message + " #" + msg
      })), this.cpForm.foundations_innercheck.map((innercheck, index) => ({
        key: "foundations_innercheck",
        index,
        link: links.innerCheckForm + innercheck,
        title: cpReason.foundations_innercheck + " #" + innercheck
      })), this.cpForm.foundations_coid.map((decl, index) => ({
        key: "foundations_coid",
        index,
        link: links.declarationForm + decl,
        title: cpReason.foundations_coid + " #" + decl
      })));
    }
  }
};