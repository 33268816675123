import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_FullName = _resolveComponent("FullName");
  const _component_OldNames = _resolveComponent("OldNames");
  const _component_GeneralData = _resolveComponent("GeneralData");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    "label-cols-sm": "12",
    "label-cols-lg": "12",
    "content-cols-sm": "",
    "content-cols-lg": "",
    class: "field mb-2",
    label: "1.Планируемая (занимаемая) должность кандидата (работника) с указанием\n      наименования подразделения"
  }, {
    default: _withCtx(() => [_ctx.stateWorker[_ctx.profileObj.user_state] === _ctx.stateWorker.worker ? (_openBlock(), _createBlock(_component_b_form_input, {
      key: 0,
      modelValue: _ctx.profileObj.position,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.position = $event),
      state: _ctx.expected_position_state,
      disabled: true
    }, null, 8, ["modelValue", "state"])) : (_openBlock(), _createBlock(_component_b_form_input, {
      key: 1,
      modelValue: _ctx.profileObj.expected_position,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.profileObj.expected_position = $event),
      onChange: _cache[2] || (_cache[2] = $event => $options.submitData('expected_position')),
      state: _ctx.expected_position_state,
      disabled: $options.readonly
    }, null, 8, ["modelValue", "state", "disabled"]))]),
    _: 1
  }), _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)), _createVNode(_component_FullName), _createVNode(_component_OldNames), _createVNode(_component_GeneralData)]);
}