import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = {
  key: 0,
  class: "mb-3 d-flex"
};
const _hoisted_3 = {
  key: 1,
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_4 = {
  key: 2,
  class: "text-end"
};
import { computed, ref, toRefs } from "vue";
import { useStore } from "vuex";

//props

export default {
  __name: 'InnerCheckFormAttachItem',
  props: {
    ICId: String,
    item: Object,
    addNew: Boolean,
    disabled: Boolean
  },
  emits: ["updateData"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const {
      ICId,
      item,
      addNew,
      disabled
    } = toRefs(props);
    const emit = __emit;

    // store
    const store = useStore();
    const icAttachItemLoading = computed(() => store.getters["innerCheck/icAttachItemLoading"]);
    const icAttachItem = computed(() => store.getters["innerCheck/icAttachItem"]);

    // add new attachment
    const currentItem = ref({});
    const showModalChange = ref(false);
    function addAttachment() {
      currentItem.value = {
        file_name: "",
        description: "",
        document: null
      };
      showModalChange.value = true;
    }
    const disabledSave = computed(() => icAttachItemLoading.value || disabled.value || !currentItem.value.document || String(currentItem.value.file_name).trim() === "");
    async function handlerOk() {
      if (addNew.value) {
        const formData = new FormData();
        formData.append("description", currentItem.value.description);
        formData.append("file_name", currentItem.value.file_name);
        formData.append("document", currentItem.value.document);
        await store.dispatch("innerCheck/createICAttach", {
          formData,
          ICId: ICId.value
        });
      } else await store.dispatch("innerCheck/changeICAttach", {
        attachId: item.value.id,
        body: {
          file_name: currentItem.value.file_name,
          description: currentItem.value.description
        },
        ICId: ICId.value
      });
      // если нет ошибки то закрыаем окно
      if (icAttachItem.value.id) {
        showModalChange.value = false;
        emit("updateData");
      }
    }

    // change attachment
    function clickShowModalChange() {
      currentItem.value = {
        ...item.value
      }; // неглубокая копия плоского объекта
      showModalChange.value = true;
    }

    // add/delete file
    function clickDeleteFile() {
      currentItem.value.document = null;
    }
    const fileInputICAttach = ref(null);
    function clickAddFile() {
      fileInputICAttach.value.click();
    }
    async function handlerAddFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      currentItem.value.document = files[0];
      fileInputICAttach.value.value = null;
    }
    return (_ctx, _cache) => {
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_form_input = _resolveComponent("b-form-input");
      const _component_b_form_group = _resolveComponent("b-form-group");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_form_textarea = _resolveComponent("b-form-textarea");
      const _component_b_modal = _resolveComponent("b-modal");
      return _openBlock(), _createElementBlock("div", null, [_unref(addNew) ? (_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: "link",
        class: "p-0 mt-2 mb-0",
        onClick: addAttachment,
        disabled: _unref(disabled)
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" + Добавить основание ")])),
        _: 1
      }, 8, ["disabled"])) : (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: "button",
        class: "btn btn-light py-0 px-1",
        onClick: clickShowModalChange,
        disabled: _unref(disabled)
      }, _cache[5] || (_cache[5] = [_createElementVNode("span", null, "...", -1)]), 8, _hoisted_1)), _createVNode(_component_b_modal, {
        modelValue: showModalChange.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => showModalChange.value = $event),
        id: `ICAttachmentItem${_unref(item).id}`,
        title: "Параметры основания",
        centered: "",
        hideFooter: "",
        scrollable: "",
        size: "lg"
      }, {
        default: _withCtx(() => [_unref(addNew) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_component_b_button, {
          variant: "outline-primary text-nowrap",
          class: "me-3",
          onClick: clickAddFile,
          size: "sm",
          disabled: _unref(disabled)
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" Выбрать файл ")])),
          _: 1
        }, 8, ["disabled"])]), currentItem.value.document ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createElementVNode("div", null, _toDisplayString(currentItem.value.document.name), 1), _createVNode(_component_b_button, {
          variant: "link",
          onClick: clickDeleteFile,
          class: "p-0 ms-3",
          disabled: _unref(disabled)
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("i", {
            class: "bi bi-trash"
          }, null, -1)])),
          _: 1
        }, 8, ["disabled"])], 64)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createVNode(_component_b_row, null, {
          default: _withCtx(() => [_createVNode(_component_b_col, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_group, {
              label: "Название файла"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_form_input, {
                modelValue: currentItem.value.file_name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => currentItem.value.file_name = $event),
                type: "text",
                trim: "",
                disabled: _unref(disabled)
              }, null, 8, ["modelValue", "disabled"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_b_row, {
          class: "mt-3"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_group, {
              label: "Описание файла"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
                modelValue: currentItem.value.description,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => currentItem.value.description = $event),
                rows: "3",
                trim: "",
                disabled: _unref(disabled)
              }, null, 8, ["modelValue", "disabled"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)), icAttachItemLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[8] || (_cache[8] = [_createElementVNode("span", {
          class: "visually-hidden"
        }, "Загрузка...", -1)]))) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_b_button, {
          variant: "primary me-5",
          onClick: handlerOk,
          disabled: disabledSave.value
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Сохранить")])),
          _: 1
        }, 8, ["disabled"]), _createVNode(_component_b_button, {
          variant: "outline-secondary",
          onClick: _cache[2] || (_cache[2] = $event => showModalChange.value = false)
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode(" Закрыть ")])),
          _: 1
        })]))]),
        _: 1
      }, 8, ["modelValue", "id"]), _createElementVNode("input", {
        type: "file",
        ref_key: "fileInputICAttach",
        ref: fileInputICAttach,
        class: "d-none",
        onChange: handlerAddFile,
        accept: ".pdf"
      }, null, 544)]);
    };
  }
};