import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "introduce-wrap"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_table = _resolveComponent("b-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_b_card_header, {
    "header-class": "p-0 m-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto ms-auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
          "total-rows": _ctx.coiForDeclarationCount,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.coiForDeclarationCount > _ctx.perPage]]), _createVNode(_component_b_table, {
    hover: "",
    class: "table-custom",
    items: _ctx.coiForDeclaration,
    fields: _ctx.coiFields,
    busy: _ctx.coiForDeclarationLoading,
    stacked: "md",
    small: "",
    "show-empty": "",
    responsive: ""
  }, {
    "cell(coi_type_name)": _withCtx(row => [_createElementVNode("div", {
      role: "button",
      onClick: row.toggleDetails
    }, _toDisplayString(row.value), 9, _hoisted_2)]),
    "cell(toggleDetailsState)": _withCtx(row => [_createElementVNode("i", {
      class: _normalizeClass(["bi text-primary", row.detailsShowing ? 'bi-chevron-up' : 'bi-chevron-down']),
      role: "button",
      onClick: row.toggleDetails
    }, null, 10, _hoisted_3)]),
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    "row-details": _withCtx(row => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.coiTemplate), keyname => {
      return _openBlock(), _createElementBlock("div", {
        key: keyname
      }, [row.item[keyname] ? (_openBlock(), _createBlock(_component_b_row, {
        key: 0
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coiTemplate[keyname].title), 1)]),
          _: 2
        }, 1024), _createVNode(_component_b_col, null, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.item[keyname]), 1)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)) : _createCommentVNode("", true)]);
    }), 128)), _createVNode(_component_b_button, {
      class: "mt-3",
      to: _ctx.links.coiForm + row.item.id,
      variant: "outline-primary",
      size: "sm"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Перейти к конфликту интересов ")])),
      _: 2
    }, 1032, ["to"])]),
    empty: _withCtx(() => [_createVNode(_component_b_alert, {
      show: "",
      variant: "light",
      class: "text-center"
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
        src: _imports_0,
        alt: "empty",
        class: "mb-4"
      }, null, -1), _createElementVNode("h4", null, "Конфликтов нет", -1)])),
      _: 1
    })]),
    _: 1
  }, 8, ["items", "fields", "busy"])]);
}