import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "overflow-hidden border-0 shadow-sm"
};
import { useRoute } from "vue-router";
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { typeText, entityTitle } from "./autochecks";
export default {
  __name: 'AutochecksList',
  props: {
    currentPage: Number,
    perPage: Number
  },
  setup(__props) {
    const props = __props;
    const {
      currentPage,
      perPage
    } = toRefs(props);
    const route = useRoute();

    // store
    const store = useStore();
    const autochecksList = computed(() => store.getters["administration2/autochecksList"]);
    const autochecksLoading = computed(() => store.getters["administration2/autochecksLoading"]);
    const tableFields = computed(() => [{
      key: "num",
      label: "№ п/п"
    }, {
      key: "created_at",
      label: "Дата",
      class: "text-nowrap"
    }, {
      key: "entity",
      label: typeText(route?.query?.type)
    }, {
      key: "hits_count",
      label: "Выявленные совпадения",
      class: "text-center"
    }]);
    return (_ctx, _cache) => {
      const _component_b_link = _resolveComponent("b-link");
      const _component_b_table = _resolveComponent("b-table");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        small: "",
        responsive: "",
        stacked: "md",
        busy: autochecksLoading.value,
        items: autochecksList.value,
        fields: tableFields.value,
        "show-empty": ""
      }, {
        "cell(num)": _withCtx(row => [_createTextVNode(_toDisplayString(row.index + 1 + (_unref(currentPage) - 1) * _unref(perPage)), 1)]),
        empty: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h5", {
          class: "text-center"
        }, "Данных нет", -1)])),
        "cell(entity)": _withCtx(row => [_createVNode(_component_b_link, {
          class: _normalizeClass(row.item.is_new ? 'font-weight-bold' : ''),
          to: {
            name: 'AutocheckForm',
            params: {
              msg: row.item.id
            },
            query: _unref(route).query
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(entityTitle)(row.item)), 1)]),
          _: 2
        }, 1032, ["class", "to"])]),
        "cell(created_at)": _withCtx(row => [_createVNode(_component_b_link, {
          class: _normalizeClass(row.item.is_new ? 'font-weight-bold' : ''),
          to: {
            name: 'AutocheckForm',
            params: {
              msg: row.item.id
            },
            query: _unref(route).query
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort))), 1)]),
          _: 2
        }, 1032, ["class", "to"])]),
        _: 1
      }, 8, ["busy", "items", "fields"])]);
    };
  }
};