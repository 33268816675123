import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_COIFormHeader = _resolveComponent("COIFormHeader");
  const _component_COIFormInfo = _resolveComponent("COIFormInfo");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_COIFormSource = _resolveComponent("COIFormSource");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_theAttachments = _resolveComponent("theAttachments");
  const _component_theComments = _resolveComponent("theComments");
  const _component_theHistory = _resolveComponent("theHistory");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _ctx.coiFormLoading || _ctx.coiLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_COIFormHeader), _createVNode(_component_b_card, {
    "no-body": "",
    class: "mt-3 border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        title: "КИ"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_COIFormInfo)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Источник"
      }, {
        title: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Источник")])),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_COIFormSource)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[2] || (_cache[2] = _createTextVNode(" Вложения ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coiAttachCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_theAttachments, {
            onAddFile: $options.addAttach,
            onDelFile: $options.delAttach,
            onSetPage: $options.setPageAttach,
            items: $options.getAttachments,
            count: _ctx.coiAttachCount,
            disabledActions: $options.readOnly,
            busy: _ctx.coiAttachLoading,
            fields: _ctx.fieldsAttach
          }, null, 8, ["onAddFile", "onDelFile", "onSetPage", "items", "count", "disabledActions", "busy", "fields"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[3] || (_cache[3] = _createTextVNode(" Комментарии ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coiCommentsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_theComments, {
            onAddComment: $options.addComment,
            onDelComment: $options.delComment,
            onSetPage: $options.setPageComment,
            onChangeComment: $options.changeComment,
            items: $options.getComments,
            count: _ctx.coiCommentsCount,
            disabledActions: $options.readOnly,
            busy: _ctx.coiCommentsLoading
          }, null, 8, ["onAddComment", "onDelComment", "onSetPage", "onChangeComment", "items", "count", "disabledActions", "busy"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[4] || (_cache[4] = _createTextVNode(" Журнал изменений ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coiEventsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_theHistory, {
            onSetPage: $options.setPageHistory,
            items: _ctx.coiEvents,
            count: _ctx.coiEventsCount,
            busy: _ctx.coiEventsLoading,
            defaultFields: _ctx.eventFields
          }, null, 8, ["onSetPage", "items", "count", "busy", "defaultFields"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })], 64))])), [[_directive_can, _ctx.$perm.view_wa_coid]]);
}