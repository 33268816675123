import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "bg-white"
};
const _hoisted_2 = {
  class: "d-flex pt-2 justify-content-center align-items-center"
};
const _hoisted_3 = {
  class: "mx-2"
};
const _hoisted_4 = {
  key: 0,
  class: "text-center text-primary my-2"
};
import Chart from "chart.js/auto";
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { chartOptions } from "@/components/Risk/Chart/settings";
import { nowYear, formatDate, fmtDate } from "@/helpers/date";

// store

export default {
  __name: 'RiskChart',
  setup(__props) {
    const store = useStore();
    const riskChart = computed(() => store.getters["risk/riskChart"]);
    const riskChartLoading = computed(() => store.getters["risk/riskChartLoading"]);

    // year
    const year = ref(nowYear);
    const yearsOptions = Array.from({
      length: 10
    }, (_, i) => nowYear - i);

    // при монтировании
    onMounted(() => {
      updateRiskChart();
    });
    let chart;
    async function updateRiskChart() {
      const params = {
        confirmed_at_gte: formatDate(new Date(year.value, 0, 1), fmtDate),
        confirmed_at_lte: formatDate(new Date(year.value, 12, 0), fmtDate)
      };
      store.dispatch("risk/setRisksYear", year.value);
      await store.dispatch("risk/updateRiskChart", params);
      if (chart) {
        chart.data = riskChart.value;
        chart.update();
      } else {
        const ctx = document.getElementById("RiskChart");
        chart = new Chart(ctx, {
          type: "scatter",
          data: riskChart.value,
          options: chartOptions
        });
      }
    }
    return (_ctx, _cache) => {
      const _component_b_form_select = _resolveComponent("b-form-select");
      const _component_b_spinner = _resolveComponent("b-spinner");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("div", null, "Риски за", -1)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_form_select, {
        modelValue: year.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => year.value = $event),
        options: _unref(yearsOptions),
        onChange: updateRiskChart,
        size: "sm"
      }, null, 8, ["modelValue", "options"])]), _cache[2] || (_cache[2] = _createElementVNode("div", null, "год", -1))]), riskChartLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _cache[4] || (_cache[4] = _createElementVNode("canvas", {
        id: "RiskChart",
        width: 1000,
        height: 500,
        class: "px-4"
      }, null, -1))]);
    };
  }
};