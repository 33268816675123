import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "text-danger"
};
const _hoisted_4 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_5 = {
  class: "text-danger"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_ImageUpload = _resolveComponent("ImageUpload");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createBlock(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      xl: "8",
      class: "pb-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, {
          class: "p-4"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_group, {
            label: "Наименование программы",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.propItem.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.propItem.title = $event),
              trim: "",
              disabled: _ctx.propItem.readonly,
              onChange: _cache[1] || (_cache[1] = $event => $options.changeValue('title'))
            }, null, 8, ["modelValue", "disabled"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Описание",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
              modelValue: _ctx.propItem.description,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.propItem.description = $event),
              rows: "5",
              "max-rows": "5",
              onChange: _cache[3] || (_cache[3] = $event => $options.changeValue('description')),
              disabled: _ctx.propItem.readonly,
              trim: ""
            }, null, 8, ["modelValue", "disabled"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Превью",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              keyImg: 'image_preview',
              value: _ctx.propItem.image_preview || '',
              types: '.gif, .jpg, .jpeg, .png',
              onFormdata: $options.addFile,
              onDeletefile: $options.deleteFile
            }, null, 8, ["value", "onFormdata", "onDeletefile"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Обложка сертификата",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              keyImg: 'image_certificate',
              value: _ctx.propItem.image_certificate || '',
              hint: 'Рекомендуется png размером 1500x2100px',
              types: '.gif, .jpg, .jpeg, .png',
              onFormdata: $options.addFile,
              onDeletefile: $options.deleteFile
            }, null, 8, ["value", "onFormdata", "onDeletefile"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Тип обучения",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "4",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.propItem.edu_type,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.propItem.edu_type = $event),
              options: _ctx.optionsEducType,
              onChange: _cache[5] || (_cache[5] = $event => $options.changeValue('edu_type')),
              disabled: _ctx.propItem.readonly
            }, null, 8, ["modelValue", "options", "disabled"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Тренер",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_ctx.propItem.edu_type === 'external' ? (_openBlock(), _createBlock(_component_b_form_input, {
              key: 0,
              trim: "",
              modelValue: _ctx.propItem.instructor,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.propItem.instructor = $event),
              onChange: _cache[7] || (_cache[7] = $event => $options.changeValue('instructor')),
              disabled: _ctx.propItem.readonly
            }, null, 8, ["modelValue", "disabled"])) : (_openBlock(), _createBlock(_component_UserSelector, {
              key: 1,
              onSelected: _cache[8] || (_cache[8] = $event => $options.selectedUser('instructor_internal', $event)),
              disabled: _ctx.propItem.readonly,
              name: $options.instructorInternal,
              fmtName: _ctx.fmtNameFull,
              showInfo: true
            }, null, 8, ["disabled", "name", "fmtName"]))]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Владелец обучения",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_ctx.propItem.edu_type === 'external' ? (_openBlock(), _createBlock(_component_b_form_input, {
              key: 0,
              trim: "",
              modelValue: _ctx.propItem.owner,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.propItem.owner = $event),
              onChange: _cache[10] || (_cache[10] = $event => $options.changeValue('owner')),
              disabled: _ctx.propItem.readonly
            }, null, 8, ["modelValue", "disabled"])) : (_openBlock(), _createElementBlock(_Fragment, {
              key: 1
            }, [!_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_form_select, {
              key: 0,
              modelValue: _ctx.propItem.owner_department,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _ctx.propItem.owner_department = $event),
              disabled: _ctx.propItem.readonly,
              options: $options.departOptions,
              onChange: _cache[12] || (_cache[12] = $event => $options.changeValue('owner_department'))
            }, null, 8, ["modelValue", "disabled", "options"])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
              class: "align-middle"
            }), _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Загрузка...", -1))]))], 64))]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Формат",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "4",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.propItem.format,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _ctx.propItem.format = $event),
              options: _ctx.optionsEducFormat,
              onChange: _cache[14] || (_cache[14] = $event => $options.changeValue('format')),
              disabled: _ctx.propItem.readonly
            }, null, 8, ["modelValue", "options", "disabled"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Место проведения",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.propItem.location,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => _ctx.propItem.location = $event),
              disabled: _ctx.propItem.readonly || _ctx.propItem.format === 'online',
              onChange: _cache[16] || (_cache[16] = $event => $options.changeValue('location')),
              trim: ""
            }, null, 8, ["modelValue", "disabled"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Дата начала обучения",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "6",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_ctx.educationCourseLoading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
              class: "align-middle"
            })])) : (_openBlock(), _createBlock(_component_Calendar, {
              key: 1,
              title: "",
              date: _ctx.propItem.startDate,
              disabled: _ctx.propItem.readonly,
              onChangeValue: _cache[17] || (_cache[17] = $event => $options.changeDate($event, 'start_date')),
              classCustom: "no-gutters"
            }, null, 8, ["date", "disabled"])), _withDirectives(_createElementVNode("div", _hoisted_3, " «Дата начала обучения» не может быть позднее «Даты окончания обучения» ", 512), [[_vShow, _ctx.invalidStartDate]])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Дата окончания обучения",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "6"
          }, {
            default: _withCtx(() => [_ctx.educationCourseLoading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_b_spinner, {
              class: "align-middle"
            })])) : (_openBlock(), _createBlock(_component_Calendar, {
              key: 1,
              title: "",
              date: _ctx.propItem.endDate,
              disabled: _ctx.propItem.readonly,
              onChangeValue: _cache[18] || (_cache[18] = $event => $options.changeDate($event, 'end_date')),
              classCustom: "no-gutters"
            }, null, 8, ["date", "disabled"])), _withDirectives(_createElementVNode("div", _hoisted_5, " «Дата окончания обучения» не может быть ранее даты внесения сведений ", 512), [[_vShow, _ctx.invalidEndDate]])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}