import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _directive_b_tooltip = _resolveDirective("b-tooltip");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Руководитель подразделения исполнителя"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineMsg.departmentChiefDetail), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Куратор"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineMsg.curatorDetail), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Ответственный исполнитель"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineMsg.assigneeDetail), 1)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-v": "center"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Дата ответа"
      }, {
        default: _withCtx(() => [_createVNode(_component_Calendar, {
          title: "",
          date: _ctx.hlineMsg.responseDate,
          disabled: $options.readOnly,
          onChangeValue: _cache[0] || (_cache[0] = $event => $options.changeData('response_date', $event))
        }, null, 8, ["date", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Номер ответа"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.hlineMsg.response_number,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.hlineMsg.response_number = $event),
          onChange: _cache[2] || (_cache[2] = $event => $options.changeData('response_number')),
          disabled: $options.readOnly,
          trim: ""
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: _ctx.hlineMsg.is_confirmed,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.hlineMsg.is_confirmed = $event),
        onChange: _cache[4] || (_cache[4] = $event => $options.changeData('is_confirmed')),
        disabled: $options.readOnly
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode(" Информация подтвердилась ")])),
        _: 1
      }, 8, ["modelValue", "disabled"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      class: "text-truncate"
    }, {
      default: _withCtx(() => [_cache[16] || (_cache[16] = _createTextVNode(" Уведомление о продлении * ")), _createVNode(_component_b_row, {
        class: "mt-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          class: "text-muted text-nowrap text-truncate file-name"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineMsg.prolongation_notice), 1)]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            class: "pe-0 ps-0 button-wrap",
            variant: "link",
            title: "Скачать файл",
            href: _ctx.hlineMsg.prolongation_notice,
            download: decodeURI(_ctx.hlineMsg.prolongation_notice)
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [_createElementVNode("i", {
              class: "bi bi-download"
            }, null, -1)])),
            _: 1
          }, 8, ["href", "download"])), [[_directive_b_tooltip, void 0, void 0, {
            hover: true
          }]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            class: "pe-0 ps-0",
            variant: "link",
            title: "Загрузить файл",
            onClick: _cache[5] || (_cache[5] = $event => $options.clickAddFile('prolongation_notice')),
            disabled: $options.readOnly
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [_createElementVNode("i", {
              class: "bi bi-file-earmark-plus"
            }, null, -1)])),
            _: 1
          }, 8, ["disabled"])), [[_directive_b_tooltip, void 0, void 0, {
            hover: true
          }]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            class: "pe-0 ps-0",
            variant: "link",
            onClick: _cache[6] || (_cache[6] = $event => $options.handlerDelFile('prolongation_notice')),
            disabled: !_ctx.hlineMsg.prolongation_notice || $options.readOnly
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [_createElementVNode("i", {
              class: "bi bi-trash"
            }, null, -1)])),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      class: "text-truncate"
    }, {
      default: _withCtx(() => [_cache[20] || (_cache[20] = _createTextVNode(" Ответ заявителю/Справка о результатах рассмотрения * ")), _createVNode(_component_b_row, {
        class: "mt-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          class: "text-muted text-nowrap text-truncate file-name"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineMsg.response_attachment), 1)]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            class: "pe-0 ps-0 button-wrap",
            variant: "link",
            title: "Скачать файл",
            href: _ctx.hlineMsg.response_attachment,
            download: decodeURI(_ctx.hlineMsg.response_attachment)
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [_createElementVNode("i", {
              class: "bi bi-download"
            }, null, -1)])),
            _: 1
          }, 8, ["href", "download"])), [[_directive_b_tooltip, void 0, void 0, {
            hover: true
          }]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            class: "pe-0 ps-0",
            variant: "link",
            title: "Загрузить файл",
            onClick: _cache[7] || (_cache[7] = $event => $options.clickAddFile('response_attachment')),
            disabled: $options.readOnly
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [_createElementVNode("i", {
              class: "bi bi-file-earmark-plus"
            }, null, -1)])),
            _: 1
          }, 8, ["disabled"])), [[_directive_b_tooltip, void 0, void 0, {
            hover: true
          }]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            class: "pe-0 ps-0",
            variant: "link",
            onClick: _cache[8] || (_cache[8] = $event => $options.handlerDelFile('response_attachment')),
            disabled: !_ctx.hlineMsg.response_attachment || $options.readOnly
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [_createElementVNode("i", {
              class: "bi bi-trash"
            }, null, -1)])),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Комментарий"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.hlineMsg.response_comment,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.hlineMsg.response_comment = $event),
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[10] || (_cache[10] = $event => $options.changeData('response_comment')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), $options.showBtn && !_ctx.innerCheckLoading ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    class: "mt-2",
    variant: "outline-primary",
    onClick: $options.clickAddInnerCheck
  }, {
    default: _withCtx(() => _cache[21] || (_cache[21] = [_createTextVNode("Добавить внутреннюю проверку")])),
    _: 1
  }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_innercheck]]) : _createCommentVNode("", true), _ctx.innerCheckLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
    key: 1,
    class: "text-primary"
  })) : _createCommentVNode("", true), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[11] || (_cache[11] = (...args) => $options.handlerAddFile && $options.handlerAddFile(...args))
  }, null, 544), _cache[22] || (_cache[22] = _createElementVNode("hr", null, null, -1))]);
}