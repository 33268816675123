import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "bi bi-exclamation-circle-fill text-danger",
  title: "У пользователя почта на небезопасном домене"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_link = _resolveComponent("b-link");
  const _component_ProfileListAction = _resolveComponent("ProfileListAction");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("h1", null, "Проверки личных листков", -1)])),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm py-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      "header-class": "py-0 m-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.profilesKadrCount,
            "per-page": _ctx.perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.profilesKadrCount > _ctx.perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0 me-auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.gotoPage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: $options.changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 512), [[_vShow, _ctx.profilesKadrCount > _ctx.perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            size: "sm",
            variant: "outline-primary",
            onClick: $options.clearPackage
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Очистить пакет ")])),
            _: 1
          }, 8, ["onClick"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            size: "sm",
            variant: "primary",
            onClick: $options.unloadPackage
          }, {
            default: _withCtx(() => [_createTextVNode(" Выгрузить пакет (" + _toDisplayString(_ctx.profilesIds1cCount) + ") ", 1)]),
            _: 1
          }, 8, ["onClick"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_table, {
      class: "table-custom",
      hover: "",
      small: "",
      responsive: "",
      "show-empty": "",
      stacked: "md",
      items: _ctx.profilesKadr,
      fields: _ctx.columns,
      busy: _ctx.profilesKadrLoading || _ctx.adminGroupsLoading || _ctx.departmentsLoading || _ctx.adminRolesRefLoading
    }, {
      "cell(problem)": _withCtx(row => [_withDirectives(_createElementVNode("i", _hoisted_1, null, 512), [[_vShow, !row.item.is_safe_email]])]),
      "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + row.index + 1), 1)]),
      "cell(author)": _withCtx(row => [_createVNode(_component_b_link, {
        class: _normalizeClass(row.item.is_new ? 'font-weight-bold' : ''),
        href: row.item.printLink,
        target: "_blank"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($options.formatName(row.item, _ctx.fmtNameShort)), 1)]),
        _: 2
      }, 1032, ["class", "href"])]),
      "cell(action)": _withCtx(row => [_withDirectives(_createVNode(_component_ProfileListAction, {
        idModal: `actionProfile${row.item.id}`,
        item: row.item,
        onProfileApprove: $options.approveProfile,
        onProfileValidate: $options.validateProfile,
        onActionReject: $options.actionReject
      }, null, 8, ["idModal", "item", "onProfileApprove", "onProfileValidate", "onActionReject"]), [[_directive_can, _ctx.$perm.validate_wa_profile || _ctx.$perm.approve_wa_profile || _ctx.$perm.decline_wa_profile]])]),
      "cell(file1c)": _withCtx(row => [_createVNode(_component_b_link, {
        class: "search-link",
        href: row.item.file1c
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
          class: "bi bi-download"
        }, null, -1)])),
        _: 2
      }, 1032, ["href"])]),
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      empty: _withCtx(() => [_createVNode(_component_b_alert, {
        show: "",
        variant: "light",
        class: "text-center"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "empty",
          class: "mb-4"
        }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
        _: 1
      })]),
      "cell(package41c)": _withCtx(row => [_createVNode(_component_b_form_checkbox, {
        modelValue: row.item.package41c,
        "onUpdate:modelValue": $event => row.item.package41c = $event,
        onChange: $event => $options.setPackage(row.item.id, row.item.package41c)
      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
      _: 1
    }, 8, ["items", "fields", "busy"])]),
    _: 1
  }), _createElementVNode("a", {
    href: $options.file41c,
    ref: "profile41c"
  }, null, 8, _hoisted_3), _createVNode(_component_b_modal, {
    title: "Очистить весь пакет выгрузки?",
    modelValue: _ctx.showModalClear,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModalClear = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Очистить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doClear
  }, null, 8, ["modelValue", "onOk"])]);
}