import "core-js/modules/es.array.push.js";
import { mapActions, mapGetters } from "vuex";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { limitBound } from "@/helpers/text";
export default {
  name: "ProfileNotifications",
  data: () => ({
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    item: {},
    fields: [{
      key: "title",
      label: "Наименование"
    }, {
      key: "sending_date",
      label: "Дата",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateMonthTime)
    }
    // {
    //   key: "glass",
    //   class: "text-center",
    //   label: "Просмотр",
    // },
    ]
  }),
  methods: {
    ...mapActions("homeStats", ["updateNotifyLk", "updateNotifyLk4m"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.notifyLkCount / this.perPage, this.currentPage);
    },
    openNotify(item) {
      this.updateNotifyLk4m(item);
      this.$router.push(item.path);
      // this.$bvModal.msgBoxOk(item.title, {
      //   okVariant: "outline-primary",
      //   noCloseOnBackdrop: true,
      //   centered: true,
      //   okTitle: "Закрыть",
      //   scrollable: true,
      //   hideHeader: true,
      // });
    }
  },
  computed: {
    ...mapGetters("homeStats", ["notifyLkList", "notifyLkCount", "notifyLkLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateNotifyLk({
          page: value
        });
      }
    }
  }
};