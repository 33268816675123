import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "table-responsive"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BTable = _resolveComponent("BTable");
  const _component_BCard = _resolveComponent("BCard");
  return _openBlock(), _createBlock(_component_BCard, null, {
    default: _withCtx(() => [_cache[0] || (_cache[0] = _createElementVNode("h6", null, "Предыдущие задачи", -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_BTable, {
      "thead-class": "d-none",
      borderless: "",
      items: $props.item,
      fields: _ctx.tableFields,
      stacked: "md",
      small: ""
    }, null, 8, ["items", "fields"])])]),
    _: 1
  });
}