import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "flex-md-nowrap"
};
const _hoisted_2 = {
  key: 0,
  class: "mb-0"
};
const _hoisted_3 = {
  href: "#",
  class: "me-3"
};
const _hoisted_4 = ["href"];
const _hoisted_5 = {
  class: "fa-layers fa-fw"
};
const _hoisted_6 = {
  key: 0,
  class: "fa-layers-counter text-error"
};
const _hoisted_7 = {
  class: "position-relative d-inline-flex align-items-center"
};
const _hoisted_8 = {
  key: 0,
  class: "mb-0 small text-muted"
};
const _hoisted_9 = {
  class: "mb-0"
};
const _hoisted_10 = {
  href: "#",
  id: "popover-button-sync",
  class: "stretched-link border-0"
};
const _hoisted_11 = {
  class: "text-muted mb-0"
};
const _hoisted_12 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_nav = _resolveComponent("b-nav");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_nav_item = _resolveComponent("b-nav-item");
  const _component_b_avatar = _resolveComponent("b-avatar");
  const _component_b_dropdown_header = _resolveComponent("b-dropdown-header");
  const _component_b_dropdown_divider = _resolveComponent("b-dropdown-divider");
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_theWorkplace = _resolveComponent("theWorkplace");
  const _component_b_nav_item_dropdown = _resolveComponent("b-nav-item-dropdown");
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav");
  const _component_b_collapse = _resolveComponent("b-collapse");
  const _component_b_navbar = _resolveComponent("b-navbar");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("header", _hoisted_1, [_createVNode(_component_b_navbar, {
    type: "light",
    class: "py-3 px-4 border-bottom"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_nav, {
      class: "p-0 d-none d-md-block"
    }, {
      default: _withCtx(() => [_cache[4] || (_cache[4] = _createElementVNode("p", {
        class: "mb-0 small text-muted"
      }, " Горячая линия по вопросам противодействия коррупции ", -1)), !_ctx.adminCommonLoading ? (_openBlock(), _createElementBlock("p", _hoisted_2, [_createElementVNode("strong", null, [_createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.phoneMask(_ctx.adminCommon.phone)), 1), _createElementVNode("a", {
        href: 'mailto:' + _ctx.adminCommon.email
      }, _toDisplayString(_ctx.adminCommon.email), 9, _hoisted_4)])])) : (_openBlock(), _createBlock(_component_b_spinner, {
        key: 1,
        class: "align-middle text-center text-primary"
      }))]),
      _: 1
    }), _createVNode(_component_b_collapse, {
      id: "nav-collapse",
      "is-nav": ""
    }, {
      default: _withCtx(() => [_createVNode(_component_b_navbar_nav, {
        class: "ms-auto d-flex align-items-center"
      }, {
        default: _withCtx(() => [_ctx.$ff.NOTIFICATION ? _withDirectives((_openBlock(), _createBlock(_component_b_nav_item, {
          key: 0,
          to: _ctx.links.profileNotifications,
          class: "ms-3"
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_5, [_createVNode(_component_font_awesome_icon, {
            icon: "bell",
            "fixed-width": "",
            size: "lg"
          }), _ctx.newNotifyLk.new ? (_openBlock(), _createElementBlock("span", _hoisted_6)) : _createCommentVNode("", true)])]),
          _: 1
        }, 8, ["to"])), [[_directive_can, _ctx.$perm.access_account]]) : _createCommentVNode("", true), _createVNode(_component_b_nav_item_dropdown, {
          right: "",
          class: "ms-3 p-0",
          "no-caret": ""
        }, {
          "button-content": _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, [_createVNode(_component_b_avatar, {
            variant: "primary",
            text: _ctx.userInitials(_ctx.userObj),
            class: "float-left me-2"
          }, null, 8, ["text"])]), _createElementVNode("div", null, [_ctx.userObj.position ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.userObj.position), 1)) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_9, [_createElementVNode("a", _hoisted_10, [_createElementVNode("strong", null, _toDisplayString(_ctx.userObj.first_name || _ctx.NONAME_FIRST) + "  " + _toDisplayString(_ctx.userObj.last_name ? _ctx.userObj.last_name : _ctx.NONAME_LAST), 1)])])])])]),
          default: _withCtx(() => [_createVNode(_component_b_dropdown_header, null, {
            default: _withCtx(() => [_createElementVNode("h6", null, [_createElementVNode("span", null, _toDisplayString(_ctx.userObj.last_name || _ctx.NONAME_LAST), 1), _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.userObj.first_name || _ctx.NONAME_FIRST) + " " + _toDisplayString(_ctx.userObj.middle_name || _ctx.NONAME_MIDDLE), 1)]), _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.userObj.email), 1)]),
            _: 1
          }), _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_dropdown_divider), _createVNode(_component_b_dropdown_header, {
            id: "profileArea"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("span", {
              class: "text-muted"
            }, "Личный кабинет", -1)])),
            _: 1
          }), _createVNode(_component_b_dropdown_item, {
            to: _ctx.links.profileSheets
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Личные листки")])),
            _: 1
          }, 8, ["to"]), _ctx.$ff.DECLARATION ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
            key: 0,
            to: _ctx.links.profileDeclarations
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode(" Декларации ")])),
            _: 1
          }, 8, ["to"])) : _createCommentVNode("", true), _ctx.$ff.EDUCATION ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
            key: 1,
            to: _ctx.links.profileEducation
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode(" Обучение ")])),
            _: 1
          }, 8, ["to"])) : _createCommentVNode("", true), _ctx.$ff.KNOWLEDGE ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
            key: 2,
            to: _ctx.links.profileAcquaintance
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode(" Ознакомление ")])),
            _: 1
          }, 8, ["to"])) : _createCommentVNode("", true), _ctx.$ff.KNOWLEDGE ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
            key: 3,
            to: _ctx.links.knowledgeAlt
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode(" Библиотека знаний ")])),
            _: 1
          }, 8, ["to"])) : _createCommentVNode("", true), _ctx.$ff.NOTIFICATION ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
            key: 4,
            to: _ctx.links.profileNotifications
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode(" Уведомления ")])),
            _: 1
          }, 8, ["to"])) : _createCommentVNode("", true), _createVNode(_component_b_dropdown_item, {
            to: _ctx.links.profileSettings
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode(" Изменить пароль ")])),
            _: 1
          }, 8, ["to"])])), [[_directive_can, _ctx.$perm.access_account]]), _createVNode(_component_b_dropdown_divider), _createVNode(_component_b_dropdown_item, {
            onClick: $options.handlerLogout,
            "data-hook": "logout"
          }, {
            default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
              icon: "sign-out-alt",
              "fixed-width": ""
            }), _cache[14] || (_cache[14] = _createTextVNode("  Выйти "))]),
            _: 1
          }, 8, ["onClick"]), !_ctx.workplacesLoading ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(_ctx.workplacesCount, wp => {
            return _openBlock(), _createElementBlock("div", {
              key: wp
            }, [_createVNode(_component_b_dropdown_divider), _createVNode(_component_b_dropdown_item, {
              onClick: $event => $options.clickWorkplace(wp - 1)
            }, {
              default: _withCtx(() => [_createVNode(_component_theWorkplace, {
                workplaceIndex: wp - 1
              }, null, 8, ["workplaceIndex"])]),
              _: 2
            }, 1032, ["onClick"])]);
          }), 128)) : (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          })])), _ctx.magicLink && !String(_ctx.magicLink).includes(_ctx.textLink4Prod) ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
            key: 2,
            href: _ctx.magicLink,
            variant: "danger",
            class: "text-danger"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode(" ссылка для входа ")])),
            _: 1
          }, 8, ["href"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_modal, {
    modelValue: _ctx.userChangeModal,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.userChangeModal = $event),
    title: _ctx.modalTitle,
    centered: "",
    onOk: $options.handlerLogoutFunc,
    "ok-title": "Да",
    "ok-disabled": _ctx.password === '',
    "cancel-title": "Нет",
    scrollable: "",
    "no-close-on-backdrop": "",
    "button-size": "sm",
    "footer-сass": "p-2",
    "hide-header-close": "",
    "content-сlass": "modal-logout"
  }, {
    default: _withCtx(() => [_createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => $options.handlerLogoutFunc && $options.handlerLogoutFunc(...args), ["stop", "prevent"]))
    }, [_createVNode(_component_b_form_group, {
      label: "Пароль",
      "label-for": "password"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        id: "password",
        required: "",
        type: "password",
        name: "password",
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.password = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    })], 32)]),
    _: 1
  }, 8, ["modelValue", "title", "onOk", "ok-disabled"]), _createVNode(_component_b_modal, {
    title: "Вы хотите выйти?",
    modelValue: _ctx.showModalExit,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModalExit = $event),
    size: "sm",
    "ok-title": "Да",
    "cancel-title": "Нет",
    centered: "",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doLogout
  }, null, 8, ["modelValue", "onOk"])]);
}