import links from "@/router/links";
import theLogin from "./theLogin.vue";
import { VMarkdownView } from "vue3-markdown";
import { mapActions, mapGetters } from "vuex";
import { phoneMask } from "@/helpers/masks";
import { SITE_NAME } from "@/helpers/text.js";
import { agreements } from "@/helpers/auth";
export default {
  name: "theAuth",
  components: {
    theLogin,
    VMarkdownView
  },
  data: () => ({
    phoneMask,
    links,
    SITE_NAME,
    docKey: "pers_data_conscent",
    showModal: false,
    agreements,
    showCookie: true
  }),
  methods: {
    ...mapActions("administration", ["updateCommon"]),
    // показываем текст соглашения
    showText(key) {
      this.docKey = key;
      this.showModal = true;
    }
  },
  computed: {
    ...mapGetters("administration", ["adminCommon", "adminCommonLoading"]),
    coverBg() {
      return {
        // "background-image": `url(${require("@/assets/auth-cover.jpg")})`,
        "background-image": `url(${this.adminCommon.cover})`
      };
    }
  },
  created() {
    this.updateCommon();
  }
};