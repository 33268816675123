import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import { formatDateObj, fmtDate, dateObjInit } from "@/helpers/date";
import ReportByWorker from "./ReportByWorker.vue";
import ReportByProgram from "./ReportByProgram.vue";
import Calendar from "@/components/Helpers/Calendar.vue";
import UserSelector from "@/components/Helpers/UserSelector";
import { fmtNameFull, selectAll } from "@/helpers/text";
import { getStateOptionsFilter } from "@/helpers/declaration";
export default {
  name: "EducationProgramReport",
  data: () => ({
    fmtNameFull,
    worker: {},
    program: null,
    typeSelected: "reportByWorker",
    typeOptions: [{
      value: "reportByWorker",
      text: "Отчёт по работнику"
    }, {
      value: "reportByProgram",
      text: "Отчёт по программе"
    }],
    departmentSelected: null,
    selected: {},
    // параметры формирования отчета
    startDate: {
      ...dateObjInit
    },
    endDate: {
      ...dateObjInit
    }
  }),
  components: {
    ReportByWorker,
    ReportByProgram,
    Calendar,
    UserSelector
  },
  methods: {
    ...mapActions("referenceBooks", ["updateDepartments", "updatePrograms"]),
    ...mapActions("education", ["updateReport", "downloadReport"]),
    ...mapActions(["showNotify"]),
    async clickReport() {
      if (this.typeSelected === "reportByProgram") {
        this.selected.course_id = this.program;
        this.selected.user_id = null;
      } else {
        this.selected.course_id = null;
        this.selected.user_id = this.worker.id;
      }
      this.selected.department_id = this.departmentSelected;
      this.selected.confirmed = true;
      this.selected.state = "finished,failed";
      this.selected.date_start_formal_gte = formatDateObj(this.startDate, fmtDate, null);
      this.selected.date_end_formal_lte = formatDateObj(this.endDate, fmtDate, null);
      await this.updateReport({
        page: 1,
        filters: this.selected
      });
    },
    selectedWorker(worker) {
      this.worker = worker;
    },
    async clickDownload() {
      if (this.typeSelected === "reportByProgram" && !this.program) {
        this.showNotify({
          msg: "Не указана программа обучения",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        return;
      } else if (this.typeSelected === "reportByWorker" && !this.worker.id) {
        this.showNotify({
          msg: "Не выбран работник",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        return;
      }
      const selected = {};
      if (this.typeSelected === "reportByProgram") {
        selected.course_id = this.program;
        selected.user_id = null;
      } else {
        selected.course_id = null;
        selected.user_id = this.worker.id;
      }
      selected.department_id = this.departmentSelected;
      selected.confirmed = true;
      selected.state = "finished,failed";
      selected.date_start_formal_gte = formatDateObj(this.startDate, fmtDate, null);
      selected.date_end_formal_lte = formatDateObj(this.endDate, fmtDate, null);
      await this.downloadReport({
        filters: selected
      });
      this.$refs.fileDownload.click();
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading", "programs", "programsLoading"]),
    ...mapGetters("education", ["reportLoading", "reportFile"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [...selectAll]);
    },
    programOptions() {
      return getStateOptionsFilter(this.programs);
    }
  },
  // в момент создания компоненты обновим справочники
  created() {
    this.updateDepartments();
    this.updatePrograms();
  }
};