import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_table, {
    class: "table-custom",
    hover: "",
    borderless: "",
    items: $options.source,
    fields: _ctx.tableFields,
    stacked: "md",
    small: "",
    responsive: "",
    "show-empty": "",
    "empty-text": "Источники не указаны",
    busy: _ctx.hlineMsgLoading
  }, {
    empty: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.emptyText), 1)]),
    "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString(row.index + 1), 1)]),
    "cell(title)": _withCtx(row => [_createVNode(_component_router_link, {
      to: row.item.link
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
      _: 2
    }, 1032, ["to"])]),
    "cell(remove)": _withCtx(row => [_createVNode(_component_b_button, {
      variant: "link",
      onClick: $event => $options.removeSource(row.item),
      class: "p-0",
      disabled: $options.readOnly
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
        class: "bi bi-trash"
      }, null, -1)])),
      _: 2
    }, 1032, ["onClick", "disabled"])]),
    _: 1
  }, 8, ["items", "fields", "busy"]), _createVNode(_component_b_button, {
    variant: "outline-primary",
    onClick: $options.addSource,
    disabled: $options.readOnly
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Добавить источник ")])),
    _: 1
  }, 8, ["onClick", "disabled"]), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModal = $event),
    id: "modalAddSource",
    title: "Добавить источник",
    centered: "",
    onOk: $options.handlerAdd,
    "ok-title": "Добавить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalAddDisabled,
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_ctx.cpFormLoading || _ctx.innerCheckFormLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
      key: 0,
      class: "text-primary"
    })) : _createCommentVNode("", true), _createVNode(_component_b_form_group, {
      label: "Тип источника"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_select, {
        modelValue: _ctx.typeSource,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.typeSource = $event),
        options: $options.optionsSource
      }, {
        first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
          value: null,
          disabled: ""
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Выберите тип источника ")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "options"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Номер источника"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.idSource,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.idSource = $event),
        trim: "",
        placeholder: "Введите номер",
        type: "number"
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onOk", "ok-disabled"]), _createVNode(_component_b_modal, {
    title: `Удалить источник ${_ctx.currentItem.title}?`,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"])]);
}