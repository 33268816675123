import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "text-danger"
};
const _hoisted_2 = {
  class: "text-danger"
};
const _hoisted_3 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_4 = {
  class: "mt-3"
};
const _hoisted_5 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Номер регистрации *"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.hlineMsg.registration_number,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.hlineMsg.registration_number = $event),
          onChange: _cache[1] || (_cache[1] = $event => $options.changeData('registration_number')),
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата поступления *",
        date: _ctx.hlineMsg.arrivalDate,
        classCustom: "mt-2 no-gutters",
        disabled: $options.readOnly,
        onChangeValue: _cache[2] || (_cache[2] = $event => $options.changeData('arrival_date', $event))
      }, null, 8, ["date", "disabled"]), _withDirectives(_createElementVNode("div", _hoisted_1, " «Дата поступления» должна быть не позднее «Срока исполнения» ", 512), [[_vShow, _ctx.invalidArrivalDate]])]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Источник поступления *"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.hlineMsg.source,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.hlineMsg.source = $event),
          options: _ctx.sourceHotline,
          onChange: _cache[4] || (_cache[4] = $event => $options.changeData('source')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("Не выбран")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Срок исполнения *",
        date: _ctx.hlineMsg.deadLine,
        classCustom: "mt-2 no-gutters",
        disabled: $options.readOnly,
        onChangeValue: _cache[5] || (_cache[5] = $event => $options.changeData('deadline', $event))
      }, null, 8, ["date", "disabled"]), _withDirectives(_createElementVNode("div", _hoisted_2, " «Срок исполнения» не может быть ранее даты внесения сведений ", 512), [[_vShow, _ctx.invalidDeadLine]])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-v": "center"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "4"
    }, {
      default: _withCtx(() => [!_ctx.hlCategoryLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Тип сообщения *"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.hlineMsg.message_type,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.hlineMsg.message_type = $event),
          options: $options.optionsCategory,
          onChange: _cache[7] || (_cache[7] = $event => $options.changeData('message_type')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("Не выбрана")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "1",
      class: "ps-0 pe-0"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString($options.relevantHlText(_ctx.hlineMsg.is_relevant)), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "3",
      class: "ps-0 pe-0"
    }, {
      default: _withCtx(() => [_cache[15] || (_cache[15] = _createElementVNode("div", null, "Категория сообщения:", -1)), _createElementVNode("div", null, _toDisplayString($options.categoryName), 1)]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [!_ctx.organizationsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Организация *"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.hlineMsg.referenced_org,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.hlineMsg.referenced_org = $event),
          options: $options.optionsOrganization,
          onChange: _cache[9] || (_cache[9] = $event => $options.changeData('referenced_org')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode(" Не выбрано ")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Суть сообщения *"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.hlineMsg.content,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.hlineMsg.content = $event),
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[11] || (_cache[11] = $event => $options.changeData('content')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  })]);
}