import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "table-footer"
};
import { computed } from "vue";
import { useStore } from "vuex";
import { statesRiskMatrix } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound } from "@/helpers/text";
import links from "@/router/links";
import { useRouter } from "vue-router";
const perPage = 20;
export default {
  __name: 'MatrixList',
  setup(__props) {
    const router = useRouter();
    const store = useStore();
    const tableFields = [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID",
      class: "text-nowrap",
      formatter: value => `Матрица рисков #${value}`
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "approved_at",
      label: "Дата утверждения",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "confirmed_at",
      label: "Дата согласования",
      formatter: value => formatDate(value, fmtDateShort),
      class: "text-nowrap"
    }, {
      key: "declined_at",
      label: "Дата отклонения",
      formatter: value => formatDate(value, fmtDateShort),
      class: "text-nowrap"
    }, {
      key: "state",
      label: "Статус",
      formatter: value => {
        return statesRiskMatrix[value];
      }
    }];
    const matrixCount = computed(() => store.getters["risk/matrixCount"]);
    const matrix = computed(() => store.getters["risk/matrix"]);
    const matrixPage = computed(() => store.getters["risk/matrixPage"]);
    const matrixLoading = computed(() => store.getters["risk/matrixLoading"]);
    const changePage = newPage => {
      store.dispatch("risk/setMatrixPage", limitBound(Number(newPage), 1, matrixCount.value / perPage, matrixPage.value));
      store.dispatch("risk/updateMatrix");
    };
    const rowClicked = data => {
      router.push(links.matrixForm + data.id);
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_table, {
        class: "table-custom table-pointer",
        hover: "",
        small: "",
        responsive: "",
        "show-empty": "",
        stacked: "md",
        items: matrix.value,
        fields: tableFields,
        busy: matrixLoading.value,
        onRowClicked: rowClicked
      }, {
        "cell(id)": _withCtx(row => [_createVNode(_component_router_link, {
          class: "link",
          to: _unref(links).matrixForm + row.item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
          _: 2
        }, 1032, ["to"])]),
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((matrixPage.value - 1) * perPage + row.index + 1), 1)]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Матриц рисков нет", -1)])),
          _: 1
        })]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        _: 1
      }, 8, ["items", "busy"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "4"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            totalRows: matrixCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, matrixCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })])]);
    };
  }
};