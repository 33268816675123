import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "work"
};
const _hoisted_2 = {
  class: "controlled-jp-position"
};
const _hoisted_3 = {
  class: "controlled-jp-name"
};
const _hoisted_4 = {
  class: "controlled-jp-tin"
};
const _hoisted_5 = {
  class: "controlled-jp-address"
};
const _hoisted_6 = {
  id: "input-list-entity-address"
};
const _hoisted_7 = ["value"];
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  colspan: "4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12 mt-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "18. Занимаете ли Вы в настоящее время должности в органах управления\n        юридических лиц, в том числе зарегистрированных за пределами Российской\n        Федерации?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          class: "w-auto",
          modelValue: _ctx.profileObj.has_entity_board_positions,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_entity_board_positions = $event),
          disabled: $options.readonly,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_entity_board_positions')),
          state: _ctx.has_entity_board_positions_state,
          options: _ctx.optionYesNo
        }, null, 8, ["modelValue", "disabled", "state", "options"])]),
        _: 1
      })]),
      _: 1
    }), _withDirectives(_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[6] || (_cache[6] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Должность"), _createElementVNode("th", null, "Наименование юридического лица"), _createElementVNode("th", null, "ИНН юридического лица"), _createElementVNode("th", null, "Адрес юридического лица")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.controlledOrganisations, entity => {
          return _openBlock(), _createElementBlock("tr", {
            key: entity.id,
            class: "controlled-jp"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_input, {
            modelValue: entity.position,
            "onUpdate:modelValue": $event => entity.position = $event,
            onChange: $event => $options.submitDataEndpoint(entity, 'position', 'controlled', _ctx.endpointControlled),
            state: entity.position_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_cache[3] || (_cache[3] = _createElementVNode("small", null, "Организационно-правовая форма", -1)), _createVNode(_component_b_form_input, {
            modelValue: entity.organization_form,
            "onUpdate:modelValue": $event => entity.organization_form = $event,
            onChange: $event => $options.submitDataEndpoint(entity, 'organization_form', 'controlled', _ctx.endpointControlled),
            state: entity.organization_form_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _cache[4] || (_cache[4] = _createElementVNode("small", null, "Наименование", -1)), _createVNode(_component_b_form_input, {
            modelValue: entity.org_name,
            "onUpdate:modelValue": $event => entity.org_name = $event,
            onChange: $event => $options.submitDataEndpoint(entity, 'org_name', 'controlled', _ctx.endpointControlled),
            state: entity.org_name_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_input, {
            modelValue: entity.inn,
            "onUpdate:modelValue": $event => entity.inn = $event,
            onChange: $event => $options.submitDataEndpoint(entity, 'inn', 'controlled', _ctx.endpointControlled),
            state: entity.inn_state,
            disabled: $options.readonly,
            type: "number",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_5, [_createVNode(_component_b_form_group, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: entity.address,
              "onUpdate:modelValue": $event => entity.address = $event,
              onChange: $event => $options.submitDataEndpoint(entity, 'address', 'controlled', _ctx.endpointControlled),
              state: entity.address_state,
              disabled: $options.readonly,
              type: "text",
              trim: "",
              list: "input-list-entity-address",
              onInput: $event => $options.inputData(entity.address)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled", "onInput"]), _createElementVNode("datalist", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataAddress, (address, index) => {
              return _openBlock(), _createElementBlock("option", {
                key: index,
                value: address
              }, _toDisplayString(address), 9, _hoisted_7);
            }), 128))])]),
            _: 2
          }, 1024), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.controlledOrganisations, entity.id, 'controlled', _ctx.endpointControlled)
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_8, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_9, [_createVNode(_component_AddRow, {
          onClicked: _cache[2] || (_cache[2] = $event => $options.addDataEndpoint(_ctx.controlledOrganisations, 'controlled', _ctx.endpointControlled, _ctx.templateControlled, _ctx.statesControlled))
        })])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    }, 512), [[_vShow, _ctx.profileObj.has_entity_board_positions]])]),
    _: 1
  });
}