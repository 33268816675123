import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_DeclarationListFull = _resolveComponent("DeclarationListFull");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_COI = _resolveComponent("COI");
  const _component_ReportHeader = _resolveComponent("ReportHeader");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createBlock(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom",
      modelValue: _ctx.tabIndex,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.tabIndex = $event)
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        class: "px-0",
        active: "",
        onClick: $options.clickTabs
      }, {
        title: _withCtx(() => [_cache[1] || (_cache[1] = _createTextVNode("Декларации о конфликте интересов ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.declarationsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_DeclarationListFull)]),
          _: 1
        })]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_b_tab, {
        class: "px-0",
        onClick: $options.clickTabs
      }, {
        title: _withCtx(() => [_cache[2] || (_cache[2] = _createTextVNode("Конфликты интересов ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coiCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_COI)]),
          _: 1
        })]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_b_tab, {
        onClick: $options.clickTabs
      }, {
        title: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Отчёты ")])),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_ReportHeader)]),
          _: 1
        })]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  });
}