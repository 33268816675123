import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_button = _resolveComponent("b-button");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    label: "IP-адрес, с которого было совершено действие"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.ip_address,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filters.ip_address = $event),
      type: "text",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Логин, который совершил действие"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.user_login,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.filters.user_login = $event),
      type: "text",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Логин, в отношении которого совершено действие"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.target_user_login,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.filters.target_user_login = $event),
      type: "text",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Событие"
  }, {
    default: _withCtx(() => [_ctx.secEventsLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
      key: 0,
      class: "align-middle"
    })) : (_openBlock(), _createBlock(_component_b_form_select, {
      key: 1,
      modelValue: _ctx.filters.event,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.filters.event = $event),
      options: $options.sourceEvents
    }, null, 8, ["modelValue", "options"]))]),
    _: 1
  }), _withDirectives((_openBlock(), _createBlock(_component_b_form_group, {
    label: "Организация"
  }, {
    default: _withCtx(() => [_ctx.organizationsLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
      key: 0,
      class: "align-middle"
    })) : (_openBlock(), _createBlock(_component_b_form_select, {
      key: 1,
      modelValue: _ctx.filters.organization_id,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.filters.organization_id = $event),
      options: $options.sourceOrgs
    }, null, 8, ["modelValue", "options"]))]),
    _: 1
  })), [[_directive_can, _ctx.$perm.list_orgs]]), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата события от... до..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.timestamp_gte,
        classCustom: "no-gutters",
        onChangeValue: _cache[5] || (_cache[5] = $event => _ctx.filters.timestamp_gte = $event)
      }, null, 8, ["date"]), _createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.timestamp_lte,
        classCustom: "no-gutters",
        onChangeValue: _cache[6] || (_cache[6] = $event => _ctx.filters.timestamp_lte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_button, {
    block: "",
    variant: "primary",
    onClick: $options.clickApplyFilters,
    class: "mt-3 me-3"
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Применить ")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_button, {
    block: "",
    variant: "outline-secondary",
    onClick: $options.clearFilters,
    class: "mt-3"
  }, {
    default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Сбросить фильтры ")])),
    _: 1
  }, 8, ["onClick"])]);
}