import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "ms-2"
};
const _hoisted_2 = {
  class: "ms-2"
};
const _hoisted_3 = {
  class: "ms-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_TaskButton = _resolveComponent("TaskButton");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_alert = _resolveComponent("b-alert");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, {
    class: "mb-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      class: "h1"
    }, {
      default: _withCtx(() => [_createTextVNode("Контрольная процедура #" + _toDisplayString(_ctx.cpForm.id), 1)]),
      _: 1
    }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.stateBtns, (btn, index) => {
      return _openBlock(), _createBlock(_component_b_col, {
        cols: "auto",
        key: index
      }, {
        default: _withCtx(() => [btn.type === 'simple' ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          variant: btn.variant,
          onClick: $event => $options.clickAction(btn, {})
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(btn.text), 1)]),
          _: 2
        }, 1032, ["variant", "onClick"])), [[_directive_can, btn.perm]]) : _createCommentVNode("", true), btn.type === 'taskBtn' ? _withDirectives((_openBlock(), _createBlock(_component_TaskButton, {
          key: 1,
          idModal: btn.modal,
          settings: btn.settings,
          onAddData: $event => $options.clickAction(btn, $event)
        }, null, 8, ["idModal", "settings", "onAddData"])), [[_directive_can, btn.perm]]) : _createCommentVNode("", true)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }), _createElementVNode("div", null, [_cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "text-muted"
  }, "Статус", -1)), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.statesCp[_ctx.cpForm.state]), 1), _cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "ms-4 text-muted"
  }, "Дата создания", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString($options.formatDate(_ctx.cpForm.created_at, _ctx.fmtDateShort)), 1), _cache[3] || (_cache[3] = _createElementVNode("span", {
    class: "ms-4 text-muted"
  }, "Дата назначения", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString($options.formatDate(_ctx.cpForm.assigned_at, _ctx.fmtDateShort)), 1)]), _createElementVNode("div", null, [_cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "text-muted me-2"
  }, "Работник", -1)), _createElementVNode("span", null, _toDisplayString($options.targetUsers), 1)]), _createElementVNode("div", null, [_cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "text-muted me-2"
  }, "Ответственный исполнитель", -1)), _createElementVNode("span", null, _toDisplayString($options.formatName(_ctx.cpForm.assignee_detail, _ctx.fmtNameShort)), 1), _cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "text-muted ms-4 me-2"
  }, "Плановая дата", -1)), _createElementVNode("span", null, _toDisplayString($options.formatDate(_ctx.cpForm.date_planned, _ctx.fmtDateShort)), 1)]), _createVNode(_component_b_alert, {
    modelValue: _ctx.showAlert,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showAlert = $event),
    variant: "danger",
    dismissible: ""
  }, {
    default: _withCtx(() => [_cache[7] || (_cache[7] = _createElementVNode("div", null, "Неверно заполнены обязательные поля", -1)), _createElementVNode("div", null, _toDisplayString(_ctx.alertTitle), 1)]),
    _: 1
  }, 8, ["modelValue"])]);
}