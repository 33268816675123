import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  id: "sidebar",
  class: "d-flex flex-column navbar-dark bg-primary sidebar collapse text-left text-white shadow-sm"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_navbar_brand = _resolveComponent("b-navbar-brand");
  const _component_b_nav_item = _resolveComponent("b-nav-item");
  const _component_b_nav = _resolveComponent("b-nav");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createVNode(_component_b_navbar_brand, {
    to: _ctx.links.dashboard,
    class: "p-3"
  }, {
    default: _withCtx(() => [_ctx.adminCommonLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
      key: 0,
      class: "align-middle text-center text-primary"
    })) : (_openBlock(), _createElementBlock("img", {
      key: 1,
      src: _ctx.adminCommon.logo_min,
      class: "d-inline-block align-top",
      style: {
        "max-height": "2.5rem"
      }
    }, null, 8, _hoisted_2))]),
    _: 1
  }, 8, ["to"]), _createVNode(_component_b_nav, {
    vertical: "",
    class: "mb-auto p-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_nav_item, {
      id: _ctx.links.personalArea,
      to: _ctx.links.personalArea,
      exact: "",
      "exact-active-class": "active",
      title: "Панель управления"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("i", {
        class: "h5 mdto-iconset icon-dashboard"
      }, null, -1)])),
      _: 1
    }, 8, ["id", "to"]), _cache[1] || (_cache[1] = _createElementVNode("hr", {
      class: "my-2"
    }, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.personalAreaCards, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "items-wrap",
        key: index
      }, [_withDirectives((_openBlock(), _createBlock(_component_b_nav_item, {
        id: item.link,
        to: item.link,
        disabled: item.link ? false : true,
        exact: "",
        "exact-active-class": "active",
        title: item.title
      }, {
        default: _withCtx(() => [_createElementVNode("i", {
          class: _normalizeClass(["h5 mdto-iconset", item.icon])
        }, null, 2)]),
        _: 2
      }, 1032, ["id", "to", "disabled", "title"])), [[_directive_can, item.perm]])]);
    }), 128))]),
    _: 1
  })]);
}