import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_SectionAdd = _resolveComponent("SectionAdd");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_KnowledgeDocuments = _resolveComponent("KnowledgeDocuments");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_KnowledgeArchive = _resolveComponent("KnowledgeArchive");
  const _component_KnowledgeReports = _resolveComponent("KnowledgeReports");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: $options.breadcrumbs
  }, null, 8, ["breadCrumb"]), !_ctx.sectionFormLoading ? (_openBlock(), _createBlock(_component_b_row, {
    key: 0,
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, _toDisplayString(_ctx.sectionForm.title), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [!$options.isAltUI ? _withDirectives((_openBlock(), _createBlock(_component_SectionAdd, {
        key: 0,
        setting: _ctx.setting,
        onAddSection: $options.clickChange,
        item: _ctx.sectionForm
      }, null, 8, ["setting", "onAddSection", "item"])), [[_directive_can, _ctx.$perm.change_wa_libsection]]) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [!$options.isAltUI ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        onClick: $options.clickAdd,
        variant: "primary"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode(" Создать ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_libmaterial]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        class: "px-0"
      }, {
        title: _withCtx(() => [_cache[2] || (_cache[2] = _createTextVNode("Действующие ЛНА ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.sectionsDocumentsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_KnowledgeDocuments, {
            section: $props.msg
          }, null, 8, ["section"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        class: "px-0"
      }, {
        title: _withCtx(() => [_cache[3] || (_cache[3] = _createTextVNode("Архивные ЛНА ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.sectionsArchiveCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_KnowledgeArchive, {
            section: $props.msg
          }, null, 8, ["section"])]),
          _: 1
        })]),
        _: 1
      }), !$options.isAltUI ? (_openBlock(), _createBlock(_component_b_tab, {
        key: 0,
        title: "Отчёты"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_KnowledgeReports, {
            section: $props.msg
          }, null, 8, ["section"])]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  })]);
}