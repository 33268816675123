import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id", "title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_avatar = _resolveComponent("b-avatar");
  return _openBlock(), _createElementBlock("div", {
    id: $props.target ? $props.target : 'target',
    title: `${$props.lastname} ${$props.firstname} ${$props.middlename}`,
    class: _normalizeClass($props.firstname || $props.middlename || $props.lastname ? 'd-inline' : 'd-inline text-gray-400')
  }, [$props.firstname || $props.middlename || $props.lastname ? (_openBlock(), _createBlock(_component_b_avatar, {
    key: 0,
    class: "me-1",
    size: 20,
    variant: "primary",
    text: ($props.firstname ? $props.firstname.substring(0, 1) : '') + ($props.lastname ? $props.lastname.substring(0, 1) : '')
  }, null, 8, ["text"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($props.firstname || $props.middlename || $props.lastname ? $options.formatName({
    first_name: $props.firstname,
    last_name: $props.lastname,
    middle_name: $props.middlename
  }, _ctx.fmtNameShort) : "—"), 1)], 10, _hoisted_1);
}