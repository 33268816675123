import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "mb-2 mt-2"
};
const _hoisted_2 = {
  class: "ms-2"
};
const _hoisted_3 = {
  class: "ms-2"
};
const _hoisted_4 = {
  class: "ms-2"
};
const _hoisted_5 = {
  class: "ms-2"
};
const _hoisted_6 = {
  class: "ms-2"
};
const _hoisted_7 = {
  key: 3,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_MatrixRiskList = _resolveComponent("MatrixRiskList");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_MatrixCheckList = _resolveComponent("MatrixCheckList");
  const _component_MatrixChangeList = _resolveComponent("MatrixChangeList");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), !_ctx.matrixFormLoading ? (_openBlock(), _createBlock(_component_b_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, "Матрица рисков #" + _toDisplayString(_ctx.matrixForm.id), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "primary",
        to: _ctx.links.matrixFormPrint + _ctx.matrixForm.id,
        target: "_blank"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Печать матрицы ")])),
        _: 1
      }, 8, ["to"])]),
      _: 1
    }), _ctx.statesRiskMatrix[_ctx.matrixForm.state] === _ctx.statesRiskMatrix.draft ? (_openBlock(), _createBlock(_component_b_col, {
      key: 0,
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "primary",
        onClick: _cache[0] || (_cache[0] = $event => $options.clickStateMatrix('submitted', 'Матрица направлена на согласование'))
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Направить на согласование ")])),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true), _ctx.statesRiskMatrix[_ctx.matrixForm.state] === _ctx.statesRiskMatrix.submitted ? _withDirectives((_openBlock(), _createBlock(_component_b_col, {
      key: 1,
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-danger",
        onClick: _cache[1] || (_cache[1] = $event => $options.clickStateMatrix('draft', 'Матрица возвращена на доработку'))
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Вернуть на доработку ")])),
        _: 1
      }), _createVNode(_component_b_button, {
        variant: "primary",
        class: "ms-3",
        onClick: _cache[2] || (_cache[2] = $event => $options.clickStateMatrix('approved', 'Матрица направлена на проверку'))
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Направить на проверку ")])),
        _: 1
      })]),
      _: 1
    })), [[_directive_can, _ctx.$perm.approve_wa_matrix]]) : _createCommentVNode("", true), _ctx.statesRiskMatrix[_ctx.matrixForm.state] === _ctx.statesRiskMatrix.approved && _ctx.matrixForm.assigned_check_id ? (_openBlock(), _createBlock(_component_b_col, {
      key: 2,
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-danger",
        onClick: _cache[3] || (_cache[3] = $event => $options.clickStateCheck('declined', 'Матрица отклонена'))
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Отклонить матрицу ")])),
        _: 1
      }), _createVNode(_component_b_button, {
        variant: "primary",
        class: "ms-3",
        onClick: _cache[4] || (_cache[4] = $event => $options.clickStateCheck('confirmed', 'Матрица согласована'))
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("Согласовать матрицу ")])),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  })) : _createCommentVNode("", true), !_ctx.matrixFormLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_cache[11] || (_cache[11] = _createElementVNode("span", {
    class: "text-muted"
  }, "Статус", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.statesRiskMatrix[_ctx.matrixForm.state]), 1), _cache[12] || (_cache[12] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Создана", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString($options.formatDate(_ctx.matrixForm.created_at, _ctx.fmtDateShort)), 1), _cache[13] || (_cache[13] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Одобрена", -1)), _createElementVNode("span", _hoisted_4, _toDisplayString($options.formatDate(_ctx.matrixForm.approved_at, _ctx.fmtDateShort)), 1), _cache[14] || (_cache[14] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Согласована", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString($options.formatDate(_ctx.matrixForm.confirmed_at, _ctx.fmtDateShort)), 1), _cache[15] || (_cache[15] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Отклонена", -1)), _createElementVNode("span", _hoisted_6, _toDisplayString($options.formatDate(_ctx.matrixForm.declined_at, _ctx.fmtDateShort)), 1)])) : _createCommentVNode("", true), !_ctx.matrixFormLoading ? (_openBlock(), _createBlock(_component_b_card, {
    key: 2,
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      card: "",
      pills: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        class: "px-0"
      }, {
        title: _withCtx(() => [_cache[16] || (_cache[16] = _createTextVNode("Риски ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixFormRisksCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_MatrixRiskList)]),
          _: 1
        })]),
        _: 1
      }), _ctx.statesRiskMatrix[_ctx.matrixForm.state] !== _ctx.statesRiskMatrix.draft ? _withDirectives((_openBlock(), _createBlock(_component_b_tab, {
        key: 0,
        class: "px-0"
      }, {
        title: _withCtx(() => [_cache[17] || (_cache[17] = _createTextVNode("Проверки ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixFormChecksCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_MatrixCheckList)]),
          _: 1
        })]),
        _: 1
      })), [[_directive_can, _ctx.$perm.view_wa_matrix_checks]]) : _createCommentVNode("", true), _createVNode(_component_b_tab, {
        class: "px-0"
      }, {
        title: _withCtx(() => [_cache[18] || (_cache[18] = _createTextVNode("Журнал изменений ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixFormChangeCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_MatrixChangeList)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true), _ctx.matrixFormLoading ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true)]);
}