import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "family"
};
const _hoisted_2 = {
  class: "relation"
};
const _hoisted_3 = {
  class: "fullname"
};
const _hoisted_4 = {
  class: "birthday"
};
const _hoisted_5 = {
  class: "job"
};
const _hoisted_6 = {
  class: "inn"
};
const _hoisted_7 = {
  class: "position"
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  colspan: "5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _directive_b_tooltip = _resolveDirective("b-tooltip");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12",
      class: "mb-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "34. Родственники"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
          modelValue: _ctx.profileObj.has_relatives_agreement,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_relatives_agreement = $event),
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_relatives_agreement')),
          state: _ctx.has_relatives_agreement_state,
          disabled: _ctx.readonly
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" Родственники представили письменное согласие на обработку их персональных данных ")])),
          _: 1
        }, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_createElementVNode("thead", null, [_createElementVNode("tr", null, [_cache[9] || (_cache[9] = _createElementVNode("th", null, " Степень родства (жена, муж, дети, отец, мать, брат, сестра) ", -1)), _cache[10] || (_cache[10] = _createElementVNode("th", null, "Фамилия, имя, отчество (полностью)", -1)), _cache[11] || (_cache[11] = _createElementVNode("th", null, "Год рождения", -1)), _createElementVNode("th", null, [_cache[4] || (_cache[4] = _createTextVNode(" Место работы, учебы (наименование юридического лица) ")), _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          variant: "outline",
          class: "p-0 lh-1"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("small", null, [_createElementVNode("i", {
            class: "bi bi-question-circle"
          })], -1)])),
          _: 1
        })), [[_directive_b_tooltip, 'сведения заполняются при условии предоставления согласия родственника на обработку его персональных данных, которое прилагается к настоящему листку', void 0, {
          click: true,
          right: true
        }]])]), _createElementVNode("th", null, [_cache[6] || (_cache[6] = _createTextVNode(" ИНН юридического лица")), _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          variant: "outline",
          class: "p-0 lh-1"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("small", null, [_createElementVNode("i", {
            class: "bi bi-question-circle"
          })], -1)])),
          _: 1
        })), [[_directive_b_tooltip, 'сведения заполняются при условии предоставления согласия родственника на обработку его персональных данных, которое прилагается к настоящему листку', void 0, {
          click: true,
          right: true
        }]])]), _createElementVNode("th", null, [_cache[8] || (_cache[8] = _createTextVNode(" Должность по месту работы, статус обучающегося ")), _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          variant: "outline",
          class: "p-0 lh-1"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("small", null, [_createElementVNode("i", {
            class: "bi bi-question-circle"
          })], -1)])),
          _: 1
        })), [[_directive_b_tooltip, 'сведения заполняются при условии предоставления согласия родственника на обработку его персональных данных, которое прилагается к настоящему листку', void 0, {
          click: true,
          right: true
        }]])])])]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatives, relative => {
          return _openBlock(), _createElementBlock("tr", {
            key: relative.id,
            class: "relative-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_select, {
            modelValue: relative.relation_degree,
            "onUpdate:modelValue": $event => relative.relation_degree = $event,
            options: $options.relativeOptions,
            onChange: $event => $options.submitDataEndpoint(relative, 'relation_degree'),
            state: relative.relation_degree_state,
            disabled: _ctx.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
            modelValue: relative.last_name,
            "onUpdate:modelValue": $event => relative.last_name = $event,
            placeholder: "Фамилия",
            onChange: $event => $options.submitDataEndpoint(relative, 'last_name'),
            state: relative.last_name_state,
            disabled: _ctx.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _createVNode(_component_b_form_input, {
            modelValue: relative.first_name,
            "onUpdate:modelValue": $event => relative.first_name = $event,
            placeholder: "Имя",
            onChange: $event => $options.submitDataEndpoint(relative, 'first_name'),
            state: relative.first_name_state,
            disabled: _ctx.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _createVNode(_component_b_form_input, {
            modelValue: relative.middle_name,
            "onUpdate:modelValue": $event => relative.middle_name = $event,
            placeholder: "Отчество",
            onChange: $event => $options.submitDataEndpoint(relative, 'middle_name'),
            state: relative.middle_name_state,
            disabled: _ctx.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_select, {
            modelValue: relative.birth_year,
            "onUpdate:modelValue": $event => relative.birth_year = $event,
            options: _ctx.yearsOptions,
            disabled: _ctx.readonly,
            state: relative.birth_year_state,
            onChange: $event => $options.submitDataEndpoint(relative, 'birth_year')
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select_option, {
              value: null,
              disabled: ""
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("Год ")])),
              _: 1
            })]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "options", "disabled", "state", "onChange"])]), _createElementVNode("td", _hoisted_5, [_cache[13] || (_cache[13] = _createElementVNode("small", null, "Организационно-правовая форма", -1)), _createVNode(_component_b_form_input, {
            modelValue: relative.organization_form,
            "onUpdate:modelValue": $event => relative.organization_form = $event,
            placeholder: "ОПФ",
            onChange: $event => $options.submitDataEndpoint(relative, 'organization_form'),
            state: relative.organization_form_state,
            disabled: _ctx.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _cache[14] || (_cache[14] = _createElementVNode("small", null, "Наименование", -1)), _createVNode(_component_b_form_input, {
            modelValue: relative.org_name,
            "onUpdate:modelValue": $event => relative.org_name = $event,
            placeholder: "Наименование",
            onChange: $event => $options.submitDataEndpoint(relative, 'org_name'),
            state: relative.org_name_state,
            disabled: _ctx.readonly,
            trim: "",
            type: "text"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_b_form_input, {
            type: "number",
            modelValue: relative.inn,
            "onUpdate:modelValue": $event => relative.inn = $event,
            placeholder: "ИНН юридического лица",
            onChange: $event => $options.submitDataEndpoint(relative, 'inn'),
            state: relative.inn_state,
            disabled: _ctx.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_7, [_createVNode(_component_b_form_input, {
            modelValue: relative.position,
            "onUpdate:modelValue": $event => relative.position = $event,
            placeholder: "Должность",
            onChange: $event => $options.submitDataEndpoint(relative, 'position'),
            state: relative.position_state,
            disabled: _ctx.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), !_ctx.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete triple-height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(relative.id)
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !_ctx.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_8, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_9, [_createVNode(_component_AddRow, {
          onClicked: $options.addDataEndpoint
        }, null, 8, ["onClicked"])])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}