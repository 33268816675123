import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "bi bi-trash"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_router_link = _resolveComponent("router-link");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_withDirectives(_createVNode(_component_b_card_header, {
    "header-class": "p-0 m-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto ms-auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
          "total-rows": _ctx.declarationsCount,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.declarationsCount > _ctx.perPage]]), _createVNode(_component_b_table, {
    class: "table-custom",
    hover: "",
    items: _ctx.declarationsObj,
    fields: _ctx.fields,
    busy: _ctx.declarationsLoading,
    stacked: "md",
    small: "",
    responsive: "",
    "show-empty": ""
  }, {
    empty: _withCtx(() => [_createVNode(_component_b_alert, {
      show: "",
      variant: "light",
      class: "text-center"
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
        src: _imports_0,
        alt: "empty",
        class: "mb-4"
      }, null, -1), _createElementVNode("h4", null, "Деклараций нет", -1)])),
      _: 1
    })]),
    "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((_ctx.declarationsPage - 1) * _ctx.perPage + row.index + 1), 1)]),
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    "cell(changeDecl)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_router_link, {
      class: "router-link-wrap",
      to: _ctx.links.profileDeclarationForm + row.item.id
    }, {
      default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("i", {
        class: "bi bi-pencil-square"
      }, null, -1)])),
      _: 2
    }, 1032, ["to"])), [[_directive_can, _ctx.$perm.change_my_coid]])]),
    "cell(cloneDecl)": _withCtx(row => [_withDirectives(_createElementVNode("i", {
      class: "bi bi-files",
      role: "button",
      onClick: $event => $options.cloneDecl(row.item.id)
    }, null, 8, _hoisted_2), [[_directive_can, _ctx.$perm.copy_my_coid]])]),
    "cell(removeDecl)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
      variant: "link",
      onClick: $event => $options.removeDecl(row.item.id),
      disabled: !['new', 'draft'].includes(row.item.state),
      class: "p-0"
    }, {
      default: _withCtx(() => [_withDirectives(_createElementVNode("i", _hoisted_3, null, 512), [[_directive_can, _ctx.$perm.change_my_coid]])]),
      _: 2
    }, 1032, ["onClick", "disabled"])), [[_directive_can, _ctx.$perm.change_my_coid]])]),
    _: 1
  }, 8, ["items", "fields", "busy"]), _createVNode(_component_b_modal, {
    title: `Удалить декларацию ${_ctx.currentId}?`,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"]), _createVNode(_component_b_modal, {
    title: `Дублировать декларацию ${_ctx.currentId}?`,
    modelValue: _ctx.showModalCopy,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModalCopy = $event),
    "ok-variant": "primary",
    "ok-title": "Дублировать",
    "cancel-variant": "secondary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doCopy
  }, null, 8, ["title", "modelValue", "onOk"])])), [[_directive_can, _ctx.$perm.view_my_coid]]);
}