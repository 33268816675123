import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, {
    class: "px-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Вид отчёта"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.typeSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.typeSelected = $event),
          options: _ctx.typeOptionsRisk
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Период"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.periodSelected,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.periodSelected = $event),
          options: _ctx.periodOptions
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "3"
    }, {
      default: _withCtx(() => [!_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Подразделение"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.departmentSelected,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.departmentSelected = $event),
          options: $options.departmentOptions
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createVNode(_component_b_button, {
          variant: "outline-primary"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Сформировать отчёт")])),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}