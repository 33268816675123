import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "OldNames",
  data: () => ({
    optionYesNo,
    has_old_names_state: null,
    fullNames: [],
    endpoint: "old-names",
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    template: {
      first_name: null,
      last_name: null,
      middle_name: null
    },
    states: {
      first_name_state: null,
      last_name_state: null,
      middle_name_state: null
    }
  }),
  created() {
    this.fullNames = this.profileObj.old_names ? this.profileObj.old_names.map(record => ({
      ...record,
      ...this.states
    })) : [];
    this.profileObjSaved = this.profileObj.old_names ? this.profileObj.old_names.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitData() {
      this.has_old_names_state = false;
      this.updateProfile({
        id: this.profileObj.id,
        has_old_names: this.profileObj.has_old_names
      }).then(() => {
        setValueTimeout(this, "has_old_names_state");
      });
    },
    submitDataEndpoint(obj, key) {
      if (this.profileObjSaved[obj.id][key] === obj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: obj[key]
        },
        endpoint: this.endpoint
      }).then(response => {
        if (response && response.id) {
          this.profileObjSaved[response.id] = response;
          setValueTimeout(obj, state);
        }
      });
    },
    addDataEndpoint() {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        if (response && response.id) {
          this.profileObjSaved[response.id] = {
            ...this.template
          };
          this.fullNames.push({
            id: response.id,
            ...this.states,
            ...this.template
          });
        }
      });
    },
    removeDataEndpoint(id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = this.fullNames.findIndex(el => el.id === id);
        delete this.profileObjSaved[id];
        this.fullNames.splice(index, 1);
      });
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};