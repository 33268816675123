import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "table-header"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "table-footer"
};
const _hoisted_4 = {
  class: "mb-2"
};
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import HotLineListSidebar from "./HotLineListSidebar.vue";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { limitBound, NONUMBER, fmtNameShort, formatName, hlineDefaultSort } from "@/helpers/text";
import links from "@/router/links";
import { statesHotline } from "@/helpers/states";
const perPage = 20;
export default {
  __name: 'HotLineList',
  setup(__props) {
    const router = useRouter();
    const store = useStore();
    const currentId = ref(null);
    const showModalRemove = ref(false);
    const showSidebar = ref(false);
    const currentPage = ref(1);
    const filters = ref({});
    const sort = ref(hlineDefaultSort);
    const tableFields = [{
      key: "num",
      label: "№ п/п",
      class: "text-nowrap"
    }, {
      key: "message_number",
      label: "UID",
      stickyColumn: true,
      class: "text-nowrap"
    }, {
      key: "applicant_full_name",
      label: "Автор"
    }, {
      key: "submitted_at",
      label: "Дата создания",
      class: "text-nowrap"
    }, {
      key: "source",
      label: "Источник"
    }, {
      key: "deadline",
      label: "Дата завершения (план)",
      class: "text-nowrap"
    }, {
      key: "curator_detail",
      label: "Руководитель",
      class: "text-nowrap"
    }, {
      key: "assignee_detail",
      label: "Исполнитель",
      class: "text-nowrap"
    }, {
      key: "state",
      label: "Статус"
    }, {
      key: "state_modified_at",
      label: "Дата изменения",
      class: "text-nowrap"
    }, {
      key: "response_number",
      label: "Номер ответа",
      class: "text-nowrap"
    }, {
      key: "response_date",
      label: "Дата ответа",
      class: "text-nowrap"
    }, {
      key: "btnRemove",
      label: ""
    }];
    const optionsSort = [{
      value: "year,message_number",
      text: "по номеру"
    }, {
      value: "submitted_at",
      text: "по дате регистрации"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "state_modified_at",
      text: "по дате изменения"
    }, {
      value: "applicant_full_name",
      text: "по заявителю"
    }, {
      value: "response_number",
      text: "по документу"
    }, {
      value: "response_date",
      text: "по дате документа"
    }, {
      value: "source",
      text: "по источнику"
    }, {
      value: "deadline",
      text: "по контрольному сроку"
    }, {
      value: "curator__last_name,curator__first_name,curator__middle_name",
      text: "по куратору"
    }, {
      value: "assignee__last_name,assignee__first_name,assignee__middle_name",
      text: "по ответственному"
    }];
    const hlinesLoading = computed(() => store.getters["hotline/hlinesLoading"]);
    const hlines = computed(() => store.getters["hotline/hlines"]);
    const hlinesCount = computed(() => store.getters["hotline/hlinesCount"]);
    const applyFilters = async data => {
      filters.value = {};
      Object.keys(data).forEach(key => {
        const value = ["submitted_at_gte", "submitted_at_lte", "state_modified_gte", "state_modified_lte", "response_date_gte", "response_date_lte", "deadline_gte", "deadline_lte"].includes(key) ? formatDateObj(data[key], fmtDate, null) : data[key];
        if (value !== null && String(value).trim() !== "") filters.value[key] = value;
      });
      if (currentPage.value === 1) await store.dispatch("hotline/updateHlines", {
        page: 1,
        sort: sort.value,
        page_size: perPage,
        ...filters.value
      });else currentPage.value = 1;
    };
    const changePage = newPage => {
      currentPage.value = limitBound(Number(newPage), 1, hlinesCount.value / perPage, currentPage.value);
      loadData();
    };
    const clickRemove = id => {
      currentId.value = id;
      showModalRemove.value = true;
    };
    const doRemove = async () => {
      console.log(1);
      await store.dispatch("hotline/deleteHlineMsg", currentId.value);
      currentPage.value = 1;
      await loadData();
    };
    const sortChanged = async ({
      sortBy,
      sortDesc
    }) => {
      sort.value = sortDesc ? `-${sortBy}` : sortBy;
      loadData();
    };
    const rowClicked = data => {
      router.push(links.hotlineForm + data.id);
    };
    function searchChanged(newSearch) {
      currentPage.value = 1;
      store.dispatch("hotline/updateHlines", {
        page: currentPage.value,
        page_size: perPage,
        search_string: newSearch
      });
    }
    async function loadData() {
      store.dispatch("hotline/updateHlines", {
        page: currentPage.value,
        sort: sort.value,
        page_size: perPage,
        ...filters.value
      });
    }
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_FilterButton = _resolveComponent("FilterButton");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_link = _resolveComponent("b-link");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_container = _resolveComponent("b-container");
      const _component_b_offcanvas = _resolveComponent("b-offcanvas");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSearch, {
            onOnSearch: searchChanged
          })]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "5",
          xl: "5"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSort, {
            optionsSort: optionsSort,
            onOnSort: sortChanged
          })]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "4",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_FilterButton, {
            onClick: _cache[0] || (_cache[0] = $event => showSidebar.value = true)
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_table, {
        class: "table-custom table-pointer",
        hover: "",
        small: "",
        responsive: "",
        "show-empty": "",
        "empty-text": "Записей нет",
        stacked: "md",
        items: hlines.value,
        fields: tableFields,
        busy: hlinesLoading.value,
        onRowClicked: rowClicked
      }, {
        "cell(num)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        "cell(applicant_full_name)": _withCtx(row => [_createTextVNode(_toDisplayString(row.item.is_anonymous ? "Анонимно" : row.value), 1)]),
        "cell(message_number)": _withCtx(row => [_createVNode(_component_b_link, {
          class: _normalizeClass({
            'font-weight-bold': row.item.is_new
          }),
          to: _unref(links).hotlineForm + row.item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value || _unref(NONUMBER)), 1)]),
          _: 2
        }, 1032, ["class", "to"])]),
        "cell(submitted_at)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort))), 1)]),
        "cell(state_modified_at)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort))), 1)]),
        "cell(response_date)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort))), 1)]),
        "cell(deadline)": _withCtx(row => [_createElementVNode("span", {
          class: _normalizeClass({
            'text-danger': row.item.state === 'draft' && new Date(row.value) < new Date()
          })
        }, _toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort))), 3)]),
        "cell(state)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(statesHotline)[row.value]), 1)]),
        "cell(curator_detail)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatName)(row.value, _unref(fmtNameShort))), 1)]),
        "cell(assignee_detail)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatName)(row.value, _unref(fmtNameShort))), 1)]),
        "cell(btnRemove)": _withCtx(row => [_unref(statesHotline)[row.item.state] === _unref(statesHotline).draft ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          variant: "link",
          onClick: $event => clickRemove(row.item.id),
          class: "p-0"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
            class: "bi bi-trash"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.delete_wa_hotmsg]]) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["items", "busy"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            totalRows: hlinesCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, hlinesCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_offcanvas, {
        modelValue: showSidebar.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showSidebar.value = $event),
        placement: "end",
        "no-header": "",
        backdrop: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-2"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_cache[6] || (_cache[6] = _createElementVNode("h5", {
            class: "d-inline"
          }, "Фильтровать сообщения", -1)), _createElementVNode("button", {
            type: "button",
            class: "btn-close float-end",
            "aria-label": "Close",
            onClick: _cache[1] || (_cache[1] = $event => showSidebar.value = false)
          })]), _createVNode(HotLineListSidebar, {
            onEmitApplyFilters: applyFilters
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_b_modal, {
        title: `Удалить сообщение ${currentId.value}?`,
        modelValue: showModalRemove.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => showModalRemove.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Удалить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doRemove
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};