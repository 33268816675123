import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createBlock(_component_b_card, null, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "mt-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("Вид деятельности")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10",
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.activity_description), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Описание риска")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10",
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.risk_description), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Рисковые должности")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10",
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.risk_positions), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.activity_regulation), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.activity_regulation), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.signing_act), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.signing_act), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.confirming_document), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.confirming_document), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.control_procedures), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.control_procedures), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.violations_internal), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.violations_internal), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.violations_partners), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.violations_partners), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.reputation_impact), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.reputation_impact), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item?.terms_map?.responsibility), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.responsibility), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Рекомендации")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10",
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.recommendations), 1)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}