import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "InnerCheckViewChanges",
  data: () => ({
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fmtNameShort,
    tableFields: [{
      key: "timestamp",
      label: "",
      formatter: value => formatDate(value, fmtDateMonthTime),
      class: "text-nowrap px-3 text-muted"
    }, {
      key: "event",
      label: ""
    }, {
      key: "FIO",
      label: ""
    }]
  }),
  props: {
    msg: {
      type: String,
      required: true
    } // id ВП
  },
  created() {
    this.updateInnerCheckChanges({
      id: this.msg
    });
  },
  methods: {
    ...mapActions("innerCheck", ["updateInnerCheckChanges"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.innerCheckChangesCount / this.perPage, this.currentPage);
      this.updateInnerCheckChanges({
        page: this.currentPage,
        id: this.msg
      });
    }
  },
  computed: {
    ...mapGetters("innerCheck", ["innerCheckChanges", "innerCheckChangesCount", "innerCheckChangesLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateInnerCheckChanges({
          page: value,
          id: this.msg
        });
      }
    }
  }
};