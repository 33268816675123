import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_EducationProgramInner = _resolveComponent("EducationProgramInner");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_EducationProgramOut = _resolveComponent("EducationProgramOut");
  const _component_TestChecks = _resolveComponent("TestChecks");
  const _component_Report = _resolveComponent("Report");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_b_row, {
    class: "px-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_BreadCrumbToHome)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    class: "sticky-top align-items-center px-4 mb-2 bg-light title-shadow"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("h1", null, "Обучение", -1)])),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "primary",
        block: "",
        onClick: $options.clickCreate
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Создать")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_course]])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_tabs, {
    pills: "",
    class: "tabs-custom",
    "nav-wrapper-class": "px-4 pb-3 h6",
    "content-class": "px-4",
    modelValue: _ctx.tabIndex,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.tabIndex = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tab, null, {
      title: _withCtx(() => [_cache[3] || (_cache[3] = _createTextVNode("Внутреннее обучение")), _createVNode(_component_b_badge, {
        variant: "secondary",
        class: "text-white ms-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coursCount), 1)]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_EducationProgramInner)]),
      _: 1
    }), _createVNode(_component_b_tab, null, {
      title: _withCtx(() => [_cache[4] || (_cache[4] = _createTextVNode("Внешнее обучение")), _createVNode(_component_b_badge, {
        variant: "secondary",
        class: "text-white ms-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coursOutCount), 1)]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_EducationProgramOut)]),
      _: 1
    }), _createVNode(_component_b_tab, null, {
      title: _withCtx(() => [_cache[5] || (_cache[5] = _createTextVNode("Проверка тестирования")), _createVNode(_component_b_badge, {
        variant: "secondary",
        class: "text-white ms-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.testsCheckCount), 1)]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_TestChecks)]),
      _: 1
    }), _createVNode(_component_b_tab, null, {
      title: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Отчёты")])),
      default: _withCtx(() => [_createVNode(_component_Report)]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}