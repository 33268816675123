import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { mapGetters, mapActions } from "vuex";
import theNotification from "@/components/theNotification";
import { statesRisk } from "@/helpers/states";
export default {
  name: "RiskForm",
  props: {
    msg: {
      type: String,
      required: true
    } // сюда получаем id риска
  },
  components: {
    BreadCrumbToHome,
    theNotification
  },
  data: () => ({
    statesRisk,
    breadcrumbs: [{
      text: "Оценка рисков",
      to: {
        name: "RiskList"
      }
    }]
  }),
  async created() {
    await this.updateRiskById(this.msg);
    await this.updateDepartments();
  },
  methods: {
    ...mapActions("risk", ["updateRiskById", "changeRisk", "changeStateRisk"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    ...mapActions(["showNotify"]),
    async changeData(key) {
      await this.changeRisk({
        id: this.msg,
        params: {
          [key]: this.riskForm[key]
        }
      });
    },
    async clickPublished() {
      const error = ["risk_owner", "activity_num", "activity_description", "risk_num", "risk_description", "risk_positions"].some(key => !this.riskForm[key] || !String(this.riskForm[key]).trim().length);
      if (error) {
        this.showNotify({
          msg: "Не все поля заполнены",
          title: "Ошибка ввода данных",
          variant: "danger"
        });
        return;
      }
      await this.changeStateRisk({
        id: this.msg,
        state: "published",
        msg: "Риск опубликован"
      });
    }
  },
  computed: {
    ...mapGetters("risk", ["riskForm", "riskFormLoading"]),
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).map(key => ({
        value: this.departments[key],
        text: key
      }));
    },
    readOnly() {
      return statesRisk[this.riskForm.state] !== statesRisk.draft;
    }
  }
};