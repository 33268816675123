import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Transition as _Transition, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "filter-wrap"
};
const _hoisted_2 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "footer mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_form_group, {
    label: "Формат"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.format,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filters.format = $event),
      options: $options.formatOptions
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  }), !_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
    key: 0,
    label: "Владелец"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.owner,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.filters.owner = $event),
      options: $options.departOptions
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Загрузка...", -1))])), _createVNode(_component_b_form_group, {
    label: "Тренер"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.instructor,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.filters.instructor = $event),
      type: "search",
      placeholder: "Фамилия",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата начала от..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.dateFrom,
        onChangeValue: _cache[3] || (_cache[3] = $event => _ctx.filters.dateFrom = $event),
        classCustom: "no-gutters"
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата окончания до..."
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.filters.dateUntill,
        onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.filters.dateUntill = $event),
        classCustom: "no-gutters"
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_form_checkbox, {
    modelValue: _ctx.filters.new,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.filters.new = $event),
    value: true,
    "unchecked-value": null
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" Только новые ")])),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_button, {
    block: "",
    variant: "primary",
    onClick: $options.clickApplyFilters
  }, {
    default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Применить")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_button, {
    block: "",
    variant: "light",
    onClick: $options.clearFilters,
    class: "ms-3"
  }, {
    default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Сбросить фильтры")])),
    _: 1
  }, 8, ["onClick"])])]);
}