import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { profileReadonly } from "@/helpers/profile";
import { setValueTimeout } from "@/helpers/timeout";
import { optionYesNo } from "@/helpers/text";
export default {
  name: "TwentyFourth",
  data: () => ({
    endpoint: "privileges",
    privileges: [],
    optionYesNo,
    has_privileges_state: null,
    profileObjSaved: {},
    template: {
      category_kind: null
    },
    states: {
      category_kind_state: null
    }
  }),
  created() {
    this.privileges = this.profileObj.privileges.map(privilege => ({
      ...privilege,
      ...this.states
    }));
    this.profileObjSaved.privileges = this.profileObj.privileges;
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitData(key) {
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        setValueTimeout(this, state);
      });
    },
    submitDataEndpoint(obj, key) {
      const value = obj[key];
      if (this.profileObjSaved.privileges.length > 0 && this.profileObjSaved.privileges[obj.id] === value) {
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: value
        },
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved.privileges[response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint(obj) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved.privileges[response.id] = {
          ...this.template
        };
        obj.push({
          id: response.id,
          ...this.states,
          ...this.template
        });
      });
    },
    removeData(index) {
      this.conditions.splice(index, 1);
      this.submitCondition(index);
    },
    removeDataEndpoint(obj, id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved.privileges[id];
        obj.splice(index, 1);
      });
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};