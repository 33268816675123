import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound, switchText } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "WorkersList",
  data: () => ({
    showAll: true,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    fmtNameShort,
    tableFields: [{
      key: "FIOT",
      label: "ФИО работника",
      class: "text-nowrap"
    }, {
      key: "student_position",
      label: "Должность"
    }, {
      key: "student_department_name",
      label: "Подразделение"
    }, {
      key: "date_end_realT",
      label: "Дата ознакомления"
      //formatter: (value) => formatDate(value, fmtDateShort),
    }, {
      key: "is_signedT",
      label: "Подпись"
      //formatter: (value) => switchText(value),
    }],
    optionShowAll: [{
      value: null,
      text: "Показать всех"
    }, {
      value: true,
      text: "Показать только ознакомившихся"
    }]
  }),
  methods: {
    ...mapActions("knowledge", ["updateWorkersList"]),
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.workersListCount / this.perPage, this.currentPage);
      this.updateWorkersList({
        page: this.currentPage,
        confirmed: true,
        libmat_id: this.knowlegeDocument.id,
        is_signed: this.showAll
      });
    },
    changeShowAll() {
      this.updateWorkersList({
        page: this.currentPage,
        confirmed: true,
        libmat_id: this.knowlegeDocument.id,
        is_signed: this.showAll
      });
    }
  },
  computed: {
    ...mapGetters("knowledge", ["workersList", "workersListCount", "workersListLoading", "knowlegeDocument"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateWorkersList({
          page: value,
          confirmed: true,
          libmat_id: this.knowlegeDocument.id,
          is_signed: this.showAll
        });
      }
    },
    items4table() {
      return Array.isArray(this.workersList) ? this.workersList.map(el => ({
        ...el,
        date_end_realT: formatDate(el.date_end_real, fmtDateShort),
        is_signedT: switchText(el.is_signed),
        FIOT: formatName({
          first_name: el.student_first_name,
          middle_name: el.student_middle_name,
          last_name: el.student_last_name
        }, fmtNameShort)
      })) : [];
    }
  }
};