import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_link = _resolveComponent("b-link");
  const _component_ActivityListDetailsAdd = _resolveComponent("ActivityListDetailsAdd");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_b_tooltip = _resolveDirective("b-tooltip");
  return _openBlock(), _createBlock(_component_b_card, null, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "mt-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "3",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("Комментарий ответственного")])),
        _: 1
      }), $props.item.commentAssignee ? (_openBlock(), _createBlock(_component_b_col, {
        key: 0
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.commentAssignee), 1)]),
        _: 1
      })) : _createCommentVNode("", true), $props.item.fileAssignee ? (_openBlock(), _createBlock(_component_b_col, {
        key: 1,
        cols: "auto"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.fileAssignee), 1), _withDirectives((_openBlock(), _createBlock(_component_b_link, {
          class: "ms-4 border-0",
          href: require(`@/assets/img/education5.png`),
          download: "checkreport.pdf",
          title: "Скачать документ",
          variant: "outline-primary"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
            class: "bi bi-download"
          }, null, -1)])),
          _: 1
        }, 8, ["href"])), [[_directive_b_tooltip, void 0, void 0, {
          hover: true
        }]])]),
        _: 1
      })) : _createCommentVNode("", true), !$props.item.commentAssignee ? (_openBlock(), _createBlock(_component_b_col, {
        key: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_ActivityListDetailsAdd, {
          item: {
            modalTitle: 'Комментарий ответственного',
            addTo: 'Assignee',
            idModal: `Assignee${$props.item.id}ModalAdd`
          },
          onAddDetails: $options.addDetails
        }, null, 8, ["item", "onAddDetails"])]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-2 mb-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "3",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Комментарий комплаенса")])),
        _: 1
      }), $props.item.commentCompliance ? (_openBlock(), _createBlock(_component_b_col, {
        key: 0
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.commentCompliance), 1)]),
        _: 1
      })) : _createCommentVNode("", true), $props.item.fileCompliance ? (_openBlock(), _createBlock(_component_b_col, {
        key: 1,
        cols: "auto"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.item.fileCompliance), 1), _withDirectives((_openBlock(), _createBlock(_component_b_link, {
          class: "ms-4 border-0",
          href: require(`@/assets/img/education5.png`),
          download: "checkreport.pdf",
          title: "Скачать документ",
          variant: "outline-primary"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
            class: "bi bi-download"
          }, null, -1)])),
          _: 1
        }, 8, ["href"])), [[_directive_b_tooltip, void 0, void 0, {
          hover: true
        }]])]),
        _: 1
      })) : _createCommentVNode("", true), !$props.item.commentCompliance ? (_openBlock(), _createBlock(_component_b_col, {
        key: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_ActivityListDetailsAdd, {
          item: {
            modalTitle: 'Комментарий комплаенса',
            addTo: 'Compliance',
            idModal: `Compliance${$props.item.id}ModalAdd`
          },
          onAddDetails: $options.addDetails
        }, null, 8, ["item", "onAddDetails"])]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  });
}