import { format } from "date-fns";
import { ru } from "date-fns/locale";
export const nowYear = new Date().getFullYear();
const startYear = 1900;
const NODATE = ""; //обрабатываем пустые значения на фронте (пример: таблицы в модуле «Обучение»)

export const dateObjInit = { day: null, month: null, year: null };
/**
 *
 * @param {String} str - дата в виде строки
 * @param {String} type - тип даты, напримерм 'dd MMM yyyy hh:mm'
 * @returns {String}
 */
export function formatDate(str, type = fmtDateShort, noDate = NODATE) {
  const date = new Date(str);
  return str ? format(date, type, { locale: ru }) : noDate;
}

/**
 * @param {Object} date - дата в виде объекта { day, month, year }
 */
export function formatDateObj(date, type = fmtDateShort, noDate = NODATE) {
  return date.year && date.month && date.day
    ? formatDate(`${date.year}-${date.month}-${date.day}`, type)
    : noDate;
}

/**
 * @param {String} date - дата в виде строки
 */
export function formatObjDate(date) {
  return {
    day: Number(formatDate(date, "dd", null)),
    month: Number(formatDate(date, "MM", null)),
    year: formatDate(date, "yyyy", null),
  };
}

// дата в формате строки
export function formatLocalDate(date) {
  try {
    return date.toLocaleDateString("ru-RU", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } catch (error) {
    return NODATE;
  }
}

// локализация для RU
export const labels = {
  labelPrevDecade: "Предыдущее десятилетие",
  labelPrevYear: "Предыдущий год",
  labelPrevMonth: "Предыдущий месяц",
  labelCurrentMonth: "Текущий месяц",
  labelNextMonth: "Следующий месяц",
  labelNextYear: "Следующий год",
  labelNextDecade: "Следующее десятилетие",
  labelToday: "Сегодня",
  labelSelected: "Дата выбрана",
  labelNoDateSelected: "Дата не выбрана",
  labelCalendar: "Календарь",
  labelNav: "Навигация по календарю",
  labelHelp: "Используйте клавиши для навигации по календарю",
};

export const monthsOptions = [
  { value: 1, text: "январь" },
  { value: 2, text: "февраль" },
  { value: 3, text: "март" },
  { value: 4, text: "апрель" },
  { value: 5, text: "май" },
  { value: 6, text: "июнь" },
  { value: 7, text: "июль" },
  { value: 8, text: "август" },
  { value: 9, text: "сентябрь" },
  { value: 10, text: "октябрь" },
  { value: 11, text: "ноябрь" },
  { value: 12, text: "декабрь" },
];

// родительный падеж для печатных форм
export const monthsCaseR = {
  1: "января",
  2: "февраля",
  3: "марта",
  4: "апреля",
  5: "мая",
  6: "июня",
  7: "июля",
  8: "августа",
  9: "сентября",
  10: "октября",
  11: "ноября",
  12: "декабря",
};

export function yearsGenerator({ offset = 0, amount }) {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - amount))
    .fill("")
    .map((v, idx) => now - offset - idx);
}

export const fmtDateShort = "dd.MM.yyyy";
export const fmtDateLong = "dd.MM.yyyy HH:mm";
export const fmtDateMonthTime = "d MMMM yyyy, HH:mm";
export const fmtTime = "HH:mm";
export const fmtDateMonth = "d MMMM yyyy";
export const fmtDate_Month_Time = "d_MM_yyyy_HH_mm";
export const fmtDate = "yyyy-MM-dd";
export const fmtDateTime = "yyyy-MM-dd HH:mm:ss";

// перечень периодов для отчетов и select
export const periodOptions = [
  { value: "day", text: "День", day: 0, month: 0, year: 0 },
  { value: "week", text: "Неделя", day: 7, month: 0, year: 0 },
  { value: "month", text: "Месяц", day: 0, month: 1, year: 0 },
  { value: "quarter", text: "Квартал", day: 0, month: 3, year: 0 },
  { value: "year", text: "Год", day: 0, month: 0, year: 1 },
  { value: null, text: "Всё время" },
];

// периодичность (декларационной компании)
export const periodicityOptions = [
  { value: "month", text: "Ежемесячно" },
  { value: "quarter", text: "Ежеквартально" },
  { value: "year", text: "Ежегодно" },
];

// генерация дней для календаря
export const daysOptions = Array.from({ length: 31 }, (_, i) => i + 1);

// генерация годов для календаря
// const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
export const yearsOptions = Array.from(
  { length: nowYear - startYear + 1 },
  (_, i) => nowYear + 5 - i
);
