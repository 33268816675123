import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
  class: "d-flex"
};
const _hoisted_2 = {
  class: "spinner-border text-primary",
  role: "status"
};
const _hoisted_3 = {
  class: "shadow-sm p-3 my-3 bg-white rounded"
};
const _hoisted_4 = {
  class: "table-responsive mt-3"
};
const _hoisted_5 = {
  class: "table table-striped"
};
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminNotificationItem from "./AdminNotificationItem.vue";
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { limitBound } from "@/helpers/text.js";
const perPage = 10;
export default {
  __name: 'AdminNotification',
  setup(__props) {
    const breadcrumbs = [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }];
    // store
    const store = useStore();
    const adminNotificationList = computed(() => store.getters["administration2/adminNotificationList"]);
    const adminNotificationCount = computed(() => store.getters["administration2/adminNotificationCount"]);
    const adminNotificationLoading = computed(() => store.getters["administration2/adminNotificationLoading"]);
    onMounted(() => {
      updateData();
    });
    const filter = ref({
      event: undefined
    });
    function updateData() {
      store.dispatch("administration2/updateAdminNotification", {
        page: currentPage.value,
        ...filter.value
      });
    }

    // pagination
    const gotoPage = ref(1);
    const currentPage = ref(1);
    const currentPageModel = computed({
      get: () => currentPage.value,
      set: value => {
        currentPage.value = value;
        updateData();
      }
    });
    function changePage() {
      currentPage.value = limitBound(Number(gotoPage.value), 1, adminNotificationCount.value / perPage, currentPage.value);
      updateData();
    }
    function changeEvent() {
      currentPage.value = 1;
      updateData();
    }
    return (_ctx, _cache) => {
      const _component_b_pagination = _resolveComponent("b-pagination");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_form_input = _resolveComponent("b-form-input");
      const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_input_group_append = _resolveComponent("b-input-group-append");
      const _component_b_input_group = _resolveComponent("b-input-group");
      const _component_b_form_row = _resolveComponent("b-form-row");
      const _directive_can = _resolveDirective("can");
      return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs
      }), _createElementVNode("div", _hoisted_1, [_cache[4] || (_cache[4] = _createElementVNode("h1", {
        class: "mb-4 me-5"
      }, "Настройки уведомлений", -1)), _withDirectives(_createElementVNode("div", _hoisted_2, _cache[3] || (_cache[3] = [_createElementVNode("span", {
        class: "visually-hidden"
      }, "Загрузка...", -1)]), 512), [[_vShow, adminNotificationLoading.value]])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
            modelValue: currentPageModel.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => currentPageModel.value = $event),
            "total-rows": adminNotificationCount.value,
            "per-page": perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows"]), [[_vShow, adminNotificationCount.value > perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: gotoPage.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => gotoPage.value = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 512), [[_vShow, adminNotificationCount.value > perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto ms-5"
        }, {
          default: _withCtx(() => [_withDirectives(_createElementVNode("select", {
            class: "form-select form-select-sm ms-5",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => filter.value.event = $event),
            onChange: changeEvent
          }, _cache[5] || (_cache[5] = [_createElementVNode("option", {
            value: undefined
          }, "Показать все записи", -1), _createElementVNode("option", {
            value: "profremind"
          }, " Напоминания об актуализации личного листа ", -1), _createElementVNode("option", {
            value: "coidremind"
          }, "Напоминания об актуализации ЭДКИ", -1)]), 544), [[_vModelSelect, filter.value.event]])]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_4, [_createElementVNode("table", _hoisted_5, [_cache[6] || (_cache[6] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "#"), _createElementVNode("th", null, "Событие"), _createElementVNode("th", null, "Роль пользователя"), _createElementVNode("th")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adminNotificationList.value, (el, index) => {
        return _openBlock(), _createElementBlock("tr", {
          key: index
        }, [_createElementVNode("td", null, _toDisplayString(el.id), 1), _createElementVNode("td", null, _toDisplayString(el.event_meaning), 1), _createElementVNode("td", null, _toDisplayString(el.role_title), 1), _createElementVNode("td", null, [_createVNode(AdminNotificationItem, {
          item: el,
          onUpdateData: updateData
        }, null, 8, ["item"])])]);
      }), 128))])])])])])), [[_directive_can, _ctx.$perm.view_admin_notif_settings]]);
    };
  }
};