import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "wrap-attachments"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createElementBlock("span", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createVNode(_component_b_row, {
      class: "mt-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_cache[1] || (_cache[1] = _createElementVNode("i", {
          class: "bi bi-paperclip"
        }, null, -1)), _createTextVNode(_toDisplayString(file.name), 1)]),
        _: 2
      }, 1024), _createVNode(_component_b_col, {
        class: "text-lg-right"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_button, {
          variant: "link",
          size: "sm",
          onClick: $event => $options.handlerDel(index),
          disabled: Boolean(file.id)
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("i", {
            class: "bi bi-trash"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick", "disabled"])]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1024)]);
  }), 128)), _createVNode(_component_b_button, {
    variant: "outline-primary",
    onClick: $options.clickAddFile,
    disabled: _ctx.files.length >= $props.limitFiles
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Добавить файл")])),
    _: 1
  }, 8, ["onClick", "disabled"]), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[0] || (_cache[0] = (...args) => $options.handlerAdd && $options.handlerAdd(...args))
  }, null, 544)]);
}