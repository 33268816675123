import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '@/assets/pattern.svg';
const _hoisted_1 = {
  id: "auth"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "mb-0"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = ["href"];
const _hoisted_6 = {
  class: "mb-0"
};
const _hoisted_7 = {
  class: "mb-0"
};
const _hoisted_8 = {
  key: 0,
  class: "cookie-div-wrap pb-3 d-flex justify-content-center w-100"
};
const _hoisted_9 = {
  class: "border-0 rounded shadow-sm p-2 d-flex align-items-center bg-white"
};
const _hoisted_10 = {
  class: "me-3 cookie-text-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_container = _resolveComponent("b-container");
  const _component_theLogin = _resolveComponent("theLogin");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_card = _resolveComponent("b-card");
  const _component_router_link = _resolveComponent("router-link");
  const _component_b_button = _resolveComponent("b-button");
  const _component_VMarkdownView = _resolveComponent("VMarkdownView");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_container, {
    fluid: "",
    class: "px-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      "no-gutters": ""
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        md: "4",
        lg: "6",
        class: "min-vh-100 auth-cover",
        style: _normalizeStyle($options.coverBg)
      }, null, 8, ["style"]), _createVNode(_component_b_col, {
        cols: "10",
        md: "8",
        lg: "6",
        class: "min-vh-100 d-flex flex-column justify-content-between"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-5"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_row, {
            class: "px-5"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_col, {
              cols: "12"
            }, {
              default: _withCtx(() => [_ctx.adminCommonLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
                key: 0,
                class: "align-middle text-center text-primary"
              })) : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _ctx.adminCommon.logo_full,
                class: "d-inline-block align-top img-fluid",
                alt: "Логотип антикоррупционной комплаенс платформы",
                style: {
                  "max-height": "4rem"
                }
              }, null, 8, _hoisted_2))]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_b_container, {
          class: "p-5"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_row, {
            class: "px-5"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_col, {
              cols: "12"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_card, {
                "no-body": "",
                class: "mb-4 border-0 rounded-lg shadow-sm w-100"
              }, {
                default: _withCtx(() => [_createVNode(_component_b_card_body, {
                  class: "position-relative p-4"
                }, {
                  default: _withCtx(() => [_createVNode(_component_b_form_row, null, {
                    default: _withCtx(() => [_createVNode(_component_b_col, {
                      cols: "12"
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("h1", {
                        class: "mb-4"
                      }, "Вход", -1)])),
                      _: 1
                    }), _createVNode(_component_b_col, {
                      cols: "12"
                    }, {
                      default: _withCtx(() => [_createVNode(_component_theLogin)]),
                      _: 1
                    })]),
                    _: 1
                  })]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_b_container, {
          class: "p-5 position-relative"
        }, {
          default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("img", {
            src: _imports_0,
            class: "pattern d-none my-5 img-fluid position-absolute",
            alt: "Брендовый паттерн антикоррупционной комплаенс платформы"
          }, null, -1)), _createVNode(_component_b_row, {
            class: "px-5"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_col, null, {
              default: _withCtx(() => [_cache[8] || (_cache[8] = _createElementVNode("p", {
                class: "mb-0"
              }, [_createElementVNode("small", null, "Горячая линия по вопросам противодействия коррупции")], -1)), _createElementVNode("p", _hoisted_3, [_ctx.adminCommonLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
                key: 0,
                class: "align-middle text-center text-primary"
              })) : (_openBlock(), _createElementBlock("strong", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.phoneMask(_ctx.adminCommon.phone)) + " ", 1), _createElementVNode("a", {
                class: "ms-2",
                href: 'mailto:' + _ctx.adminCommon.email
              }, _toDisplayString(_ctx.adminCommon.email), 9, _hoisted_5)]))]), _createElementVNode("p", _hoisted_6, [_createElementVNode("small", null, _toDisplayString(new Date().getFullYear()) + " © ", 1), _createVNode(_component_router_link, {
                to: _ctx.links.about
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("small", null, "Сведения о Платформе", -1)])),
                _: 1
              }, 8, ["to"])]), _createElementVNode("p", _hoisted_7, [_createElementVNode("small", {
                class: "span-link",
                onClick: _cache[0] || (_cache[0] = $event => $options.showText('pers_data_conscent'))
              }, "Согласие на обработку персональных данных"), _createElementVNode("small", {
                class: "span-link ms-3",
                onClick: _cache[1] || (_cache[1] = $event => $options.showText('user_agreement'))
              }, "Пользовательское соглашение сервисов сайта \"Комплаенс-платформа.рф\"")]), _createElementVNode("p", null, [_createElementVNode("small", {
                class: "span-link",
                onClick: _cache[2] || (_cache[2] = $event => $options.showText('pers_data_policy'))
              }, "Политика обработки персональных данных")])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _ctx.showCookie ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_cache[10] || (_cache[10] = _createTextVNode(" Продолжая использовать этот сайт и нажимая кнопку «Принимаю», вы даете ")), _createElementVNode("span", {
      class: "span-link",
      onClick: _cache[3] || (_cache[3] = $event => $options.showText('cookies_data_policy'))
    }, "согласие на обработку файлов cookie")]), _createElementVNode("div", null, [_createVNode(_component_b_button, {
      variant: "primary",
      onClick: _cache[4] || (_cache[4] = $event => _ctx.showCookie = false)
    }, {
      default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("Принимаю")])),
      _: 1
    })])])])) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_modal, {
    title: _ctx.agreements[_ctx.docKey],
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.showModal = $event),
    size: "lg",
    "ok-only": "",
    "ok-title": "Закрыть",
    centered: "",
    scrollable: "",
    "no-close-on-backdrop": "",
    "ok-variant": "outline-primary"
  }, {
    default: _withCtx(() => [_createVNode(_component_VMarkdownView, {
      content: _ctx.adminCommon?.[_ctx.docKey]
    }, null, 8, ["content"])]),
    _: 1
  }, 8, ["title", "modelValue"])]);
}