import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "table-header"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  key: 0,
  class: "text-primary"
};
const _hoisted_4 = {
  key: 2,
  class: "pt-2 text-success"
};
const _hoisted_5 = {
  class: "table-footer"
};
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import links from "@/router/links";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { mapCheckToText } from "@/helpers/profile";
import { formatName, limitBound, fmtNameShort } from "@/helpers/text";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification.vue";
import ProfileListAction from "./ProfileListAction.vue";
import { checkPermissions } from "@/auth/auth.js";
const perPage = 20;
export default {
  __name: 'ProfileChecksList',
  setup(__props) {
    const permissions = ref({});
    const store = useStore();
    const router = useRouter();
    const currentPage = ref(1);
    const breadcrumbs = ref([{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]);
    const columns = ref([{
      key: "index",
      label: "№ п/п"
    }, {
      key: "profile",
      label: "UID"
    }, {
      key: "author",
      label: "Автор",
      class: "text-nowrap"
    }, {
      key: "profile_created_at",
      label: "Дата создания",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "profile_submitted_at",
      label: "Дата трудоустройства",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateShort)
    }, {
      key: "state",
      label: "Статус",
      class: "text-nowrap",
      formatter: value => mapCheckToText[value]
    }, {
      key: "action",
      class: "text-center",
      label: "Действие"
    }]);
    const currentItem = ref({});
    const showModalAutocheck = ref(false);
    const profileChecksList = computed(() => store.getters["profileChecks/profileChecksList"]);
    const profileChecksCount = computed(() => store.getters["profileChecks/profileChecksCount"]);
    const profileChecksLoading = computed(() => store.getters["profileChecks/profileChecksLoading"]);
    const autochecksEntity = computed(() => store.getters["administration2/autochecksEntity"]);
    const autochecksEntityLoading = computed(() => store.getters["administration2/autochecksEntityLoading"]);
    const onlyNew = computed(() => store.getters["homeStats/onlyNew"]);
    const getProfileChecksList = params => {
      store.dispatch("profileChecks/getProfileChecksList", params);
    };
    const changePage = newPage => {
      currentPage.value = limitBound(Number(newPage), 1, profileChecksCount.value / perPage, currentPage.value);
      getProfileChecksList({
        page: currentPage.value,
        page_size: perPage
      });
    };
    const completeCheck = async id => {
      await store.dispatch("profileChecks/transitionProfileCheck", {
        id: id,
        body: {
          state: "completed"
        }
      });
      getProfileChecksList({
        page: 1,
        page_size: perPage
      });
    };
    const clickAutocheck = item => {
      currentItem.value = item;
      showModalAutocheck.value = true;
    };
    const doAutocheck = async () => {
      await store.dispatch("administration2/postAutochecksEntity", {
        endpoint: "profiles",
        id: currentItem.value.profile
      });
      if (autochecksEntity.value.id !== undefined) router.push({
        name: "AutocheckForm",
        params: {
          msg: autochecksEntity.value.id
        },
        query: {
          type: "profile_only"
        }
      });else getProfileChecksList({
        page: currentPage.value,
        page_size: perPage
      });
    };
    const gotoAutocheck = () => {
      router.push({
        name: "AutochecksArea",
        query: {
          type: "profile_only"
        }
      });
    };
    function searchChanged(search) {
      getProfileChecksList({
        search_string: search,
        page: 1,
        page_size: perPage
      });
    }
    onMounted(() => {
      const instance = getCurrentInstance();
      permissions.value = instance?.appContext.config.globalProperties.$perm;
      const params = {
        page: 1,
        page_size: perPage
      };
      if (onlyNew.value) params.is_new = true;
      getProfileChecksList(params);
      if (checkPermissions(permissions.value.trigger_wa_autocheck) || checkPermissions(permissions.value.view_wa_autocheck)) columns.value.push({
        key: "autochecks",
        label: "Автосверка",
        class: "text-center p-0"
      });
      store.dispatch("homeStats/setOnlyNew", null); // обнулимся
    });
    return (_ctx, _cache) => {
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_row = _resolveComponent("b-row");
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_link = _resolveComponent("b-link");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(theNotification), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs.value
      }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
        class: "mb-4"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("h1", null, "Проверки личных листков", -1)])),
          _: 1
        }), _createVNode(_component_b_col, {
          class: "text-end"
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            variant: "primary",
            onClick: gotoAutocheck
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Результаты автосверки ")])),
            _: 1
          })), [[_directive_can, _ctx.$perm.view_wa_autocheck]])]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSearch, {
            onOnSearch: searchChanged
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        small: "",
        responsive: "",
        "show-empty": "",
        stacked: "md",
        items: profileChecksList.value,
        fields: columns.value,
        busy: profileChecksLoading.value
      }, {
        "cell(profile)": _withCtx(row => [_createVNode(_component_b_link, {
          class: _normalizeClass([row.item.is_new ? 'font-weight-bold' : '', "search-link"]),
          href: _unref(links).profileCard + `{"profileId":${row.item.profile}, "myProfile": false}/print`,
          target: "_blank"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.item.profile), 1)]),
          _: 2
        }, 1032, ["class", "href"])]),
        "cell(author)": _withCtx(row => [_createVNode(_component_b_link, {
          class: _normalizeClass([row.item.is_new ? 'font-weight-bold' : '', "search-link"]),
          href: _unref(links).profileCard + `{"profileId":${row.item.profile}, "myProfile": false}/print`,
          target: "_blank"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(formatName)(row.item.user_detail, _unref(fmtNameShort))), 1)]),
          _: 2
        }, 1032, ["class", "href"])]),
        "cell(action)": _withCtx(row => [_withDirectives(_createVNode(ProfileListAction, {
          idModal: `actionProfile${row.item.id}`,
          item: row.item,
          onProfileCheck: completeCheck
        }, null, 8, ["idModal", "item"]), [[_directive_can, _ctx.$perm.complete_wa_profilecheck]])]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Проверок нет", -1)])),
          _: 1
        })]),
        "cell(autochecks)": _withCtx(row => [autochecksEntityLoading.value ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createVNode(_component_b_spinner)])) : !row.item.has_autochecks ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 1,
          variant: "link",
          class: "p-0",
          onClick: $event => clickAutocheck(row.item)
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
            class: "bi bi-repeat"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.trigger_wa_autocheck]]) : row.item.has_autochecks ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, _cache[6] || (_cache[6] = [_createElementVNode("i", {
          class: "bi bi-check-all"
        }, null, -1)]))), [[_directive_can, _ctx.$perm.view_wa_autocheck]]) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            onOnChangePage: changePage,
            totalRows: profileChecksCount.value
          }, null, 8, ["totalRows"]), [[_vShow, profileChecksCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_modal, {
        title: `Выполнить автосверку личного листка ${currentItem.value.profile} ?`,
        modelValue: showModalAutocheck.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => showModalAutocheck.value = $event),
        "ok-variant": "outline-primary",
        "ok-title": "Выполнить",
        "cancel-variant": "outline-secondary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doAutocheck
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};