import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "table-footer"
};
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { limitBound } from "@/helpers/text.js";
import TrafficLight from "@/components/Helpers/TrafficLight.vue";
import RiskListDetails from "./RiskListDetails.vue";
import links from "@/router/links";
import { statesRisk } from "@/helpers/states";
import CustomPagination from "../../common/CustomPagination.vue";
const perPage = 20;
export default {
  __name: 'RiskList',
  setup(__props) {
    const store = useStore();
    const tableFields = computed(() => [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "risk_owner_detail.department_name",
      label: "Владелец риска"
    }, {
      key: "risk_num",
      label: "Номер риска",
      class: "text-center"
    }, {
      key: "activity_num",
      label: "Номер вида деятельности",
      class: "text-center"
    }, {
      key: "risk_probabilityT",
      label: "Вероятность наступления",
      class: "text-center"
    }, {
      key: "financial_costs",
      label: adminRisk.value?.terms_map?.financial_costs
    }, {
      key: "risk_impactT",
      label: "Степень влияния",
      class: "text-center"
    }, {
      key: "risk_levelT",
      label: "Уровень риска",
      class: "text-center"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "toggleDetailsState",
      label: ""
    }, {
      key: "btnChange",
      label: ""
    }, {
      key: "btnRemove",
      label: ""
    }]);
    const showModalRemove = ref(false);
    const currentItem = ref({});

    // store
    const risks = computed(() => store.getters["risk/risks"]);
    const risksCount = computed(() => store.getters["risk/risksCount"]);
    const risksPage = computed(() => store.getters["risk/risksPage"]);
    const risksLoading = computed(() => store.getters["risk/risksLoading"]);
    const adminRisk = computed(() => store.getters["administration/adminRisk"]);
    const adminRiskLoading = computed(() => store.getters["administration/adminRiskLoading"]);
    const clickRemove = item => {
      currentItem.value = item;
      showModalRemove.value = true;
    };
    const doRemove = async () => {
      await store.dispatch("risk/removeRisk", currentItem.value);
      await store.dispatch("risk/setRisksPage", 1);
      await store.dispatch("risk/updateRisks");
    };
    const changePage = newPage => {
      store.dispatch("risk/setRisksPage", limitBound(Number(newPage), 1, risksCount.value / perPage, risksPage.value));
      store.dispatch("risk/updateRisks");
    };
    return (_ctx, _cache) => {
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_table = _resolveComponent("b-table");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        small: "",
        responsive: "",
        "show-empty": "",
        stacked: "md",
        items: risks.value,
        fields: tableFields.value,
        busy: risksLoading.value || adminRiskLoading.value
      }, {
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Рисков нет", -1)])),
          _: 1
        })]),
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((risksPage.value - 1) * perPage + row.index + 1), 1)]),
        "cell(risk_levelT)": _withCtx(row => [_createVNode(TrafficLight, {
          typeLight: row.value
        }, null, 8, ["typeLight"])]),
        "cell(toggleDetailsState)": _withCtx(row => [_createElementVNode("i", {
          class: _normalizeClass(["bi text-primary", row.detailsShowing ? 'bi-chevron-up' : 'bi-chevron-down']),
          role: "button",
          onClick: row.toggleDetails
        }, null, 10, _hoisted_1)]),
        "row-details": _withCtx(row => [_createVNode(RiskListDetails, {
          item: row.item
        }, null, 8, ["item"])]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        "cell(btnChange)": _withCtx(row => [_unref(statesRisk)[row.item.state] === _unref(statesRisk).draft ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "p-0 border-0",
          to: _unref(links).riskForm + row.item.id
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
            class: "bi bi-pencil-square"
          }, null, -1)])),
          _: 2
        }, 1032, ["to"])), [[_directive_can, _ctx.$perm.change_wa_risk]]) : _createCommentVNode("", true)]),
        "cell(btnRemove)": _withCtx(row => [_unref(statesRisk)[row.item.state] === _unref(statesRisk).draft ? _withDirectives((_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "bi bi-trash",
          role: "button",
          onClick: $event => clickRemove(row.item)
        }, null, 8, _hoisted_3)), [[_directive_can, _ctx.$perm.delete_wa_risk]]) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "4"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(CustomPagination, {
            totalRows: risksCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, risksCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_modal, {
        title: `Удалить риск ${currentItem.value.risk_num || ''}-${currentItem.value.activity_num || ''}?`,
        modelValue: showModalRemove.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => showModalRemove.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Удалить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doRemove
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};