import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, vShow as _vShow } from "vue";
const _hoisted_1 = {
  class: "table-header px-2"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "table-footer"
};
const _hoisted_6 = {
  class: "mb-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterButton = _resolveComponent("FilterButton");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_TrafficLight = _resolveComponent("TrafficLight");
  const _component_MatrixRiskListDetails = _resolveComponent("MatrixRiskListDetails");
  const _component_b_table = _resolveComponent("b-table");
  const _component_MatrixRiskAdd = _resolveComponent("MatrixRiskAdd");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_modal = _resolveComponent("b-modal");
  const _component_MatrixRiskListSidebar = _resolveComponent("MatrixRiskListSidebar");
  const _component_b_container = _resolveComponent("b-container");
  const _component_b_offcanvas = _resolveComponent("b-offcanvas");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_FilterButton, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.showSidebar = true)
  })]), _createVNode(_component_b_table, {
    borderless: "",
    items: _ctx.matrixFormRisks,
    fields: $options.tableFields,
    busy: _ctx.matrixFormRisksLoading,
    stacked: "md",
    small: "",
    responsive: "",
    "show-empty": "",
    class: "table-custom",
    hover: "",
    "empty-text": "Рисков нет, попробуйте изменить фильтры отбора или добавить новые риски"
  }, {
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    "cell(risk_levelT)": _withCtx(row => [_createVNode(_component_TrafficLight, {
      typeLight: row.value
    }, null, 8, ["typeLight"])]),
    "cell(toggleDetailsState)": _withCtx(row => [_createElementVNode("i", {
      class: _normalizeClass(["bi text-primary", row.detailsShowing ? 'bi-chevron-up' : 'bi-chevron-down']),
      role: "button",
      onClick: row.toggleDetails
    }, null, 10, _hoisted_3)]),
    "row-details": _withCtx(row => [_createVNode(_component_MatrixRiskListDetails, {
      item: row.item
    }, null, 8, ["item"])]),
    "cell(btnRemove)": _withCtx(row => [!$options.readOnly ? (_openBlock(), _createElementBlock("i", {
      key: 0,
      role: "button",
      class: "bi bi-trash",
      onClick: $event => $options.clickRemove(row.item)
    }, null, 8, _hoisted_4)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_b_form_row, {
    class: "m-0 p-0"
  }, {
    default: _withCtx(() => [!$options.readOnly ? _withDirectives((_openBlock(), _createBlock(_component_b_col, {
      key: 0,
      cols: "auto"
    }, {
      default: _withCtx(() => [!$options.readOnly ? _withDirectives((_openBlock(), _createBlock(_component_MatrixRiskAdd, {
        key: 0,
        onAddRisks: $options.addRisks
      }, null, 8, ["onAddRisks"])), [[_directive_can, _ctx.$perm.change_wa_matrix]]) : _createCommentVNode("", true)]),
      _: 1
    })), [[_directive_can, _ctx.$perm.change_wa_matrix]]) : _createCommentVNode("", true), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
        modelValue: $options.currentPageModel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.currentPageModel = $event),
        "total-rows": _ctx.matrixFormRisksCount,
        "per-page": _ctx.perPage,
        size: "sm",
        "first-number": "",
        "last-number": "",
        "hide-goto-end-buttons": "",
        class: "my-0"
      }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.matrixFormRisksCount > _ctx.perPage]])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto",
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
        prepend: "Перейти к",
        size: "sm"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.gotoPage,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.gotoPage = $event),
          style: {
            "width": "3em"
          },
          oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
        }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            variant: "outline-primary-muted",
            onClick: $options.changePage,
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
              icon: ['far', 'arrow-alt-circle-right'],
              "fixed-width": ""
            })]),
            _: 1
          }, 8, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 512), [[_vShow, _ctx.matrixFormRisksCount > _ctx.perPage]])]),
      _: 1
    })]),
    _: 1
  })]), _createVNode(_component_b_modal, {
    title: `Удалить риск ${_ctx.currentItem.risk_num || ''}-${_ctx.currentItem.activity_num || ''} из матрицы?`,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"]), _createVNode(_component_b_offcanvas, {
    modelValue: _ctx.showSidebar,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.showSidebar = $event),
    placement: "end",
    "no-header": "",
    backdrop: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_container, {
      class: "p-2"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_cache[7] || (_cache[7] = _createElementVNode("h5", {
        class: "d-inline"
      }, "Фильтровать риски", -1)), _createElementVNode("button", {
        type: "button",
        class: "btn-close float-end",
        "aria-label": "Close",
        onClick: _cache[4] || (_cache[4] = $event => _ctx.showSidebar = false)
      })]), _createVNode(_component_MatrixRiskListSidebar, {
        onEmitApplyFilters: $options.applyFilters
      }, null, 8, ["onEmitApplyFilters"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"])]);
}