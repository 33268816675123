import "core-js/modules/es.array.push.js";
import theNotification from "@/components/theNotification";
import { mapActions, mapGetters } from "vuex";
import { adminCards, settingsCards, directoryCards } from "./admin-cards";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { checkPermissions } from "@/auth/auth";
export default {
  name: "AdminArea",
  components: {
    theNotification,
    BreadCrumbToHome
  },
  data: () => ({
    adminCards,
    settingsCards,
    directoryCards
  }),
  async created() {
    if (checkPermissions(this.$perm.access_admin)) await this.updateStats4admin(); // task #523
  },
  computed: {
    ...mapGetters("declarations", ["declarationsObj"]),
    ...mapGetters("homeStats", ["stats"])
  },
  methods: {
    ...mapActions("declarations", ["fetchDeclarations"]),
    ...mapActions("homeStats", ["updateStats4admin", "setOnlyNew"]),
    onMouseClick(item) {
      this.$router.push(item.link);
    },
    clickToNew(item) {
      this.setOnlyNew(true);
      this.$router.push(item.link);
    }
  }
};