import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import DeclarationFormPartI from "@/components/Declaration/DeclarationFormPartI";
import DeclarationFormPartII from "@/components/Declaration/DeclarationFormPartII";
import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import { validateSendDeclaration } from "@/helpers/validate";
import { formatDate, fmtDateMonthTime } from "@/helpers/date";
import { statesDeclarations } from "@/helpers/states";
import links from "@/router/links";
import theAttachments from "@/components/Helpers/theAttachments.vue";
import SignPEP from "@/components/Helpers/SignPEP.vue";
import { signTypes } from "@/helpers/text";
export default {
  name: "ProfileDeclarationForm",
  props: {
    msg: String
  },
  // id декларации

  // в момент создания компоненты запросим данные
  created() {
    this.fetchDeclarationForm({
      id: this.msg,
      myDecl: true
    });
    this.updateDeclarationFormAttachments({
      id: this.msg,
      page: 1
    });
  },
  data: () => ({
    showAlert: false,
    alertTitle: "",
    showModal: false,
    signTypes,
    confirmTitle: "Настоящим подтверждаю, что я прочитал и понял все вышеуказанные вопросы, мои ответы и любая пояснительная информация являются полными, правдивыми и достоверными. Достоверность и полнота изложенной в Декларации информации мною проверена.",
    links,
    statesDeclarations,
    fmtDateMonthTime,
    breadcrumbs: [{
      text: "Личный кабинет",
      to: links.profileDeclarations
    }]
  }),
  methods: {
    ...mapActions("declarationForm", ["fetchDeclarationForm", "sendDeclaration", "saveDeclarationAttachments", "delDeclarationAttachments", "updateDeclarationFormAttachments", "addCOI", "delCOI", "changeCOI"]),
    formatDate,
    async addFile(file) {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("file_name", file.name);
      await this.saveDeclarationAttachments(formData);
      await this.updateDeclarationFormAttachments({
        id: this.msg,
        page: 1
      });
    },
    async delFile(idAttachment) {
      await this.delDeclarationAttachments(idAttachment);
      await this.updateDeclarationFormAttachments({
        id: this.msg,
        page: 1
      });
    },
    async setPage(page) {
      await this.updateDeclarationFormAttachments({
        id: this.msg,
        page
      });
    },
    async signPEP() {
      const errorMessage = validateSendDeclaration(this.declarationFormPartII, this.declarationFormObj);
      this.showAlert = false;
      if (errorMessage) {
        this.alertTitle = errorMessage;
        this.showAlert = true;
        return;
      }
      // Достоверность и полнота информации
      this.showModal = true;
    },
    doSend() {
      this.$refs.btnPEP.handlerClick();
    },
    async emitAddCOI(data) {
      await this.addCOI(data);
      await this.fetchDeclarationForm({
        id: this.msg,
        myDecl: true
      });
    },
    async emitDelCOI(data) {
      await this.delCOI(data);
      await this.fetchDeclarationForm({
        id: this.msg,
        myDecl: true
      });
    },
    async emitChangeCOI(data) {
      await this.changeCOI(data);
      await this.fetchDeclarationForm({
        id: this.msg,
        myDecl: true
      });
    }
  },
  components: {
    theNotification,
    BreadCrumbToHome,
    DeclarationFormPartI,
    DeclarationFormPartII,
    theAttachments,
    SignPEP
  },
  computed: {
    ...mapGetters("declarationForm", ["declarationFormPartII", "declarationFormObj", "declarationFormLoading", "declarationFormAttachments", "declarationFormAttachmentsCount", "declarationFormAttachmentsLoading"]),
    renderUser() {
      const {
        position,
        organization,
        worker_status,
        worker_code,
        senior,
        email
      } = this.declarationFormObj.user_detail;
      return {
        position,
        organization,
        worker_status,
        worker_code,
        senior,
        email,
        ...this.declarationFormObj.profile_detail
      };
    },
    disabledDeclActions() {
      return ["new", "draft"].includes(this.declarationFormObj.state) ? false : true;
    },
    disabledDeclSend() {
      return this.declarationFormObj.transitions.includes("submitted") && !this.disabledDeclActions ? false : true;
    },
    getAttachments() {
      return this.declarationFormAttachments.map(el => ({
        name: el.file_name,
        id: el.id,
        date: el.created_at,
        file: el.document
      }));
    }
  }
};