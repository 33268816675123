import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "p-2"
};
const _hoisted_2 = {
  key: 0,
  class: "spinner-border",
  role: "status"
};
const _hoisted_3 = {
  key: 1,
  class: "title-wrap"
};
import { useRoute } from "vue-router";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { formatLocalDate } from "@/helpers/date";
import { formatName } from "@/helpers/text.js";
export default {
  __name: 'EducationCourseCertificate',
  setup(__props) {
    const store = useStore();
    const route = useRoute();
    const getCourse = computed(() => store.getters["education/getCourse"]);
    const courseLoading = computed(() => store.getters["education/courseLoading"]);

    // при монтировании
    onMounted(() => {
      store.dispatch("education/updateCourse4User", route?.query?.id);
    });
    const coverBg = computed(() => ({
      "background-image": getCourse.value?.image_certificate ? `url(${getCourse.value.image_certificate})` : `url(${require("@/assets/img/certificate.png")}`
    }));
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "div-cover",
        style: _normalizeStyle(coverBg.value)
      }, null, 4), courseLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", null, [_cache[0] || (_cache[0] = _createElementVNode("h1", {
        class: "mb-5"
      }, "СЕРТИФИКАТ", -1)), _createElementVNode("h2", null, _toDisplayString(_unref(formatName)({
        first_name: getCourse.value?.assignment?.student_first_name,
        middle_name: getCourse.value?.assignment?.student_middle_name,
        last_name: getCourse.value?.assignment?.student_last_name
      })), 1), _cache[1] || (_cache[1] = _createElementVNode("h3", null, "обучение по программе", -1)), _createElementVNode("h2", null, _toDisplayString(getCourse.value.title), 1), _createElementVNode("h3", null, "успешно пройдено " + _toDisplayString(_unref(formatLocalDate)(new Date())), 1)])]))]);
    };
  }
};