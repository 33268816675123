import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "declaration-part-II-wrap"
};
const _hoisted_2 = {
  class: "part-II-fields"
};
const _hoisted_3 = {
  class: "switch-btn"
};
const _hoisted_4 = {
  key: 0,
  class: "button-add pt-2"
};
const _hoisted_5 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_DeclarationFormPartIIItem = _resolveComponent("DeclarationFormPartIIItem");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_BFormCheckbox = _resolveComponent("BFormCheckbox");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_BFormInput = _resolveComponent("BFormInput");
  const _component_BFormGroup = _resolveComponent("BFormGroup");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.sectionData, (item, prop) => {
    return _openBlock(), _createElementBlock("div", {
      key: prop
    }, [_createVNode(_component_b_form_group, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: item.switchSection,
        "onUpdate:modelValue": $event => item.switchSection = $event,
        name: "check-button",
        id: item.switchSectionId,
        switch: "",
        disabled: $props.disabledDeclActions
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.text), 1)]),
        _: 2
      }, 1032, ["modelValue", "onUpdate:modelValue", "id", "disabled"]), _withDirectives(_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.bottomSectionRender, (itemInfo, propInfo) => {
        return _openBlock(), _createElementBlock("div", {
          class: "section-item",
          key: propInfo
        }, [_createVNode(_component_DeclarationFormPartIIItem, {
          item: itemInfo,
          itemIndex: propInfo,
          sectionId: prop,
          onRemoveItem: $options.onRemoveItem,
          onChangeItem: $options.onChangeItem,
          disabledDeclActions: $props.disabledDeclActions
        }, null, 8, ["item", "itemIndex", "sectionId", "onRemoveItem", "onChangeItem", "disabledDeclActions"])]);
      }), 128)), item['bottomSection'] ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_b_button, {
        disabled: $props.disabledDeclActions,
        size: "sm",
        variant: "outline-primary",
        onClick: $event => $options.onAddItem(item.switchSectionId)
      }, {
        default: _withCtx(() => [_createElementVNode("small", null, [_createVNode(_component_font_awesome_icon, {
          icon: "plus",
          "fixed-width": ""
        })]), _cache[3] || (_cache[3] = _createTextVNode(" Добавить информацию "))]),
        _: 2
      }, 1032, ["disabled", "onClick"])])) : _createCommentVNode("", true)], 512), [[_vShow, item.switchSection]])]),
      _: 2
    }, 1024)]);
  }), 128))]), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.showModal = $event),
    id: "changeItem",
    size: "lg",
    title: "Редактировать информацию",
    onOk: $options.changeItemOk,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    centered: "",
    "ok-disabled": _ctx.okDisabled,
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.sectionName), 1), _createElementVNode("form", {
      ref: "form-modal",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers((...args) => $options.handleSubmit && $options.handleSubmit(...args), ["stop", "prevent"]))
    }, [_createVNode(_component_b_form_row, null, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bottomSectionRenderItem, (item, index) => {
        return _openBlock(), _createBlock(_component_b_col, {
          key: index,
          cols: "12",
          lg: item.gridLg || 12
        }, {
          default: _withCtx(() => [item.hidden ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [], 64)) : item.type === 'checkbox' ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 1
          }, [_createTextVNode(_toDisplayString(item.name) + " ", 1), _createVNode(_component_BFormCheckbox, {
            modelValue: _ctx.bottomSectionRenderItem[index].data,
            "onUpdate:modelValue": $event => _ctx.bottomSectionRenderItem[index].data = $event,
            name: "check-button-'modal' + String(index)",
            id: 'modal' + String(index),
            switch: ""
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString($options.switchTextRender(_ctx.bottomSectionRenderItem[index].data)), 1)]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "id"])], 64)) : item.type === 'date' ? (_openBlock(), _createBlock(_component_Calendar, {
            key: 2,
            title: item.name + ' *',
            date: $options.formatObjDate(_ctx.bottomSectionRenderItem[index].data),
            classCustom: "mt-2 no-gutters",
            onChangeValue: $event => $options.changeDate(index, $event),
            onBlur: $event => $options.blurMethod(index),
            state: $options.stateInput(index)
          }, null, 8, ["title", "date", "onChangeValue", "onBlur", "state"])) : (_openBlock(), _createBlock(_component_BFormGroup, {
            key: 3,
            id: 'modal' + String(index),
            label: item.name + ' *',
            "label-for": 'modal' + String(index),
            "invalid-feedback": item['dataInvalidMessage'] || '',
            state: $options.stateInput(index)
          }, {
            default: _withCtx(() => [_createVNode(_component_BFormInput, {
              id: 'modal' + String(index),
              modelValue: _ctx.bottomSectionRenderItem[index].data,
              "onUpdate:modelValue": $event => _ctx.bottomSectionRenderItem[index].data = $event,
              required: "",
              type: item['type'],
              onBlur: $event => $options.blurMethod(index),
              onInput: $event => $options.changeMethod(index),
              state: $options.stateInput(index),
              trim: ""
            }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "type", "onBlur", "onInput", "state"])]),
            _: 2
          }, 1032, ["id", "label", "label-for", "invalid-feedback", "state"]))]),
          _: 2
        }, 1032, ["lg"]);
      }), 128))]),
      _: 1
    })], 544), _cache[4] || (_cache[4] = _createElementVNode("p", {
      class: "text-muted"
    }, "* обязательные к заполнению поля", -1))]),
    _: 1
  }, 8, ["modelValue", "onOk", "ok-disabled"]), _createVNode(_component_b_modal, {
    title: _ctx.currentItem.title,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"])]);
}