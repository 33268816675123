import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { statesTestUser } from "@/helpers/states";
import { mapActions, mapGetters } from "vuex";
import { limitBound, formatName, switchText, answerResult, notVerified, fmtNameShort } from "@/helpers/text";
export default {
  name: "TestChecks",
  data: () => ({
    showModalFinish: false,
    notVerified,
    showModal: false,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "index",
      label: "№ п/п",
      class: "text-nowrap"
    }, {
      key: "id",
      label: "UID",
      class: "font-mono"
    }, {
      key: "student_detailT",
      label: "Автор"
    }, {
      key: "course_title",
      label: "Наименование"
    }, {
      key: "created_atT",
      label: "Дата создания",
      class: "text-nowrap font-mono"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "attempt_num",
      label: "Номер попытки",
      class: "text-center"
    }],
    // answers
    currentAttempt: {
      all_checked: false
    },
    filterAnw: {
      question_type: "open"
    },
    answerResult,
    currentPageAnsw: 1,
    tableFieldsAnsw: [{
      key: "question",
      label: "ID вопроса",
      class: "font-mono"
    }, {
      key: "is_correct",
      label: "Статус"
    }]
  }),
  methods: {
    ...mapActions("education", ["updateTestsCheck", "updateAnswersCheck", "changeAnswersCheck", "changeTestsState", "updateEducationTestWp"]),
    switchText,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.testsCheckCount / this.perPage, this.currentPage);
      this.updateTestsCheck({
        page: this.currentPage
      });
    },
    clickRow(attempt) {
      this.updateAnswersCheck({
        page: 1,
        attempt: attempt.id,
        filters: this.filterAnw
      });
      this.currentAttempt = attempt;
      this.showModal = true;
    },
    async setAnswerCheck(answer, value) {
      await this.changeAnswersCheck({
        attempt: this.currentAttempt.id,
        answer,
        body: {
          is_correct: value
        }
      });
      await this.updateAnswersCheck({
        page: this.currentPageAnsw,
        attempt: this.currentAttempt.id,
        filters: this.filterAnw
      });
      await this.updateEducationTestWp(this.currentAttempt.id);
      this.currentAttempt = this.educationTestWp;
      this.updateTestsCheck({
        page: this.currentPage
      });
    },
    finishCheck() {
      this.showModalFinish = true;
    },
    async doFinish() {
      await this.changeTestsState({
        id: this.currentAttempt.id,
        state: "checked"
      });
      await this.updateEducationTestWp(this.currentAttempt.id); // тест уже не новый, нужен технический запрос
      await this.updateTestsCheck({
        page: this.currentPage
      });
    },
    class4isCorrect(value) {
      return value === null ? "" : value ? "text-success" : "text-danger";
    }
  },
  computed: {
    ...mapGetters("education", ["testsCheck", "testsCheckLoading", "testsCheckCount", "answersCheck", "answersCheckCount", "answersCheckLoading", "educationTestWp", "educationTestWpLoading"]),
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateTestsCheck({
          page: value
        });
      }
    },
    currentPageModelAnsw: {
      get() {
        return this.currentPageAnsw;
      },
      set(value) {
        this.currentPageAnsw = value;
        this.updateAnswersCheck({
          page: value,
          attempt: this.currentAttempt.id,
          filters: this.filterAnw
        });
      }
    },
    items4table() {
      return Array.isArray(this.testsCheck) ? this.testsCheck.map(el => ({
        ...el,
        created_atT: formatDate(el.created_at, fmtDateShort),
        student_detailT: formatName(el.student_detail, fmtNameShort),
        stateT: statesTestUser[el.state]
      })) : [];
    },
    showBtnCheckDisabled() {
      return statesTestUser[this.currentAttempt.state] === statesTestUser.submitted || statesTestUser[this.currentAttempt.state] === statesTestUser.closed;
    },
    modalOkDisabled() {
      return !this.currentAttempt.all_checked || !this.showBtnCheckDisabled;
    }
  }
};