import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_b_tooltip = _resolveDirective("b-tooltip");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_button, {
    variant: "link",
    class: "text-primary",
    onClick: $options.addDetails
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Добавить")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_modal, {
    id: $props.item.idModal,
    title: $props.item.modalTitle,
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Добавить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalDisabled,
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Комментарий",
          "label-for": "modal-comment"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
            id: "modal-comment",
            modelValue: _ctx.comment,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.comment = $event),
            placeholder: "Укажите комментарий",
            rows: "3",
            "max-rows": "3",
            trim: ""
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.file ? _ctx.file.name : _ctx.NONAME_SHORT), 1)]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto"
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
          class: "pe-0",
          variant: "link",
          title: "Загрузить файл",
          onClick: $options.clickAddFile,
          disabled: !!_ctx.file
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
            class: "bi bi-file-earmark-plus"
          }, null, -1)])),
          _: 1
        }, 8, ["onClick", "disabled"])), [[_directive_b_tooltip, void 0, void 0, {
          hover: true
        }]])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_button, {
          class: "pe-0",
          variant: "link",
          disabled: !_ctx.file,
          onClick: _cache[1] || (_cache[1] = $event => _ctx.file = null)
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
            class: "bi bi-trash"
          }, null, -1)])),
          _: 1
        }, 8, ["disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["id", "title", "onOk", "ok-disabled"]), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[2] || (_cache[2] = (...args) => $options.handlerAdd && $options.handlerAdd(...args))
  }, null, 544)]);
}