import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = {
  "b-container": "",
  class: "mb-2"
};
const _hoisted_3 = {
  class: "text-center"
};
const _hoisted_4 = {
  key: 0,
  class: "mb-0"
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "mt-5 pt-5"
};
const _hoisted_7 = {
  class: "table-wrap"
};
const _hoisted_8 = {
  class: "text-start"
};
const _hoisted_9 = {
  class: "text-start"
};
const _hoisted_10 = {
  class: "text-center"
};
const _hoisted_11 = {
  class: "text-center"
};
const _hoisted_12 = {
  key: 1,
  class: "d-none",
  id: "loaded"
};
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { chartOptions } from "@/components/Risk/Chart/settings";
import { formatDate, fmtDate } from "@/helpers/date";

// store

export default {
  __name: 'RiskChartPrint',
  setup(__props) {
    const store = useStore();
    const riskChart = computed(() => store.getters["risk/riskChart"]);
    const riskChartLoading = computed(() => store.getters["risk/riskChartLoading"]);
    const options4chartPrint = computed(() => ({
      responsive: chartOptions.responsive,
      aspectRatio: chartOptions.aspectRatio,
      scales: chartOptions.scales,
      plugins: {
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            pointStyle: "rounded",
            usePointStyle: true,
            padding: 24
          }
        },
        tooltip: {
          enabled: false
        },
        datalabels: {
          formatter: value => value.risk,
          labels: {
            title: {
              align: -20,
              offset: 10,
              font: {
                // weight: "bold",
                size: 16
              }
            }
          }
        }
      },
      animation: false
    }));
    const loaded = ref(false);
    const route = useRoute();
    // при монтировании
    onMounted(async () => {
      loaded.value = false;
      const params = {};
      if (route?.query?.year) {
        params.confirmed_at_gte = formatDate(new Date(route.query.year, 0, 1), fmtDate);
        params.confirmed_at_lte = formatDate(new Date(route.query.year, 12, 0), fmtDate);
      }
      if (route?.query?.passcode) params.passcode = route.query.passcode;
      await store.dispatch("risk/updateRiskChart", params);
      const ctx = document.getElementById("RiskChart");
      new Chart(ctx, {
        type: "scatter",
        data: riskChart.value,
        options: options4chartPrint.value,
        plugins: [ChartDataLabels]
      });
      setTimeout(() => {
        loaded.value = true;
      }, 0);
    });
    return (_ctx, _cache) => {
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_container = _resolveComponent("b-container");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [riskChartLoading.value ? (_openBlock(), _createBlock(_component_b_spinner, {
        key: 0,
        class: "align-middle text-center text-primary"
      })) : _createCommentVNode("", true), _createElementVNode("header", _hoisted_2, [_createVNode(_component_b_container, {
        fluid: ""
      }, {
        default: _withCtx(() => [_createElementVNode("h2", _hoisted_3, [_cache[0] || (_cache[0] = _createTextVNode(" СВОДНЫЙ ГРАФИК РИСКОВ ")), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)), riskChart.value.org_name ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(riskChart.value.org_name), 1)) : _createCommentVNode("", true), _unref(route)?.query?.year ? (_openBlock(), _createElementBlock("h4", _hoisted_5, "за " + _toDisplayString(_unref(route).query.year) + " год", 1)) : _createCommentVNode("", true)])]),
        _: 1
      })]), _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "px-4 div-chart-wrap"
      }, [_createElementVNode("canvas", {
        id: "RiskChart"
      })], -1)), _createElementVNode("div", _hoisted_6, [_createElementVNode("table", _hoisted_7, [_cache[2] || (_cache[2] = _createElementVNode("tr", null, [_createElementVNode("th", {
        class: "text-start"
      }, "№"), _createElementVNode("th", {
        class: "text-start"
      }, "Описание"), _createElementVNode("th", {
        class: "vertical-wrap text-center"
      }, "Вероятность, %"), _createElementVNode("th", {
        class: "text-center"
      }, "Влияние, %")], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(riskChart.value.datasets, ds => {
        return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ds.data, (risk, inr) => {
          return _openBlock(), _createElementBlock("tr", {
            key: inr,
            style: _normalizeStyle(`background-color:${ds.pointBackgroundColor}`)
          }, [_createElementVNode("td", _hoisted_8, _toDisplayString(risk.risk), 1), _createElementVNode("td", _hoisted_9, _toDisplayString(risk.riskName), 1), _createElementVNode("td", _hoisted_10, _toDisplayString(risk.y), 1), _createElementVNode("td", _hoisted_11, _toDisplayString(risk.x), 1)], 4);
        }), 128))], 64);
      }), 256))])])]), loaded.value ? (_openBlock(), _createElementBlock("div", _hoisted_12)) : _createCommentVNode("", true)]);
    };
  }
};