import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vShow as _vShow } from "vue";
const _hoisted_1 = ["onUpdate:modelValue"];
const _hoisted_2 = {
  class: "mt-1"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "py-2 px-3"
};
const _hoisted_5 = {
  class: "col"
};
const _hoisted_6 = ["onUpdate:modelValue", "onChange"];
const _hoisted_7 = {
  class: "col text-muted pt-1"
};
import { computed, ref } from "vue";
import { useStore } from "vuex";

// store

export default {
  __name: 'AdminRiskCritNameSet',
  setup(__props) {
    const store = useStore();
    const adminRisk = computed(() => store.getters["administration/adminRisk"]);
    const adminRiskElements = computed(() => store.getters["administration/adminRiskElements"]);
    const values_probability_map = ["signing_act", "control_procedures", "activity_regulation", "confirming_document", "violations_internal", "violations_partners"];
    const values_impact_map = ["responsibility", "financial_costs", "reputation_impact"];
    const showDetails = ref({});
    function changeElement(key) {
      let rootKey = undefined;
      if (values_probability_map.includes(key)) rootKey = "values_probability_map";else if (values_impact_map.includes(key)) rootKey = "values_impact_map";
      if (rootKey === undefined) return;
      const body = {
        [rootKey]: {
          ...adminRisk.value[rootKey]
        }
      };
      body[rootKey][key] = {};
      adminRiskElements.value[key].forEach(are => {
        body[rootKey][key][are.key] = are.value;
      });
      store.dispatch("administration/changeRisk", {
        id: adminRisk.value.id,
        body
      });
    }
    function changeTerms() {
      store.dispatch("administration/changeRisk", {
        id: adminRisk.value.id,
        body: {
          terms_map: adminRisk.value.terms_map
        }
      });
    }
    return (_ctx, _cache) => {
      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(adminRisk.value.terms_map), tk => {
        return _openBlock(), _createElementBlock("div", {
          key: tk,
          class: "border-top mb-2 py-2"
        }, [_withDirectives(_createElementVNode("input", {
          type: "text",
          class: "form-control",
          "onUpdate:modelValue": $event => adminRisk.value.terms_map[tk] = $event,
          onChange: changeTerms
        }, null, 40, _hoisted_1), [[_vModelText, adminRisk.value.terms_map[tk], void 0, {
          trim: true
        }]]), _createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
          type: "button",
          class: "btn btn-link btn-sm",
          onClick: $event => showDetails.value[tk] = !showDetails.value[tk]
        }, _toDisplayString(showDetails.value[tk] ? "- скрыть " : "+ показать ") + " элементы ", 9, _hoisted_3)]), _withDirectives(_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adminRiskElements.value[tk], (el, eli) => {
          return _openBlock(), _createElementBlock("div", {
            class: "row mx-0 mb-2",
            key: eli
          }, [_createElementVNode("div", _hoisted_5, [_withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control form-control-sm",
            "onUpdate:modelValue": $event => el.key = $event,
            onChange: $event => changeElement(tk)
          }, null, 40, _hoisted_6), [[_vModelText, el.key, void 0, {
            trim: true
          }]])]), _createElementVNode("div", _hoisted_7, _toDisplayString(el.value), 1)]);
        }), 128))], 512), [[_vShow, showDetails.value[tk]]])]);
      }), 128);
    };
  }
};