import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_row = _resolveComponent("b-row");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_col, {
      cols: "12",
      md: "2",
      lg: "2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Итоговый уровень риска"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.partnerForm.resulting_risk_level,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.partnerForm.resulting_risk_level = $event),
          options: $options.optionsRisk,
          onChange: _cache[1] || (_cache[1] = $event => $options.updateCheckRisk('resulting_risk_level')),
          disabled: $options.readOnlyTotalRisk
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("Выберите уровень риска ")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    })), [[_directive_can, _ctx.$perm.change_wa_bp_final_risk]]), _createVNode(_component_b_col, {
      cols: "12",
      md: "4",
      lg: "4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Подразделение инициатор"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.partnerForm.initiator_department,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.partnerForm.initiator_department = $event),
          options: $options.departmentOptions,
          onChange: _cache[3] || (_cache[3] = $event => $options.updateCheckRisk('initiator_department')),
          disabled: $options.readOnlyAssign
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("Выберите подразделение ")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Ответственное лицо"
      }, {
        default: _withCtx(() => [_createVNode(_component_UserSelector, {
          onSelected: _cache[4] || (_cache[4] = $event => $options.updateCheckRisk('assignee', $event)),
          name: _ctx.partnerForm.assigneeDetail,
          fmtName: _ctx.fmtNameFull,
          showInfo: true,
          disabled: $options.readOnlyAssign
        }, null, 8, ["name", "fmtName", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата заключения договора",
        date: _ctx.partnerForm.contractDate,
        classCustom: "mt-2",
        onChangeValue: _cache[5] || (_cache[5] = $event => $options.updateCheckRisk('contract_date', $event)),
        disabled: $options.readOnlyContract
      }, null, 8, ["date", "disabled"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Номер договора"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.partnerForm.contract_num,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.partnerForm.contract_num = $event),
          onChange: _cache[7] || (_cache[7] = $event => $options.updateCheckRisk('contract_num')),
          trim: "",
          disabled: $options.readOnlyContract
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Обоснование заключения договора"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.partnerForm.contract_reason,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.partnerForm.contract_reason = $event),
      placeholder: "Введите текст",
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[9] || (_cache[9] = $event => $options.updateCheckRisk('contract_reason')),
      disabled: $options.readOnlyContract
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  })]);
}