import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.map.js";
import Calendar from "@/components/Helpers/Calendar.vue";
import TaskButton from "@/components/Declaration/Tasks/TaskButton.vue";
import StringsWithX from "@/components/Helpers/StringsWithX.vue";
import { mapGetters, mapActions } from "vuex";
import { checkPermissions } from "@/auth/auth.js";
import { states } from "@/helpers/states";
import { option4select } from "@/helpers/global";
import declTemplate from "@/components/Declaration/declarationSectionTemplate";
import coiTemplate from "./coiTemplate";
import { formatDateObj, fmtDate, formatObjDate } from "@/helpers/date";
import { switchText, formatName, fmtNameShort } from "@/helpers/text";
export default {
  name: "COIFormInfo",
  data: () => ({
    coiTemplate,
    noRenderKeys: ["id"],
    settingsBtn: {
      buttonTitle: "Добавить работника",
      modalTitle: "Добавить работника",
      buttonType: "outline-primary",
      addAssignee: true
    }
  }),
  components: {
    Calendar,
    TaskButton,
    StringsWithX
  },
  methods: {
    ...mapActions("coi", ["changeCOIForm"]),
    switchText,
    formatObjDate,
    changeData(key) {
      let value = this.coiForm[key];
      this.changeCOIForm({
        id: this.coiForm.id,
        params: {
          [key]: value
        }
      });
    },
    async changeDataDate(key, data) {
      const value = formatDateObj(data, fmtDate, null);
      if (!value) return;
      this.changeCOIForm({
        id: this.coiForm.id,
        params: {
          [key]: value
        }
      });
    },
    clickAddWorker(data) {
      this.coiForm.workers.push(data.worker);
      this.changeData("workers");
    },
    clickRemoveWorker(id) {
      const index = this.coiForm.workers.findIndex(el => el === id);
      if (index >= 0) {
        this.coiForm.workers.splice(index, 1);
        this.changeData("workers");
      }
    }
  },
  computed: {
    ...mapGetters("coi", ["coiForm", "coiDirectory", "coiFormChangeLoading"]),
    option4type() {
      return option4select(this.coiDirectory, false);
    },
    readOnly() {
      return states[this.coiForm.state] !== states.draft || !checkPermissions(this.$perm.add_wa_coi) || this.coiFormChangeLoading;
    },
    coiRenderTemplate() {
      if (this.coiForm.coi_type_code) {
        const template = Object.values(declTemplate).find(el => el.bottomSectionMaping.keyDB === this.coiForm.coi_type_code);
        return template ? template.bottomSectionRenderTemplate : {};
      } else return {};
    },
    workersArray() {
      return Array.isArray(this.coiForm.workers_detail) ? this.coiForm.workers_detail.map(el => ({
        id: el.user_id,
        text: formatName(el, fmtNameShort)
      })) : [];
    }
  }
};