import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.reduce.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = {
  "b-container": "",
  class: "mb-2"
};
const _hoisted_3 = {
  class: "text-center"
};
const _hoisted_4 = {
  width: "100%",
  class: "table-wrap"
};
const _hoisted_5 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_6 = {
  colspan: "1",
  class: "vertical-wrap text-center tr-head-wrap"
};
const _hoisted_7 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_8 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_9 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_10 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_11 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_12 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_13 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_14 = {
  colspan: "1",
  class: "vertical-wrap text-center"
};
const _hoisted_15 = ["rowspan"];
const _hoisted_16 = ["rowspan"];
const _hoisted_17 = ["rowspan"];
const _hoisted_18 = {
  class: "text-center p-0"
};
const _hoisted_19 = {
  class: "text-center"
};
const _hoisted_20 = {
  class: "text-center"
};
const _hoisted_21 = {
  class: "text-center"
};
const _hoisted_22 = {
  class: "text-center"
};
const _hoisted_23 = {
  class: "text-center"
};
const _hoisted_24 = {
  class: "text-center"
};
const _hoisted_25 = {
  class: "text-center"
};
const _hoisted_26 = {
  class: "text-center"
};
const _hoisted_27 = {
  class: "text-center"
};
const _hoisted_28 = {
  class: "text-center"
};
const _hoisted_29 = {
  class: "text-center"
};
const _hoisted_30 = {
  class: "text-center"
};
const _hoisted_31 = {
  class: "text-center"
};
import { useRoute } from "vue-router";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { riskLevelPartners } from "@/helpers/text";
import { statesRiskMatrix } from "@/helpers/states";
export default {
  __name: 'MatrixFormPrint',
  setup(__props) {
    const store = useStore();
    const route = useRoute();

    // store
    const matrixForm = computed(() => store.getters["risk/matrixForm"]);
    const matrixFormLoading = computed(() => store.getters["risk/matrixFormLoading"]);
    const matrixFormRisks = computed(() => store.getters["risk/matrixFormRisks"]);
    const matrixFormRisksLoading = computed(() => store.getters["risk/matrixFormRisksLoading"]);
    const organizations = computed(() => store.getters["referenceBooks/organizations"]);
    const organizationsLoading = computed(() => store.getters["referenceBooks/organizationsLoading"]);
    const risks4table = computed(() => {
      const obj = {};
      matrixFormRisks.value.forEach(mfr => {
        const owner = mfr?.risk_owner_detail?.id;
        if (obj[owner] === undefined && owner) obj[owner] = {};
        const an = mfr?.activity_num;
        if (obj[owner][an] === undefined && an) obj[owner][an] = [];
        obj[owner][an].push({
          ...mfr
        });
      });
      return obj;
    });
    function count4owner(data) {
      return data.reduce((acc, curr) => acc += curr.length, 0);
    }

    // при монтировании
    onMounted(() => {
      store.dispatch("risk/updateMatrixById", route.params.msg);
      store.dispatch("risk/updateMatrixFormRisks4Print", route.params.msg);
      store.dispatch("referenceBooks/updateOrganizations");
    });
    const orgName = computed(() => organizations.value ? Object.entries(organizations.value).find(o => o?.[1] === matrixForm.value?.organization)?.[0] : "");
    return (_ctx, _cache) => {
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_container = _resolveComponent("b-container");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [matrixFormLoading.value || organizationsLoading.value || matrixFormRisksLoading.value ? (_openBlock(), _createBlock(_component_b_spinner, {
        key: 0,
        class: "align-middle text-center text-primary"
      })) : _createCommentVNode("", true), _createElementVNode("header", _hoisted_2, [_createVNode(_component_b_container, {
        fluid: ""
      }, {
        default: _withCtx(() => [_createElementVNode("h2", _hoisted_3, [_createTextVNode(" МАТРИЦА РИСКОВ № " + _toDisplayString(matrixForm.value.id) + " ", 1), _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)), _createElementVNode("h4", null, _toDisplayString(_unref(statesRiskMatrix)[matrixForm.value.state]) + " " + _toDisplayString(_unref(formatDate)(matrixForm.value.confirmed_at, _unref(fmtDateShort))), 1)])]),
        _: 1
      })]), _createElementVNode("table", _hoisted_4, [_cache[2] || (_cache[2] = _createElementVNode("tr", null, [_createElementVNode("th", {
        rowspan: "2",
        class: "vertical-wrap text-center"
      }, " Наименование учреждения "), _createElementVNode("th", {
        rowspan: "2",
        class: "vertical-wrap text-center"
      }, " № вида деятельности "), _createElementVNode("th", {
        rowspan: "2",
        class: "vertical-wrap text-center"
      }, "Вид деятельности"), _createElementVNode("th", {
        rowspan: "2",
        class: "vertical-wrap text-center"
      }, "№ риска"), _createElementVNode("th", {
        rowspan: "2",
        class: "vertical-wrap text-center"
      }, "Описание риска"), _createElementVNode("th", {
        rowspan: "2",
        class: "vertical-wrap text-center"
      }, " Рисковые должности "), _createElementVNode("th", {
        colspan: "7",
        class: "text-center"
      }, " Факторы, влияющие на увеличение вероятности возникновения риска "), _createElementVNode("th", {
        colspan: "4",
        class: "text-center"
      }, "Оценка влияния"), _createElementVNode("th", {
        rowspan: "2",
        class: "vertical-wrap text-center"
      }, "Уровень риска")], -1)), _createElementVNode("tr", null, [_createElementVNode("th", _hoisted_5, _toDisplayString(matrixForm.value?.terms_map?.activity_regulation), 1), _createElementVNode("th", _hoisted_6, _toDisplayString(matrixForm.value?.terms_map?.signing_act), 1), _createElementVNode("th", _hoisted_7, _toDisplayString(matrixForm.value?.terms_map?.confirming_document), 1), _createElementVNode("th", _hoisted_8, _toDisplayString(matrixForm.value?.terms_map?.control_procedures), 1), _createElementVNode("th", _hoisted_9, _toDisplayString(matrixForm.value?.terms_map?.violations_internal) + " " + _toDisplayString(orgName.value), 1), _createElementVNode("th", _hoisted_10, _toDisplayString(matrixForm.value?.terms_map?.violations_partners), 1), _cache[1] || (_cache[1] = _createElementVNode("th", {
        colspan: "1",
        class: "vertical-wrap text-center"
      }, " Вероятность наступления риска ", -1)), _createElementVNode("th", _hoisted_11, _toDisplayString(matrixForm.value?.terms_map?.reputation_impact), 1), _createElementVNode("th", _hoisted_12, _toDisplayString(matrixForm.value?.terms_map?.responsibility), 1), _createElementVNode("th", _hoisted_13, _toDisplayString(matrixForm.value?.terms_map?.financial_costs) + " " + _toDisplayString(orgName.value), 1), _createElementVNode("th", _hoisted_14, " Степень влияния риска на деятельность " + _toDisplayString(orgName.value), 1)]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(risks4table.value), ov => {
        return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(ov), (vd, invd) => {
          return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vd, (risk, inr) => {
            return _openBlock(), _createElementBlock("tr", {
              key: inr
            }, [invd === 0 && inr === 0 ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              class: "vertical-wrap text-center p-0",
              rowspan: count4owner(Object.values(ov))
            }, _toDisplayString(risk?.risk_owner_detail?.department_name), 9, _hoisted_15)) : _createCommentVNode("", true), inr === 0 ? (_openBlock(), _createElementBlock("td", {
              key: 1,
              class: "text-center p-0",
              rowspan: vd.length
            }, _toDisplayString(risk.activity_num), 9, _hoisted_16)) : _createCommentVNode("", true), inr === 0 ? (_openBlock(), _createElementBlock("td", {
              key: 2,
              class: "text-center",
              rowspan: vd.length
            }, _toDisplayString(risk.activity_description), 9, _hoisted_17)) : _createCommentVNode("", true), _createElementVNode("td", _hoisted_18, _toDisplayString(risk.risk_num), 1), _createElementVNode("td", _hoisted_19, _toDisplayString(risk.risk_description), 1), _createElementVNode("td", _hoisted_20, _toDisplayString(risk.risk_positions), 1), _createElementVNode("td", _hoisted_21, _toDisplayString(risk.activity_regulation), 1), _createElementVNode("td", _hoisted_22, _toDisplayString(risk.signing_act), 1), _createElementVNode("td", _hoisted_23, _toDisplayString(risk.confirming_document), 1), _createElementVNode("td", _hoisted_24, _toDisplayString(risk.control_procedures), 1), _createElementVNode("td", _hoisted_25, _toDisplayString(risk.violations_internal), 1), _createElementVNode("td", _hoisted_26, _toDisplayString(risk.violations_partners), 1), _createElementVNode("td", _hoisted_27, _toDisplayString(Math.round(100 * risk.risk_probability) + "%"), 1), _createElementVNode("td", _hoisted_28, _toDisplayString(risk.reputation_impact), 1), _createElementVNode("td", _hoisted_29, _toDisplayString(risk.responsibility), 1), _createElementVNode("td", _hoisted_30, _toDisplayString(risk.financial_costs), 1), _createElementVNode("td", _hoisted_31, _toDisplayString(Math.round(100 * risk.risk_impact) + "%"), 1), _createElementVNode("td", {
              class: _normalizeClass(["text-center p-0", `bg-${risk.risk_levelT} bg-opacity-25`])
            }, _toDisplayString(_unref(riskLevelPartners)?.[risk.risk_level]), 3)]);
          }), 128))], 64);
        }), 256))], 64);
      }), 256))])])]);
    };
  }
};