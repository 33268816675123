import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "military"
};
const _hoisted_2 = {
  class: "rank"
};
const _hoisted_3 = {
  class: "military_card_number"
};
const _hoisted_4 = {
  class: "military_commissariat_name"
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  colspan: "3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "13. Отношение к воинской обязанности и воинское звание"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.military_service,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.military_service = $event),
          options: _ctx.militaryStatusOptions,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('military_service')),
          state: _ctx.military_service_state,
          disabled: $options.readonly
        }, null, 8, ["modelValue", "options", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("h6", null, "Воинские звания", -1)])),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[5] || (_cache[5] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Звание"), _createElementVNode("th", null, "Номер военного билета"), _createElementVNode("th", null, "Наименование военного комиссариата по месту жительства")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranks, rank => {
          return _openBlock(), _createElementBlock("tr", {
            key: rank.id,
            class: "rank-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_input, {
            modelValue: rank.rank,
            "onUpdate:modelValue": $event => rank.rank = $event,
            onChange: $event => $options.submitDataEndpoint(rank, 'rank'),
            state: rank.rank_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
            modelValue: rank.military_card_number,
            "onUpdate:modelValue": $event => rank.military_card_number = $event,
            onChange: $event => $options.submitDataEndpoint(rank, 'military_card_number'),
            state: rank.military_card_number_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_input, {
            modelValue: rank.military_commissariat_name,
            "onUpdate:modelValue": $event => rank.military_commissariat_name = $event,
            onChange: $event => $options.submitDataEndpoint(rank, 'military_commissariat_name'),
            state: rank.military_commissariat_name_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.ranks, rank.id)
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_5, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_6, [_createVNode(_component_AddRow, {
          onClicked: _cache[2] || (_cache[2] = $event => $options.addDataEndpoint(_ctx.ranks))
        })])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}