import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "work"
};
const _hoisted_2 = {
  class: "jp-name"
};
const _hoisted_3 = {
  class: "jp-tin"
};
const _hoisted_4 = {
  class: "jp-share"
};
const _hoisted_5 = {
  class: "jp-type"
};
const _hoisted_6 = {
  class: "jp-address"
};
const _hoisted_7 = {
  id: "input-list-org-address"
};
const _hoisted_8 = ["value"];
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  colspan: "4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "17. Принимаете ли Вы участие в деятельности юридических лиц, в том числе\n        зарегистрированных за пределами Российской Федерации?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          class: "w-auto",
          modelValue: _ctx.profileObj.has_participation_entities,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_participation_entities = $event),
          disabled: $options.readonly,
          state: _ctx.has_participation_entities_state,
          options: _ctx.optionYesNo,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_participation_entities'))
        }, null, 8, ["modelValue", "disabled", "state", "options"])]),
        _: 1
      })]),
      _: 1
    }), _withDirectives(_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[6] || (_cache[6] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Наименование юридического лица"), _createElementVNode("th", null, "ИНН юридического лица"), _createElementVNode("th", null, "Доля в уставном капитале (%)"), _createElementVNode("th", null, "Вид владения"), _createElementVNode("th", null, "Адрес юридического лица")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organisations, org => {
          return _openBlock(), _createElementBlock("tr", {
            key: org.id,
            class: "jp-record"
          }, [_createElementVNode("td", _hoisted_2, [_cache[3] || (_cache[3] = _createElementVNode("small", null, "Организационно-правовая форма", -1)), _createVNode(_component_b_form_input, {
            modelValue: org.organization_form,
            "onUpdate:modelValue": $event => org.organization_form = $event,
            onChange: $event => $options.submitDataEndpoint(org, 'organization_form', 'organisations', _ctx.endpointOrganisations),
            state: org.organization_form_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _cache[4] || (_cache[4] = _createElementVNode("small", null, "Наименование", -1)), _createVNode(_component_b_form_input, {
            modelValue: org.org_name,
            "onUpdate:modelValue": $event => org.org_name = $event,
            onChange: $event => $options.submitDataEndpoint(org, 'org_name', 'organisations', _ctx.endpointOrganisations),
            state: org.org_name_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
            modelValue: org.inn,
            "onUpdate:modelValue": $event => org.inn = $event,
            onChange: $event => $options.submitDataEndpoint(org, 'inn', 'organisations', _ctx.endpointOrganisations),
            state: org.inn_state,
            disabled: $options.readonly,
            type: "number",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_input, {
            modelValue: org.share,
            "onUpdate:modelValue": $event => org.share = $event,
            type: "number",
            min: "0",
            step: "1",
            max: "100",
            onChange: $event => $options.submitDataEndpoint(org, 'share', 'organisations', _ctx.endpointOrganisations),
            state: org.share_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_5, [_createVNode(_component_b_form_select, {
            modelValue: org.participation_type,
            "onUpdate:modelValue": $event => org.participation_type = $event,
            options: _ctx.optionsOwnerLevel,
            onChange: $event => $options.submitDataEndpoint(org, 'participation_type', 'organisations', _ctx.endpointOrganisations),
            state: org.participation_type_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_b_form_group, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: org.address,
              "onUpdate:modelValue": $event => org.address = $event,
              onChange: $event => $options.submitDataEndpoint(org, 'address', 'organisations', _ctx.endpointOrganisations),
              state: org.address_state,
              disabled: $options.readonly,
              type: "text",
              trim: "",
              list: "input-list-org-address",
              onInput: $event => $options.inputData(org.address)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled", "onInput"]), _createElementVNode("datalist", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataAddress, (address, index) => {
              return _openBlock(), _createElementBlock("option", {
                key: index,
                value: address
              }, _toDisplayString(address), 9, _hoisted_8);
            }), 128))])]),
            _: 2
          }, 1024), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.organisations, org.id, 'organisations', _ctx.endpointOrganisations)
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_9, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_10, [_createVNode(_component_AddRow, {
          onClicked: _cache[2] || (_cache[2] = $event => $options.addDataEndpoint(_ctx.organisations, 'organisations', _ctx.endpointOrganisations, _ctx.templateOrganisations, _ctx.statesOrganisations))
        })])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    }, 512), [[_vShow, _ctx.profileObj.has_participation_entities]])]),
    _: 1
  });
}