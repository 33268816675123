import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { option4select } from "@/helpers/global";
import { selectAll } from "@/helpers/text";
import { mapGetters } from "vuex";
import Calendar from "@/components/Helpers/Calendar.vue";
import { dateObjInit } from "@/helpers/date";
export default {
  name: "SecurityEventsSidebar",
  components: {
    Calendar
  },
  data: () => ({
    showItem: true,
    filters: {
      ip_address: null,
      user_login: null,
      target_user_login: null,
      organization_id: null,
      event: null,
      timestamp_gte: {
        ...dateObjInit
      },
      timestamp_lte: {
        ...dateObjInit
      }
    }
  }),
  methods: {
    clearFilters() {
      this.showItem = false;
      Object.keys(this.filters).forEach(key => {
        if (["timestamp_gte", "timestamp_lte"].includes(key)) this.filters[key] = {
          ...dateObjInit
        };else this.filters[key] = null;
      });
      setTimeout(() => {
        this.showItem = true;
      }, 0);
    },
    clickApplyFilters() {
      this.$emit("emitApplyFilters", this.filters);
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["organizations", "organizationsLoading", "secEvents", "secEventsLoading"]),
    sourceOrgs() {
      return selectAll.concat(option4select(this.organizations, false));
    },
    sourceEvents() {
      return selectAll.concat(option4select(this.secEvents, false));
    }
  }
};