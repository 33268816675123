import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "introduce-wrap d-flex"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "text-danger"
};
const _hoisted_4 = {
  class: "text-danger"
};
const _hoisted_5 = {
  class: "text-danger"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_thePersona = _resolveComponent("thePersona");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  const _component_b_card = _resolveComponent("b-card");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  const _directive_b_popover = _resolveDirective("b-popover");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_card, {
    "no-body": "",
    class: "flex-shrink-1 overflow-hidden border-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_text, {
      class: "mb-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_table, {
        class: "table-custom mb-0",
        hover: "",
        items: _ctx.studentsToAss,
        fields: _ctx.tableFields,
        stacked: "md",
        busy: _ctx.studentsToAssLoading,
        small: "",
        "show-empty": "",
        responsive: ""
      }, {
        "cell()": _withCtx(row => [_createElementVNode("span", {
          class: _normalizeClass(row.value ? '' : 'text-gray-400')
        }, _toDisplayString(row.value ? row.value : "—"), 3)]),
        "cell(FIO)": _withCtx(row => [_createVNode(_component_thePersona, {
          firstname: row.item.student_first_name,
          lastname: row.item.student_last_name,
          middlename: row.item.student_middle_name,
          target: 'user-' + row.item.id
        }, null, 8, ["firstname", "lastname", "middlename", "target"])]),
        "cell(date_start_formal)": _withCtx(row => [_createTextVNode(_toDisplayString($options.formatDate(row.value, _ctx.fmtDateShort)), 1)]),
        "cell(date_end_formal)": _withCtx(row => [_createTextVNode(_toDisplayString($options.formatDate(row.value, _ctx.fmtDateShort)), 1)]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
          _: 1
        })]),
        "cell(btnChange)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
          variant: "link",
          class: "pt-0",
          onClick: $event => $options.clickChange(row.item)
        }, {
          default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: "edit",
            "fixed-width": ""
          })]),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.change_wa_course_assignment], [_directive_b_popover, 'Просмотреть/Измененить', void 0, {
          hover: true,
          top: true
        }]])]),
        "cell(btnRemove)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
          variant: "link",
          class: "pt-0",
          onClick: $event => $options.clickRemove(row.item)
        }, {
          default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: "trash-alt",
            "fixed-width": ""
          })]),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.unassign_wa_course], [_directive_b_popover, 'Удалить', void 0, {
          hover: true,
          top: true
        }]])]),
        _: 1
      }, 8, ["items", "fields", "busy"])]),
      _: 1
    }), _createVNode(_component_b_card_text, {
      class: "px-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_row, null, {
        default: _withCtx(() => [_createVNode(_component_b_col), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            variant: "primary",
            onClick: $options.clickConfirm
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Назначить обучение")])),
            _: 1
          }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.confirm_wa_course_assignment]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _withDirectives(_createVNode(_component_b_card_footer, {
      class: "bg-white"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto ms-auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_pagination, {
            class: "mb-0",
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.studentsToAssCount,
            "per-page": _ctx.perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows", "per-page"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.gotoPage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: $options.changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 512), [[_vShow, _ctx.studentsToAssCount > _ctx.perPage]])]),
    _: 1
  }), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.showModal = $event),
    id: "modalAddStudent",
    title: "Параметры обучения",
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "light",
    scrollable: "",
    "ok-disabled": $options.okModalDisabled
  }, {
    default: _withCtx(() => [_createVNode(_component_Calendar, {
      title: "Начало обучения",
      date: _ctx.dateStart,
      classCustom: "mt-2 no-gutters",
      onChangeValue: _cache[2] || (_cache[2] = $event => _ctx.dateStart = $event)
    }, null, 8, ["date"]), _withDirectives(_createElementVNode("div", _hoisted_3, " «Дата начала обучения» не может быть позднее «Даты окончания обучения» ", 512), [[_vShow, $options.invalidStartDate]]), _createVNode(_component_Calendar, {
      title: "Окончание обучения",
      date: _ctx.dateEnd,
      onChangeValue: _cache[3] || (_cache[3] = $event => $options.changeDateEnd($event)),
      class: "mt-3",
      classCustom: "mt-2 no-gutters"
    }, null, 8, ["date"]), _withDirectives(_createElementVNode("div", _hoisted_4, " «Дата окончания обучения» не может быть ранее даты внесения сведений ", 512), [[_vShow, $options.invalidEndDate]]), _createVNode(_Transition, null, {
      default: _withCtx(() => [_createElementVNode("div", null, [_ctx.showDeadline ? (_openBlock(), _createBlock(_component_Calendar, {
        key: 0,
        title: "Дедлайн",
        date: _ctx.deadline,
        class: "mt-3 mb-3",
        classCustom: "mt-2 no-gutters",
        onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.deadline = $event)
      }, null, 8, ["date"])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_5, " «Дедлайн» не может быть ранее «Даты начала обучения» и не может быть позднее «Даты окончания обучения» ", 512), [[_vShow, $options.invalidDeadline]])])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Работник"
    }, {
      default: _withCtx(() => [!_ctx.isChange ? (_openBlock(), _createBlock(_component_UserSelector, {
        key: 0,
        onSelected: $options.selectedWorker,
        fmtName: _ctx.fmtNameFull,
        showInfo: true
      }, null, 8, ["onSelected", "fmtName"])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(_toDisplayString($options.formatName({
        first_name: _ctx.worker.student_first_name,
        middle_name: _ctx.worker.student_middle_name,
        last_name: _ctx.worker.student_last_name
      }, _ctx.fmtNameFull)), 1)], 64))]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onOk", "ok-disabled"]), _createVNode(_component_b_modal, {
    title: _ctx.titleRemove,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"])]);
}