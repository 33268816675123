import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "text-white"
};
const _hoisted_2 = {
  class: "text-warning"
};
const _hoisted_3 = {
  class: "text-white"
};
const _hoisted_4 = {
  class: "text-warning"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_b_nav_item = _resolveComponent("b-nav-item");
  const _component_b_nav = _resolveComponent("b-nav");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome), _createVNode(_component_b_row, {
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h1", null, "Личный кабинет", -1)])),
      _: 1
    }), _ctx.$route.path === _ctx.links.profileDeclarations ? (_openBlock(), _createBlock(_component_b_col, {
      key: 0,
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "primary",
        onClick: $options.clickNewDecl,
        "data-hook": "buttonCreateDecl"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Создать новую декларацию ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_my_coid]])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm tabs-custom",
    title: "Card Title"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      "header-tag": "nav"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_nav, {
        tabs: ""
      }, {
        default: _withCtx(() => [_ctx.$ff.PROFILE ? (_openBlock(), _createBlock(_component_b_nav_item, {
          key: 0,
          to: _ctx.links.profileSheets,
          active: _ctx.$route.path === _ctx.links.profileSheets
        }, {
          default: _withCtx(() => [_cache[2] || (_cache[2] = _createTextVNode(" Личные листки ")), _createVNode(_component_b_badge, {
            variant: "secondary",
            class: "text-white"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.profilesCount), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["to", "active"])) : _createCommentVNode("", true), _ctx.$ff.DECLARATION ? _withDirectives((_openBlock(), _createBlock(_component_b_nav_item, {
          key: 1,
          to: _ctx.links.profileDeclarations,
          active: _ctx.$route.path === _ctx.links.profileDeclarations
        }, {
          default: _withCtx(() => [_cache[3] || (_cache[3] = _createTextVNode(" Декларации о конфликте интересов ")), _createVNode(_component_b_badge, {
            variant: "secondary",
            class: "text-white"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.declarationsCount), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["to", "active"])), [[_directive_can, _ctx.$perm.view_my_coid]]) : _createCommentVNode("", true), _ctx.$ff.DECLARATION ? _withDirectives((_openBlock(), _createBlock(_component_b_nav_item, {
          key: 2,
          to: _ctx.links.profilePresent,
          active: _ctx.$route.path === _ctx.links.profilePresent
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" Декларации подарков ")])),
          _: 1
        }, 8, ["to", "active"])), [[_directive_can, _ctx.$perm.view_my_coid]]) : _createCommentVNode("", true), _ctx.$ff.EDUCATION ? (_openBlock(), _createBlock(_component_b_nav_item, {
          key: 3,
          to: _ctx.links.profileEducation,
          active: _ctx.$route.path === _ctx.links.profileEducation
        }, {
          default: _withCtx(() => [_cache[6] || (_cache[6] = _createTextVNode(" Обучение ")), _createVNode(_component_b_badge, {
            variant: "secondary"
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.coursUserCount), 1), _ctx.stats?.education?.new ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [_cache[5] || (_cache[5] = _createElementVNode("span", {
              class: "text-white"
            }, "/", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.stats?.education?.new), 1)], 64)) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["to", "active"])) : _createCommentVNode("", true), _ctx.$ff.KNOWLEDGE ? (_openBlock(), _createBlock(_component_b_nav_item, {
          key: 4,
          to: _ctx.links.profileAcquaintance,
          active: _ctx.$route.path === _ctx.links.profileAcquaintance
        }, {
          default: _withCtx(() => [_cache[8] || (_cache[8] = _createTextVNode(" Ознакомление ")), _createVNode(_component_b_badge, {
            variant: "secondary"
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.lnaUserListCount), 1), _ctx.stats?.knowledge?.new ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [_cache[7] || (_cache[7] = _createElementVNode("span", {
              class: "text-white"
            }, "/", -1)), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.stats?.knowledge?.new), 1)], 64)) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["to", "active"])) : _createCommentVNode("", true), _ctx.$ff.NOTIFICATION ? (_openBlock(), _createBlock(_component_b_nav_item, {
          key: 5,
          to: _ctx.links.profileNotifications,
          active: _ctx.$route.path === _ctx.links.profileNotifications
        }, {
          default: _withCtx(() => [_cache[9] || (_cache[9] = _createTextVNode(" Уведомления ")), _createVNode(_component_b_badge, {
            variant: "secondary",
            class: "text-white"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.notifyLkCount), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["to", "active"])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "px-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    })]),
    _: 1
  })])), [[_directive_can, _ctx.$perm.access_account]]);
}