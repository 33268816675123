import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "card-title mb-0"
};
const _hoisted_2 = {
  class: "position-relative me-3"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  class: "mb-4 mt-4"
};
const _hoisted_5 = {
  class: "card-title mb-0"
};
const _hoisted_6 = {
  class: "card-title mb-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_link = _resolveComponent("b-link");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome), _cache[0] || (_cache[0] = _createElementVNode("h1", {
    class: "mb-4"
  }, "Администрирование", -1)), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminCards, (item, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_b_col, {
        cols: "4",
        key: index,
        class: "d-flex align-self-stretch"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card, {
          "no-body": "",
          class: "mb-4 border-0 shadow-sm w-100"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_card_body, {
            class: "position-relative p-4"
          }, {
            default: _withCtx(() => [_createElementVNode("h5", _hoisted_1, [_createVNode(_component_b_link, {
              "data-hook": item.hook,
              class: "card-link stretched-link",
              onClick: $event => $options.onMouseClick(item)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
              _: 2
            }, 1032, ["data-hook", "onClick"])])]),
            _: 2
          }, 1024), item.name && _ctx.stats[item.name] && _ctx.stats[item.name].new ? (_openBlock(), _createBlock(_component_b_card_footer, {
            key: 0,
            class: "px-4 pb-4 pt-0 bg-transparent border-0"
          }, {
            default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_2, [_createElementVNode("span", {
              onClick: $event => $options.clickToNew(item),
              class: "card-link stretched-link new-wrap"
            }, " Новых ", 8, _hoisted_3), _createVNode(_component_b_badge, {
              class: "ms-1",
              variant: "danger"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.stats[item.name].new), 1)]),
              _: 2
            }, 1024)])])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)), [[_directive_can, item.perm]]);
    }), 128))]),
    _: 1
  }), _ctx.$ff.SETTINGS ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "Настройки")) : _createCommentVNode("", true), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.settingsCards, (item, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_b_col, {
        cols: "4",
        key: index,
        class: "d-flex align-self-stretch"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card, {
          "no-body": "",
          class: "mb-4 border-0 shadow-sm w-100"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_card_body, {
            class: "position-relative p-4"
          }, {
            default: _withCtx(() => [_createElementVNode("h5", _hoisted_5, [_createVNode(_component_b_link, {
              class: "card-link stretched-link",
              onClick: $event => $options.onMouseClick(item)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
              _: 2
            }, 1032, ["onClick"])])]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)), [[_directive_can, item.perm]]);
    }), 128))]),
    _: 1
  }), _cache[1] || (_cache[1] = _createElementVNode("h2", {
    class: "mb-4 mt-4"
  }, "Справочники", -1)), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.directoryCards, (item, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_b_col, {
        cols: "4",
        key: index,
        class: "d-flex align-self-stretch"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card, {
          "no-body": "",
          class: "mb-4 border-0 shadow-sm w-100"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_card_body, {
            class: "position-relative p-4"
          }, {
            default: _withCtx(() => [_createElementVNode("h5", _hoisted_6, [_createVNode(_component_b_link, {
              class: "card-link stretched-link",
              onClick: $event => $options.onMouseClick(item)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
              _: 2
            }, 1032, ["onClick"])])]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024)), [[_directive_can, item.perm]]);
    }), 128))]),
    _: 1
  })]);
}