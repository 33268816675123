import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createBlock(_component_b_button, {
    variant: "link",
    onClick: _cache[0] || (_cache[0] = $event => $options.onClick()),
    class: "p-0"
  }, {
    default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
      class: "bi bi-plus"
    }, null, -1), _createElementVNode("span", {
      class: "link inner"
    }, "Добавить строку", -1)])),
    _: 1
  });
}