import "core-js/modules/es.array.push.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import InnerCheckReport from "./InnerCheckReport.vue";
import InnerCheckList from "./InnerCheckList.vue";
import links from "@/router/links";
export default {
  name: "InnerCheckArea",
  components: {
    BreadCrumbToHome,
    theNotification,
    InnerCheckReport,
    InnerCheckList
  },
  async created() {
    const params = {
      filters: {}
    };
    if (this.onlyNew) params.filters.is_new = true;
    await this.updateInnerCheck(params);
    this.setOnlyNew(null); // обнулимся
  },
  data: () => ({}),
  methods: {
    ...mapActions("innerCheck", ["updateInnerCheck", "createInnerCheck"]),
    ...mapActions("homeStats", ["setOnlyNew"]),
    async clickCreate() {
      await this.createInnerCheck();
      this.$router.push(links.innerCheckForm + this.innerCheckForm.id);
    }
  },
  computed: {
    ...mapGetters("innerCheck", ["innerCheckForm", "innerCheckCount"]),
    ...mapGetters("homeStats", ["onlyNew"])
  }
};