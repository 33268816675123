import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "text-end"
};
import Calendar from "@/components/Helpers/Calendar.vue";
import { toRefs, ref, computed } from "vue";
import { useStore } from "vuex";
import { checkPermissions } from "@/auth/auth.js";
import { formatDateObj, fmtDate } from "@/helpers/date";

//props

export default {
  __name: 'AdminNotificationItem',
  props: {
    item: Object
  },
  emits: ["updateData"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const {
      item
    } = toRefs(props);
    const emit = __emit;

    // store
    const store = useStore();
    const adminNotificationItem = computed(() => store.getters["administration2/adminNotificationItem"]);
    const adminNotificationItemLoading = computed(() => store.getters["administration2/adminNotificationItemLoading"]);
    const item4form = ref({});
    const showModal = ref(false);
    function clickShowModal() {
      item4form.value = {
        ...item.value
      };
      showModal.value = true;
    }
    const keys2change = ["regularity_days", "days_before", "deadline", "title", "text"];
    async function handlerOk() {
      item4form.value.deadline = formatDateObj(item4form.value.deadlineObj, fmtDate, null);
      const body = keys2change.reduce((acc, curr) => {
        if (item.value[curr] !== item4form.value[curr]) acc[curr] = item4form.value[curr];
        return acc;
      }, {});
      if (Object.keys(body).length > 0) {
        store.state.administration2.adminNotificationItem = {}; // обнулим данные
        await store.dispatch("administration2/changeAdminNotificationItem", {
          id: item.value.id,
          body
        });
        if (adminNotificationItem.value.id === undefined) return; // если ошибка, то форму не закрываем и не обновляем таблицу
        emit("updateData");
      }
      showModal.value = false;
    }
    const readOnly = computed(() => !checkPermissions("change_admin_notif_settings") || adminNotificationItemLoading.value);
    const regularityDaysOptions = [{
      value: 30,
      text: "Месяц"
    }, {
      value: 90,
      text: "Квартал"
    }, {
      value: 365,
      text: "Год"
    }, {
      value: 1000000,
      text: "Никогда"
    }];
    return (_ctx, _cache) => {
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_form_select = _resolveComponent("b-form-select");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_form_input = _resolveComponent("b-form-input");
      const _component_b_form_group = _resolveComponent("b-form-group");
      const _component_b_form_textarea = _resolveComponent("b-form-textarea");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_modal = _resolveComponent("b-modal");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("button", {
        type: "button",
        class: "btn btn-light py-0 px-1",
        onClick: clickShowModal
      }, _cache[6] || (_cache[6] = [_createElementVNode("span", null, "...", -1)])), _createVNode(_component_b_modal, {
        modelValue: showModal.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => showModal.value = $event),
        id: `AdminNotificationItem${_unref(item).id}`,
        title: `${_unref(item).event_meaning} (${_unref(item).role_title})`,
        centered: "",
        hideFooter: "",
        scrollable: "",
        size: "xl"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_row, null, {
          default: _withCtx(() => [_createVNode(_component_b_col, null, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Регулярность подачи")])),
            _: 1
          }), _createVNode(_component_b_col, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: item4form.value.regularity_days,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => item4form.value.regularity_days = $event),
              options: regularityDaysOptions,
              disabled: readOnly.value
            }, null, 8, ["modelValue", "disabled"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_b_row, {
          class: "mt-3"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, null, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Контрольный срок подачи")])),
            _: 1
          }), _createVNode(_component_b_col, null, {
            default: _withCtx(() => [_createVNode(Calendar, {
              title: null,
              date: item4form.value.deadlineObj,
              disabled: readOnly.value,
              onChangeValue: _cache[1] || (_cache[1] = $event => item4form.value.deadlineObj = $event)
            }, null, 8, ["date", "disabled"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_b_row, {
          class: "mt-3"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_group, {
              label: "Заголовок для письма"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_form_input, {
                modelValue: item4form.value.title,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => item4form.value.title = $event),
                type: "text",
                trim: "",
                disabled: readOnly.value
              }, null, 8, ["modelValue", "disabled"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_b_row, {
          class: "mt-3"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_group, {
              label: "Текст письма"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
                modelValue: item4form.value.text,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => item4form.value.text = $event),
                rows: "3",
                trim: "",
                disabled: readOnly.value
              }, null, 8, ["modelValue", "disabled"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)), _createElementVNode("div", _hoisted_1, [!readOnly.value ? (_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          variant: "primary me-5",
          onClick: handlerOk
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Сохранить")])),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_b_button, {
          variant: "outline-secondary",
          onClick: _cache[4] || (_cache[4] = $event => showModal.value = false)
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode(" Закрыть ")])),
          _: 1
        })])]),
        _: 1
      }, 8, ["modelValue", "id", "title"])]);
    };
  }
};