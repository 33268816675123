import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "ms-2"
};
const _hoisted_2 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_PartnersFormAbout = _resolveComponent("PartnersFormAbout");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_PartnersFormInfo = _resolveComponent("PartnersFormInfo");
  const _component_PartnersChecking = _resolveComponent("PartnersChecking");
  const _component_PartnersResult = _resolveComponent("PartnersResult");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: $options.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, "Деловой партнер #" + _toDisplayString($props.msg), 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
        class: "text-muted"
      }, "Статус", -1)), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.statesPartners[_ctx.partnerForm.state]), 1)]),
      _: 1
    }), !$options.isAdminPath ? (_openBlock(), _createBlock(_component_b_col, {
      key: 0,
      cols: "auto"
    }, {
      default: _withCtx(() => [_ctx.statesPartners[_ctx.partnerForm.state] === _ctx.statesPartners.draft ? (_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: "primary",
        onClick: _cache[0] || (_cache[0] = $event => $options.clickBtnProc('submitted', 'Проверка делового партнера начата')),
        disabled: $options.disabledStart
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Начать проверку ")])),
        _: 1
      }, 8, ["disabled"])) : _createCommentVNode("", true), _ctx.statesPartners[_ctx.partnerForm.state] === _ctx.statesPartners.halfway && _ctx.partnerForm.object_permissions.includes('forward_wa_bp') ? (_openBlock(), _createBlock(_component_b_button, {
        key: 1,
        class: "ms-3",
        variant: "primary",
        onClick: _cache[1] || (_cache[1] = $event => $options.clickBtnProc('forward', 'Проверка делового партнера продолжена'))
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Продолжить проверку ")])),
        _: 1
      })) : _createCommentVNode("", true), _ctx.statesPartners[_ctx.partnerForm.state] === _ctx.statesPartners.pending && _ctx.partnerForm.object_permissions.includes('resolve_wa_bp') ? (_openBlock(), _createBlock(_component_b_button, {
        key: 2,
        class: "ms-3",
        variant: "primary",
        onClick: _cache[2] || (_cache[2] = $event => $options.clickBtnProc('approved', 'Проверка делового партнера завершена'))
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Согласовать ")])),
        _: 1
      })) : _createCommentVNode("", true), _ctx.statesPartners[_ctx.partnerForm.state] === _ctx.statesPartners.pending && _ctx.partnerForm.object_permissions.includes('resolve_wa_bp') ? (_openBlock(), _createBlock(_component_b_button, {
        key: 3,
        class: "ms-3",
        variant: "outline-danger",
        onClick: _cache[3] || (_cache[3] = $event => $options.clickBtnProc('declined', 'Проверка делового партнера завершена'))
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Отклонить ")])),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), !_ctx.partnerFormLoading ? (_openBlock(), _createBlock(_component_b_card, {
    key: 0,
    "no-body": "",
    class: "border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        title: "Об организации"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_PartnersFormAbout)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Анкета"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_PartnersFormInfo)]),
          _: 1
        })]),
        _: 1
      }), !$options.isAdminPath ? (_openBlock(), _createBlock(_component_b_tab, {
        key: 0,
        title: "Проверка делового партнера"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_PartnersChecking)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true), !$options.isAdminPath ? (_openBlock(), _createBlock(_component_b_tab, {
        key: 1,
        title: "Результаты проверки"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_PartnersResult)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Загрузка...", -1))]))])), [[_directive_can, _ctx.$perm.change_wa_bp]]);
}