import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "mb-4"
};
const _hoisted_2 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  id: "profile"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_ProfileActions = _resolveComponent("ProfileActions");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_col = _resolveComponent("b-col");
  const _component_ProfilePhoto = _resolveComponent("ProfilePhoto");
  const _component_b_row = _resolveComponent("b-row");
  const _component_theFirst = _resolveComponent("theFirst");
  const _component_theSecond = _resolveComponent("theSecond");
  const _component_theThird = _resolveComponent("theThird");
  const _component_theFourth = _resolveComponent("theFourth");
  const _component_theFifth = _resolveComponent("theFifth");
  const _component_theSixth = _resolveComponent("theSixth");
  const _component_theSeventh = _resolveComponent("theSeventh");
  const _component_theEighth = _resolveComponent("theEighth");
  const _component_Ninth = _resolveComponent("Ninth");
  const _component_Tenth = _resolveComponent("Tenth");
  const _component_Eleventh = _resolveComponent("Eleventh");
  const _component_Twelfth = _resolveComponent("Twelfth");
  const _component_theThirteenth = _resolveComponent("theThirteenth");
  const _component_theFourteenth = _resolveComponent("theFourteenth");
  const _component_theFifteenth = _resolveComponent("theFifteenth");
  const _component_theSixteenth = _resolveComponent("theSixteenth");
  const _component_theSeventeenth = _resolveComponent("theSeventeenth");
  const _component_theEighteenth = _resolveComponent("theEighteenth");
  const _component_theNineteenth = _resolveComponent("theNineteenth");
  const _component_theTwentieth = _resolveComponent("theTwentieth");
  const _component_TwentyFirst = _resolveComponent("TwentyFirst");
  const _component_TwentySecond = _resolveComponent("TwentySecond");
  const _component_TwentyThird = _resolveComponent("TwentyThird");
  const _component_TwentyFourth = _resolveComponent("TwentyFourth");
  const _component_TwentyFifth = _resolveComponent("TwentyFifth");
  const _component_TwentySixth = _resolveComponent("TwentySixth");
  const _component_TwentySeventh = _resolveComponent("TwentySeventh");
  const _component_TwentyEighth = _resolveComponent("TwentyEighth");
  const _component_TwentyNinth = _resolveComponent("TwentyNinth");
  const _component_theThirtieth = _resolveComponent("theThirtieth");
  const _component_ThirtyFirst = _resolveComponent("ThirtyFirst");
  const _component_ThirtySecond = _resolveComponent("ThirtySecond");
  const _component_ThirtyThird = _resolveComponent("ThirtyThird");
  const _component_ThirtyFourth = _resolveComponent("ThirtyFourth");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createElementVNode("h3", _hoisted_1, " Личный листок " + _toDisplayString(_ctx.formatDate(_ctx.profileObj.state_modified_at, _ctx.fmtDateTime)), 1), _ctx.profileLoading || _ctx.profileSheetSettingsLoading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createElementVNode("form", _hoisted_3, [_createVNode(_component_b_card, {
    "no-body": "",
    class: "mt-2 overflow-hidden border-0"
  }, {
    default: _withCtx(() => [_withDirectives(_createVNode(_component_b_card_body, {
      class: "px-4 py-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_ProfileActions, {
        profileId: $props.id
      }, null, 8, ["profileId"])]),
      _: 1
    }, 512), [[_vShow, !_ctx.profileLoading]]), !_ctx.profileLoading ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_row, null, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "",
          lg: "9"
        }), _createVNode(_component_b_col, {
          cols: "",
          lg: "3"
        }, {
          default: _withCtx(() => [_createVNode(_component_ProfilePhoto)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theFirst)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theSecond)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theThird)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theFourth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theFifth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theSixth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theSeventh)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theEighth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Ninth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Tenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Eleventh)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Twelfth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theThirteenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theFourteenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theFifteenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theSixteenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theSeventeenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theEighteenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theNineteenth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theTwentieth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentyFirst)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentySecond)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentyThird)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentyFourth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentyFifth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentySixth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentySeventh)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentyEighth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_TwentyNinth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_theThirtieth)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_ThirtyFirst)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_ThirtySecond)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_ThirtyThird)]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "border-top p-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_ThirtyFourth)]),
      _: 1
    })], 64)) : _createCommentVNode("", true)]),
    _: 1
  })])]);
}