import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "decl-full"
};
const _hoisted_2 = {
  class: "table-header"
};
const _hoisted_3 = {
  class: "text-center text-primary my-2"
};
const _hoisted_4 = {
  key: 0,
  class: "text-primary"
};
const _hoisted_5 = {
  key: 2,
  class: "pt-2 text-success"
};
const _hoisted_6 = {
  class: "table-footer"
};
const _hoisted_7 = {
  class: "mb-2"
};
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { statesDeclarations } from "@/helpers/states";
import { FIO, limitBound, optionsDesc } from "@/helpers/text";
import links from "@/router/links";
import { useRouter } from "vue-router";
import { checkPermissions } from "@/auth/auth.js";
import CustomPagination from "../common/CustomPagination.vue";
export default {
  __name: 'DeclarationListFull',
  setup(__props) {
    const permissions = ref({});
    const store = useStore();
    const router = useRouter();
    const filters = ref({
      state: null,
      decl_id: null,
      state_modified_lte: null,
      //До указанной даты изменения состояния включительно
      state_modified_gte: null,
      //Начиная с указанной даты изменения состояния
      department_id: null,
      author_search: null,
      //Поиск по ФИО автора
      assignee_search: null //Поиск по ФИО ответственного
    }); //кодировка полей snake_case (для соответствия полей бэку)

    const currentPage = ref(1);
    const perPage = ref(10);
    const sortBy = ref(null);
    const sortDesc = ref(true);
    const optionsSort = ref([{
      value: "id",
      text: "по декларации"
    }, {
      value: "author",
      text: "по сотруднику"
    }, {
      value: "state_modified_at",
      text: "по дате изменения"
    }, {
      value: "user_detail.department",
      text: "по подразделению"
    }, {
      value: "worker_in_charge",
      text: "по ответственному"
    }]);
    function autoCheckLabel() {
      return checkPermissions(permissions.value.trigger_wa_autocheck) || checkPermissions(permissions.value.view_wa_autocheck) ? "Автосверка" : "";
    }
    const declarationsFields = computed(() => [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "authorT",
      label: "Автор",
      class: "text-nowrap"
    }, {
      key: "user_detail.department",
      label: "Подразделение"
    }, {
      key: "worker_in_chargeT",
      label: "Исполнитель",
      class: "text-nowrap"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "state_modified_atT",
      label: "Дата изменения"
    }, {
      key: "autochecks",
      label: autoCheckLabel(),
      class: "text-center p-0"
    }]);
    const showSidebar = ref(false);
    const currentItem = ref({});
    const showModalAutocheck = ref(false);
    const declarationsObj = computed(() => {
      return Array.isArray(store.getters["declarations/declarationsObj"]) ? store.getters["declarations/declarationsObj"].map(el => ({
        ...el,
        authorT: FIO(el.author),
        stateT: statesDeclarations[el.state],
        state_modified_atT: formatDate(el.state_modified_at, fmtDateShort),
        worker_in_chargeT: FIO(el.worker_in_charge)
      })) : [];
    });
    const declarationsCount = computed(() => store.getters["declarations/declarationsCount"]);
    const declarationsLoading = computed(() => store.getters["declarations/declarationsLoading"]);
    const autochecksEntityLoading = computed(() => store.getters["administration2/autochecksEntityLoading"]);
    const items4table = computed(() => declarationsObj.value);
    onMounted(() => {
      const instance = getCurrentInstance();
      permissions.value = instance?.appContext.config.globalProperties.$perm;
      requestDeclarations();
    });
    const requestDeclarations = () => {
      const page = currentPage.value;
      store.dispatch("declarations/fetchDeclarations", {
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        page: page,
        pageSize: 20
      });
    };
    const sortChanged = () => {
      currentPage.value = 1;
      requestDeclarations();
    };
    const searchChanged = string => {
      store.dispatch("declarations/fetchDeclarations", {
        sortBy: {},
        sortDesc: {},
        filters: {},
        page: 1,
        pageSize: 20,
        searchString: string
      });
    };
    const changePage = newPage => {
      currentPage.value = limitBound(Number(newPage), 1, declarationsCount.value / perPage.value, currentPage.value);
      requestDeclarations();
    };
    const applyFilters = data => {
      filters.value.state = Object.keys(statesDeclarations).filter(key => statesDeclarations[key] === data.state).join();
      filters.value.department_id = data.department;
      filters.value.state_modified_gte = formatDateObj(data.dateFrom, fmtDate);
      filters.value.state_modified_lte = formatDateObj(data.dateUntill, fmtDate);
      filters.value.author_search = data.author;
      filters.value.assignee_search = data.assignee;
      filters.value.decl_id = data.number;
      filters.value.is_new = data.new;
      if (currentPage.value === 1) requestDeclarations();else currentPage.value = 1;
    };
    const clickAutocheck = item => {
      currentItem.value = item;
      showModalAutocheck.value = true;
    };
    const doAutocheck = async () => {
      await store.dispatch("administration2/postAutochecksEntity", {
        endpoint: "declarations",
        id: currentItem.value.id
      });
      const id = store.getters["administration2/autochecksEntity"].id;
      if (id !== undefined) {
        router.push({
          name: "AutocheckForm",
          params: {
            msg: id
          },
          query: {
            type: "coid_only"
          }
        });
      } else {
        requestDeclarations();
      }
    };
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_FilterButton = _resolveComponent("FilterButton");
      const _component_b_row = _resolveComponent("b-row");
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_table = _resolveComponent("b-table");
      const _component_DeclarationListFullSidebar = _resolveComponent("DeclarationListFullSidebar");
      const _component_b_container = _resolveComponent("b-container");
      const _component_b_offcanvas = _resolveComponent("b-offcanvas");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSearch, {
            onOnSearch: searchChanged
          })]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "4"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSort, {
            optionsSort: optionsSort.value,
            optionsDesc: _unref(optionsDesc),
            onOnSort: sortChanged
          }, null, 8, ["optionsSort", "optionsDesc"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "4",
          xl: "2",
          class: "me-auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_FilterButton, {
            onClick: _cache[0] || (_cache[0] = $event => showSidebar.value = true)
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        "show-empty": "",
        small: "",
        responsive: "",
        stacked: "md",
        items: items4table.value,
        fields: declarationsFields.value,
        busy: declarationsLoading.value
      }, {
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage.value + row.index + 1), 1)]),
        "cell()": _withCtx(row => [_createVNode(_component_router_link, {
          class: _normalizeClass(["link", row.item.is_new ? 'font-weight-bold' : '']),
          to: _unref(links).declarationForm + row.item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
          _: 2
        }, 1032, ["class", "to"])]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Деклараций нет", -1)])),
          _: 1
        })]),
        "cell(autochecks)": _withCtx(row => [autochecksEntityLoading.value ? (_openBlock(), _createElementBlock("span", _hoisted_4, [_createVNode(_component_b_spinner)])) : !row.item.has_autochecks ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 1,
          variant: "link",
          class: "p-0",
          onClick: $event => clickAutocheck(row.item)
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("i", {
            class: "bi bi-repeat"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.trigger_wa_autocheck]]) : row.item.has_autochecks ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, _cache[7] || (_cache[7] = [_createElementVNode("i", {
          class: "bi bi-check-all"
        }, null, -1)]))), [[_directive_can, _ctx.$perm.view_wa_autocheck]]) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(CustomPagination, {
            totalRows: declarationsCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, declarationsCount.value > perPage.value]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_offcanvas, {
        modelValue: showSidebar.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showSidebar.value = $event),
        placement: "end",
        "no-header": "",
        backdrop: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-2"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_cache[8] || (_cache[8] = _createElementVNode("h5", {
            class: "d-inline"
          }, "Фильтровать декларации", -1)), _createElementVNode("button", {
            type: "button",
            class: "btn-close float-end",
            "aria-label": "Close",
            onClick: _cache[1] || (_cache[1] = $event => showSidebar.value = false)
          })]), _createVNode(_component_DeclarationListFullSidebar, {
            onEmitApplyFilters: applyFilters
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_b_modal, {
        title: `Выполнить автосверку декларации ${currentItem.value.id} ?`,
        modelValue: showModalAutocheck.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => showModalAutocheck.value = $event),
        "ok-variant": "outline-primary",
        "ok-title": "Выполнить",
        "cancel-variant": "outline-secondary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doAutocheck
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};