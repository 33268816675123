import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 3,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_CourseAssign = _resolveComponent("CourseAssign");
  const _component_ProgramAddDescription = _resolveComponent("ProgramAddDescription");
  const _component_ProgramAddMaterial = _resolveComponent("ProgramAddMaterial");
  const _component_ProgramAddTesting = _resolveComponent("ProgramAddTesting");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_ProgramEvents = _resolveComponent("ProgramEvents");
  const _component_StudentsToAssign = _resolveComponent("StudentsToAssign");
  const _component_StudentsList = _resolveComponent("StudentsList");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_b_row, {
    class: "px-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_BreadCrumbToHome, {
        breadCrumb: _ctx.breadcrumbs
      }, null, 8, ["breadCrumb"])]),
      _: 1
    })]),
    _: 1
  }), !_ctx.courseLoading ? (_openBlock(), _createBlock(_component_b_row, {
    key: 0,
    class: "sticky-top align-items-center px-4 mb-2 bg-light title-shadow"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, "Обучающая программа #" + _toDisplayString($props.msg), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [$options.showBtn2Submitted ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: "primary",
        onClick: _cache[0] || (_cache[0] = $event => $options.clickChangeState('submitted')),
        class: "m-1"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "magnifying-glass-arrow-right",
          "fixed-width": ""
        }), _cache[5] || (_cache[5] = _createTextVNode(" На рассмотрение"))]),
        _: 1
      })), [[_directive_can, _ctx.$perm.add_wa_course]]) : _createCommentVNode("", true), $options.stateSubmitted ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 1,
        variant: "primary",
        onClick: _cache[1] || (_cache[1] = $event => $options.clickChangeState('draft')),
        class: "m-1"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "arrow-rotate-left",
          "fixed-width": ""
        }), _cache[6] || (_cache[6] = _createTextVNode(" На доработку"))]),
        _: 1
      })), [[_directive_can, _ctx.$perm.publish_wa_course]]) : _createCommentVNode("", true), $options.stateSubmitted ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 2,
        variant: "primary",
        onClick: _cache[2] || (_cache[2] = $event => $options.clickChangeState('published')),
        class: "m-1"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "file-arrow-up",
          "fixed-width": ""
        }), _cache[7] || (_cache[7] = _createTextVNode(" Опубликовать"))]),
        _: 1
      })), [[_directive_can, _ctx.$perm.publish_wa_course]]) : _createCommentVNode("", true), $options.statePublished ? _withDirectives((_openBlock(), _createBlock(_component_CourseAssign, {
        key: 3,
        item: _ctx.getCourse
      }, null, 8, ["item"])), [[_directive_can, _ctx.$perm.assign_wa_course]]) : _createCommentVNode("", true), $options.statePublished ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 4,
        variant: "gray-300",
        onClick: _cache[3] || (_cache[3] = $event => $options.clickChangeState('closed')),
        class: "m-1"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "file-arrow-down",
          "fixed-width": ""
        }), _cache[8] || (_cache[8] = _createTextVNode(" Снять с публикации"))]),
        _: 1
      })), [[_directive_can, _ctx.$perm.archive_wa_course]]) : _createCommentVNode("", true), !$options.stateArchived ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 5,
        variant: "gray-300",
        onClick: _cache[4] || (_cache[4] = $event => $options.clickChangeState('archived')),
        class: "m-1"
      }, {
        default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
          icon: "box-archive",
          "fixed-width": ""
        }), _cache[9] || (_cache[9] = _createTextVNode(" Архивировать"))]),
        _: 1
      })), [[_directive_can, _ctx.$perm.archive_wa_course]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true), !_ctx.courseLoading ? (_openBlock(), _createBlock(_component_b_row, {
    key: 1,
    class: "px-4 mb-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      class: "text-muted h4"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.statesEducProg[_ctx.getCourse.state]), 1)]),
      _: 1
    })]),
    _: 1
  })) : _createCommentVNode("", true), !_ctx.courseLoading ? (_openBlock(), _createBlock(_component_b_tabs, {
    key: 2,
    pills: "",
    class: "tabs-custom",
    "nav-wrapper-class": "px-4 pb-3 h6",
    "content-class": "px-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tab, {
      title: "Программа обучения"
    }, {
      default: _withCtx(() => [_createVNode(_component_ProgramAddDescription, {
        item: _ctx.getCourse
      }, null, 8, ["item"]), _createVNode(_component_ProgramAddMaterial, {
        item: _ctx.getCourse
      }, null, 8, ["item"]), _createVNode(_component_ProgramAddTesting)]),
      _: 1
    }), _createVNode(_component_b_tab, null, {
      title: _withCtx(() => [_cache[10] || (_cache[10] = _createTextVNode("Журнал изменений")), _createVNode(_component_b_badge, {
        variant: "secondary",
        class: "text-white ms-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.courseEventsCount), 1)]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_ProgramEvents, {
        item: _ctx.getCourse
      }, null, 8, ["item"])]),
      _: 1
    }), $options.statePublished ? (_openBlock(), _createBlock(_component_b_tab, {
      key: 0
    }, {
      title: _withCtx(() => [_cache[11] || (_cache[11] = _createTextVNode("Список к назначению")), _createVNode(_component_b_badge, {
        variant: "secondary",
        class: "text-white ms-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.studentsToAssCount), 1)]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_StudentsToAssign, {
        item: _ctx.getCourse
      }, null, 8, ["item"])]),
      _: 1
    })) : _createCommentVNode("", true), _createVNode(_component_b_tab, null, {
      title: _withCtx(() => [_cache[12] || (_cache[12] = _createTextVNode("Реестр обучения")), _createVNode(_component_b_badge, {
        variant: "secondary",
        class: "text-white ms-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.studentsListCount), 1)]),
        _: 1
      })]),
      default: _withCtx(() => [_createVNode(_component_StudentsList, {
        item: _ctx.getCourse
      }, null, 8, ["item"])]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Загрузка...", -1))]))]);
}