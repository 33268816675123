import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_nav_item = _resolveComponent("b-nav-item");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_b_nav = _resolveComponent("b-nav");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome), _createVNode(_component_b_row, {
    class: "mb-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h1", null, "Оценка рисков", -1)])),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_ctx.$route.path === _ctx.links.riskChart ? (_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: "primary",
        to: _ctx.links.riskChartPrint + `?year=${_ctx.riskYear}`,
        target: "_blank"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Печать графика ")])),
        _: 1
      }, 8, ["to"])) : _createCommentVNode("", true), _ctx.$route.path === _ctx.links.matrixList ? (_openBlock(), _createBlock(_component_b_button, {
        key: 1,
        variant: "primary",
        onClick: $options.clickCreateMatrix
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Создать")])),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.$route.path === _ctx.links.riskList ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 2,
        variant: "primary",
        onClick: $options.clickCreateRisk
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Создать ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_risk]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm tabs-custom"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      "header-tag": "nav"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_nav, {
        tabs: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_nav_item, {
          to: _ctx.links.riskChart,
          active: _ctx.$route.path === _ctx.links.riskChart
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" График ")])),
          _: 1
        }, 8, ["to", "active"]), _withDirectives((_openBlock(), _createBlock(_component_b_nav_item, {
          to: _ctx.links.matrixList,
          active: _ctx.$route.path === _ctx.links.matrixList
        }, {
          default: _withCtx(() => [_cache[5] || (_cache[5] = _createTextVNode(" Матрицы ")), _createVNode(_component_b_badge, {
            variant: "secondary",
            class: "text-white"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixCount), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["to", "active"])), [[_directive_can, _ctx.$perm.view_wa_matrix]]), _withDirectives((_openBlock(), _createBlock(_component_b_nav_item, {
          to: _ctx.links.riskList,
          active: _ctx.$route.path === _ctx.links.riskList
        }, {
          default: _withCtx(() => [_cache[6] || (_cache[6] = _createTextVNode(" Риски ")), _createVNode(_component_b_badge, {
            variant: "secondary",
            class: "text-white"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.risksCount), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["to", "active"])), [[_directive_can, _ctx.$perm.view_wa_risk]])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_card_body, {
      class: "px-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    })]),
    _: 1
  })]);
}