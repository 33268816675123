import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "tests-wrap"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "table-footer"
};
const _hoisted_4 = {
  class: "mb-2"
};
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import KnowledgeSidebar from "./KnowledgeSidebar.vue";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { limitBound, CONST_NONAME, formatName, fmtNameShort } from "@/helpers/text";
import { statesKnowledge } from "@/helpers/states";
import links from "@/router/links";
export default {
  __name: 'KnowledgeArchive',
  props: {
    section: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const store = useStore();
    const route = useRoute();
    const gotoPage = ref(1);
    const currentPage = ref(1);
    const perPage = ref(10);
    const fields = ref([{
      key: "num",
      label: "№ п/п",
      class: "text-nowrap"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "owner_department_name",
      label: "Подразделение"
    }, {
      key: "titleT",
      label: "Наименование"
    }, {
      key: "document_number",
      label: "Номер"
    }, {
      key: "document_dateT",
      label: "Дата"
    }, {
      key: "visibility_level",
      label: "Доступность"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "responsible_detailT",
      label: "Исполнитель"
    }]);
    const sort = ref(null);
    const optionsSort = ref([{
      value: "owner_department_name",
      text: "по владельцу"
    }, {
      value: "title",
      text: "по названию"
    }, {
      value: "document_date",
      text: "по дате документа"
    }, {
      value: "document_number",
      text: "по номеру документу"
    }, {
      value: "assignee__last_name,assignee__first_name,assignee__middle_name",
      text: "по ответственному"
    }]);
    const filters = ref({});
    const showSidebar = ref(false);
    const key4filters = ref(["owner_department_id", "title_search", "document_date_gte", "document_date_lte", "document_number_search", "responsible_id"]);
    const sectionsArchive = computed(() => store.getters["knowledge/sectionsArchive"]);
    const sectionsArchiveLoading = computed(() => store.getters["knowledge/sectionsArchiveLoading"]);
    const sectionsArchiveCount = computed(() => store.getters["knowledge/sectionsArchiveCount"]);
    const isAltUI = computed(() => route.path.includes(links.knowledgeListAlt));
    const items4table = computed(() => {
      return Array.isArray(sectionsArchive.value) ? sectionsArchive.value.map(el => ({
        ...el,
        titleT: el.title || CONST_NONAME,
        stateT: statesKnowledge[el.state],
        document_dateT: formatDate(el.document_date, fmtDateShort),
        responsible_detailT: formatName(el.responsible_detail, fmtNameShort)
      })) : [];
    });
    function changePage() {
      currentPage.value = limitBound(Number(gotoPage.value), 1, sectionsArchiveCount.value / perPage.value, currentPage.value);
      sortChanged();
    }
    async function applyFilters(data) {
      filters.value = {};
      Object.keys(data).forEach(key => {
        const value = ["document_date_gte", "document_date_lte"].includes(key) ? formatDateObj(data[key], fmtDate, null) : data[key];
        if (value !== null && String(value).trim() !== "") filters.value[key] = value;
      });
      if (currentPage.value === 1) {
        const params = {
          page: 1,
          section_id: props.section,
          sort: sort.value,
          ...filters.value
        };
        if (isAltUI.value) params.alt = true;
        store.dispatch("knowledge/updateArchive", params);
      } else currentPage.value = 1;
    }
    async function sortChanged({
      sortBy,
      sortDesc
    }) {
      sort.value = sortDesc ? `-${sortBy}` : sortBy;
      const params = {
        page: currentPage.value,
        section_id: props.section,
        sort: sort.value,
        ...filters.value
      };
      if (isAltUI.value) params.alt = true;
      store.dispatch("knowledge/updateArchive", params);
    }
    async function searchChanged(search) {
      const params = {
        page: currentPage.value,
        section_id: props.section,
        search_string: search,
        ...filters.value
      };
      if (isAltUI.value) params.alt = true;
      store.dispatch("knowledge/updateArchive", params);
    }
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_FilterButton = _resolveComponent("FilterButton");
      const _component_b_form_row = _resolveComponent("b-form-row");
      const _component_b_card_header = _resolveComponent("b-card-header");
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_container = _resolveComponent("b-container");
      const _component_b_offcanvas = _resolveComponent("b-offcanvas");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_card_header, {
        "header-class": "py-0 m-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_row, {
          class: "mt-0 mb-2 ms-0 me-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "6",
            xl: "2"
          }, {
            default: _withCtx(() => [_createVNode(_component_CustomSearch, {
              onOnSearch: searchChanged
            })]),
            _: 1
          }), _createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "5",
            xl: "5"
          }, {
            default: _withCtx(() => [_createVNode(_component_CustomSort, {
              optionsSort: optionsSort.value,
              onOnSort: sortChanged
            }, null, 8, ["optionsSort"])]),
            _: 1
          }), _createVNode(_component_b_col, {
            cols: "12",
            md: "4",
            lg: "4",
            xl: "2"
          }, {
            default: _withCtx(() => [_createVNode(_component_FilterButton, {
              onClick: _cache[0] || (_cache[0] = $event => showSidebar.value = true)
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        items: items4table.value,
        fields: fields.value,
        stacked: "md",
        small: "",
        responsive: "",
        "show-empty": "",
        busy: sectionsArchiveLoading.value
      }, {
        "cell()": _withCtx(row => [_createVNode(_component_router_link, {
          class: _normalizeClass({
            'font-weight-bold': row.item.is_new
          }),
          to: (isAltUI.value ? _unref(links).profileAcquaintanceAltView : _unref(links).knowledgeForm) + row.item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
          _: 2
        }, 1032, ["class", "to"])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Документов нет", -1)])),
          _: 1
        })]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        "cell(num)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage.value + row.index + 1), 1)]),
        _: 1
      }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            onOnChangePage: changePage,
            "total-rows": sectionsArchiveCount.value
          }, null, 8, ["total-rows"]), [[_vShow, sectionsArchiveCount.value > perPage.value]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_offcanvas, {
        modelValue: showSidebar.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showSidebar.value = $event),
        placement: "end",
        "no-header": "",
        backdrop: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-2"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_cache[5] || (_cache[5] = _createElementVNode("h5", {
            class: "d-inline"
          }, "Фильтровать", -1)), _createElementVNode("button", {
            type: "button",
            class: "btn-close float-end",
            "aria-label": "Close",
            onClick: _cache[1] || (_cache[1] = $event => showSidebar.value = false)
          })]), _createVNode(KnowledgeSidebar, {
            onEmitApplyFilters: applyFilters,
            key4filters: key4filters.value
          }, null, 8, ["key4filters"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};