import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "mb-2"
};
const _hoisted_2 = {
  class: "mt-2"
};
const _hoisted_3 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_4 = {
  class: "mb-0"
};
const _hoisted_5 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_SignPEP = _resolveComponent("SignPEP");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_input_group_prepend = _resolveComponent("b-input-group-prepend");
  const _component_b_form_radio = _resolveComponent("b-form-radio");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_SignPEP, {
    ref: "btnPEP",
    type: _ctx.signTypes.test,
    id: String(_ctx.attempt)
  }, null, 8, ["type", "id"]), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: $options.breadcrumbs
  }, null, 8, ["breadCrumb"]), _cache[17] || (_cache[17] = _createElementVNode("h1", {
    class: "mt-2"
  }, "Тестирование", -1)), _createElementVNode("h5", _hoisted_1, [_cache[11] || (_cache[11] = _createElementVNode("span", {
    class: "text-muted me-1"
  }, "по курсу", -1)), _createTextVNode(_toDisplayString(_ctx.getCourse.title) + " ", 1), _createElementVNode("div", _hoisted_2, [_cache[9] || (_cache[9] = _createElementVNode("span", {
    class: "text-muted"
  }, "попытка #", -1)), _createTextVNode(_toDisplayString(_ctx.educationTest.attempt_num) + " ", 1), _cache[10] || (_cache[10] = _createElementVNode("span", {
    class: "text-muted ms-3 me-1"
  }, "статус попытки", -1)), _createTextVNode(_toDisplayString(_ctx.statesTestUser[_ctx.educationTest.state]), 1)])]), _ctx.courseLoading || _ctx.educationTestLoading || _ctx.educationTestQuestLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_b_row, {
    class: "mt-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "3",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-primary",
        onClick: _cache[0] || (_cache[0] = $event => $options.updateTest(-1)),
        disabled: $options.disabledPrevious
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("❬ Предыдущий вопрос")])),
        _: 1
      }, 8, ["disabled"])]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-primary",
        onClick: _cache[1] || (_cache[1] = $event => $options.updateTest(1)),
        disabled: $options.disabledNext
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode("Следующий вопрос ❭")])),
        _: 1
      }, 8, ["disabled"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "primary",
        onClick: $options.onClickSend,
        disabled: !$options.disabledNext || $options.readOnly
      }, {
        default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("Завершить тестирование")])),
        _: 1
      }, 8, ["onClick", "disabled"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "mt-3 border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      class: "py-3 m-0"
    }, {
      default: _withCtx(() => [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.educationTestQuest.question_text), 1), _createElementVNode("div", _hoisted_5, [_cache[16] || (_cache[16] = _createElementVNode("span", {
        class: "text-muted"
      }, "Верный вариант ответа ", -1)), _createTextVNode(_toDisplayString(_ctx.answerType[_ctx.educationTestQuest.question_type]), 1)]), _ctx.answerType[_ctx.educationTestQuest.question_type] === _ctx.answerType.open ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "text",
          placeholder: "Введите ответ",
          trim: "",
          modelValue: _ctx.educationTestQuest.answer_text,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.educationTestQuest.answer_text = $event),
          onChange: _cache[3] || (_cache[3] = $event => $options.changeData('answer_text')),
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(_ctx.educationTestQuest.answer_options, (answer, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "mb-2",
          key: index
        }, [_createVNode(_component_b_input_group, null, {
          default: _withCtx(() => [_createVNode(_component_b_input_group_prepend, {
            "is-text": "",
            class: "index-wrap"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(index + 1), 1)]),
            _: 2
          }, 1024), _createVNode(_component_b_input_group_prepend, {
            "is-text": ""
          }, {
            default: _withCtx(() => [_ctx.answerType[_ctx.educationTestQuest.question_type] === _ctx.answerType.singlechoice ? (_openBlock(), _createBlock(_component_b_form_radio, {
              key: 0,
              name: "modal-radios",
              value: answer.id,
              modelValue: _ctx.singlechoice,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.singlechoice = $event),
              onChange: _cache[5] || (_cache[5] = $event => $options.changeData('singlechoice')),
              disabled: $options.readOnly
            }, null, 8, ["value", "modelValue", "disabled"])) : (_openBlock(), _createBlock(_component_b_form_checkbox, {
              key: 1,
              modelValue: _ctx.educationTestQuest.answer_ids,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.educationTestQuest.answer_ids = $event),
              value: answer.id,
              onChange: _cache[7] || (_cache[7] = $event => $options.changeData('answer_ids')),
              disabled: $options.readOnly
            }, null, 8, ["modelValue", "value", "disabled"]))]),
            _: 2
          }, 1024), _createVNode(_component_b_form_input, {
            placeholder: answer.content,
            disabled: ""
          }, null, 8, ["placeholder"])]),
          _: 2
        }, 1024)]);
      }), 128))]),
      _: 1
    })]),
    _: 1
  })], 64)), _createVNode(_component_b_modal, {
    title: "Завершить тестирование?",
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.showModal = $event),
    "ok-variant": "primary",
    "ok-title": "Завершить",
    "cancel-variant": "outline-secondary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doSend
  }, null, 8, ["modelValue", "onOk"])]);
}