import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.reduce.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  key: 1,
  class: "shadow-sm p-3 my-3 bg-white rounded"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = {
  class: "col"
};
const _hoisted_5 = ["value", "disabled"];
const _hoisted_6 = {
  class: "col-auto align-self-center px-0"
};
const _hoisted_7 = ["disabled"];
const _hoisted_8 = {
  class: "mt-3"
};
const _hoisted_9 = ["disabled"];
const _hoisted_10 = {
  class: "col"
};
const _hoisted_11 = ["value", "disabled"];
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import { onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { requiredFields } from "@/helpers/profile";
const symbolDevider = "|";
export default {
  __name: 'ProfileSheetSettings',
  setup(__props) {
    const breadcrumbs = [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }];
    // store
    const store = useStore();
    const profileSheetSettings = computed(() => store.getters["administration/profileSheetSettings"]);
    const profileSheetSettingsLoading = computed(() => store.getters["administration/profileSheetSettingsLoading"]);
    onMounted(() => {
      store.dispatch("administration/updateProfileSheetSettings");
    });

    // selects
    const fieldsRequired = ref([]);
    const fieldsNoRequired = ref([]);
    const disabledBtn2Left = computed(() => fieldsNoRequired.value.length === 0);
    const disabledBtn2Right = computed(() => fieldsRequired.value.length === 0);
    const optionsRequired = computed(() => Object.entries(requiredFields).reduce((acc, [k, v]) => {
      if (profileSheetSettings.value?.config?.[k]) {
        if (typeof v === "string") acc.push({
          key: k,
          text: v
        });else if (v.typeOne) acc.push({
          key: k,
          text: v.name
        });else if (v.typeObject) {
          acc.push({
            key: k,
            text: v.name.replace("(", ""),
            disabled: true
          });
          Object.entries(v.fields).forEach(([fk, fv]) => {
            if (profileSheetSettings.value?.config?.[k].includes(fk)) acc.push({
              key: `${k}${symbolDevider}${fk}`,
              text: fv.replace(")", "")
            });
          });
        }
      }
      return acc;
    }, []));
    const optionsNoRequired = computed(() => Object.entries(requiredFields).reduce((acc, [k, v]) => {
      if (!profileSheetSettings.value?.config?.[k]) {
        if (typeof v === "string") acc.push({
          key: k,
          text: v
        });else if (v.typeOne) acc.push({
          key: k,
          text: v.name
        });else if (v.typeObject) {
          acc.push({
            key: k,
            text: v.name.replace("(", ""),
            disabled: true
          });
          Object.entries(v.fields).forEach(([fk, fv]) => {
            acc.push({
              key: `${k}${symbolDevider}${fk}`,
              text: fv.replace(")", "")
            });
          });
        }
      } else if (profileSheetSettings.value?.config?.[k] && typeof v === "object" && v.typeObject && profileSheetSettings.value?.config?.[k].length !== Object.keys(v.fields).length) {
        acc.push({
          key: k,
          text: v.name.replace("(", ""),
          disabled: true
        });
        Object.entries(v.fields).forEach(([fk, fv]) => {
          if (!profileSheetSettings.value?.config?.[k].includes(fk)) acc.push({
            key: `${k}${symbolDevider}${fk}`,
            text: fv.replace(")", "")
          });
        });
      }
      return acc;
    }, []));
    function Btn2Left() {
      fieldsNoRequired.value.forEach(el => {
        const arr = el.split(symbolDevider);
        const settings = store.state.administration.profileSheetSettings?.config;
        if (!settings) return;
        if (arr.length > 1) {
          if (settings[arr[0]]) settings[arr[0]].push(arr[1]);else {
            settings[arr[0]] = [arr[1]];
          }
        } else settings[arr[0]] = true;
      });
    }
    function Btn2Right() {
      fieldsRequired.value.forEach(el => {
        const arr = el.split(symbolDevider);
        const settings = store.state.administration.profileSheetSettings?.config;
        if (!settings) return;
        if (arr.length > 1) {
          const index = settings[arr[0]].indexOf(arr[1]);
          if (index >= 0) settings[arr[0]].splice(index, 1);
          if (settings[arr[0]].length === 0) delete settings[arr[0]];
        } else delete settings[arr[0]];
      });
    }
    function saveSettings() {
      store.dispatch("administration/changeProfileSheetSettings", {
        id: profileSheetSettings.value.id,
        body: {
          config: profileSheetSettings.value.config
        }
      });
    }

    // watch fieldsRequired
    watch(fieldsRequired, newValue => {
      if (newValue.length > 0) fieldsNoRequired.value = [];
    });
    // watch fieldsNoRequired
    watch(fieldsNoRequired, newValue => {
      if (newValue.length > 0) fieldsRequired.value = [];
    });
    return (_ctx, _cache) => {
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _directive_can = _resolveDirective("can");
      return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs
      }), _createElementVNode("div", {
        class: "d-flex"
      }, [_cache[2] || (_cache[2] = _createElementVNode("h1", {
        class: "mb-4 me-auto"
      }, "Настройка личного листка", -1)), _createElementVNode("div", null, [_createElementVNode("button", {
        type: "button",
        class: "btn btn-primary mt-3",
        onClick: saveSettings
      }, " Сохранить настройки ")])]), profileSheetSettingsLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "fs-5 mb-1"
      }, "Обязательные поля", -1)), _withDirectives(_createElementVNode("select", {
        class: "form-select",
        multiple: "",
        size: "15",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => fieldsRequired.value = $event)
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsRequired.value, (el, index) => {
        return _openBlock(), _createElementBlock("option", {
          key: index,
          value: el.key,
          disabled: el.disabled,
          class: _normalizeClass({
            'ms-3': el.key.includes(symbolDevider)
          })
        }, _toDisplayString(el.text), 11, _hoisted_5);
      }), 128))], 512), [[_vModelSelect, fieldsRequired.value]])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, [_createElementVNode("button", {
        type: "button",
        class: "btn btn-outline-secondary fs-4 py-0",
        disabled: disabledBtn2Left.value,
        onClick: Btn2Left
      }, " < ", 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("button", {
        type: "button",
        class: "btn btn-outline-secondary fs-4 py-0",
        disabled: disabledBtn2Right.value,
        onClick: Btn2Right
      }, " > ", 8, _hoisted_9)])]), _createElementVNode("div", _hoisted_10, [_cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "fs-5 mb-1"
      }, "Необязательные поля", -1)), _withDirectives(_createElementVNode("select", {
        class: "form-select",
        multiple: "",
        size: "15",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => fieldsNoRequired.value = $event)
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsNoRequired.value, (el, index) => {
        return _openBlock(), _createElementBlock("option", {
          key: index,
          value: el.key,
          disabled: el.disabled,
          class: _normalizeClass({
            'ms-3': el.key.includes(symbolDevider)
          })
        }, _toDisplayString(el.text), 11, _hoisted_11);
      }), 128))], 512), [[_vModelSelect, fieldsNoRequired.value]])])])]))])), [[_directive_can, _ctx.$perm.change_profile_required_fields]]);
    };
  }
};