import mutations from "@/store/mutations";
import { formatDate, fmtDate } from "@/helpers/date";
import { fetchAPI } from "@/helpers/fetch";
import { checkPermissions } from "@/auth/auth";
import { wrapCommitDispatch } from "@/helpers/global";
import perm from "@/auth/permissions";

const activityArray = [
  {
    date: new Date("2001-04-10T18:15:00"),
    state: "Утверждено",
    author: "Главарев А.Д.",
    reason: "decl",
    text: "Результаты внутренней проверки #133-7 утверждены директором Главаревым А.Д.",
  },
  {
    date: new Date("2001-04-09T13:37:00"),
    text: "Добавлен риск #1-1.1.",
    state: "Создан",
    author: "Иванов С.П.",
    reason: "risk",
  },
  {
    date: new Date("2001-04-01T12:00:00"),
    text: "По декларации #23-721 назначен ответственный исполнитель Работников А.Б.",
    state: "Назначено",
    author: "Петров В.С.",
    reason: "decl",
  },
  {
    date: new Date("2001-04-01T10:05:00"),
    text: "Назначена проверка #133-7",
    state: "Назначено",
    author: "Петров В.С.",
    reason: "check",
  },
];
const backupArray = [
  {
    created: new Date("2001-10-29T18:59:00"),
    name: "compliance_backup_29_10_2021_18_59",
    number: "1",
    state: "created",
  },
  {
    created: new Date("2001-10-05T10:22:00"),
    name: "compliance_backup_05_10_2021_10_22",
    number: "2",
    state: "created",
  },
  {
    created: new Date("2001-09-02T14:21:00"),
    name: "compliance_backup_2_9_2021_14_21",
    number: "3",
    state: "created",
  },
  {
    created: new Date("2001-08-27T04:20:00"),
    name: "compliance_backup_27_08_2021_4_20",
    number: "4",
    state: "created",
  },
  {
    created: new Date("2001-07-27T18:11:00"),
    name: "compliance_backup_21_7_2021_18_11",
    number: "5",
    state: "error",
  },
];
let calendarObj = {};
const maps = ["values_probability_map", "values_impact_map"];

const adminCommon4Editor = [
  "pers_data_policy",
  "user_agreement",
  "pers_data_conscent",
  "cookies_data_policy",
];
function handlerAdminCommon(obj) {
  adminCommon4Editor.forEach((key) => {
    if (!obj[key]) obj[key] = ""; // а то ошибка падает
  });
  return obj;
}

const {
  // Activity
  ADMIN_ACTIVITY,
  ADMIN_ACTIVITY_COUNT,
  ADMIN_ACTIVITY_LOADING,
  // Backup
  ADMIN_BACKUP,
  ADMIN_BACKUP_COUNT,
  ADMIN_BACKUP_LOADING,
  // Users
  ADMIN_USERS,
  ADMIN_USERS_COUNT,
  ADMIN_USERS_LOADING,
  // Workers
  ADMIN_WORKERS,
  ADMIN_WORKERS_COUNT,
  ADMIN_WORKERS_LOADING,
  // Organization
  ADMIN_ORG,
  ADMIN_ORG_COUNT,
  ADMIN_ORG_LOADING,
  // Departament
  ADMIN_DEPART,
  ADMIN_DEPART_COUNT,
  ADMIN_DEPART_LOADING,
  // Common
  ADMIN_COMMON,
  ADMIN_COMMON_LOADING,
  // Knowledge
  ADMIN_KNOWLEDGE,
  ADMIN_KNOWLEDGE_LOADING,
  //Education
  ADMIN_EDUCATION,
  ADMIN_EDUCATION_LOADING,
  // Declaration
  ADMIN_DECLARATION,
  ADMIN_DECLARATION_LOADING,
  // InnerCheck
  ADMIN_INNER_CHECK,
  ADMIN_INNER_CHECK_LOADING,
  // Risk
  ADMIN_RISK,
  ADMIN_RISK_LOADING,
  // Partners
  ADMIN_PARTNERS,
  ADMIN_PARTNERS_LOADING,
  //HotLine
  ADMIN_HL,
  ADMIN_HL_LOADING,
  ADMIN_HL_COUNT,
  // Admin
  ADMIN_ADMIN,
  ADMIN_ADMIN_LOADING,
  // Calendar
  ADMIN_CALENDAR,
  ADMIN_CALENDAR_LOADING,
  // roles groups
  ADMIN_GROUPS,
  ADMIN_GROUPS_LOADING,
  // cp-types
  ADMIN_CP,
  ADMIN_CP_COUNT,
  ADMIN_CP_LOADING,
  // lna-types
  ADMIN_LNA_TYPES,
  ADMIN_LNA_TYPES_COUNT,
  ADMIN_LNA_TYPES_LOADING,
  // countries
  ADMIN_COUNTRY,
  ADMIN_COUNTRY_COUNT,
  ADMIN_COUNTRY_LOADING,
  // roles
  ADMIN_ROLES,
  ADMIN_ROLES_COUNT,
  ADMIN_ROLES_LOADING,
  // roles ref
  ADMIN_ROLESREF,
  ADMIN_ROLESREF_LOADING,
  // stats for dept
  ADMIN_STATS_DEPT,
  ADMIN_STATS_DEPT_LOADING,
  // риски для статистики
  ADMIN_STATS_RISK,
  ADMIN_STATS_RISK_COUNT,
  ADMIN_STATS_RISK_LOADING,
  // securityEvents
  SECURITY_EVENTS,
  SECURITY_EVENTS_COUNT,
  SECURITY_EVENTS_LOADING,
  SECURITY_EVENTS_ITEM,
  SECURITY_EVENTS_ITEM_LOADING,
  // /security-events/guide/
  SECURITY_GUIDE,
  SECURITY_GUIDE_LOADING,
  // profileSheetSettings
  ADMIN_PROFILESHEET_SETTINGS,
  ADMIN_PROFILESHEET_SETTINGS_LOADING,
  // документация по платформе
  ADMIN_COMMON_DOCS,
  ADMIN_COMMON_DOCS_LOADING,
} = mutations;

const administration = {
  namespaced: true,

  state: {
    // Activity
    adminActivity: [],
    adminActivityCount: 0,
    adminActivityLoading: false,
    // Backup
    adminBackup: [],
    adminBackupCount: 0,
    adminBackupLoading: false,
    // Users
    adminUsers: [],
    adminUsersCount: 0,
    adminUsersLoading: false,
    // Workers
    adminWorkers: [],
    adminWorkersCount: 0,
    adminWorkersLoading: false,
    // Organization
    adminOrg: [],
    adminOrgCount: 0,
    adminOrgLoading: false,
    // Departament
    adminDepart: [],
    adminDepartCount: 0,
    adminDepartLoading: false,
    // Common
    adminCommon: { phone: "", email: "", safe_domains: [] },
    adminCommonLoading: false,
    // Knowledge
    adminKnowledge: {},
    adminKnowledgeLoading: false,
    //Education
    adminEducation: {},
    adminEducationLoading: false,
    // Declaration
    adminDeclaration: {},
    adminDeclarationLoading: false,
    // InnerCheck
    adminInnerCheck: [],
    adminInnerCheckLoading: false,
    // Risk
    adminRisk: {
      values_probability_map: {
        activity_regulation: {},
        signing_act: {},
        confirming_document: {},
        control_procedures: {},
        violations_internal: {},
        violations_partners: {},
      },
      values_impact_map: {
        reputation_impact: {},
        responsibility: {},
        financial_costs: {},
      },
      weight_probability_map: {},
      weight_impact_map: {},
      terms_map: {},
    },
    adminRiskLoading: false,
    adminRiskElements: {
      activity_regulation: [],
      signing_act: [],
      confirming_document: [],
      control_procedures: [],
      violations_internal: [],
      violations_partners: [],
      reputation_impact: [],
      responsibility: [],
      financial_costs: [],
    },
    // Partners
    adminPartners: {},
    adminPartnersLoading: false,
    //HotLine
    adminHlCategory: [],
    adminHlCategoryLoading: false,
    adminHlCategoryCount: 0,
    // Admin
    adminAdmin: {},
    adminAdminLoading: false,
    // Calendar
    adminCalendar: {},
    adminCalendarLoading: false,
    // Directory
    adminDirectory: {},
    adminDirectoryLoading: false,
    adminDirectoryTitle: "",
    // roles groups
    adminGroups: {},
    adminGroupsLoading: false,
    // cp-types
    adminCpTypes: [],
    adminCpTypesLoading: false,
    adminCpTypesCount: 0,
    // lna-types
    adminLnaTypes: [],
    adminLnaTypesLoading: false,
    adminLnaTypesCount: 0,
    // countries
    adminCountry: [],
    adminCountryLoading: false,
    adminCountryCount: 0,
    // roles
    adminRoles: [],
    adminRolesLoading: false,
    adminRolesCount: 0,
    // roles ref
    adminRolesRef: {},
    adminRolesRefLoading: false,
    // stats for dept
    adminStatsDept: [],
    adminStatsDeptLoading: false,
    // риски для статистики
    adminStatsRisk: [],
    adminStatsRiskCount: 0,
    adminStatsRiskLoading: false,
    // securityEvents
    securityEvents: [],
    securityEventsCount: 0,
    securityEventsLoading: false,
    securityEventsItem: {
      old_roles: [],
      current_roles: [],
      user_detail: {},
      target_user_detail: {},
    },
    securityEventsItemLoading: false,
    // /security-events/guide/
    securityGuide: { table: {}, guide: [] },
    securityGuideLoading: false,
    // profileSheetSettings
    profileSheetSettings: {},
    profileSheetSettingsLoading: false,
    // документация по платформе
    adminCommonDocs: [],
    adminCommonDocsLoading: false,
  },

  getters: {
    // Activity
    adminActivity: ({ adminActivity }) => adminActivity,
    adminActivityCount: ({ adminActivityCount }) => adminActivityCount,
    adminActivityLoading: ({ adminActivityLoading }) => adminActivityLoading,
    // Backup
    adminBackup: ({ adminBackup }) => adminBackup,
    adminBackupCount: ({ adminBackupCount }) => adminBackupCount,
    adminBackupLoading: ({ adminBackupLoading }) => adminBackupLoading,
    // Users
    adminUsers: ({ adminUsers }) => adminUsers,
    adminUsersCount: ({ adminUsersCount }) => adminUsersCount,
    adminUsersLoading: ({ adminUsersLoading }) => adminUsersLoading,
    // Workers
    adminWorkers: ({ adminWorkers }) => adminWorkers,
    adminWorkersCount: ({ adminWorkersCount }) => adminWorkersCount,
    adminWorkersLoading: ({ adminWorkersLoading }) => adminWorkersLoading,
    // Organization
    adminOrg: ({ adminOrg }) => adminOrg,
    adminOrgCount: ({ adminOrgCount }) => adminOrgCount,
    adminOrgLoading: ({ adminOrgLoading }) => adminOrgLoading,
    // Departament
    adminDepart: ({ adminDepart }) => adminDepart,
    adminDepartCount: ({ adminDepartCount }) => adminDepartCount,
    adminDepartLoading: ({ adminDepartLoading }) => adminDepartLoading,
    // Common
    adminCommon: ({ adminCommon }) => adminCommon,
    adminCommonLoading: ({ adminCommonLoading }) => adminCommonLoading,
    // Knowledge
    adminKnowledge: ({ adminKnowledge }) => adminKnowledge,
    adminKnowledgeLoading: ({ adminKnowledgeLoading }) => adminKnowledgeLoading,
    //Education
    adminEducation: ({ adminEducation }) => adminEducation,
    adminEducationLoading: ({ adminEducationLoading }) => adminEducationLoading,
    // Declaration
    adminDeclaration: ({ adminDeclaration }) => adminDeclaration,
    adminDeclarationLoading: ({ adminDeclarationLoading }) =>
      adminDeclarationLoading,
    // InnerCheck
    adminInnerCheck: ({ adminInnerCheck }) => adminInnerCheck,
    adminInnerCheckLoading: ({ adminInnerCheckLoading }) =>
      adminInnerCheckLoading,
    // Risk
    adminRisk: ({ adminRisk }) => adminRisk,
    adminRiskLoading: ({ adminRiskLoading }) => adminRiskLoading,
    adminRiskElements: ({ adminRisk }) => {
      const obj = {};
      maps.forEach((m) => {
        if (adminRisk?.[m])
          Object.keys(adminRisk[m]).forEach((mk) => {
            obj[mk] = Object.entries(adminRisk[m][mk]).map(([k, v]) => ({
              key: k,
              value: v,
            }));
          });
      });
      return obj;
    },
    // Partners
    adminPartners: ({ adminPartners }) => adminPartners,
    adminPartnersLoading: ({ adminPartnersLoading }) => adminPartnersLoading,
    //HotLine
    adminHlCategory: ({ adminHlCategory }) => adminHlCategory,
    adminHlCategoryLoading: ({ adminHlCategoryLoading }) =>
      adminHlCategoryLoading,
    adminHlCategoryCount: ({ adminHlCategoryCount }) => adminHlCategoryCount,
    // Admin
    adminAdmin: ({ adminAdmin }) => adminAdmin,
    adminAdminLoading: ({ adminAdminLoading }) => adminAdminLoading,
    // Calendar
    adminCalendar: ({ adminCalendar }) => adminCalendar,
    adminCalendarLoading: ({ adminCalendarLoading }) => adminCalendarLoading,
    // Directory
    adminDirectory: ({ adminDirectory }) => adminDirectory,
    adminDirectoryLoading: ({ adminDirectoryLoading }) => adminDirectoryLoading,
    adminDirectoryTitle: ({ adminDirectoryTitle }) => adminDirectoryTitle,
    // roles groups
    adminGroups: ({ adminGroups }) => adminGroups,
    adminGroupsLoading: ({ adminGroupsLoading }) => adminGroupsLoading,
    // roles
    adminCountry: ({ adminCountry }) => adminCountry,
    adminCountryLoading: ({ adminCountryLoading }) => adminCountryLoading,
    adminCountryCount: ({ adminCountryCount }) => adminCountryCount,
    // roles ref
    adminRolesRef: ({ adminRolesRef }) => adminRolesRef,
    adminRolesRefLoading: ({ adminRolesRefLoading }) => adminRolesRefLoading,
    // cp-types
    adminCpTypes: ({ adminCpTypes }) => adminCpTypes,
    adminCpTypesCount: ({ adminCpTypesCount }) => adminCpTypesCount,
    adminCpTypesLoading: ({ adminCpTypesLoading }) => adminCpTypesLoading,
    // lna-types
    adminLnaTypes: ({ adminLnaTypes }) => adminLnaTypes,
    adminLnaTypesLoading: ({ adminLnaTypesLoading }) => adminLnaTypesLoading,
    adminLnaTypesCount: ({ adminLnaTypesCount }) => adminLnaTypesCount,
    // countries
    adminRoles: ({ adminRoles }) => adminRoles,
    adminRolesLoading: ({ adminRolesLoading }) => adminRolesLoading,
    adminRolesCount: ({ adminRolesCount }) => adminRolesCount,
    // stats for dept
    adminStatsDept: ({ adminStatsDept }) => adminStatsDept,
    adminStatsDeptLoading: ({ adminStatsDeptLoading }) => adminStatsDeptLoading,
    // риски для статистики
    adminStatsRisk: ({ adminStatsRisk }) => adminStatsRisk,
    adminStatsRiskCount: ({ adminStatsRiskCount }) => adminStatsRiskCount,
    adminStatsRiskLoading: ({ adminStatsRiskLoading }) => adminStatsRiskLoading,
    // securityEvents
    securityEvents: ({ securityEvents }) => securityEvents,
    securityEventsCount: ({ securityEventsCount }) => securityEventsCount,
    securityEventsLoading: ({ securityEventsLoading }) => securityEventsLoading,
    securityEventsItem: ({ securityEventsItem }) => securityEventsItem,
    securityEventsItemLoading: ({ securityEventsItemLoading }) =>
      securityEventsItemLoading,
    // /security-events/guide/
    securityGuide: ({ securityGuide }) => securityGuide,
    securityGuideLoading: ({ securityGuideLoading }) => securityGuideLoading,
    // profileSheetSettings
    profileSheetSettings: ({ profileSheetSettings }) => profileSheetSettings,
    profileSheetSettingsLoading: ({ profileSheetSettingsLoading }) =>
      profileSheetSettingsLoading,
    // документация по платформе
    adminCommonDocs: ({ adminCommonDocs }) => adminCommonDocs,
    adminCommonDocsLoading: ({ adminCommonDocsLoading }) =>
      adminCommonDocsLoading,
  },

  mutations: {
    // Activity
    [ADMIN_ACTIVITY](state, value) {
      state.adminActivity = value;
    },
    [ADMIN_ACTIVITY_COUNT](state, value) {
      state.adminActivityCount = value;
    },
    [ADMIN_ACTIVITY_LOADING](state, value) {
      state.adminActivityLoading = value;
    },
    //Backup
    [ADMIN_BACKUP](state, value) {
      state.adminBackup = value;
    },
    [ADMIN_BACKUP_COUNT](state, value) {
      state.adminBackupCount = value;
    },
    [ADMIN_BACKUP_LOADING](state, value) {
      state.adminBackupLoading = value;
    },
    // Users
    [ADMIN_USERS](state, value) {
      state.adminUsers = value;
    },
    [ADMIN_USERS_COUNT](state, value) {
      state.adminUsersCount = value;
    },
    [ADMIN_USERS_LOADING](state, value) {
      state.adminUsersLoading = value;
    },
    // Workers
    [ADMIN_WORKERS](state, value) {
      state.adminWorkers = value;
    },
    [ADMIN_WORKERS_COUNT](state, value) {
      state.adminWorkersCount = value;
    },
    [ADMIN_WORKERS_LOADING](state, value) {
      state.adminWorkersLoading = value;
    },
    // Organization
    [ADMIN_ORG](state, value) {
      state.adminOrg = value;
    },
    [ADMIN_ORG_COUNT](state, value) {
      state.adminOrgCount = value;
    },
    [ADMIN_ORG_LOADING](state, value) {
      state.adminOrgLoading = value;
    },
    // Departament
    [ADMIN_DEPART](state, value) {
      state.adminDepart = value;
    },
    [ADMIN_DEPART_COUNT](state, value) {
      state.adminDepartCount = value;
    },
    [ADMIN_DEPART_LOADING](state, value) {
      state.adminDepartLoading = value;
    },
    // Common
    [ADMIN_COMMON](state, value) {
      state.adminCommon = value;
    },
    [ADMIN_COMMON_LOADING](state, value) {
      state.adminCommonLoading = value;
    },
    // Knowledge
    [ADMIN_KNOWLEDGE](state, value) {
      state.adminKnowledge = value;
    },
    [ADMIN_KNOWLEDGE_LOADING](state, value) {
      state.adminKnowledgeLoading = value;
    },
    //Education
    [ADMIN_EDUCATION](state, value) {
      state.adminEducation = value;
    },
    [ADMIN_EDUCATION_LOADING](state, value) {
      state.adminEducationLoading = value;
    },
    // Declaration
    [ADMIN_DECLARATION](state, value) {
      state.adminDeclaration = value;
    },
    [ADMIN_DECLARATION_LOADING](state, value) {
      state.adminDeclarationLoading = value;
    },
    // InnerCheck
    [ADMIN_INNER_CHECK](state, value) {
      state.adminInnerCheck = value;
    },
    [ADMIN_INNER_CHECK_LOADING](state, value) {
      state.adminInnerCheckLoading = value;
    },
    // Risk
    [ADMIN_RISK](state, value) {
      state.adminRisk = value;
    },
    [ADMIN_RISK_LOADING](state, value) {
      state.adminRiskLoading = value;
    },
    // Partners
    [ADMIN_PARTNERS](state, value) {
      state.adminPartners = value;
    },
    [ADMIN_PARTNERS_LOADING](state, value) {
      state.adminPartnersLoading = value;
    },
    //HotLine
    [ADMIN_HL](state, value) {
      state.adminHlCategory = value;
    },
    [ADMIN_HL_LOADING](state, value) {
      state.adminHlCategoryLoading = value;
    },
    [ADMIN_HL_COUNT](state, value) {
      state.adminHlCategoryCount = value;
    },
    // Admin
    [ADMIN_ADMIN](state, value) {
      state.adminAdmin = value;
    },
    [ADMIN_ADMIN_LOADING](state, value) {
      state.adminAdminLoading = value;
    },
    // Calendar
    [ADMIN_CALENDAR](state, value) {
      state.adminCalendar = value;
    },
    [ADMIN_CALENDAR_LOADING](state, value) {
      state.adminCalendarLoading = value;
    },
    // roles groups
    [ADMIN_GROUPS](state, value) {
      state.adminGroups = value;
    },
    [ADMIN_GROUPS_LOADING](state, value) {
      state.adminGroupsLoading = value;
    },
    // roles
    [ADMIN_ROLES](state, value) {
      state.adminRoles = value;
    },
    [ADMIN_ROLES_LOADING](state, value) {
      state.adminRolesLoading = value;
    },
    [ADMIN_ROLES_COUNT](state, value) {
      state.adminRolesCount = value;
    },
    // roles ref
    [ADMIN_ROLESREF](state, value) {
      state.adminRolesRef = value;
    },
    [ADMIN_ROLESREF_LOADING](state, value) {
      state.adminRolesRefLoading = value;
    },
    // cp-types
    [ADMIN_CP](state, value) {
      state.adminCpTypes = value;
    },
    [ADMIN_CP_COUNT](state, value) {
      state.adminCpTypesCount = value;
    },
    [ADMIN_CP_LOADING](state, value) {
      state.adminCpTypesLoading = value;
    },
    // lna-types
    [ADMIN_LNA_TYPES](state, value) {
      state.adminLnaTypes = value;
    },
    [ADMIN_LNA_TYPES_LOADING](state, value) {
      state.adminLnaTypesLoading = value;
    },
    [ADMIN_LNA_TYPES_COUNT](state, value) {
      state.adminLnaTypesCount = value;
    },
    // countries
    [ADMIN_COUNTRY](state, value) {
      state.adminCountry = value;
    },
    [ADMIN_COUNTRY_LOADING](state, value) {
      state.adminCountryLoading = value;
    },
    [ADMIN_COUNTRY_COUNT](state, value) {
      state.adminCountryCount = value;
    },
    // stats for dept
    [ADMIN_STATS_DEPT](state, value) {
      state.adminStatsDept = value;
    },
    [ADMIN_STATS_DEPT_LOADING](state, value) {
      state.adminStatsDeptLoading = value;
    },
    // риски для статистики
    [ADMIN_STATS_RISK](state, value) {
      state.adminStatsRisk = value;
    },
    [ADMIN_STATS_RISK_COUNT](state, value) {
      state.adminStatsRiskCount = value;
    },
    [ADMIN_STATS_RISK_LOADING](state, value) {
      state.adminStatsRiskLoading = value;
    },
    // securityEvents
    [SECURITY_EVENTS](state, value) {
      state.securityEvents = value;
    },
    [SECURITY_EVENTS_COUNT](state, value) {
      state.securityEventsCount = value;
    },
    [SECURITY_EVENTS_LOADING](state, value) {
      state.securityEventsLoading = value;
    },
    [SECURITY_EVENTS_ITEM](state, value) {
      state.securityEventsItem = value;
    },
    [SECURITY_EVENTS_ITEM_LOADING](state, value) {
      state.securityEventsItemLoading = value;
    },
    // /security-events/guide/
    [SECURITY_GUIDE](state, value) {
      state.securityGuide = value;
    },
    [SECURITY_GUIDE_LOADING](state, value) {
      state.securityGuideLoading = value;
    },
    // profileSheetSettings
    [ADMIN_PROFILESHEET_SETTINGS](state, value) {
      state.profileSheetSettings = value;
    },
    [ADMIN_PROFILESHEET_SETTINGS_LOADING](state, value) {
      state.profileSheetSettingsLoading = value;
    },
    // документация по платформе
    [ADMIN_COMMON_DOCS](state, value) {
      state.adminCommonDocs = value;
    },
    [ADMIN_COMMON_DOCS_LOADING](state, value) {
      state.adminCommonDocsLoading = value;
    },
  },

  actions: {
    // stats for dept
    // запрос статистики для отчета дептранса
    async updateAdminStatsDept(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/dt-stats", data);
          context.commit(ADMIN_STATS_DEPT, response);
        },
        ADMIN_STATS_DEPT_LOADING
      );
    },
    // cp-types
    // запрос списка контрольных процедур с сервера
    async updateAdminCpTypes(context, { page, pageSize }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/cp-types/", {
            page,
            page_size: pageSize,
          });
          context.commit(ADMIN_CP, response.results);
          context.commit(ADMIN_CP_COUNT, response.count);
        },
        ADMIN_CP_LOADING
      );
    },
    // создание контрольной процедуры
    async createAdminCpTypes(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI("/cp-types/", null, "POST", data.params);
          return `Контрольная процедура создана`;
        },
        ADMIN_CP_LOADING
      );
    },
    // изменить контрольную процедуру
    async changeAdminCpTypes(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/cp-types/${data.item.id}/`,
            null,
            "PATCH",
            data.params
          );
          return `Контрольная процедура ${data.item.cp_name} изменена`;
        },
        ADMIN_CP_LOADING
      );
    },

    // Activity
    // запрос журнала событий с сервера
    updateAdminActivity({ commit, dispatch }, { page }) {
      commit(ADMIN_ACTIVITY_LOADING, true);
      try {
        commit(ADMIN_ACTIVITY, activityArray.slice((page - 1) * 10, page * 10));
        commit(ADMIN_ACTIVITY_COUNT, activityArray.length);
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(ADMIN_ACTIVITY_LOADING, false);
      }
    },

    //Backup
    // запрос списка резервных копий с сервера
    updateAdminBackup({ commit, dispatch }, { page }) {
      commit(ADMIN_BACKUP_LOADING, true);
      try {
        commit(ADMIN_BACKUP, backupArray.slice((page - 1) * 10, page * 10));
        commit(ADMIN_BACKUP_COUNT, backupArray.length);
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(ADMIN_BACKUP_LOADING, false);
      }
    },

    // удаление резервной копии
    removeAdminBackup({ dispatch }, item) {
      try {
        const index = backupArray.findIndex(
          (el) => el["number"] === item["number"]
        );
        backupArray.splice(index, 1);
        dispatch(
          "showNotify",
          {
            msg: `Резервная копия ${item["name"]} удалена`,
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // восстановление резервной копии
    unpackAdminBackup({ dispatch }, item) {
      try {
        dispatch(
          "showNotify",
          {
            msg: `Резервная копия ${item["name"]} восстановлена`,
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // создание резервной копии
    createAdminBackup({ dispatch }, { nameBackup }) {
      try {
        backupArray.push({
          created: new Date(),
          name: nameBackup,
          number: "" + new Date().getTime(),
          state: "created",
        });
        dispatch(
          "showNotify",
          {
            msg: `Резервная копия ${nameBackup} создана`,
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // Users
    // запрос списка пользоватлей с сервера
    async updateAdminUsers(context, params) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/users/", params);
          context.commit(ADMIN_USERS, response.results);
          context.commit(ADMIN_USERS_COUNT, response.count);
        },
        ADMIN_USERS_LOADING
      );
    },

    // удаление пользователя
    async removeAdminUser(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/users/${data.id}/`, null, "DELETE");
        return `Пользователь ${data.login} удален`;
      });
    },

    // создание нового пользователя
    async createAdminUser(context, data) {
      await wrapCommitDispatch(context, async () => {
        const body = {
          login: data.params.login,
          password: data.params.password,
        };
        if (data.is_analyst) body.is_analyst = true;
        else body.organization = data.org;

        await fetchAPI("/register", null, "POST", body);
        return `Пользователь ${data.params.login} создан`;
      });
    },

    // изменить пользователя
    async changeAdminUser(context, data) {
      let isChange = false;
      const params = Object.keys(data.params).reduce((acc, curr) => {
        if (data.params[curr] !== null) {
          acc[curr] = data.params[curr];
          isChange = true;
        }
        return acc;
      }, {});
      if (!isChange) return;

      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/users/${data.user}/`, null, "PATCH", params);
          return `${data.name} изменен`;
        },
        ADMIN_USERS_LOADING
      );
    },
    // изменить пароль пользователя
    async changePasswordUser(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/users/${data.user}/password/`,
            null,
            "PATCH",
            data.params
          );
          return `Пароль для ${data.name} изменен`;
        },
        ADMIN_USERS_LOADING
      );
    },

    // сменить статус пользователя (принять на работу)
    async stateToAdminUser(context, { id, stateTo, msg }) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/users/${id}/transition/`, null, "PATCH", {
          state: stateTo,
        });
        return msg;
      });
    },

    // Workers
    // запрос списка работников с сервера
    async updateAdminWorkers(context, requestParams) {
      // if (!checkPermissions(perm.view_wa_worker)) {
      //   // нет доступа на просмотр
      //   return;
      // }
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/workers/", requestParams);
          context.commit(ADMIN_WORKERS, response.results);
          context.commit(ADMIN_WORKERS_COUNT, response.count);
        },
        ADMIN_WORKERS_LOADING
      );
    },

    // изменить работника
    async changeAdminWorkers(context, data) {
      let isChange = false;
      const params = Object.keys(data.params).reduce((acc, curr) => {
        if (data.params[curr] !== null) {
          acc[curr] = data.params[curr];
          isChange = true;
        }
        return acc;
      }, {});
      if (!isChange) {
        return;
      }
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/workers/${data.item.id}/`, null, "PATCH", params);
        return `Работник ${data.item.last_name} изменен`;
      });
    },

    // сменить статус работника - например уволить
    async stateToAdminWorkers(context, { id, stateTo, msg }) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/workers/${id}/transition/`, null, "PATCH", {
          state: stateTo,
        });
        return msg;
      });
    },

    // Organization
    // запрос списка организаций с сервера
    async updateAdminOrg(context, { page }) {
      if (!checkPermissions(perm.view_admin_organization)) {
        // нет доступа на просмотр организации
        return;
      }
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/organizations/", { page });
          context.commit(ADMIN_ORG, response.results);
          context.commit(ADMIN_ORG_COUNT, response.count);
        },
        ADMIN_ORG_LOADING
      );
    },

    // удаление организации
    async removeAdminOrg(context, item) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/organizations/${item.id}/`, null, "DELETE");
        return `Организация ${item.fullname} удалена`;
      });
    },

    // создание новой организации
    async createAdminOrg(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI("/organizations/", null, "POST", { ...data.params });
        return `Организация ${data.params.fullname} созданa`;
      });
    },

    // изменить организацию
    async changeAdminOrg(context, data) {
      let isChange = false;
      const params = Object.keys(data.params).reduce((acc, curr) => {
        if (data.params[curr] !== null) {
          acc[curr] = data.params[curr];
          isChange = true;
        }
        return acc;
      }, {});
      if (!isChange) {
        return;
      }
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/organizations/${data.item.id}/`,
          null,
          "PATCH",
          params
        );
        return `Организация ${data.item.fullname} изменена`;
      });
    },

    // Departament
    // запрос списка подразделений с сервера
    async updateAdminDepart(context, { page }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/departments/", { page });
          context.commit(ADMIN_DEPART, response.results);
          context.commit(ADMIN_DEPART_COUNT, response.count);
        },
        ADMIN_DEPART_LOADING
      );
    },

    // удаление подразделения
    async removeAdminDepart(context, item) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(`/departments/${item.id}/`, null, "DELETE");
        return `Подразделение ${item.department_name} удалёно`;
      });
    },

    // создание нового подразделения
    async createAdminDepart(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI("/departments/", null, "POST", data.params);
        return `Подразделение создано`;
      });
    },

    // изменить подразделение
    async changeAdminDepart(context, data) {
      await wrapCommitDispatch(context, async () => {
        await fetchAPI(
          `/departments/${data.item.id}/`,
          null,
          "PATCH",
          data.params
        );
        return "Подразделение изменено";
      });
    },

    // Common
    // запрос общих настроек
    async updateCommon(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            "/public-properties/",
            null,
            "GET",
            {},
            false,
            false
          );
          context.commit(ADMIN_COMMON, handlerAdminCommon(response));
        },
        ADMIN_COMMON_LOADING
      );
    },
    // изменить общие настройки
    async changeCommon(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/properties/${data.id}/`,
            null,
            "PATCH",
            data.params,
            data.fileUpload
          );
          context.commit(ADMIN_COMMON, handlerAdminCommon(response));
          return "Настройки сохранены";
        },
        ADMIN_COMMON_LOADING
      );
    },
    // создать объект общей настройки
    async createCommon(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/properties/", null, "POST");
          context.commit(ADMIN_COMMON, handlerAdminCommon(response));
          return "Настройка создана";
        },
        ADMIN_COMMON_LOADING
      );
    },
    // документация по платформе
    // запрос документации
    async updateCommonDocs(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/properties/${id}/docs/`);
          context.commit(ADMIN_COMMON_DOCS, response.results);
        },
        ADMIN_COMMON_DOCS_LOADING
      );
    },
    // создать новый документ
    async createCommonDocs(context, { id, formData }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/properties/${id}/docs/`,
            null,
            "POST",
            formData,
            true
          );
          return "Файл прикреплен";
        },
        ADMIN_COMMON_DOCS_LOADING
      );
    },
    // удалить документ
    async deleteCommonDocs(context, { id, idAttach }) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/properties/${id}/docs/${idAttach}/`, null, "DELETE");
          return "Файл удален";
        },
        ADMIN_COMMON_DOCS_LOADING
      );
    },

    // Knowledge
    // запрос настроек базы знаний с сервера
    updateKnowledge({ commit, dispatch }) {
      commit(ADMIN_KNOWLEDGE_LOADING, true);
      try {
        commit(ADMIN_KNOWLEDGE, {
          days: 10,
          period: "month",
          organisation: "all",
          department: "all",
        });
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(ADMIN_KNOWLEDGE_LOADING, false);
      }
    },

    // изменить настройки базы знаний
    changeKnowledge({ commit, dispatch }, data) {
      try {
        commit(ADMIN_KNOWLEDGE, data);
        dispatch(
          "showNotify",
          {
            msg: "Настройки базы знаний сохранены",
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    //Education
    // запрос настроек обучения с сервера
    updateEducation({ commit, dispatch }) {
      commit(ADMIN_EDUCATION_LOADING, true);
      try {
        commit(ADMIN_EDUCATION, {
          daysInner: 10,
          daysOuter: 10,
          period: "month",
          organisation: "all",
          department: "all",
          type: "statsEducation",
        });
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(ADMIN_EDUCATION_LOADING, false);
      }
    },

    // изменить настройки обучения
    changeEducation({ commit, dispatch }, data) {
      try {
        commit(ADMIN_EDUCATION, data);
        dispatch(
          "showNotify",
          {
            msg: 'Настройки модуля "Обучение" сохранены',
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // Declaration
    // запрос настроек деклараций с сервера
    updateDeclaration({ commit, dispatch }) {
      commit(ADMIN_DECLARATION_LOADING, true);
      try {
        commit(ADMIN_DECLARATION, {
          days: 10,
          deadline: new Date(new Date().getFullYear(), 8, 1),
          periodicity: "year",
          period: "month",
          organisation: "all",
          department: "all",
          type: "statsEDKI",
        });
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(ADMIN_DECLARATION_LOADING, false);
      }
    },

    // изменить настройки деклараций
    changeDeclaration({ commit, dispatch }, data) {
      try {
        commit(ADMIN_DECLARATION, data);
        dispatch(
          "showNotify",
          {
            msg: 'Настройки модуля "Декларации" сохранены',
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // InnerCheck
    // Справочник категории проверок 12 записей в одном массиве сразу
    async updateInnerCheckDirectory(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/inch-categories/");
          context.commit(ADMIN_INNER_CHECK, response.results);
        },
        ADMIN_INNER_CHECK_LOADING
      );
    },

    // Risk
    // запрос настроек модуля рисков с сервера
    async updateRisk(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/risks-settings/");
          if (Array.isArray(response.results) && response.results.length > 0)
            // берем 1й и единственный элемент
            context.commit(ADMIN_RISK, response.results[0]);
        },
        ADMIN_RISK_LOADING
      );
    },
    // изменить настройки модуля рисков
    async changeRisk(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/risks-settings/${data.id}/`,
            null,
            "PATCH",
            data.body
          );
          context.commit(ADMIN_RISK, response);
          return "Настройки сохранены";
        },
        ADMIN_RISK_LOADING
      );
    },

    //HotLine
    // запрос списка типов сообщений горячей линии с сервера
    async updateAdminHlCategory(context, { page, pageSize }) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/hotline-types/", {
            page,
            page_size: pageSize,
          });
          context.commit(ADMIN_HL, response.results);
          context.commit(ADMIN_HL_COUNT, response.count);
        },
        ADMIN_HL_LOADING
      );
    },
    // создание нового типа сообщений горячей линии
    async createAdminHlCategory(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI("/hotline-types/", null, "POST", data.params);
          return `Тип сообщений горячей линии создан`;
        },
        ADMIN_HL_LOADING
      );
    },
    // изменить тип сообщений горячей линии
    async changeAdminHlCategory(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/hotline-types/${data.item.id}/`,
            null,
            "PATCH",
            data.params
          );
          return `Тип сообщений горячей линии ${data.item.message_type} изменен`;
        },
        ADMIN_HL_LOADING
      );
    },

    // Admin
    // запрос настроек администрирования
    updateAdmin({ commit, dispatch }) {
      commit(ADMIN_ADMIN_LOADING, true);
      try {
        commit(ADMIN_ADMIN, {
          email: "admin@mdto.ru",
          period: "month",
        });
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(ADMIN_ADMIN_LOADING, false);
      }
    },

    // изменить настройки администрирования
    changeAdmin({ commit, dispatch }, data) {
      try {
        commit(ADMIN_ADMIN, data);
        dispatch(
          "showNotify",
          {
            msg: 'Настройки модуля "Администрирование" сохранены',
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // Calendar
    // запрос настроек календаря
    updateCalendar({ commit, dispatch }, year) {
      commit(ADMIN_CALENDAR_LOADING, true);
      try {
        calendarObj = {};
        let curDate = new Date(year, 0, 1);
        const endDate = new Date(year + 1, 0, 1);
        while (curDate < endDate) {
          const id = formatDate(curDate, fmtDate);
          const weekDay = curDate.getDay();
          calendarObj[id] = {};
          calendarObj[id].worker = weekDay !== 6 && weekDay !== 0;
          calendarObj[id].active = true;
          curDate.setDate(curDate.getDate() + 1);
        }
        commit(ADMIN_CALENDAR, calendarObj);
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      } finally {
        commit(ADMIN_CALENDAR_LOADING, false);
      }
    },

    // изменить настройки календаря
    changeCalendar({ commit, dispatch }, data) {
      try {
        const { date, worker, active } = data;
        calendarObj[date].worker = worker;
        calendarObj[date].active = active;
        commit(ADMIN_CALENDAR, calendarObj);
        dispatch(
          "showNotify",
          {
            msg: "Настройки календаря сохранены",
            title: "Успех",
            variant: "success",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "showNotify",
          {
            msg: error.message,
            title: "Ошибка",
            variant: "danger",
          },
          { root: true }
        );
      }
    },

    // roles groups
    // запрос списка ролей с сервера
    async updateAdminGroups(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/roles");
          context.commit(ADMIN_GROUPS, response);
        },
        ADMIN_GROUPS_LOADING
      );
    },

    // lna-types
    // запрос списка типов ЛНА с сервера
    async updateAdminLnaTypes(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/lna-types/", data);
          context.commit(ADMIN_LNA_TYPES, response.results);
          context.commit(ADMIN_LNA_TYPES_COUNT, response.count);
        },
        ADMIN_LNA_TYPES_LOADING
      );
    },
    // создание типа ЛНА
    async createAdminLnaTypes(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI("/lna-types/", null, "POST", data.params);
          return "Типа ЛНА создан";
        },
        ADMIN_LNA_TYPES_LOADING
      );
    },
    // изменить тип ЛНА
    async changeAdminLnaTypes(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/lna-types/${data.item.id}/`,
            null,
            "PATCH",
            data.params
          );
          return `Типа ЛНА ${data.item.type_name} изменен`;
        },
        ADMIN_LNA_TYPES_LOADING
      );
    },

    // countries
    // запрос списка стран
    async updateAdminCountry(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/countries/", data);
          context.commit(ADMIN_COUNTRY, response.results);
          context.commit(ADMIN_COUNTRY_COUNT, response.count);
        },
        ADMIN_COUNTRY_LOADING
      );
    },
    // создание страны
    async createAdminCountry(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI("/countries/", null, "POST", data.params);
          return `Страна ${data.params.short_name} создана`;
        },
        ADMIN_COUNTRY_LOADING
      );
    },
    // изменить страну
    async changeAdminCountry(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/countries/${data.item.id}/`,
            null,
            "PATCH",
            data.params
          );
          return `Страна ${data.item.short_name} изменена`;
        },
        ADMIN_COUNTRY_LOADING
      );
    },
    // удалить страну
    async removeAdminCountry(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(`/countries/${data.id}/`, null, "DELETE");
          return `Страна ${data.short_name} удалена`;
        },
        ADMIN_COUNTRY_LOADING
      );
    },
    // сгенерировать страны на основании данных ОКСМ
    async generateAdminCountryOKSM(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI("/countries/autocreate/", null, "POST");
          return "Справочинк стран сгенерирован на основании данных ОКСМ";
        },
        ADMIN_COUNTRY_LOADING
      );
    },
    // риски для статистики
    async updateAdminStatsRisk(context, requestParams) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/dt-stats-detail/", requestParams);
          context.commit(ADMIN_STATS_RISK, response.results);
          context.commit(ADMIN_STATS_RISK_COUNT, response.count);
        },
        ADMIN_STATS_RISK_LOADING
      );
    },
    // securityEvents получить список
    async updateSecurityEvents(context, params) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/security-events/", params);
          context.commit(SECURITY_EVENTS, response.results);
          context.commit(SECURITY_EVENTS_COUNT, response.count);
        },
        SECURITY_EVENTS_LOADING
      );
    },
    // securityEvents получить элемент
    async updateSecurityEventsItem(context, id) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(`/security-events/${id}/`);
          context.commit(SECURITY_EVENTS_ITEM, response);
        },
        SECURITY_EVENTS_ITEM_LOADING
      );
    },
    // /security-events/guide/ получить данные
    async updateSecurityGuide(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/security-events/guide/");
          context.commit(SECURITY_GUIDE, response);
        },
        SECURITY_GUIDE_LOADING
      );
    },
    // role-names
    // запрос списка ролей
    async updateAdminRoles(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/role-names/", data);
          context.commit(ADMIN_ROLES, response.results);
          context.commit(ADMIN_ROLES_COUNT, response.count);
        },
        ADMIN_ROLES_LOADING
      );
    },
    // изменить роль
    async changeAdminRoles(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          await fetchAPI(
            `/role-names/${data.item.id}/`,
            null,
            "PATCH",
            data.params
          );
          return `Роль ${data.item.title} изменена`;
        },
        ADMIN_ROLES_LOADING
      );
    },
    // roles ref
    async updateAdminRolesRef(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/role-names/rolesref/");
          context.commit(ADMIN_ROLESREF, response);
        },
        ADMIN_ROLESREF_LOADING
      );
    },
    // update profileSheetSettings
    async updateProfileSheetSettings(context) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI("/profile-settings/");
          if (Array.isArray(response.results) && response.results.length > 0)
            // берем 1й и единственный элемент
            context.commit(ADMIN_PROFILESHEET_SETTINGS, response.results[0]);
        },
        ADMIN_PROFILESHEET_SETTINGS_LOADING
      );
    },
    // change profileSheetSettings
    async changeProfileSheetSettings(context, data) {
      await wrapCommitDispatch(
        context,
        async () => {
          const response = await fetchAPI(
            `/profile-settings/${data.id}/`,
            null,
            "PATCH",
            data.body
          );
          context.commit(ADMIN_PROFILESHEET_SETTINGS, response);
          return "Настройки сохранены";
        },
        ADMIN_PROFILESHEET_SETTINGS_LOADING
      );
    },
  },
};

export default administration;
