import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "ms-2"
};
const _hoisted_2 = {
  class: "mb-0"
};
const _hoisted_3 = {
  class: "mb-0"
};
const _hoisted_4 = {
  class: "conflict-of-interest mb-3"
};
const _hoisted_5 = {
  key: 0,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_col = _resolveComponent("b-col");
  const _component_TaskButton = _resolveComponent("TaskButton");
  const _component_b_row = _resolveComponent("b-row");
  const _component_DeclarationFormPartI = _resolveComponent("DeclarationFormPartI");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_COIForDeclaration = _resolveComponent("COIForDeclaration");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_EventsList = _resolveComponent("EventsList");
  const _component_AttachmentsList = _resolveComponent("AttachmentsList");
  const _component_DeclarationFormComments = _resolveComponent("DeclarationFormComments");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_b_row, {
    class: "mb-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, "Декларация #" + _toDisplayString(_ctx.declarationFormObj.id), 1), _createVNode(_component_b_button, {
        variant: "primary",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.back())
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Назад")])),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "primary",
        to: _ctx.links.printDeclaration + `${JSON.stringify({
          id: $props.msg,
          myDecl: false
        })}`,
        target: "_blank"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Печать декларации ")])),
        _: 1
      }, 8, ["to"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.declarationFormObj.transitions, tran => {
        return _openBlock(), _createElementBlock("span", {
          key: tran
        }, [_ctx.buttons4transitions[tran] ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_ctx.buttons4transitions[tran].type === 'simple' ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          variant: _ctx.buttons4transitions[tran].variant,
          onClick: $event => $options.clickAction(_ctx.buttons4transitions[tran])
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.buttons4transitions[tran].text), 1)]),
          _: 2
        }, 1032, ["variant", "onClick"])), [[_directive_can, _ctx.buttons4transitions[tran].perm]]) : _createCommentVNode("", true), _ctx.buttons4transitions[tran].type === 'taskBtn' ? _withDirectives((_openBlock(), _createBlock(_component_TaskButton, {
          key: 1,
          idModal: _ctx.buttons4transitions[tran].modal,
          settings: $options.butRef4transitions(tran),
          onAddData: $event => $options.addTask(_ctx.buttons4transitions[tran], $event)
        }, null, 8, ["idModal", "settings", "onAddData"])), [[_directive_can, _ctx.buttons4transitions[tran].perm]]) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
      }), 128))]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("p", _hoisted_2, [_cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "text-muted"
  }, "Статус ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.statesDeclarations[_ctx.declarationFormObj.state]), 1)]), _createElementVNode("p", _hoisted_3, [_cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "text-muted"
  }, "Обновлено ", -1)), _createElementVNode("span", null, _toDisplayString($options.formatDate(_ctx.declarationFormObj.created_at, _ctx.fmtDateMonthTime)), 1)]), !_ctx.declarationFormLoading ? (_openBlock(), _createBlock(_component_DeclarationFormPartI, {
    key: 0,
    user: $options.renderUser
  }, null, 8, ["user"])) : _createCommentVNode("", true), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm mt-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom",
      modelValue: _ctx.tabIndex,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.tabIndex = $event)
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[7] || (_cache[7] = _createTextVNode("Декларация КИ ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.coiForDeclarationCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_COIForDeclaration, {
            declarationId: $props.msg
          }, null, 8, ["declarationId"])])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[8] || (_cache[8] = _createTextVNode("История")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.declarationEventsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_EventsList, {
            declId: $props.msg
          }, null, 8, ["declId"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[9] || (_cache[9] = _createTextVNode("Вложения")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.declarationFormAttachmentsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_AttachmentsList, {
            declId: $props.msg
          }, null, 8, ["declId"]), _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)), _ctx.declarationFormObj.decision ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            variant: "outline-primary",
            href: _ctx.declarationFormObj.decision,
            download: decodeURI(_ctx.declarationFormObj.decision)
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("Скачать решение комиссии")])),
            _: 1
          }, 8, ["href", "download"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[12] || (_cache[12] = _createTextVNode(" Комментарии ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.declFormCommentsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_DeclarationFormComments)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  })], 512), [[_vShow, !_ctx.declarationFormLoading && !_ctx.declarationFormAttachmentsLoading && !_ctx.coiForDeclarationLoading && !_ctx.tasksForDeclarationLoading && !_ctx.declarationEventsLoading]]), _ctx.declarationFormLoading || _ctx.declarationFormAttachmentsLoading || _ctx.coiForDeclarationLoading || _ctx.tasksForDeclarationLoading || _ctx.declarationEventsLoading ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createVNode(_component_b_modal, {
    title: _ctx.data4send.confirmedText,
    modelValue: _ctx.showModalConfirm,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModalConfirm = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Продолжить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doConfirm
  }, null, 8, ["title", "modelValue", "onOk"])]);
}