import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "table-header"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "bi bi-exclamation-circle-fill text-danger",
  title: "У пользователя почта на небезопасном домене"
};
const _hoisted_4 = {
  class: "table-footer"
};
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { formatDate, fmtDateMonthTime, fmtDateShort } from "@/helpers/date";
import { limitBound, formatName, fmtNameShort } from "@/helpers/text";
import { stateWorker } from "@/helpers/states";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminUsersAdd from "./AdminUsersAdd.vue";
import { getOrgId, getOrgName } from "@/auth/auth";
const perPage = 20;
export default {
  __name: 'AdminUsers',
  setup(__props) {
    const store = useStore();
    const showModalRemove = ref(false);
    const currentItem = ref({});
    const currentPage = ref(1);
    const sort = ref(null);
    const tableFields = ref([{
      key: "problem",
      label: ""
    }, {
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "FIO",
      label: "Работник"
    }, {
      key: "login",
      label: "Логин",
      class: "user-login-list"
    }, {
      key: "organization_name",
      label: "Организация"
    }, {
      key: "position",
      label: "Должность"
    }, {
      key: "created_at",
      label: "Дата создания",
      formatter: value => formatDate(value, fmtDateMonthTime),
      tdAttr: value => ({
        "data-created": formatDate(value, fmtDateShort)
      }),
      class: "text-nowrap user-date-list"
    }, {
      key: "state",
      label: "Статус",
      formatter: value => stateWorker[value],
      class: "user-state-list"
    }, {
      key: "btnChange",
      label: "",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: "",
      class: "text-center"
    }]);
    const breadcrumbs = ref([{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]);
    const settingsAdd = ref({
      title: "Создать пользователя",
      btnTitle: "Создать",
      buttonIcon: false,
      iconType: null,
      new: true
    });
    const settingsChange = ref({
      title: "Редактировать пользователя",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      new: false
    });
    async function loadData() {
      await store.dispatch("administration/updateAdminUsers", {
        page: currentPage.value,
        sort: sort.value,
        page_size: 20
      });
    }
    onMounted(async () => {
      await loadData();
      await store.dispatch("referenceBooks/updateOrganizations");
      await store.dispatch("administration/updateAdminGroups");
      await store.dispatch("administration/updateAdminRolesRef");
    });
    async function changePage(newPage) {
      currentPage.value = limitBound(Number(newPage), 1, adminUsersCount.value / perPage, currentPage.value);
      await loadData();
    }
    async function clickCreateUser(data) {
      await store.dispatch("administration/createAdminUser", data);
      currentPage.value = 1;
      await loadData();
    }
    async function clickRemove(item) {
      currentItem.value = item;
      showModalRemove.value = true;
    }
    async function doRemove() {
      await store.dispatch("administration/removeAdminUser", currentItem.value);
      currentPage.value = 1;
      await loadData();
    }
    async function clickChange(data) {
      await store.dispatch("administration/changeAdminUser", data);
      await loadData();
    }
    async function updatePassword(data) {
      await store.dispatch("administration/changePasswordUser", data);
      await loadData();
    }
    async function onSearch(search) {
      await store.dispatch("administration/updateAdminUsers", {
        page: currentPage.value,
        sort: sort.value,
        page_size: 20,
        search_string: search
      });
    }
    const adminUsers = computed(() => store.getters["administration/adminUsers"]);
    const adminUsersLoading = computed(() => store.getters["administration/adminUsersLoading"]);
    const adminUsersCount = computed(() => store.getters["administration/adminUsersCount"]);
    const organizationsLoading = computed(() => store.getters["referenceBooks/organizationsLoading"]);
    const adminGroupsLoading = computed(() => store.getters["administration/adminGroupsLoading"]);
    const adminRolesRefLoading = computed(() => store.getters["administration/adminRolesRefLoading"]);
    const templateAdd = computed(() => ({
      organization: getOrgId(),
      organization_name: getOrgName()
    }));
    return (_ctx, _cache) => {
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_card = _resolveComponent("b-card");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs.value
      }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
        class: "mb-4"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("h1", null, "Пользователи", -1)])),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(AdminUsersAdd, {
            idModal: "createNewUser",
            settings: settingsAdd.value,
            onUpdateUser: clickCreateUser,
            item: templateAdd.value
          }, null, 8, ["settings", "item"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0 shadow-sm py-2"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_row, {
          class: "d-flex flex-wrap m-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "6",
            md: "6",
            lg: "2",
            xl: "2"
          }, {
            default: _withCtx(() => [_createVNode(_component_CustomSearch, {
              onOnSearch: onSearch
            })]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(_component_b_table, {
          class: "table-custom",
          responsive: "",
          hover: "",
          small: "",
          "show-empty": "",
          stacked: "md",
          items: adminUsers.value,
          fields: tableFields.value,
          busy: adminUsersLoading.value || organizationsLoading.value || adminGroupsLoading.value || adminRolesRefLoading.value
        }, {
          "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
          empty: _withCtx(() => [_createVNode(_component_b_alert, {
            show: "",
            variant: "light",
            class: "text-center"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
              src: _imports_0,
              alt: "empty",
              class: "mb-4"
            }, null, -1), _createElementVNode("h4", null, "Пользователей нет", -1)])),
            _: 1
          })]),
          "cell(problem)": _withCtx(row => [_withDirectives(_createElementVNode("i", _hoisted_3, null, 512), [[_vShow, !row.item.is_safe_email]])]),
          "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
          "cell(FIO)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatName)(row.item, _unref(fmtNameShort))), 1)]),
          "cell(btnChange)": _withCtx(row => [_createVNode(AdminUsersAdd, {
            idModal: 'changeUser' + row.item.id,
            settings: settingsChange.value,
            item: row.item,
            onUpdateUser: clickChange,
            onUpdatePassword: updatePassword,
            title: "Редактировать"
          }, null, 8, ["idModal", "settings", "item"])]),
          "cell(btnRemove)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            class: "p-0",
            variant: "link",
            onClick: $event => clickRemove(row.item),
            "data-hook": "iconRemoveUser",
            title: "Удалить"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
              class: "bi bi-trash"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.delete_admin_user]])]),
          _: 1
        }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_b_row, {
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "auto"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
              totalRows: adminUsersCount.value,
              onOnChangePage: changePage
            }, null, 8, ["totalRows"]), [[_vShow, adminUsersCount.value > perPage]])]),
            _: 1
          })]),
          _: 1
        })])]),
        _: 1
      }), _createVNode(_component_b_modal, {
        title: 'Удалить пользователя ' + currentItem.value.login + ' ?',
        modelValue: showModalRemove.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => showModalRemove.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Удалить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doRemove
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};