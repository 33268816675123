import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "health"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, {
    class: "mb-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "24. Относитесь ли Вы к льготной категории граждан (ветеран,\n            мать-одиночка и т.д.)?"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          class: "w-auto mt-2",
          modelValue: _ctx.profileObj.has_privileges,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_privileges = $event),
          options: _ctx.optionYesNo,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('has_privileges')),
          state: _ctx.has_privileges_state,
          disabled: $options.readonly
        }, null, 8, ["modelValue", "options", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[4] || (_cache[4] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Вид льготной категории")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.privileges, (privilege, index) => {
          return _openBlock(), _createElementBlock("tr", {
            key: index
          }, [_createElementVNode("td", null, [_createVNode(_component_b_form_input, {
            modelValue: privilege.category_kind,
            "onUpdate:modelValue": $event => privilege.category_kind = $event,
            onChange: $event => $options.submitDataEndpoint(privilege, 'category_kind'),
            disabled: $options.readonly,
            state: privilege.category_kind_state,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled", "state"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.privileges, privilege.id)
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_2, [_createElementVNode("tr", null, [_createElementVNode("td", null, [_createVNode(_component_AddRow, {
          onClicked: _cache[2] || (_cache[2] = $event => $options.addDataEndpoint(_ctx.privileges))
        })])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}