import { formatDate, fmtDateTime } from "@/helpers/date";
import { mapStateToText, endptProfile1c } from "@/helpers/profile";
import { formatName, limitBound, fmtNameShort } from "@/helpers/text";
import { workerToStates, stateWorker } from "@/helpers/states";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification.vue";
import ProfileListAction from "./ProfileListAction.vue";
import { getUrl } from "@/helpers/fetch";
export default {
  name: "ProfileList",
  data: () => ({
    showModalClear: false,
    fmtNameShort,
    mapStateToText,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }],
    columns: [{
      key: "problem",
      label: ""
    }, {
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "author",
      label: "Автор",
      class: "text-nowrap"
    }, {
      key: "created_at",
      label: "Дата создания",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateTime)
    }, {
      key: "submitted_at",
      label: "Дата трудоустройства",
      class: "text-nowrap",
      formatter: value => formatDate(value, fmtDateTime)
    }, {
      key: "state",
      label: "Статус",
      class: "text-nowrap",
      formatter: value => mapStateToText[value]
    }, {
      key: "action",
      class: "text-center",
      label: "Действие"
    }, {
      key: "file1c",
      class: "text-center",
      label: "Файл"
    }, {
      key: "package41c",
      class: "text-center",
      label: "Пакет"
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification,
    ProfileListAction
  },
  created() {
    const params = {
      page: 1
    };
    if (this.onlyNew) params.is_new = true;
    this.updateProfilesKadr(params);
    this.updateAdminGroups();
    this.updateDepartments();
    this.updateAdminRolesRef();
    this.setOnlyNew(null); // обнулимся
  },
  methods: {
    ...mapActions("profiles", ["updateProfilesKadr", "updateProfileState", "updateProfilesIds1c"]),
    ...mapActions("administration", ["updateAdminGroups", "stateToAdminUser", "changeAdminUser", "updateAdminRolesRef"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    ...mapActions("homeStats", ["setOnlyNew"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.profilesKadrCount / this.perPage, this.currentPage);
      this.updateProfilesKadr({
        page: this.currentPage
      });
    },
    async validateProfile(id) {
      await this.updateProfileState({
        id: id,
        state: "validated",
        msg: `Личный листок #${id} отправлен на проверку`
      });
    },
    async approveProfile(data) {
      const name = this.formatName(data.item, fmtNameShort);
      // сменить статус ЛЛ
      await this.updateProfileState({
        id: data.item.id,
        state: "approved",
        msg: `Личный листок #${data.item.id} одобрен`
      });
      // перевести пользователя из кандидата в работники
      if (stateWorker[data.item.user_state] === stateWorker.candidate) {
        await this.stateToAdminUser({
          id: data.item.user,
          stateTo: workerToStates.toWorker,
          msg: name + " переведен в работники"
        });
      }
      // обновить параметры пользователя (роль, должность, ТН, подразделение)
      await this.changeAdminUser({
        params: data.params,
        user: data.item.user,
        name: name
      });
      // обновить список ЛЛ
      await this.updateProfilesKadr({
        page: this.currentPage
      });
    },
    async actionReject(data) {
      await this.updateProfileState({
        id: data,
        state: "declined",
        msg: `Личный листок #${data} отклонен`
      });
      await this.updateProfilesKadr({
        page: this.currentPage
      });
    },
    // добавить/удалить ЛЛ в пакет выгрузки
    setPackage(id, flag) {
      this.updateProfilesIds1c({
        id,
        flag
      });
    },
    // очистить пакет выгрузки
    clearPackage() {
      this.showModalClear = true;
    },
    doClear() {
      this.updateProfilesIds1c({
        id: null,
        flag: false,
        clear: true
      });
    },
    // сформировать файл выгрузки в 1С
    async unloadPackage() {
      this.$refs.profile41c.click();
    }
  },
  computed: {
    ...mapGetters("profiles", ["profilesKadr", "profilesKadrCount", "profilesKadrLoading", "profilesIds1c", "profilesIds1cCount"]),
    ...mapGetters("administration", ["adminGroupsLoading", "adminRolesRefLoading"]),
    ...mapGetters("referenceBooks", ["departmentsLoading"]),
    ...mapGetters("homeStats", ["onlyNew"]),
    file41c() {
      return this.profilesIds1cCount ? getUrl(endptProfile1c, {
        profile_id: [...this.profilesIds1c].join(",")
      }) : "#";
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateProfilesKadr({
          page: value
        });
      }
    }
  }
};