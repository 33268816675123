import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_ActivityListDetails = _resolveComponent("ActivityListDetails");
  const _component_b_table = _resolveComponent("b-table");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_card_header, {
    "header-class": "py-0 m-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_row, {
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
          "total-rows": _ctx.activityCount,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.activityCount > _ctx.perPage]])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        }, 512), [[_vShow, _ctx.activityCount > _ctx.perPage]])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_table, {
    class: "table-custom",
    hover: "",
    small: "",
    responsive: "",
    "show-empty": "",
    stacked: "md",
    items: _ctx.activity,
    fields: _ctx.tableFields
  }, {
    empty: _withCtx(() => [_createVNode(_component_b_alert, {
      show: "",
      variant: "light",
      class: "text-center"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
        src: _imports_0,
        alt: "empty",
        class: "mb-4"
      }, null, -1), _createElementVNode("h4", null, "Мероприятий нет", -1)])),
      _: 1
    })]),
    "cell(recommendation)": _withCtx(row => [_createElementVNode("span", {
      class: "link inner p-0",
      onClick: row.toggleDetails
    }, _toDisplayString(row.value), 9, _hoisted_1)]),
    "row-details": _withCtx(row => [_createVNode(_component_ActivityListDetails, {
      item: {
        ...row.item.details,
        id: row.item.id
      },
      onAddDetails: $options.addDetails
    }, null, 8, ["item", "onAddDetails"])]),
    _: 1
  }, 8, ["items", "fields"])]);
}