import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.every.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
//import SignPEP from "@/components/Helpers/SignPEP.vue";
import theNotification from "@/components/theNotification";
import EducationCourseItem from "./EducationCourseItem.vue";
import CoursePreview from "./CoursePreview.vue";
import { mapActions, mapGetters } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { statesEducUser, statesTestUser } from "@/helpers/states";
import { signTypes } from "@/helpers/text";
import links from "@/router/links";
export default {
  name: "EducationCourse",
  components: {
    BreadCrumbToHome,
    theNotification,
    EducationCourseItem,
    CoursePreview
    //SignPEP,
  },
  props: {
    msg: String
  },
  //сюда получаем id назначения курса

  // в момент создания компоненты запросим данные
  async created() {
    await this.updateCourse4User(this.msg);
    this.updateProgress();
  },
  data: () => ({
    showModal: false,
    links,
    signTypes,
    fmtDateShort,
    currentSchemeItem: 0,
    statesEducUser,
    statesTestUser,
    breadcrumbs: [{
      text: "Личный кабинет",
      to: {
        name: "ProfileEducation"
      }
    }],
    testFields: [{
      key: "attempt_num",
      label: "Попытка"
    }, {
      key: "state",
      label: "Статус"
    }]
  }),
  methods: {
    ...mapActions("education", ["updateCourse4User", "actionCourse4User", "createNewTest4user", "changeCourse4User"]),
    formatDate,
    // показать информацию о тестировании
    clickTest() {
      this.showModal = true;
    },
    // создать новое тестирование
    async clickNewTest() {
      await this.createNewTest4user({
        assignment: this.msg
      });
      this.$router.push(links.educationCourseTest + JSON.stringify({
        assignment: this.msg,
        attempt: this.educationTest.id
      }));
    },
    // обновить прогресс в бэке
    updateProgress() {
      if (this.currentProgress > Number(this.getCourse.assignment.percent)) {
        this.changeCourse4User({
          id: this.msg,
          body: {
            percent: this.currentProgress
          }
        });
      }
    },
    // нажали на перечень тем из materials
    onClickScheme(index) {
      this.currentSchemeItem = index;
      this.updateProgress();
    },
    // изменение currentSchemeItem из дочерней компоненты
    changeCurrent(num) {
      if (this.currentSchemeItem + num > -1) {
        this.currentSchemeItem += num;
      }
      this.updateProgress();
    },
    // начать обучение
    async clickStart() {
      await this.actionCourse4User({
        id: this.msg,
        state: "started",
        title: "начато"
      });
      await this.updateCourse4User(this.msg);
    },
    uploadCertificate() {
      this.$refs.fileInput.click();
    },
    async handlerAdd(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const formData = new FormData();
      formData.append("certificate", files[0]);
      await this.changeCourse4User({
        id: this.msg,
        body: formData,
        fileUpload: true,
        msg: "Сертификат загружен"
      });
      this.$refs.fileInput.value = null;
      await this.updateCourse4User(this.msg);
    }

    // завершить обучение
    // async clickFinish() {
    //   const isConfirmed = await this.$bvModal.msgBoxConfirm(
    //     "Пройти обучение еще раз не получится. Вы хотите завершить обучение?",
    //     {
    //       buttonSize: "sm",
    //       okVariant: "primary",
    //       cancelVariant: "outline-primary",
    //       okTitle: "Завершить",
    //       cancelTitle: "Отменить",
    //       footerClass: "p-2",
    //       hideHeaderClose: true,
    //       centered: true,
    //     }
    //   );
    //   if (isConfirmed) this.$refs.btnPEP.handlerClick();
    // },
  },
  computed: {
    ...mapGetters("education", ["getCourse", "courseLoading", "educationTest", "educationTestLoading"]),
    progress() {
      return Math.round(this.getCourse.assignment.percent * 100);
    },
    // от 0 до 1
    currentProgress() {
      return this.getCourse.materials.length === 1 ? 1 : Math.round(this.currentSchemeItem / (this.getCourse.materials.length - 1) * 100) / 100;
    },
    showBtnStartTest() {
      return this.getCourse.assignment.test_detail.length < this.getCourse.assignment.course_max_attempts && this.getCourse.assignment.test_detail.every(el => statesTestUser[el.state] !== statesTestUser.started);
    }
  }
};