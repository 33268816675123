import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import { formatDate } from "@/helpers/date";
import { mapActions, mapGetters } from "vuex";
import { relativeDegree, militaryStatusDictionary, registrationType, mapMaritalStatusToText } from "@/helpers/profile";
import { switchText } from "@/helpers/text.js";
import { getOrgName } from "@/auth/auth";
import { stateWorker } from "@/helpers/states";
import { snilsMask, phoneMask } from "@/helpers/masks";
export default {
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  name: "ProfilePrint",
  async created() {
    const {
      profileId,
      myProfile
    } = JSON.parse(this.msg);
    await this.fetchProfile({
      id: profileId,
      myProfile
    });
    if (Array.isArray(this.profileObj.addresses)) {
      this.address_registration = this.profileObj.addresses.find(address => address.is_registration);
      this.address_actual = this.profileObj.addresses.find(address => address.is_actual);
    }
  },
  computed: {
    ...mapGetters("profile", ["profileStatus", "profileObj", "profileLoading"]),
    orgName() {
      return getOrgName();
    }
  },
  data: () => ({
    phoneMask,
    snilsMask,
    mapMaritalStatusToText,
    stateWorker,
    militaryStatusDictionary,
    address_registration: {},
    address_actual: {},
    now: new Date(),
    registrationType,
    relativeDegree
  }),
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    switchText,
    formatDate
  }
};