import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import TaskButton from "@/components/Declaration/Tasks/TaskButton.vue";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
import { statesCp } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort } from "@/helpers/text";
import { btn4transitCp } from "@/helpers/cp";
import { validateObject } from "@/helpers/validate";
export default {
  name: "CPFormHeader",
  data: () => ({
    statesCp,
    fmtDateShort,
    links,
    fmtNameShort,
    showAlert: false,
    alertTitle: ""
  }),
  components: {
    TaskButton
  },
  methods: {
    ...mapActions("cp", ["changeStateCp", "updateCpAttach", "updateCpComments", "createCpAttach", "createCpComments", "changeCPForm", "updateCpEvents"]),
    formatDate,
    formatName,
    async clickAction(btn, data) {
      this.showAlert = false;
      // сначала проверка на обязательные поля
      const errors = [];
      if (btn.validate) validateObject(this.cpForm, btn.validate, errors);
      if (errors.length) {
        this.alertTitle = errors.join(", ");
        this.showAlert = true;
        return;
      }
      if (btn.type === "taskBtn") {
        // change cp
        if (btn.settings && (btn.settings.addAssignee || btn.settings.addDeadline)) {
          const params = {};
          if (btn.settings.addAssignee) params.assignee = data.worker;
          if (btn.settings.addDeadline) params.date_planned = data.deadline;
          await this.changeCPForm({
            id: this.cpForm.id,
            params
          });
        }
        // add comment
        if (data.comment.trim() !== "") {
          await this.createCpComments({
            id: this.cpForm.id,
            body: data.comment.trim()
          });
          this.updateCpComments({
            id: this.cpForm.id,
            page: 1
          });
        }
        // add file
        if (data.files.length > 0) {
          for (const file of data.files) {
            const formData = new FormData();
            formData.append("document", file);
            formData.append("file_name", file.name);
            await this.createCpAttach({
              id: this.cpForm.id,
              formData
            });
          }
          this.updateCpAttach({
            id: this.cpForm.id,
            page: 1
          });
        }
      }
      // смена статуса
      await this.changeStateCp({
        id: this.cpForm.id,
        msg: btn.msg,
        state: btn.stateTo
      });
      this.updateCpEvents({
        cp_id: this.cpForm.id,
        page: 1
      });
    }
  },
  computed: {
    ...mapGetters("cp", ["cpForm"]),
    targetUsers() {
      return this.cpForm.target_users.map(x => `${formatName(x, fmtNameShort)} (${x.worker_code})`).join(", ");
    },
    stateBtns() {
      return btn4transitCp.filter(el => el.stateFrom === this.cpForm.state);
    }
  }
};