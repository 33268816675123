import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "table-header"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "table-footer"
};
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { formatName, limitBound, NONAME_DOTS, fmtNameShort } from "@/helpers/text";
import TrafficLight from "@/components/Helpers/TrafficLight.vue";
import InnerCheckListDetails from "./InnerCheckListDetails.vue";
import links from "@/router/links";
import { statesInnerCheck } from "@/helpers/states";
const perPage = 20;
export default {
  __name: 'InnerCheckList',
  setup(__props) {
    const store = useStore();
    const currentPage = ref(1);
    const showModalRemove = ref(false);
    const currentItem = ref({});
    const tableFields = [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "date_planned",
      label: "Дата завершения (план)",
      class: "text-nowrap"
    }, {
      key: "violation_detected",
      label: "Результат проверки",
      class: "text-center"
    }, {
      key: "assignee_detail",
      label: "Исполнитель",
      class: "text-nowrap"
    }, {
      key: "state",
      label: "Статус"
    }, {
      key: "date_actual",
      label: "Дата завершения (факт)",
      class: "text-nowrap"
    }, {
      key: "date_confirmed",
      label: "Дата утверждения",
      class: "text-nowrap"
    }, {
      key: "toggleDetailsState",
      label: ""
    }, {
      key: "btnRemove",
      label: "",
      class: "p-0"
    }];
    const innerCheck = computed(() => store.getters["innerCheck/innerCheck"]);
    const innerCheckCount = computed(() => store.getters["innerCheck/innerCheckCount"]);
    const innerCheckLoading = computed(() => store.getters["innerCheck/innerCheckLoading"]);
    async function changePage(newPage) {
      currentPage.value = limitBound(Number(newPage), 1, innerCheckCount.value / perPage, currentPage.value);
      await loadData();
    }
    async function clickRemove(item) {
      currentItem.value = item;
      showModalRemove.value = true;
    }
    const doRemove = async () => {
      await store.dispatch("innerCheck/removeInnerCheck", currentItem.value);
      currentPage.value = 1;
      await loadData();
    };
    async function loadData() {
      store.dispatch("innerCheck/updateInnerCheck", {
        page: currentPage.value,
        pageSize: perPage
      });
    }
    function searchChanged(newSearch) {
      currentPage.value = 1;
      store.dispatch("innerCheck/updateInnerCheck", {
        page: currentPage.value,
        sort: null,
        pageSize: perPage,
        searchString: newSearch
      });
    }
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_link = _resolveComponent("b-link");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSearch, {
            onOnSearch: searchChanged
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        small: "",
        responsive: "",
        stacked: "md",
        items: innerCheck.value,
        fields: tableFields,
        busy: innerCheckLoading.value,
        "show-empty": ""
      }, {
        "cell(violation_detected)": _withCtx(row => [_createVNode(TrafficLight, {
          typeLight: row.value ? 'danger' : row.value === null ? row.value : 'success'
        }, null, 8, ["typeLight"])]),
        "cell(assignee_detail)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatName)(row.value, _unref(fmtNameShort))), 1)]),
        "cell(state)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(statesInnerCheck)[row.value]), 1)]),
        "cell(date_planned)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort), _unref(NONAME_DOTS))), 1)]),
        "cell(date_actual)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort), _unref(NONAME_DOTS))), 1)]),
        "cell(date_confirmed)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatDate)(row.value, _unref(fmtDateShort), _unref(NONAME_DOTS))), 1)]),
        "cell()": _withCtx(row => [_createVNode(_component_b_link, {
          class: _normalizeClass({
            'font-weight-bold': row.item.is_new
          }),
          to: _unref(links).innerCheckForm + row.item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
          _: 2
        }, 1032, ["class", "to"])]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
        "cell(toggleDetailsState)": _withCtx(row => [_createElementVNode("i", {
          class: _normalizeClass(["bi text-primary", row.detailsShowing ? 'bi-chevron-up' : 'bi-chevron-down']),
          role: "button",
          onClick: row.toggleDetails
        }, null, 10, _hoisted_3)]),
        "row-details": _withCtx(row => [_createVNode(InnerCheckListDetails, {
          item: row.item
        }, null, 8, ["item"])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
          _: 1
        })]),
        "cell(btnRemove)": _withCtx(row => [_unref(statesInnerCheck)[row.item.state] === _unref(statesInnerCheck).draft ? _withDirectives((_openBlock(), _createElementBlock("i", {
          key: 0,
          role: "button",
          class: "bi bi-trash",
          onClick: $event => clickRemove(row.item)
        }, null, 8, _hoisted_4)), [[_directive_can, _ctx.$perm.delete_wa_innercheck]]) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["items", "busy"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_b_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            totalRows: innerCheckCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, innerCheckCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_modal, {
        title: `Удалить внутреннюю проверку ${currentItem.value.id} ?`,
        modelValue: showModalRemove.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => showModalRemove.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Удалить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doRemove
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};