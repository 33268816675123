import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "input-group"
};
const _hoisted_2 = ["value"];
const _hoisted_3 = ["value"];
import { ref } from "vue";
export default {
  __name: 'CustomSort',
  props: {
    optionsSort: {
      type: Array,
      required: true
    },
    optionsDesc: {
      type: Array,
      required: false,
      default: () => [{
        value: false,
        text: "по возрастанию"
      }, {
        value: true,
        text: "по убыванию"
      }]
    }
  },
  emits: ["onSort"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const sort = ref(null);
    const isDescending = ref(true);
    function updateSorting() {
      emit("onSort", {
        sortBy: sort.value,
        sortDesc: isDescending.value
      });
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[3] || (_cache[3] = _createElementVNode("span", {
        class: "input-group-prepend"
      }, "Сортировать", -1)), _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => sort.value = $event),
        onChange: updateSorting,
        class: "form-select"
      }, [_cache[2] || (_cache[2] = _createElementVNode("option", {
        value: null,
        disabled: ""
      }, "Не выбрано", -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.optionsSort, option => {
        return _openBlock(), _createElementBlock("option", {
          key: option.value,
          value: option.value
        }, _toDisplayString(option.text), 9, _hoisted_2);
      }), 128))], 544), [[_vModelSelect, sort.value]]), _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => isDescending.value = $event),
        onChange: updateSorting,
        class: "form-select"
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.optionsDesc, option => {
        return _openBlock(), _createElementBlock("option", {
          key: option.value,
          value: option.value
        }, _toDisplayString(option.text), 9, _hoisted_3);
      }), 128))], 544), [[_vModelSelect, isDescending.value]])]);
    };
  }
};