import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 2,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_button = _resolveComponent("b-button");
  const _component_ReportCount = _resolveComponent("ReportCount");
  const _component_ReportRisk = _resolveComponent("ReportRisk");
  const _component_b_spinner = _resolveComponent("b-spinner");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Вид отчёта"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.typeSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.typeSelected = $event),
          options: _ctx.typeOptions
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата начала",
        date: _ctx.startDate,
        classCustom: "mt-2",
        onChangeValue: _cache[1] || (_cache[1] = $event => _ctx.startDate = $event)
      }, null, 8, ["date"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "4"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата окончания",
        date: _ctx.endDate,
        classCustom: "mt-2",
        onChangeValue: _cache[2] || (_cache[2] = $event => _ctx.endDate = $event)
      }, null, 8, ["date"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "start"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-primary",
        onClick: $options.clickReport,
        disabled: _ctx.partnerReportLoading
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Сформировать отчёт")])),
        _: 1
      }, 8, ["onClick", "disabled"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-primary",
        onClick: $options.clickDownload,
        disabled: _ctx.partnerReportLoading
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Скачать файл")])),
        _: 1
      }, 8, ["onClick", "disabled"])]),
      _: 1
    })]),
    _: 1
  }), _ctx.selected.report_type === 'report_num_bp' && !_ctx.partnerReportLoading && _ctx.showReport ? (_openBlock(), _createBlock(_component_ReportCount, {
    key: 0,
    class: "mt-4"
  })) : _ctx.selected.report_type === 'report_risk_level_by_bp' && !_ctx.partnerReportLoading && _ctx.showReport ? (_openBlock(), _createBlock(_component_ReportRisk, {
    key: 1,
    filters: _ctx.selected,
    class: "mt-4"
  }, null, 8, ["filters"])) : _createCommentVNode("", true), _ctx.partnerReportLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createElementVNode("a", {
    href: _ctx.partnerReportFile,
    ref: "fileDownload"
  }, null, 8, _hoisted_2)]);
}