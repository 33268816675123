import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "table-header"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  key: 0,
  class: "text-primary"
};
const _hoisted_4 = {
  key: 2,
  class: "pt-2 text-success"
};
const _hoisted_5 = {
  class: "table-footer"
};
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { formatDate, fmtDateShort } from "@/helpers/date";
import links from "@/router/links";
import { statesPartners } from "@/helpers/states";
import { limitBound, CONST_NONAME, formatName, fmtNameShort, typeRiskLevel } from "@/helpers/text";
import { checkPermissions } from "@/auth/auth.js";
export default {
  __name: 'PartnersList',
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    const permissions = ref({});
    const currentPage = ref(1);
    const perPage = ref(20);
    const currentItem = ref({});
    const showModalRemove = ref(false);
    const showModalAutocheck = ref(false);
    const partners = computed(() => store.getters["partners/partners"]);
    const partnersCount = computed(() => store.getters["partners/partnersCount"]);
    const partnersLoading = computed(() => store.getters["partners/partnersLoading"]);
    const autochecksEntity = computed(() => store.getters["administration2/autochecksEntity"]);
    const autochecksEntityLoading = computed(() => store.getters["administration2/autochecksEntityLoading"]);
    const sortBy = computed(() => {
      return isAdminPath.value ? {
        sort: "full_name"
      } : {};
    });
    const isAdminPath = computed(() => router.currentRoute.value.path === links.adminPartners);
    function autoCheckLabel() {
      return checkPermissions(permissions.value.trigger_wa_autocheck) || checkPermissions(permissions.value.view_wa_autocheck) ? "Автосверка" : "";
    }
    const tableFields = computed(() => {
      return isAdminPath.value ? [{
        key: "index",
        label: "№ п/п"
      }, {
        key: "id",
        label: "UID"
      }, {
        key: "full_name",
        label: "Наименование"
      }, {
        key: "contract_num",
        label: "Номер договора",
        class: "text-nowrap"
      }, {
        key: "contract_date",
        label: "Дата договора",
        class: "text-nowrap",
        formatter: value => formatDate(value, fmtDateShort)
      }, {
        key: "resulting_risk_level",
        label: "Уровень риска",
        class: "text-center"
      }] : [{
        key: "index",
        label: "№ п/п"
      }, {
        key: "id",
        label: "UID"
      }, {
        key: "full_name",
        label: "Наименование"
      }, {
        key: "contract_num",
        label: "Номер договора",
        class: "text-nowrap"
      }, {
        key: "contract_date",
        label: "Дата договора",
        class: "text-nowrap",
        formatter: value => formatDate(value, fmtDateShort)
      }, {
        key: "user_detail",
        label: "Автор",
        formatter: value => {
          return formatName(value, fmtNameShort);
        }
      }, {
        key: "state",
        label: "Статус",
        class: "text-nowrap",
        formatter: value => statesPartners[value]
      }, {
        key: "checks",
        label: "Результат проверки",
        class: "text-nowrap"
      }, {
        key: "resulting_risk_level",
        label: "Уровень риска",
        class: "text-center"
      }, {
        key: "btnRemove",
        label: "",
        class: "p-0"
      }, {
        key: "autochecks",
        label: autoCheckLabel(),
        class: "text-center p-0"
      }];
    });
    onMounted(async () => {
      const instance = getCurrentInstance();
      permissions.value = instance?.appContext.config.globalProperties.$perm;
      await store.dispatch("partners/updatePartners", {
        ...sortBy.value,
        page: currentPage.value,
        page_size: 20
      });
    });
    async function changePage(newPage) {
      currentPage.value = limitBound(Number(newPage), 1, partnersCount.value / perPage.value, currentPage.value);
      await store.dispatch("partners/updatePartners", {
        ...sortBy.value,
        page: currentPage.value,
        page_size: 20
      });
    }
    async function clickRemove(item) {
      currentItem.value = item;
      showModalRemove.value = true;
    }
    async function doRemove() {
      await store.dispatch("partners/removePartner", currentItem.value);
      currentPage.value = 1;
      await store.dispatch("partners/updatePartners", {
        ...sortBy.value,
        page: 1,
        page_size: 20
      });
    }
    async function clickAutocheck(item) {
      currentItem.value = item;
      showModalAutocheck.value = true;
    }
    async function doAutocheck() {
      await store.dispatch("administration2/postAutochecksEntity", {
        endpoint: "business-partners",
        id: currentItem.value.id
      });
      if (autochecksEntity.value.id !== undefined) router.push({
        name: "AutocheckForm",
        params: {
          msg: autochecksEntity.value.id
        },
        query: {
          type: "bp_only"
        }
      });else await store.dispatch("partners/updatePartners", {
        ...sortBy.value,
        page: currentPage.value,
        page_size: 20
      });
    }
    async function onSearch(searchString) {
      currentPage.value = 1;
      await store.dispatch("partners/updatePartners", {
        page: 1,
        page_size: 20,
        search_string: searchString
      });
    }
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_link = _resolveComponent("b-link");
      const _component_TrafficLight = _resolveComponent("TrafficLight");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_modal = _resolveComponent("b-modal");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_CustomSearch, {
        onOnSearch: onSearch
      })]), _createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        small: "",
        responsive: "",
        stacked: "md",
        busy: partnersLoading.value,
        items: partners.value,
        fields: tableFields.value,
        "show-empty": ""
      }, {
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage.value + row.index + 1), 1)]),
        "cell(full_name)": _withCtx(row => [_createVNode(_component_b_link, {
          to: (isAdminPath.value ? _unref(links).adminPartnersForm : _unref(links).partnersForm) + row.item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value || _unref(CONST_NONAME)), 1)]),
          _: 2
        }, 1032, ["to"])]),
        "cell(checks)": _withCtx(row => [_createVNode(_component_TrafficLight, {
          typeLight: _unref(typeRiskLevel)(row.item.check_compliance.risk_level),
          class: "me-3",
          tooltip: {
            target: row.item.check_compliance.dept_name
          }
        }, null, 8, ["typeLight", "tooltip"]), _createVNode(_component_TrafficLight, {
          typeLight: _unref(typeRiskLevel)(row.item.check_security.risk_level),
          class: "me-3",
          tooltip: {
            target: row.item.check_security.dept_name
          }
        }, null, 8, ["typeLight", "tooltip"]), _createVNode(_component_TrafficLight, {
          typeLight: _unref(typeRiskLevel)(row.item.check_law.risk_level),
          class: "me-3",
          tooltip: {
            target: row.item.check_law.dept_name
          }
        }, null, 8, ["typeLight", "tooltip"]), _createVNode(_component_TrafficLight, {
          typeLight: _unref(typeRiskLevel)(row.item.check_finance.risk_level),
          tooltip: {
            target: row.item.check_finance.dept_name
          }
        }, null, 8, ["typeLight", "tooltip"])]),
        "cell(resulting_risk_level)": _withCtx(row => [_createVNode(_component_TrafficLight, {
          typeLight: _unref(typeRiskLevel)(row.item.resulting_risk_level)
        }, null, 8, ["typeLight"])]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
          _: 1
        })]),
        "cell(btnRemove)": _withCtx(row => [_unref(statesPartners)[row.item.state] === _unref(statesPartners).draft ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          variant: "link",
          onClick: $event => clickRemove(row.item)
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
            class: "bi bi-trash"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.delete_wa_bp]]) : _createCommentVNode("", true)]),
        "cell(autochecks)": _withCtx(row => [autochecksEntityLoading.value ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createVNode(_component_b_spinner)])) : !row.item.has_autochecks ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 1,
          variant: "link",
          onClick: $event => clickAutocheck(row.item)
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
            class: "bi bi-repeat"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.trigger_wa_autocheck]]) : row.item.has_autochecks ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, _cache[6] || (_cache[6] = [_createElementVNode("i", {
          class: "bi bi-check-all"
        }, null, -1)]))), [[_directive_can, _ctx.$perm.view_wa_autocheck]]) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["busy", "items", "fields"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_b_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            totalRows: partnersCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, partnersCount.value > perPage.value]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_modal, {
        title: `Удалить партнера ${currentItem.value.full_name || _unref(CONST_NONAME)} ?`,
        modelValue: showModalRemove.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => showModalRemove.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Удалить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doRemove
      }, null, 8, ["title", "modelValue"]), _createVNode(_component_b_modal, {
        title: `Выполнить автосверку партнера ${currentItem.value.full_name || _unref(CONST_NONAME)} ?`,
        modelValue: showModalAutocheck.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => showModalAutocheck.value = $event),
        "ok-variant": "outline-primary",
        "ok-title": "Выполнить",
        "cancel-variant": "outline-secondary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doAutocheck
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};