import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_row = _resolveComponent("b-row");
  const _component_router_link = _resolveComponent("router-link");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    label: "Наименование ЛНА"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.knowlegeDocument.title,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.knowlegeDocument.title = $event),
      onChange: _cache[1] || (_cache[1] = $event => $options.changeData('title')),
      disabled: $options.readOnly,
      trim: ""
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Номер документа"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.knowlegeDocument.document_number,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.knowlegeDocument.document_number = $event),
          onChange: _cache[3] || (_cache[3] = $event => $options.changeData('document_number')),
          disabled: $options.readOnly,
          trim: ""
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата документа",
        date: _ctx.knowlegeDocument.documentDate,
        classCustom: "mt-2 no-gutters",
        disabled: $options.readOnly,
        onChangeValue: $options.changeDate
      }, null, 8, ["date", "disabled", "onChangeValue"])]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [!_ctx.lnaTypesLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Тип документа"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.knowlegeDocument.document_type,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.knowlegeDocument.document_type = $event),
          options: $options.optionsType,
          onChange: _cache[5] || (_cache[5] = $event => $options.changeData('document_type')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode(" Не выбран ")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_b_spinner, {
        key: 1,
        class: "align-middle text-center text-primary my-2"
      }))]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [!_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Владелец"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.knowlegeDocument.owner_department,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.knowlegeDocument.owner_department = $event),
          options: $options.optionsOwner,
          onChange: _cache[7] || (_cache[7] = $event => $options.changeData('owner_department')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode(" Не выбран ")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_b_spinner, {
        key: 1,
        class: "align-middle text-center text-primary my-2"
      }))]),
      _: 1
    })]),
    _: 1
  }), _ctx.knowlegeDocument.parent ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, {
    to: _ctx.links.knowledgeForm + _ctx.knowlegeDocument.parent,
    class: "text-info"
  }, {
    default: _withCtx(() => [_createTextVNode(" Предыдущая версия (документ # " + _toDisplayString(_ctx.knowlegeDocument.parent) + ") ", 1)]),
    _: 1
  }, 8, ["to"])])) : _createCommentVNode("", true), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_cache[20] || (_cache[20] = _createTextVNode(" Документ (файл) ")), _createVNode(_component_b_row, {
        class: "mt-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          class: "text-muted text-nowrap text-truncate file-name"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.knowlegeDocument.attachment), 1)]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            class: "p-0",
            variant: "link",
            title: "Скачать файл",
            href: _ctx.knowlegeDocument.attachment,
            download: decodeURI(_ctx.knowlegeDocument.attachment)
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [_createElementVNode("i", {
              class: "bi bi-download"
            }, null, -1)])),
            _: 1
          }, 8, ["href", "download"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            class: "p-0",
            variant: "link",
            title: "Загрузить файл",
            onClick: $options.clickAddFile,
            disabled: $options.readOnly
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [_createElementVNode("i", {
              class: "bi bi-file-earmark-plus"
            }, null, -1)])),
            _: 1
          }, 8, ["onClick", "disabled"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            class: "p-0",
            variant: "link",
            onClick: $options.handlerDelFile,
            disabled: !_ctx.knowlegeDocument.attachment || $options.readOnly
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [_createElementVNode("i", {
              class: "bi bi-trash"
            }, null, -1)])),
            _: 1
          }, 8, ["onClick", "disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Ссылка на видео"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.knowlegeDocument.video,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.knowlegeDocument.video = $event),
          onChange: _cache[9] || (_cache[9] = $event => $options.changeData('video')),
          disabled: $options.readOnly,
          trim: ""
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Основание ознакомления",
    class: "mb-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.knowlegeDocument.reason,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.knowlegeDocument.reason = $event),
      onChange: _cache[11] || (_cache[11] = $event => $options.changeData('reason')),
      trim: "",
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Формат ознакомления"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_checkbox_group, {
      modelValue: _ctx.knowlegeDocument.confirmation_ways,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.knowlegeDocument.confirmation_ways = $event),
      options: _ctx.knowledgeFormat,
      onChange: _cache[13] || (_cache[13] = $event => $options.changeData('confirmation_ways')),
      disabled: $options.readOnly || !$options.useAppoint
    }, null, 8, ["modelValue", "options", "disabled"])]),
    _: 1
  }), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[14] || (_cache[14] = (...args) => $options.handlerAddFile && $options.handlerAddFile(...args))
  }, null, 544)]);
}