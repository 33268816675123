import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import { profileReadonly, optionsOwnerLevel } from "@/helpers/profile";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { optionYesNo } from "@/helpers/text";
import { debounce } from "@/helpers/global";
export default {
  name: "theSeventeenth",
  components: {
    AddRow
  },
  data: () => ({
    optionYesNo,
    optionsOwnerLevel,
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    has_participation_entities_state: null,
    endpointOrganisations: "participation-entities",
    organisations: [],
    templateOrganisations: {
      inn: null,
      org_name: null,
      organization_form: null,
      address: null,
      share: null,
      participation_type: null
    },
    statesOrganisations: {
      inn_state: null,
      org_name_state: null,
      organization_form_state: null,
      address_state: null,
      share_state: null,
      participation_type_state: null
    },
    currentAddress: null
  }),
  created() {
    this.organisations = this.profileObj.participation_entities ? this.profileObj.participation_entities.map(org => ({
      ...org,
      ...this.statesOrganisations,
      share: org.share * 100
    })) : [];
    this.profileObjSaved.organisations = this.profileObj.participation_entities ? this.profileObj.participation_entities.reduce((acc, curr) => {
      curr.share = curr.share * 100;
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    ...mapGetters("administration2", ["dataAddress"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  },
  methods: {
    ...mapActions("profile", ["updateProfileData", "addProfileData", "delProfileData", "updateProfile"]),
    ...mapActions("administration2", ["updateDataAdress"]),
    submitData(key) {
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    },
    submitDataEndpoint(obj, key, savekey, endpoint) {
      const value = key === "share" ? obj[key] / 100 : obj[key];
      if (this.profileObjSaved[savekey][obj.id][key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: value
        },
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint(obj, savekey, endpoint, template, states) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = {
          ...template
        };
        obj.push({
          id: response.id,
          ...states,
          ...template
        });
      });
    },
    removeDataEndpoint(obj, id, savekey, endpoint) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved[savekey][id];
        obj.splice(index, 1);
      });
    },
    inputData(value) {
      this.currentAddress = value;
      debounce(this.updateDataAddress, 1000);
    },
    updateDataAddress() {
      this.updateDataAdress(this.currentAddress);
    }
  }
};