import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mb-2"
};
const _hoisted_2 = {
  class: "ms-2"
};
const _hoisted_3 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), !_ctx.riskFormLoading ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, "Карточка риска #" + _toDisplayString(_ctx.riskForm.id), 1), _createElementVNode("div", _hoisted_1, [_cache[12] || (_cache[12] = _createElementVNode("span", {
        class: "text-muted"
      }, "Статус", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.statesRisk[_ctx.riskForm.state]), 1)])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [!$options.readOnly ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: "primary",
        onClick: $options.clickPublished
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("Опубликовать риск ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_bp]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, null, {
    default: _withCtx(() => [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode("Владелец риска")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.riskForm.risk_owner,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.riskForm.risk_owner = $event),
          options: $options.departmentOptions,
          disabled: $options.readOnly,
          onChange: _cache[1] || (_cache[1] = $event => $options.changeData('risk_owner'))
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("Выберите подразделение ")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode("Вид деятельности")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "№"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.riskForm.activity_num,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.riskForm.activity_num = $event),
            placeholder: "Введите №",
            trim: "",
            disabled: $options.readOnly,
            onChange: _cache[3] || (_cache[3] = $event => $options.changeData('activity_num'))
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "8"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "Описание"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.riskForm.activity_description,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.riskForm.activity_description = $event),
            placeholder: "Введите описание вида деятельности",
            trim: "",
            disabled: $options.readOnly,
            onChange: _cache[5] || (_cache[5] = $event => $options.changeData('activity_description'))
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode("Риск")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "№"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.riskForm.risk_num,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.riskForm.risk_num = $event),
            placeholder: "Введите №",
            trim: "",
            disabled: $options.readOnly,
            onChange: _cache[7] || (_cache[7] = $event => $options.changeData('risk_num'))
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "8"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "Описание"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.riskForm.risk_description,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.riskForm.risk_description = $event),
            placeholder: "Введите описание риска",
            trim: "",
            disabled: $options.readOnly,
            onChange: _cache[9] || (_cache[9] = $event => $options.changeData('risk_description'))
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2"
      }, {
        default: _withCtx(() => _cache[18] || (_cache[18] = [_createTextVNode("Рисковые должности")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, null, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.riskForm.risk_positions,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.riskForm.risk_positions = $event),
            placeholder: "Введите должности",
            type: "text",
            disabled: $options.readOnly,
            onChange: _cache[11] || (_cache[11] = $event => $options.changeData('risk_positions'))
          }, null, 8, ["modelValue", "disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Загрузка...", -1))]))])), [[_directive_can, _ctx.$perm.change_wa_risk]]);
}