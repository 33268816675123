import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "row mb-3"
};
const _hoisted_2 = {
  class: "col"
};
const _hoisted_3 = {
  class: "col"
};
const _hoisted_4 = {
  class: "row mb-4"
};
const _hoisted_5 = {
  class: "col"
};
const _hoisted_6 = {
  class: "mt-3"
};
const _hoisted_7 = {
  class: "row mb-3"
};
const _hoisted_8 = {
  class: "col"
};
const _hoisted_9 = {
  class: "row mb-3"
};
const _hoisted_10 = {
  class: "col"
};
const _hoisted_11 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_12 = {
  key: 1,
  class: "mt-2 border border-gray rounded mx-0 p-2",
  style: {
    "border-style": "dashed !important"
  }
};
const _hoisted_13 = {
  class: "mt-2"
};
const _hoisted_14 = {
  key: 0,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_avatar = _resolveComponent("b-avatar");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_StringsWithX = _resolveComponent("StringsWithX");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_VMarkdownEditor = _resolveComponent("VMarkdownEditor");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_b_tooltip = _resolveDirective("b-tooltip");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _cache[35] || (_cache[35] = _createElementVNode("h1", {
    class: "mb-4"
  }, "Общие настройки", -1)), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm p-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      class: "tabs-custom",
      "nav-wrapper-class": "pb-3 h6",
      "content-class": "px-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        title: "Основные"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_form_group, {
          label: "Телефон горячей линии"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.adminCommon.phone,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.adminCommon.phone = $event),
            placeholder: "Укажите телефон",
            trim: "",
            onChange: _cache[1] || (_cache[1] = $event => $options.submitData('phone'))
          }, null, 8, ["modelValue"])]),
          _: 1
        })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_form_group, {
          label: "Электронная почта горячей линии",
          "invalid-feedback": "Поле должно быть заполнено по шаблону example@example.com"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.adminCommon.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.adminCommon.email = $event),
            placeholder: "Укажите адрес электронной почты",
            trim: "",
            state: _ctx.email_state,
            onChange: _cache[3] || (_cache[3] = $event => $options.submitData('email'))
          }, null, 8, ["modelValue", "state"])]),
          _: 1
        })])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_b_form_group, {
          label: "Контакты технической поддержки"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.adminCommon.tech_support_contacts,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.adminCommon.tech_support_contacts = $event),
            placeholder: "Укажите контакты технической поддержки",
            trim: "",
            onChange: _cache[5] || (_cache[5] = $event => $options.submitData('tech_support_contacts'))
          }, null, 8, ["modelValue"])]),
          _: 1
        })])]), _cache[30] || (_cache[30] = _createElementVNode("hr", null, null, -1)), _createVNode(_component_b_row, {
          class: "mt-2 text-center"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, null, {
            default: _withCtx(() => [_cache[25] || (_cache[25] = _createElementVNode("div", null, "Логотип", -1)), _withDirectives((_openBlock(), _createBlock(_component_b_button, {
              variant: "link",
              onClick: _cache[6] || (_cache[6] = $event => $options.clickAddFile('logo_owner')),
              title: "Загрузить картинку"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_avatar, {
                square: "",
                size: "6rem",
                src: _ctx.adminCommon.logo_owner,
                variant: "light",
                icon: "image"
              }, null, 8, ["src"])]),
              _: 1
            })), [[_directive_b_tooltip, void 0, void 0, {
              hover: true
            }]])]),
            _: 1
          }), _createVNode(_component_b_col, null, {
            default: _withCtx(() => [_cache[26] || (_cache[26] = _createElementVNode("div", null, "Логотип платформы с текстом", -1)), _withDirectives((_openBlock(), _createBlock(_component_b_button, {
              variant: "link",
              onClick: _cache[7] || (_cache[7] = $event => $options.clickAddFile('logo_full')),
              title: "Загрузить картинку"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_avatar, {
                square: "",
                size: "6rem",
                src: _ctx.adminCommon.logo_full,
                variant: "light",
                icon: "image"
              }, null, 8, ["src"])]),
              _: 1
            })), [[_directive_b_tooltip, void 0, void 0, {
              hover: true
            }]])]),
            _: 1
          }), _createVNode(_component_b_col, null, {
            default: _withCtx(() => [_cache[27] || (_cache[27] = _createElementVNode("div", null, "Логотип платформы без текста", -1)), _withDirectives((_openBlock(), _createBlock(_component_b_button, {
              variant: "link",
              onClick: _cache[8] || (_cache[8] = $event => $options.clickAddFile('logo_min')),
              title: "Загрузить картинку"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_avatar, {
                square: "",
                size: "6rem",
                src: _ctx.adminCommon.logo_min,
                variant: "light",
                icon: "image"
              }, null, 8, ["src"])]),
              _: 1
            })), [[_directive_b_tooltip, void 0, void 0, {
              hover: true
            }]])]),
            _: 1
          }), _createVNode(_component_b_col, null, {
            default: _withCtx(() => [_cache[28] || (_cache[28] = _createElementVNode("div", null, "Изображение на стартовой странице", -1)), _withDirectives((_openBlock(), _createBlock(_component_b_button, {
              variant: "link",
              onClick: _cache[9] || (_cache[9] = $event => $options.clickAddFile('cover')),
              title: "Загрузить картинку"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_avatar, {
                square: "",
                size: "6rem",
                src: _ctx.adminCommon.cover,
                variant: "light",
                icon: "image"
              }, null, 8, ["src"])]),
              _: 1
            })), [[_directive_b_tooltip, void 0, void 0, {
              hover: true
            }]])]),
            _: 1
          })]),
          _: 1
        }), _createElementVNode("div", _hoisted_6, [_cache[29] || (_cache[29] = _createElementVNode("hr", null, null, -1)), _createVNode(_component_b_form_group, {
          label: "Безопасные почтовые домены",
          "invalid-feedback": "Поле должно быть заполнено по шаблону example.com"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            type: "url",
            modelValue: _ctx.domain,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.domain = $event),
            placeholder: "Укажите домен",
            trim: "",
            state: _ctx.domain_state,
            onChange: $options.addDomain
          }, null, 8, ["modelValue", "state", "onChange"])]),
          _: 1
        }), _createVNode(_component_StringsWithX, {
          items: $options.domens4comp,
          onRemoveItem: $options.removeDomain
        }, null, 8, ["items", "onRemoveItem"])])]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Информация"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_b_form_group, {
          label: "Разработчик"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
            rows: "4",
            modelValue: _ctx.adminCommon.developer,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _ctx.adminCommon.developer = $event),
            placeholder: "Укажите разработчика",
            trim: "",
            onChange: _cache[12] || (_cache[12] = $event => $options.submitData('developer'))
          }, null, 8, ["modelValue"])]),
          _: 1
        })])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_b_form_group, {
          label: "Правообладатель"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
            rows: "4",
            modelValue: _ctx.adminCommon.copyright_holder,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _ctx.adminCommon.copyright_holder = $event),
            placeholder: "Укажите правообладателя",
            trim: "",
            onChange: _cache[14] || (_cache[14] = $event => $options.submitData('copyright_holder'))
          }, null, 8, ["modelValue"])]),
          _: 1
        })])]), _cache[33] || (_cache[33] = _createElementVNode("div", null, "Документация", -1)), _ctx.adminCommonDocsLoading ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[31] || (_cache[31] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(), _createElementBlock("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminCommonDocs, doc => {
          return _openBlock(), _createBlock(_component_b_row, {
            key: doc.id
          }, {
            default: _withCtx(() => [_createVNode(_component_b_col, {
              cols: "auto"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                class: "px-0",
                variant: "link",
                onClick: $event => $options.handlerDeleteFile(doc.id)
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: "trash-can",
                  "fixed-width": ""
                })]),
                _: 2
              }, 1032, ["onClick"])]),
              _: 2
            }, 1024), _createVNode(_component_b_col, {
              class: "text-muted text-nowrap text-truncate pt-2"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(doc.document), 1)]),
              _: 2
            }, 1024)]),
            _: 2
          }, 1024);
        }), 128)), _createElementVNode("div", _hoisted_13, [_createVNode(_component_b_button, {
          variant: "outline-primary",
          onClick: $options.clickAddFileDocs,
          size: "sm"
        }, {
          default: _withCtx(() => _cache[32] || (_cache[32] = [_createTextVNode(" + Добавить файл ")])),
          _: 1
        }, 8, ["onClick"])])]))]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Политика обработки персональных данных"
      }, {
        default: _withCtx(() => [_createElementVNode("button", {
          type: "button",
          class: "btn btn-outline-primary mb-3",
          onClick: _cache[15] || (_cache[15] = $event => $options.submitData('pers_data_policy'))
        }, " Сохранить политику обработки персональных данных "), _createVNode(_component_VMarkdownEditor, {
          modelValue: _ctx.adminCommon.pers_data_policy,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => _ctx.adminCommon.pers_data_policy = $event),
          locale: "en",
          class: "editor-wrap",
          "upload-action": $options.handleUpload
        }, null, 8, ["modelValue", "upload-action"])]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Пользовательское соглашение"
      }, {
        default: _withCtx(() => [_createElementVNode("button", {
          type: "button",
          class: "btn btn-outline-primary mb-3",
          onClick: _cache[17] || (_cache[17] = $event => $options.submitData('user_agreement'))
        }, " Сохранить пользовательское соглашение "), _createVNode(_component_VMarkdownEditor, {
          modelValue: _ctx.adminCommon.user_agreement,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => _ctx.adminCommon.user_agreement = $event),
          locale: "en",
          class: "editor-wrap",
          "upload-action": $options.handleUpload
        }, null, 8, ["modelValue", "upload-action"])]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Согласие на обработку персональных данных"
      }, {
        default: _withCtx(() => [_createElementVNode("button", {
          type: "button",
          class: "btn btn-outline-primary mb-3",
          onClick: _cache[19] || (_cache[19] = $event => $options.submitData('pers_data_conscent'))
        }, " Сохранить согласие на обработку персональных данных "), _createVNode(_component_VMarkdownEditor, {
          modelValue: _ctx.adminCommon.pers_data_conscent,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => _ctx.adminCommon.pers_data_conscent = $event),
          locale: "en",
          class: "editor-wrap",
          "upload-action": $options.handleUpload
        }, null, 8, ["modelValue", "upload-action"])]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Политика обработки файлов cookie"
      }, {
        default: _withCtx(() => [_createElementVNode("button", {
          type: "button",
          class: "btn btn-outline-primary mb-3",
          onClick: _cache[21] || (_cache[21] = $event => $options.submitData('cookies_data_policy'))
        }, " Сохранить политику обработки файлов cookie "), _createVNode(_component_VMarkdownEditor, {
          modelValue: _ctx.adminCommon.cookies_data_policy,
          "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => _ctx.adminCommon.cookies_data_policy = $event),
          locale: "en",
          class: "editor-wrap",
          "upload-action": $options.handleUpload
        }, null, 8, ["modelValue", "upload-action"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _ctx.adminCommonLoading ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[34] || (_cache[34] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[23] || (_cache[23] = (...args) => $options.handlerAddFile && $options.handlerAddFile(...args))
  }, null, 544), _createElementVNode("input", {
    type: "file",
    ref: "fileInputDocs",
    class: "d-none",
    onChange: _cache[24] || (_cache[24] = (...args) => $options.handlerAddFileDocs && $options.handlerAddFileDocs(...args))
  }, null, 544)]);
}