import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "need-wrap"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_table = _resolveComponent("b-table");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_alert, {
    modelValue: _ctx.showAlert,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showAlert = $event),
    variant: "danger",
    dismissible: ""
  }, {
    default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("div", null, "Некорректные данные", -1)), _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.alertTitle), 1)]),
    _: 1
  }, 8, ["modelValue"]), _withDirectives(_createVNode(_component_b_card_header, {
    "header-class": "p-0 m-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto ms-auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.currentPageModel = $event),
          "total-rows": _ctx.workersToAssCount,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.workersToAssCount > _ctx.perPage]]), _createVNode(_component_b_table, {
    class: "table-custom",
    hover: "",
    items: $options.items4table,
    fields: _ctx.tableFields,
    stacked: "md",
    busy: _ctx.workersToAssLoading,
    small: "",
    "show-empty": "",
    responsive: ""
  }, {
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    empty: _withCtx(() => [_createVNode(_component_b_alert, {
      show: "",
      variant: "light",
      class: "text-center"
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("img", {
        src: _imports_0,
        alt: "empty",
        class: "mb-4"
      }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
      _: 1
    })]),
    "cell(btnChange)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
      variant: "link",
      class: "p-0",
      onClick: $event => $options.clickChange(row.item),
      disabled: $options.readOnly
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [_createElementVNode("i", {
        class: "bi bi-pencil-square"
      }, null, -1)])),
      _: 2
    }, 1032, ["onClick", "disabled"])), [[_directive_can, _ctx.$perm.assign_wa_libmaterial]])]),
    "cell(btnRemove)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
      variant: "link",
      class: "p-0",
      onClick: $event => $options.clickRemove(row.item),
      disabled: $options.readOnly
    }, {
      default: _withCtx(() => _cache[10] || (_cache[10] = [_createElementVNode("i", {
        class: "bi bi-trash"
      }, null, -1)])),
      _: 2
    }, 1032, ["onClick", "disabled"])), [[_directive_can, _ctx.$perm.assign_wa_libmaterial]])]),
    _: 1
  }, 8, ["items", "fields", "busy"]), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "link",
        class: "p-0",
        onClick: $options.clickAddWorker,
        disabled: $options.readOnly
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode(" + Добавить работника")])),
        _: 1
      }, 8, ["onClick", "disabled"])), [[_directive_can, _ctx.$perm.assign_wa_libmaterial]])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "primary",
        onClick: $options.clickConfirm,
        disabled: $options.readOnly
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("Назначить ознакомление")])),
        _: 1
      }, 8, ["onClick", "disabled"])), [[_directive_can, _ctx.$perm.confirm_wa_libmaterial]])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModalAdd,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.showModalAdd = $event),
    id: "modalAddWorker",
    title: "Добавить ознакомление работнику",
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalDisabled
  }, {
    default: _withCtx(() => [_createVNode(_component_Calendar, {
      title: "Срок ознакомления",
      date: _ctx.deadline,
      class: "mt-2 mb-3",
      classCustom: "mt-2",
      onChangeValue: _cache[3] || (_cache[3] = $event => _ctx.deadline = $event)
    }, null, 8, ["date"]), _createVNode(_component_b_form_group, {
      label: "Работник"
    }, {
      default: _withCtx(() => [!_ctx.isChange ? (_openBlock(), _createBlock(_component_UserSelector, {
        key: 0,
        onSelected: $options.selectedWorker,
        fmtName: _ctx.fmtNameFull,
        showInfo: true
      }, null, 8, ["onSelected", "fmtName"])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(_toDisplayString($options.formatName({
        first_name: _ctx.worker.student_first_name,
        middle_name: _ctx.worker.student_middle_name,
        last_name: _ctx.worker.student_last_name
      }, _ctx.fmtNameFull)), 1)], 64))]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onOk", "ok-disabled"]), _createVNode(_component_b_modal, {
    title: _ctx.titleModalRemove,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"])]);
}