import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { innerCheckReason } from "@/helpers/text";
import { statesInnerCheck } from "@/helpers/states";
import { mapActions, mapGetters } from "vuex";
import links from "@/router/links";
export default {
  name: "InnerCheckFormSource",
  data: () => ({
    showModalAdd: false,
    typeSource: null,
    idSource: "",
    tableFields: [{
      key: "index",
      label: "№"
    }, {
      key: "title",
      label: "Повод"
    }, {
      key: "remove",
      label: "Удалить",
      class: "text-center"
    }],
    showModalRemove: false,
    currentItem: {}
  }),
  methods: {
    ...mapActions("innerCheck", ["changeInnerCheck"]),
    ...mapActions("coi", ["updateCOIForm", "dropCOIForm"]),
    ...mapActions("hotline", ["updateHlineMsg", "dropHlForm"]),
    ...mapActions("cp", ["updateCPForm", "dropCPForm"]),
    ...mapActions(["showNotify"]),
    addSource() {
      this.typeSource = null;
      this.idSource = "";
      this.showModalAdd = true;
    },
    async handlerAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      // foundations_coi: "Конфликт интересов",
      // foundations_message: "Сообщение горячей линии",
      // foundations_cp: "Контрольная процедура",
      if (innerCheckReason[this.typeSource] === innerCheckReason.foundations_coi) {
        await this.dropCOIForm();
        await this.updateCOIForm(this.idSource);
        if (!this.coiForm.id) {
          this.showNotify({
            msg: `Конфликт интересов #${this.idSource} недоступен`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else if (innerCheckReason[this.typeSource] === innerCheckReason.foundations_message) {
        await this.dropHlForm();
        await this.updateHlineMsg(this.idSource);
        if (!this.hlineMsg.id) {
          this.showNotify({
            msg: `Сообщение горячей линии #${this.idSource} недоступно`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else if (innerCheckReason[this.typeSource] === innerCheckReason.foundations_cp) {
        await this.dropCPForm();
        await this.updateCPForm(this.idSource);
        if (!this.cpForm.id) {
          this.showNotify({
            msg: `Контрольная процедура #${this.idSource} недоступна`,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else return;
      await this.changeInnerCheck({
        id: this.innerCheckForm.id,
        params: {
          [this.typeSource]: this.innerCheckForm[this.typeSource].concat(this.idSource)
        }
      });
      this.showModalAdd = false;
    },
    async removeSource(item) {
      this.currentItem = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      this.innerCheckForm[this.currentItem.key].splice(this.currentItem.index, 1);
      await this.changeInnerCheck({
        id: this.innerCheckForm.id,
        params: {
          [this.currentItem.key]: this.innerCheckForm[this.currentItem.key]
        }
      });
    },
    async changeItem(key) {
      const value = this.innerCheckForm[key];
      await this.changeInnerCheck({
        id: this.innerCheckForm.id,
        params: {
          [key]: value
        }
      });
    }
  },
  computed: {
    ...mapGetters("innerCheck", ["innerCheckForm"]),
    ...mapGetters("coi", ["coiForm", "coiFormLoading"]),
    ...mapGetters("hotline", ["hlineMsg", "hlineMsgLoading"]),
    ...mapGetters("cp", ["cpForm", "cpFormLoading"]),
    okModalAddDisabled() {
      return !this.typeSource || !this.idSource.trim().length;
    },
    optionsSource() {
      return Object.keys(innerCheckReason).map(key => ({
        value: key,
        text: innerCheckReason[key]
      }));
    },
    readOnly() {
      return statesInnerCheck[this.innerCheckForm.state] !== statesInnerCheck.draft;
    },
    source() {
      return [].concat(this.innerCheckForm.foundations_coi.map((coi, index) => ({
        key: "foundations_coi",
        index,
        link: links.coiForm + coi,
        title: innerCheckReason.foundations_coi + " #" + coi
      })), this.innerCheckForm.foundations_message.map((msg, index) => ({
        key: "foundations_message",
        index,
        link: links.hotlineForm + msg,
        title: innerCheckReason.foundations_message + " #" + msg
      })), this.innerCheckForm.foundations_cp.map((cp, index) => ({
        key: "foundations_cp",
        index,
        link: links.cpForm + cp,
        title: innerCheckReason.foundations_cp + " #" + cp
      })));
    }
  }
};