import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h1", null, "Категории проверок", -1)])),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm py-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_table, {
      class: "table-custom",
      responsive: "",
      hover: "",
      small: "",
      "show-empty": "",
      stacked: "md",
      items: _ctx.adminInnerCheck,
      fields: _ctx.tableFields,
      busy: _ctx.adminInnerCheckLoading
    }, {
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      empty: _withCtx(() => [_createVNode(_component_b_alert, {
        show: "",
        variant: "light",
        class: "text-center"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "empty",
          class: "mb-4"
        }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
        _: 1
      })]),
      _: 1
    }, 8, ["items", "fields", "busy"])]),
    _: 1
  })]);
}