import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "languages"
};
const _hoisted_2 = {
  class: "level"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  colspan: "6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "4. Владение иностранными языками"
      }, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Название языка"), _createElementVNode("th", null, "Уровень владения")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, lang => {
          return _openBlock(), _createElementBlock("tr", {
            key: lang.id,
            class: "lang-record"
          }, [_createElementVNode("td", null, [_createVNode(_component_b_form_input, {
            modelValue: lang.language,
            "onUpdate:modelValue": $event => lang.language = $event,
            onChange: $event => $options.submitDataEndpoint(lang, 'language', 'languages', _ctx.endpointLanguages),
            disabled: _ctx.readonly,
            state: lang.language_state,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled", "state"])]), _createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_select, {
            modelValue: lang.level,
            "onUpdate:modelValue": $event => lang.level = $event,
            options: _ctx.languageLevelOptions,
            onChange: $event => $options.submitDataEndpoint(lang, 'level', 'languages', _ctx.endpointLanguages),
            disabled: _ctx.readonly,
            state: lang.level_state
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "disabled", "state"]), !_ctx.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete double-height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.languages, lang.id, 'languages', _ctx.endpointLanguages)
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !_ctx.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_3, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_4, [_createVNode(_component_AddRow, {
          onClicked: _cache[0] || (_cache[0] = $event => $options.addDataEndpoint(_ctx.languages, 'languages', _ctx.endpointLanguages, _ctx.templateLanguages, _ctx.statesLanguages))
        })])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}