const link404 = "/a/404";
import { ff } from "@/helpers/global";

const links = {
  home: "/a",
  start: "/a/start",
  auth: "/a/login",
  about: "/a/about",
  dashboard: "/a",
  personalArea: "/a/dashboard",
  declarations: ff.DECLARATION ? "/a/declarations" : link404,
  declarationForm: ff.DECLARATION ? "/a/declaration-form/" : link404,
  // cp coi
  cpForm: ff.CP ? "/a/cp-form/" : link404,
  cpBase: ff.CP ? "/a/cp-base/" : link404,
  coiForm: ff.DECLARATION ? "/a/coi-form/" : link404,
  education: ff.EDUCATION ? "/a/education" : link404,
  educationCourse: ff.EDUCATION ? "/a/education/course/" : link404,
  educationCourseCertificate: ff.EDUCATION
    ? "/a/education/course/certificate"
    : link404,
  educationCourseTest: ff.EDUCATION ? "/a/education/course-test/" : link404,
  educationProgramAdd: ff.EDUCATION ? "/a/education/program-add/" : link404,
  hotline: ff.HOTLINE ? "/a/hotline" : link404,
  hotlineForm: ff.HOTLINE ? "/a/hotline-form/" : link404,
  profile: ff.PROFILE ? "/a/profile" : link404,
  profileSheets: ff.PROFILE ? "/a/profile/sheets" : link404,
  profileDeclarations: ff.DECLARATION ? "/a/profile/declarations" : link404,
  profilePresent: ff.DECLARATION ? "/a/profile/presents" : link404,
  printDeclaration: ff.DECLARATION ? "/a/declaration/print/" : link404,
  profileEducation: ff.EDUCATION ? "/a/profile/education" : link404,
  profileAcquaintance: ff.KNOWLEDGE ? "/a/profile/acquaintance" : link404,
  profileAcquaintanceFormView: ff.KNOWLEDGE
    ? "/a/profile/acquaintance-view/"
    : link404,
  profileAcquaintanceAltView: ff.KNOWLEDGE
    ? "/a/profile/acquaintance-alt-view/"
    : link404, // альтернативный интерфейс БЗ
  profileNotifications: ff.NOTIFICATION ? "/a/profile/notification" : link404,
  profileCard: ff.PROFILE ? "/a/profile/card/" : link404,
  profilesKadr: ff.PROFILE ? "/a/administration/profiles" : link404,
  profileChecks: ff.PROFILE ? "/a/administration/profile-checks" : link404,
  print: ff.PROFILE ? "/a/profile/card/:msg/print" : link404,
  profileDeclarationForm: ff.DECLARATION
    ? "/a/profile/declaration-form/"
    : link404,
  profileSettings: "/a/profile/settings/",
  knowledge: ff.KNOWLEDGE ? "/a/knowledge" : link404,
  knowledgeList: ff.KNOWLEDGE ? "/a/knowledge/list/" : link404,
  knowledgeForm: ff.KNOWLEDGE ? "/a/knowledge/document-form/" : link404,
  knowledgeAlt: ff.KNOWLEDGE ? "/a/knowledge-alt" : link404, // альтернативный интерфейс БЗ
  knowledgeListAlt: ff.KNOWLEDGE ? "/a/knowledge-alt/list/" : link404, // альтернативный интерфейс БЗ
  partners: ff.PARTNERS ? "/a/partners" : link404,
  partnersForm: ff.PARTNERS ? "/a/partners-form/" : link404,
  innerCheck: ff.INNERCHECK ? "/a/inner-check" : link404,
  innerCheckForm: ff.INNERCHECK ? "/a/inner-check-form/" : link404,
  risk: ff.RISK ? "/a/risk" : link404,
  riskChart: ff.RISK ? "/a/risk/chart" : link404,
  riskChartPrint: ff.RISK ? "/a/chart/print" : link404,
  matrixList: ff.RISK ? "/a/risk/matrix-list" : link404,
  riskList: ff.RISK ? "/a/risk/risk-list" : link404,
  riskActivityList: ff.RISK ? "/a/risk/activity-list" : link404,
  riskReports: ff.RISK ? "/a/risk/reports" : link404,
  riskForm: ff.RISK ? "/a/risk-form/" : link404,
  matrixForm: ff.RISK ? "/a/matrix-form/" : link404,
  matrixFormPrint: ff.RISK ? "/a/matrix-form/print/" : link404,
  administration: ff.ADMINISTRATION ? "/a/administration" : link404,
  adminActivity: ff.SETTINGS ? "/a/administration/activity" : link404,
  adminBackup: ff.SETTINGS ? "/a/administration/backup" : link404,
  adminUsers: ff.ADMINISTRATION ? "/a/administration/users" : link404,
  adminOrg: ff.ADMINISTRATION ? "/a/administration/org" : link404,
  adminWorkers: ff.ADMINISTRATION ? "/a/administration/workers" : link404,
  adminStatistics: ff.ADMINISTRATION ? "/a/administration/statistics" : link404,
  adminCommon: ff.SETTINGS ? "/a/administration/common" : link404,
  adminKnowledge: ff.KNOWLEDGE ? "/a/administration/knowledge" : link404,
  adminLnaTypes: ff.KNOWLEDGE ? "/a/administration/lna-types" : link404,
  adminCountries: ff.PROFILE ? "/a/administration/countries" : link404,
  adminEducation: ff.EDUCATION ? "/a/administration/education" : link404,
  adminDeclaration: ff.DECLARATION ? "/a/administration/declaration" : link404,
  adminCpTypes: ff.CP ? "/a/administration/cp-types" : link404,
  adminHlCategory: ff.HOTLINE ? "/a/administration/hl-category" : link404,
  adminInnerCheck: ff.INNERCHECK ? "/a/administration/inner-check" : link404,
  adminRisk: ff.RISK ? "/a/administration/risk" : link404,
  adminPartners: ff.PARTNERS ? "/a/administration/partners" : link404,
  adminPartnersForm: ff.PARTNERS ? "/a/administration/partners-form/" : link404,
  adminHotLine: ff.HOTLINE ? "/a/administration/hotline" : link404,
  adminAdmin: ff.SETTINGS ? "/a/administration/admin" : link404,
  adminCalendar: ff.SETTINGS ? "/a/administration/calendar" : link404,
  adminDepartment: ff.ADMINISTRATION ? "/a/administration/department" : link404,
  adminRoles: ff.ADMINISTRATION ? "/a/administration/roles" : link404,
  adminDirectoryList: ff.ADMINISTRATION
    ? "/a/administration/directory-list/"
    : link404,
  securityEvents: ff.ADMINISTRATION
    ? "/a/administration/security-events"
    : link404,
  securitySettings: ff.ADMINISTRATION
    ? "/a/administration/security-settings"
    : link404,
  profileSheetSettings: ff.ADMINISTRATION
    ? "/a/administration/profile-settings"
    : link404,
  adminNotification: ff.ADMINISTRATION
    ? "/a/administration/notification"
    : link404,
  // автосверка
  autochecks: ff.ADMINISTRATION ? "/a/autochecks" : link404,
  autocheckForm: ff.ADMINISTRATION ? "/a/autochecks/form/" : link404,
};

export default links;
