import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_AdminBackupAdd = _resolveComponent("AdminBackupAdd");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_icon = _resolveComponent("b-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_BTable = _resolveComponent("BTable");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("h1", null, "Резервное копирование", -1)])),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_AdminBackupAdd, {
        onCreateBackup: $options.clickCreateBackup
      }, null, 8, ["onCreateBackup"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm py-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_BTable, {
      class: "table-custom",
      hover: "",
      small: "",
      "show-empty": "",
      stacked: "md",
      items: _ctx.adminBackup,
      fields: _ctx.tableFields,
      busy: _ctx.adminBackupLoading
    }, {
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      empty: _withCtx(() => [_createVNode(_component_b_alert, {
        show: "",
        variant: "light",
        class: "text-center"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "empty",
          class: "mb-4"
        }, null, -1), _createElementVNode("h4", null, "Копий нет", -1)])),
        _: 1
      })]),
      "cell(btnUnpack)": _withCtx(row => [_createVNode(_component_b_button, {
        variant: "link",
        onClick: $event => $options.clickUnpack(row.item)
      }, {
        default: _withCtx(() => [_createVNode(_component_b_icon, {
          icon: "download",
          "fixed-width": ""
        })]),
        _: 2
      }, 1032, ["onClick"])]),
      "cell(btnRemove)": _withCtx(row => [_createVNode(_component_b_button, {
        variant: "link",
        onClick: $event => $options.clickRemove(row.item)
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
          class: "bi bi-trash"
        }, null, -1)])),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    }, 8, ["items", "fields", "busy"]), _withDirectives(_createVNode(_component_b_card_footer, {
      "footer-bg-variant": "white",
      "footer-class": "px-4 py-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_pagination, {
        modelValue: $options.currentPageModel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
        "total-rows": _ctx.adminBackupCount,
        "per-page": _ctx.perPage,
        pills: "",
        class: "mb-0"
      }, null, 8, ["modelValue", "total-rows", "per-page"])]),
      _: 1
    }, 512), [[_vShow, _ctx.adminBackupCount > _ctx.perPage]])]),
    _: 1
  })]);
}