import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "table-footer"
};
const _hoisted_3 = {
  class: "mb-2"
};
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import KnowledgeSidebar from "./KnowledgeSidebar.vue";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import links from "@/router/links";
import { limitBound, CONST_NONAME, formatName, fmtNameShort } from "@/helpers/text";
import { statesKnowledge } from "@/helpers/states";
const perPage = 20;
export default {
  __name: 'KnowledgeDocuments',
  props: {
    section: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const store = useStore();
    const route = useRoute();
    const currentPage = ref(1);
    const sort = ref(null);
    const filters = ref({});
    const showSidebar = ref(false);
    const optionsSort = [{
      value: "owner_department_name",
      text: "по владельцу"
    }, {
      value: "title",
      text: "по названию"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "document_date",
      text: "по дате документа"
    }, {
      value: "document_number",
      text: "по номеру документу"
    }, {
      value: "assignee__last_name,assignee__first_name,assignee__middle_name",
      text: "по ответственному"
    }];
    const key4filters = ["owner_department_id", "title_search", "state", "document_date_gte", "document_date_lte", "document_number_search", "responsible_id"];
    const sectionsDocuments = computed(() => store.getters["knowledge/sectionsDocuments"]);
    const sectionsDocumentsLoading = computed(() => store.getters["knowledge/sectionsDocumentsLoading"]);
    const sectionsDocumentsCount = computed(() => store.getters["knowledge/sectionsDocumentsCount"]);
    const isAltUI = computed(() => route.path.includes(links.knowledgeListAlt));
    const items4table = computed(() => Array.isArray(sectionsDocuments.value) ? sectionsDocuments.value.map(el => ({
      ...el,
      titleT: el.title || CONST_NONAME,
      stateT: statesKnowledge[el.state],
      document_dateT: formatDate(el.document_date, fmtDateShort),
      responsible_detailT: formatName(el.responsible_detail, fmtNameShort)
    })) : []);
    const fields = computed(() => {
      const arr = [{
        key: "num",
        label: "№ п/п",
        class: "text-nowrap"
      }, {
        key: "id",
        label: "UID"
      }, {
        key: "owner_department_name",
        label: "Подразделение"
      }, {
        key: "titleT",
        label: "Наименование"
      }, {
        key: "document_number",
        label: "Номер"
      }, {
        key: "document_dateT",
        label: "Дата"
      }, {
        key: "visibility_level",
        label: "Доступность"
      }, {
        key: "stateT",
        label: "Статус",
        formatter: value => statesKnowledge[value]
      }, {
        key: "responsible_detailT",
        label: "Исполнитель"
      }];
      return isAltUI.value ? arr : arr.concat({
        key: "btnClone",
        label: ""
      });
    });
    async function changePage(newPage) {
      currentPage.value = limitBound(Number(newPage), 1, sectionsDocumentsCount.value / perPage, currentPage.value);
      await loadData({
        page: currentPage.value,
        section_id: props.section,
        sort: sort.value,
        page_size: perPage,
        ...filters.value
      });
    }
    async function copyDocument(id) {
      await store.dispatch("knowledge/cloneDocument", id);
      await loadData({
        page: currentPage.value,
        section_id: props.section,
        sort: sort.value,
        page_size: perPage,
        ...filters.value
      });
    }
    async function applyFilters(data) {
      filters.value = {};
      Object.keys(data).forEach(key => {
        const value = ["document_date_gte", "document_date_lte"].includes(key) ? formatDateObj(data[key], fmtDate, null) : data[key];
        if (value !== null && String(value).trim() !== "") filters.value[key] = value;
      });
      if (currentPage.value === 1) {
        const params = {
          page: 1,
          section_id: props.section,
          sort: sort.value,
          page_size: perPage,
          ...filters.value
        };
        await loadData(params);
      } else currentPage.value = 1;
    }
    async function loadData(params) {
      if (isAltUI.value) params.alt = true;
      await store.dispatch("knowledge/updateDocuments", params);
    }
    async function sortChanged({
      sortBy,
      sortDesc
    }) {
      sort.value = sortDesc ? `-${sortBy}` : sortBy;
      const params = {
        page: currentPage.value,
        section_id: props.section,
        sort: sort.value,
        page_size: perPage,
        ...filters.value
      };
      await loadData(params);
    }
    async function searchChanged(newSearch) {
      currentPage.value = 1;
      const params = {
        page: currentPage.value,
        section_id: props.section,
        page_size: perPage,
        search_string: newSearch
      };
      await loadData(params);
    }
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_FilterButton = _resolveComponent("FilterButton");
      const _component_b_form_row = _resolveComponent("b-form-row");
      const _component_b_card_header = _resolveComponent("b-card-header");
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      const _component_b_link = _resolveComponent("b-link");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_container = _resolveComponent("b-container");
      const _component_b_offcanvas = _resolveComponent("b-offcanvas");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_card_header, {
        "header-class": "py-0 m-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_row, {
          class: "mt-0 mb-2 ms-0 me-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "6",
            xl: "2"
          }, {
            default: _withCtx(() => [_createVNode(_component_CustomSearch, {
              onOnSearch: searchChanged
            })]),
            _: 1
          }), _createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "5",
            xl: "5"
          }, {
            default: _withCtx(() => [_createVNode(_component_CustomSort, {
              optionsSort: optionsSort,
              onOnSort: sortChanged
            })]),
            _: 1
          }), _createVNode(_component_b_col, {
            cols: "12",
            md: "4",
            xl: "2",
            class: "me-auto"
          }, {
            default: _withCtx(() => [_createVNode(_component_FilterButton, {
              onClick: _cache[0] || (_cache[0] = $event => showSidebar.value = true)
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        small: "",
        "show-empty": "",
        responsive: "",
        stacked: "md",
        items: items4table.value,
        fields: fields.value,
        busy: sectionsDocumentsLoading.value
      }, {
        "cell()": _withCtx(row => [_createVNode(_component_router_link, {
          class: _normalizeClass({
            'font-weight-bold': row.item.is_new
          }),
          to: (isAltUI.value ? _unref(links).profileAcquaintanceAltView : _unref(links).knowledgeForm) + row.item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
          _: 2
        }, 1032, ["class", "to"])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Документов нет", -1)])),
          _: 1
        })]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        "cell(btnClone)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_link, {
          onClick: $event => copyDocument(row.item.id),
          class: "border-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: ['far', 'clone'],
            "fixed-width": ""
          })]),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_libmaterial]])]),
        "cell(num)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
        _: 1
      }, 8, ["items", "fields", "busy"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            totalRows: sectionsDocumentsCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, sectionsDocumentsCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_offcanvas, {
        modelValue: showSidebar.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showSidebar.value = $event),
        placement: "end",
        "no-header": "",
        backdrop: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-2"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_cache[5] || (_cache[5] = _createElementVNode("h5", {
            class: "d-inline"
          }, "Фильтровать", -1)), _createElementVNode("button", {
            type: "button",
            class: "btn-close float-end",
            "aria-label": "Close",
            onClick: _cache[1] || (_cache[1] = $event => showSidebar.value = false)
          })]), _createVNode(KnowledgeSidebar, {
            onEmitApplyFilters: applyFilters,
            key4filters: key4filters
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};