import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { dateObjInit } from "@/helpers/date";
import { states } from "@/helpers/states";
import { mapGetters } from "vuex";
import { getArrayOptionsFilter, getStateOptionsFilter } from "@/helpers/declaration";
import Calendar from "@/components/Helpers/Calendar.vue";
export default {
  name: "COISidebar",
  components: {
    Calendar
  },
  data: () => ({
    showItem: true,
    filters: {
      state: null,
      dateFrom: {
        ...dateObjInit
      },
      dateUntill: {
        ...dateObjInit
      },
      assignee: null,
      number: null,
      author: null,
      coi: null
    }
  }),
  computed: {
    ...mapGetters("coi", ["coiDirectory"]),
    stateOptions() {
      return getStateOptionsFilter(states);
    },
    coiOptions() {
      return getArrayOptionsFilter(this.coiDirectory);
    }
  },
  methods: {
    clearFilters() {
      this.showItem = false;
      Object.keys(this.filters).forEach(key => {
        if (["dateFrom", "dateUntill"].includes(key)) this.filters[key] = {
          ...dateObjInit
        };else this.filters[key] = null;
      });
      setTimeout(() => {
        this.showItem = true;
      }, 0);
    },
    clickApplyFilters() {
      this.$emit("emitApplyFilters", this.filters);
    }
  }
};