import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  key: 2
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  key: 3
};
const _hoisted_6 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      class: "title-wrap"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.items[$props.currentItem].title), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "link",
        onClick: _cache[0] || (_cache[0] = $event => $options.onClick(-1)),
        disabled: $props.currentItem <= 0
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("i", {
          class: "bi bi-chevron-left"
        }, null, -1)])),
        _: 1
      }, 8, ["disabled"]), _createTextVNode(_toDisplayString($props.currentItem + 1) + " из " + _toDisplayString($props.items.length), 1), _createVNode(_component_b_button, {
        variant: "link",
        onClick: _cache[1] || (_cache[1] = $event => $options.onClick(1)),
        disabled: $props.currentItem >= $props.items.length - 1
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
          class: "bi bi-chevron-right"
        }, null, -1)])),
        _: 1
      }, 8, ["disabled"])]),
      _: 1
    })]),
    _: 1
  }), _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)), _ctx.optionsEducMaterials[$props.items[$props.currentItem].material_type] === _ctx.optionsEducMaterials.attachment ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_button, {
    href: $props.items[$props.currentItem].attachment,
    download: decodeURI($props.items[$props.currentItem].attachment),
    variant: "outline-primary"
  }, {
    default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Скачать документ")])),
    _: 1
  }, 8, ["href", "download"])])) : _createCommentVNode("", true), _ctx.optionsEducMaterials[$props.items[$props.currentItem].material_type] === _ctx.optionsEducMaterials.text ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    innerHTML: $props.items[$props.currentItem].text
  }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _ctx.optionsEducMaterials[$props.items[$props.currentItem].material_type] === _ctx.optionsEducMaterials.presentation ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("embed", {
    height: "500",
    width: "100%",
    src: $props.items[$props.currentItem].presentation,
    type: "application/pdf"
  }, null, 8, _hoisted_4)])) : _createCommentVNode("", true), _ctx.optionsEducMaterials[$props.items[$props.currentItem].material_type] === _ctx.optionsEducMaterials.video ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("embed", {
    type: "video/webm",
    height: "500",
    width: "100%",
    src: $props.items[$props.currentItem].video_file
  }, null, 8, _hoisted_6)])) : _createCommentVNode("", true)]);
}