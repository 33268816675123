import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = ["href"];
const _hoisted_4 = {
  key: 0,
  class: "pb-4"
};
const _hoisted_5 = {
  key: 1,
  class: "pb-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_button = _resolveComponent("b-button");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_ReportByWorker = _resolveComponent("ReportByWorker");
  const _component_ReportByProgram = _resolveComponent("ReportByProgram");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      xl: "6",
      class: "pb-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, {
          class: "p-4"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_group, {
            label: "Вид отчёта",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.typeSelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.typeSelected = $event),
              options: _ctx.typeOptions
            }, null, 8, ["modelValue", "options"])]),
            _: 1
          }), !_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
            key: 0,
            label: "Подразделение",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.departmentSelected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.departmentSelected = $event),
              options: $options.departmentOptions
            }, null, 8, ["modelValue", "options"])]),
            _: 1
          })) : (_openBlock(), _createBlock(_component_b_form_group, {
            key: 1
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
              class: "align-middle"
            }), _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
            _: 1
          })), _createVNode(_component_b_form_group, {
            label: "Работник",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_UserSelector, {
              onSelected: $options.selectedWorker,
              fmtName: _ctx.fmtNameFull,
              showInfo: true,
              filters: {
                has_courses: true
              }
            }, null, 8, ["onSelected", "fmtName"])]),
            _: 1
          }), !_ctx.programsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
            key: 2,
            label: "Программа обучения",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.program,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.program = $event),
              options: $options.programOptions
            }, null, 8, ["modelValue", "options"])]),
            _: 1
          })) : (_openBlock(), _createBlock(_component_b_form_group, {
            key: 3
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
              class: "align-middle"
            }), _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
            _: 1
          })), _createVNode(_component_b_form_group, {
            label: "Дата начала",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_Calendar, {
              title: "",
              date: _ctx.startDate,
              classCustom: "no-gutters",
              onChangeValue: _cache[3] || (_cache[3] = $event => _ctx.startDate = $event)
            }, null, 8, ["date"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Дата окончания",
            "label-cols": "12",
            "label-cols-md": "4",
            "content-cols": "12",
            "content-cols-md": "8"
          }, {
            default: _withCtx(() => [_createVNode(_component_Calendar, {
              title: "",
              date: _ctx.endDate,
              classCustom: "no-gutters",
              onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.endDate = $event)
            }, null, 8, ["date"])]),
            _: 1
          }), _createVNode(_component_b_button, {
            variant: "primary",
            class: "me-2",
            onClick: $options.clickReport,
            disabled: _ctx.reportLoading
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Сформировать отчёт")])),
            _: 1
          }, 8, ["onClick", "disabled"]), _createVNode(_component_b_button, {
            variant: "light",
            onClick: $options.clickDownload,
            disabled: _ctx.reportLoading
          }, {
            default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
              icon: "file-arrow-down",
              "fixed-width": ""
            }), _cache[8] || (_cache[8] = _createTextVNode(" Скачать файл"))]),
            _: 1
          }, 8, ["onClick", "disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createElementVNode("a", {
      href: _ctx.reportFile,
      ref: "fileDownload"
    }, null, 8, _hoisted_3)]),
    _: 1
  }), _ctx.typeSelected === 'reportByWorker' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_b_card, {
    "no-body": "",
    class: "border-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
      default: _withCtx(() => [_createVNode(_component_ReportByWorker, {
        filters: _ctx.selected,
        class: "mt-4",
        worker: _ctx.worker.name
      }, null, 8, ["filters", "worker"])]),
      _: 1
    })]),
    _: 1
  })])) : _ctx.typeSelected === 'reportByProgram' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_b_card, {
    "no-body": "",
    class: "border-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
      default: _withCtx(() => [_createVNode(_component_ReportByProgram, {
        filters: _ctx.selected,
        class: "mt-4",
        program: _ctx.programs[_ctx.program]
      }, null, 8, ["filters", "program"])]),
      _: 1
    })]),
    _: 1
  })])) : _createCommentVNode("", true)]);
}