import links from "@/router/links";
import titles from "./titles";
import theAuth from "../components/Auth/theAuth.vue";
import ProfilePrint from "../components/Profile/ProfilePrint.vue";
import PersonalArea from "../components/PersonalArea/PersonalArea.vue";
import ProfileArea from "../components/Profile/ProfileArea.vue";
import ProfileCard from "../components/Profile/ProfileCard.vue";
import ProfileCardList from "../components/Profile/ProfileCardList.vue";
import ProfileList from "../components/Adminstration/common/ProfileList.vue";
import ProfileChecksList from "../components/Adminstration/common/ProfileChecksList.vue";
import ProfileDeclarations from "../components/Profile/ProfileDeclarations.vue";
import ProfilePresent from "../components/Profile/ProfilePresent.vue";
import ProfileEducationProgram from "../components/Profile/ProfileEducationProgram.vue";
import ProfileLNA from "../components/Profile/ProfileLNA.vue";
import ProfileNotifications from "../components/Profile/ProfileNotifications.vue";
import DeclarationArea from "../components/Declaration/DeclarationArea.vue";
import DeclarationForm from "../components/Declaration/DeclarationForm.vue";
import ProfileSettings from "../components/Profile/ProfileSettings.vue";
// cp coi
import CPBase from "../components/Declaration/CP/CPBase.vue";
import CPFormBase from "../components/Declaration/CP/CPFormBase.vue";
import COIFormBase from "../components/Declaration/COI/COIFormBase.vue";
import DeclarationPrint from "../components/Declaration/DeclarationPrint.vue";
import EducationArea from "../components/Education/EducationArea.vue";
import HotLineArea from "../components/HotLine/HotLineArea.vue";
import HotlineForm from "../components/HotLine/HotLineForm/HotlineFormBase.vue";
import EducationCourseTest from "../components/Education/EducationCourseTest.vue";
import EducationCourse from "../components/Education/EducationCourse.vue";
import EducationCourseCertificate from "../components/Education/EducationCourseCertificate.vue";
import ProgramAddBase from "../components/Education/ProgramAdd/ProgramAddBase.vue";
import KnowledgeArea from "../components/Knowledge/KnowledgeArea.vue";
import KnowledgeList from "../components/Knowledge/KnowledgeList.vue";
import KnowledgeForm from "../components/Knowledge/KnowledgeForm/KnowledgeFormBase";
import PartnersArea from "../components/Partners/PartnersArea.vue";
import PartnersForm from "../components/Partners/PartnersForm/PartnersFormBase.vue";
import InnerCheckArea from "../components/InnerCheck/InnerCheckArea.vue";
import InnerCheckForm from "../components/InnerCheck/InnerCheckForm/InnerCheckFormBase.vue";
import ProfileDeclarationForm from "../components/Profile/ProfileDeclarationForm.vue";
import ProfileLNAFormView from "../components/Profile/ProfileLNAFormView.vue";
import Risk from "../components/Risk/RiskArea.vue";
import RiskChart from "../components/Risk/Chart/RiskChart.vue";
import MatrixFormPrint from "../components/Risk/Matrix/MatrixFormPrint.vue";
import MatrixList from "../components/Risk/Matrix/MatrixList.vue";
import RiskList from "../components/Risk/Risks/RiskList.vue";
import RiskActivityList from "../components/Risk/Activity/ActivityList.vue";
import RiskReports from "../components/Risk/Reports/RiskReportHeader.vue";
import RiskForm from "../components/Risk/Risks/RiskForm.vue";
import MatrixForm from "../components/Risk/Matrix/MatrixForm.vue";
import RiskChartPrint from "../components/Risk/Chart/RiskChartPrint.vue";
import AdminArea from "../components/Adminstration/AdminArea.vue";
import AdminActivity from "../components/Adminstration/common/AdminActivity.vue";
import AdminBackup from "../components/Adminstration/common/AdminBackup.vue";
import AdminUsers from "../components/Adminstration/common/AdminUsers.vue";
import AdminWorkers from "../components/Adminstration/Directory/AdminWorkers.vue";
import AdminStatistics from "../components/Adminstration/common/AdminStatistics.vue";
import AdminOrg from "../components/Adminstration/common/AdminOrg.vue";
import AdminCommon from "../components/Adminstration/Settings/AdminCommon.vue";
import AdminKnowledge from "../components/Adminstration/Settings/AdminKnowledge.vue";
import AdminEducation from "../components/Adminstration/Settings/AdminEducation.vue";
import AdminDeclaration from "../components/Adminstration/Settings/AdminDeclaration.vue";
import AdminInnerCheck from "../components/Adminstration/Directory/AdminInnerChecks.vue";
import AdminRisk from "../components/Adminstration/Settings/AdminRisk.vue";
import AdminNotification from "../components/Adminstration/Settings/AdminNotification/AdminNotification.vue";
import AdminHotLine from "../components/Adminstration/Settings/AdminHotLine.vue";
import AdminAdmin from "../components/Adminstration/Settings/AdminAdmin.vue";
import AdminCalendar from "../components/Adminstration/Directory/AdminCalendar.vue";
import AdminDirectoryList from "../components/Adminstration/Directory/AdminDirectoryList.vue";
import AdminDepart from "../components/Adminstration/common/AdminDepart.vue";
import AdminCpTypes from "../components/Adminstration/Directory/AdminCpTypes.vue";
import AdminHlCategory from "../components/Adminstration/Directory/AdminHlCategory.vue";
import AdminLnaTypes from "../components/Adminstration/Directory/AdminLnaTypes.vue";
import AdminCountries from "../components/Adminstration/Directory/AdminCountries.vue";
import AdminRoles from "../components/Adminstration/Directory/AdminRoles.vue";
import SecurityEvents from "../components/Adminstration/common/SecurityEvents.vue";
import SecuritySettings from "../components/Adminstration/Settings/SecuritySettings.vue";
import ProfileSheetSettings from "../components/Adminstration/Settings/ProfileSheetSettings.vue";
import thePage404 from "../components/common/thePage404.vue";
// автосверка
import AutochecksArea from "../components/Autochecks/AutochecksArea.vue";
import AutocheckForm from "../components/Autochecks/AutocheckForm/AutocheckFormBase.vue";

import theAbout from "../views/theAbout.vue";
import AppLayout from "../layouts/App";
import PublicLayout from "../layouts/Public";

export default [
  {
    path: links.home,
    //name: "Home",
    component: PublicLayout,
    meta: {
      title: titles.home,
      metaTags: [
        {
          name: "description",
          content: titles.home,
        },
        {
          property: "og:description",
          content: titles.home,
        },
      ],
    },
    children: [
      { path: "/", redirect: links.dashboard },
      {
        path: links.auth,
        name: "theAuth",
        meta: {
          title: titles.auth,
          metaTags: [
            {
              name: "description",
              content: titles.auth,
            },
            {
              property: "og:description",
              content: titles.auth,
            },
          ],
        },
        component: theAuth,
      },
      {
        path: links.about,
        name: "theAbout",
        meta: {
          title: titles.about,
          metaTags: [
            {
              name: "description",
              content: titles.about,
            },
            {
              property: "og:description",
              content: titles.about,
            },
          ],
        },
        component: theAbout,
      },
      {
        path: links.print,
        props: true,
        name: "Profile preprint",
        meta: {
          title: titles.profile,
          metaTags: [
            {
              name: "description",
              content: titles.profile,
            },
            {
              property: "og:description",
              content: titles.profile,
            },
          ],
        },
        component: ProfilePrint,
      },
      {
        path: links.matrixFormPrint + ":msg",
        props: true,
        name: "MatrixFormPrint",
        meta: {
          title: titles.risk,
          metaTags: [
            {
              name: "description",
              content: titles.risk,
            },
            {
              property: "og:description",
              content: titles.risk,
            },
          ],
        },
        component: MatrixFormPrint,
      },
      // riskChartPrint
      {
        path: links.riskChartPrint,
        // props: true,
        name: "RiskChartPrint",
        meta: {
          title: titles.risk,
          metaTags: [
            {
              name: "description",
              content: titles.risk,
            },
            {
              property: "og:description",
              content: titles.risk,
            },
          ],
        },
        component: RiskChartPrint,
      },
      // EducationCourseCertificate
      {
        path: links.educationCourseCertificate,
        name: "EducationCourseCertificate",
        component: EducationCourseCertificate,
        props: true,
        meta: {
          title: titles.education,
          metaTags: [
            {
              name: "description",
              content: titles.education,
            },
            {
              property: "og:description",
              content: titles.education,
            },
          ],
        },
      },
      {
        path: links.printDeclaration + ":msg",
        props: true,
        name: "DeclarationPrint",
        meta: {
          title: titles.declarations,
          metaTags: [
            {
              name: "description-print",
              content: titles.declarations,
            },
            {
              property: "og:description",
              content: titles.declarations,
            },
          ],
        },
        component: DeclarationPrint,
      },
      // Dashboard
      {
        path: links.dashboard,
        name: "Dashboard",
        meta: {
          title: titles.home,
          metaTags: [
            {
              name: "description",
              content: titles.home,
            },
            {
              property: "og:description",
              content: titles.home,
            },
          ],
        },
        component: AppLayout,
        children: [
          { path: "/a", redirect: "/a/login" },
          {
            path: links.personalArea,
            name: "PersonalArea",
            meta: {
              title: titles.home,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: PersonalArea,
          },
          // profile
          {
            path: links.profile,
            //name: "Profile",
            meta: {
              title: titles.profile,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: ProfileArea,
            children: [
              { path: "/", redirect: links.profileSheets },
              {
                path: links.profileSheets,
                name: "ProfileSheets",
                meta: {
                  title: titles.profile,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.profile,
                    },
                    {
                      property: "og:description",
                      content: titles.profile,
                    },
                  ],
                },
                component: ProfileCardList,
              },
              {
                path: links.profileDeclarations,
                name: "ProfileDeclarations",
                meta: {
                  title: titles.profile,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.profile,
                    },
                    {
                      property: "og:description",
                      content: titles.profile,
                    },
                  ],
                },
                component: ProfileDeclarations,
              },
              {
                path: links.profileEducation,
                name: "ProfileEducation",
                meta: {
                  title: titles.profile,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.profile,
                    },
                    {
                      property: "og:description",
                      content: titles.profile,
                    },
                  ],
                },
                component: ProfileEducationProgram,
              },
              {
                path: links.profileAcquaintance,
                name: "ProfileAcquaintance",
                meta: {
                  title: titles.profile,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.profile,
                    },
                    {
                      property: "og:description",
                      content: titles.profile,
                    },
                  ],
                },
                component: ProfileLNA,
              },
              {
                path: links.profileNotifications,
                name: "ProfileNotifications",
                meta: {
                  title: titles.profile,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.profile,
                    },
                    {
                      property: "og:description",
                      content: titles.profile,
                    },
                  ],
                },
                component: ProfileNotifications,
              },
              {
                path: links.profilePresent,
                name: "ProfilePresent",
                meta: {
                  title: titles.profile,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.profile,
                    },
                    {
                      property: "og:description",
                      content: titles.profile,
                    },
                  ],
                },
                component: ProfilePresent,
              },
            ],
          },
          {
            path: links.profileAcquaintanceFormView + ":msg",
            name: "ProfileAcquaintanceFormView",
            props: true,
            meta: {
              title: titles.profile,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: ProfileLNAFormView,
          },
          {
            path: links.declarations,
            name: "Declarations",
            meta: {
              title: titles.declarations,
              metaTags: [
                {
                  name: "description",
                  content: titles.declarations,
                },
                {
                  property: "og:description",
                  content: titles.declarations,
                },
              ],
            },
            component: DeclarationArea,
          },
          {
            path: links.declarationForm + ":msg",
            name: "DeclarationForm",
            component: DeclarationForm,
            props: true,
            meta: {
              title: titles.declarations,
              metaTags: [
                {
                  name: "description-full",
                  content: titles.declarations,
                },
                {
                  property: "og:description",
                  content: titles.declarations,
                },
              ],
            },
          },
          {
            path: links.education,
            name: "Education",
            meta: {
              title: titles.education,
              metaTags: [
                {
                  name: "description",
                  content: titles.education,
                },
                {
                  property: "og:description",
                  content: titles.education,
                },
              ],
            },
            component: EducationArea,
          },
          {
            path: links.educationCourse + ":msg",
            name: "EducationCourse",
            component: EducationCourse,
            props: true,
            meta: {
              title: titles.education,
              metaTags: [
                {
                  name: "description",
                  content: titles.education,
                },
                {
                  property: "og:description",
                  content: titles.education,
                },
              ],
            },
          },
          {
            path: links.educationCourseTest + ":msg",
            name: "EducationCourseTest",
            component: EducationCourseTest,
            props: true,
            meta: {
              title: titles.education,
              metaTags: [
                {
                  name: "description",
                  content: titles.education,
                },
                {
                  property: "og:description",
                  content: titles.education,
                },
              ],
            },
          },
          {
            path: links.educationProgramAdd + ":msg",
            name: "EducationProgramAdd",
            component: ProgramAddBase,
            props: true,
            meta: {
              title: titles.education,
              metaTags: [
                {
                  name: "description",
                  content: titles.education,
                },
                {
                  property: "og:description",
                  content: titles.education,
                },
              ],
            },
          },
          {
            path: links.hotline,
            name: "HotLine",
            meta: {
              title: titles.hotline,
              metaTags: [
                {
                  name: "description",
                  content: titles.hotline,
                },
                {
                  property: "og:description",
                  content: titles.hotline,
                },
              ],
            },
            component: HotLineArea,
          },
          {
            path: links.hotlineForm + ":idMessage",
            name: "HotlineForm",
            component: HotlineForm,
            props: true,
            meta: {
              title: titles.hotline,
              metaTags: [
                {
                  name: "description",
                  content: titles.hotline,
                },
                {
                  property: "og:description",
                  content: titles.hotline,
                },
              ],
            },
          },
          {
            path: links.profileCard + ":id",
            props: true,
            name: "ProfileCard",
            meta: {
              title: titles.profile,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: ProfileCard,
          },
          {
            path: links.profileDeclarationForm + ":msg",
            name: "ProfileDeclarationForm",
            props: true,
            meta: {
              title: titles.profile,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: ProfileDeclarationForm,
          },
          {
            path: links.knowledge,
            name: "Knowledge",
            meta: {
              title: titles.knowledge,
              metaTags: [
                {
                  name: "description",
                  content: titles.knowledge,
                },
                {
                  property: "og:description",
                  content: titles.knowledge,
                },
              ],
            },
            component: KnowledgeArea,
          },
          {
            path: links.knowledgeList + ":msg",
            name: "KnowledgeList",
            props: true,
            meta: {
              title: titles.knowledge,
              metaTags: [
                {
                  name: "description",
                  content: titles.knowledge,
                },
                {
                  property: "og:description",
                  content: titles.knowledge,
                },
              ],
            },
            component: KnowledgeList,
          },
          // альтернативный интерфейс БЗ
          {
            path: links.knowledgeAlt,
            name: "KnowledgeAlt",
            meta: {
              title: titles.knowledge,
              metaTags: [
                {
                  name: "description",
                  content: titles.knowledge,
                },
                {
                  property: "og:description",
                  content: titles.knowledge,
                },
              ],
            },
            component: KnowledgeArea,
          },
          {
            path: links.knowledgeListAlt + ":msg",
            name: "KnowledgeListAlt",
            props: true,
            meta: {
              title: titles.knowledge,
              metaTags: [
                {
                  name: "description",
                  content: titles.knowledge,
                },
                {
                  property: "og:description",
                  content: titles.knowledge,
                },
              ],
            },
            component: KnowledgeList,
          },
          {
            path: links.profileAcquaintanceAltView + ":msg",
            name: "profileAcquaintanceAltView",
            props: true,
            meta: {
              title: titles.profile,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: ProfileLNAFormView,
          },
          {
            path: links.knowledgeForm + ":msg",
            name: "KnowledgeForm",
            props: true,
            meta: {
              title: titles.knowledge,
              metaTags: [
                {
                  name: "description",
                  content: titles.knowledge,
                },
                {
                  property: "og:description",
                  content: titles.knowledge,
                },
              ],
            },
            component: KnowledgeForm,
          },
          {
            path: links.partners,
            name: "Partners",
            meta: {
              title: titles.partners,
              metaTags: [
                {
                  name: "description",
                  content: titles.partners,
                },
                {
                  property: "og:description",
                  content: titles.partners,
                },
              ],
            },
            component: PartnersArea,
          },
          {
            path: links.partnersForm + ":msg",
            name: "PartnersForm",
            props: true,
            meta: {
              title: titles.partners,
              metaTags: [
                {
                  name: "description",
                  content: titles.partners,
                },
                {
                  property: "og:description",
                  content: titles.partners,
                },
              ],
            },
            component: PartnersForm,
          },
          // cp
          {
            path: links.cpForm + ":msg",
            name: "CPFormBase",
            component: CPFormBase,
            props: true,
            meta: {
              title: titles.cp,
              metaTags: [
                {
                  name: "description-full",
                  content: titles.cp,
                },
                {
                  property: "og:description",
                  content: titles.cp,
                },
              ],
            },
          },
          {
            path: links.cpBase,
            name: "CPBase",
            component: CPBase,
            meta: {
              title: titles.cp,
              metaTags: [
                {
                  name: "description-full",
                  content: titles.cp,
                },
                {
                  property: "og:description",
                  content: titles.cp,
                },
              ],
            },
          },
          // coi
          {
            path: links.coiForm + ":msg",
            name: "COIFormBase",
            component: COIFormBase,
            props: true,
            meta: {
              title: titles.cp,
              metaTags: [
                {
                  name: "description-full",
                  content: titles.cp,
                },
                {
                  property: "og:description",
                  content: titles.cp,
                },
              ],
            },
          },
          // innerCheck
          {
            path: links.innerCheck,
            name: "InnerCheck",
            meta: {
              title: titles.innerCheck,
              metaTags: [
                {
                  name: "description",
                  content: titles.innerCheck,
                },
                {
                  property: "og:description",
                  content: titles.innerCheck,
                },
              ],
            },
            component: InnerCheckArea,
          },
          {
            path: links.innerCheckForm + ":msg",
            name: "InnerCheckForm",
            props: true,
            meta: {
              title: titles.innerCheck,
              metaTags: [
                {
                  name: "description",
                  content: titles.innerCheck,
                },
                {
                  property: "og:description",
                  content: titles.innerCheck,
                },
              ],
            },
            component: InnerCheckForm,
          },
          // risk
          {
            path: links.risk,
            //name: "Risk",
            meta: {
              title: titles.risk,
              metaTags: [
                {
                  name: "description",
                  content: titles.risk,
                },
                {
                  property: "og:description",
                  content: titles.risk,
                },
              ],
            },
            component: Risk,
            children: [
              { path: "", redirect: { name: "RiskChart" } },
              {
                path: links.riskChart,
                name: "RiskChart",
                meta: {
                  title: titles.risk,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.risk,
                    },
                    {
                      property: "og:description",
                      content: titles.risk,
                    },
                  ],
                },
                component: RiskChart,
              },
              {
                path: links.matrixList,
                name: "MatrixList",
                meta: {
                  title: titles.risk,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.risk,
                    },
                    {
                      property: "og:description",
                      content: titles.risk,
                    },
                  ],
                },
                component: MatrixList,
              },
              {
                path: links.riskList,
                name: "RiskList",
                meta: {
                  title: titles.risk,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.risk,
                    },
                    {
                      property: "og:description",
                      content: titles.risk,
                    },
                  ],
                },
                component: RiskList,
              },
              {
                path: links.riskActivityList,
                name: "RiskActivityList",
                meta: {
                  title: titles.risk,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.risk,
                    },
                    {
                      property: "og:description",
                      content: titles.risk,
                    },
                  ],
                },
                component: RiskActivityList,
              },
              {
                path: links.riskReports,
                name: "RiskReports",
                meta: {
                  title: titles.risk,
                  metaTags: [
                    {
                      name: "description",
                      content: titles.risk,
                    },
                    {
                      property: "og:description",
                      content: titles.risk,
                    },
                  ],
                },
                component: RiskReports,
              },
            ],
          },
          {
            path: links.riskForm + ":msg",
            name: "RiskForm",
            props: true,
            meta: {
              title: titles.risk,
              metaTags: [
                {
                  name: "description",
                  content: titles.risk,
                },
                {
                  property: "og:description",
                  content: titles.risk,
                },
              ],
            },
            component: RiskForm,
          },
          {
            path: links.matrixForm + ":msg",
            name: "MatrixForm",
            props: true,
            meta: {
              title: titles.risk,
              metaTags: [
                {
                  name: "description",
                  content: titles.risk,
                },
                {
                  property: "og:description",
                  content: titles.risk,
                },
              ],
            },
            component: MatrixForm,
          },
          // administration
          {
            path: links.administration,
            name: "AdminArea",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminArea,
          },
          {
            path: links.adminActivity,
            name: "AdminActivity",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminActivity,
          },
          {
            path: links.adminBackup,
            name: "AdminBackup",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminBackup,
          },
          {
            path: links.adminUsers,
            name: "AdminUsers",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminUsers,
          },
          {
            path: links.adminOrg,
            name: "AdminOrg",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminOrg,
          },
          {
            path: links.profilesKadr,
            name: "ProfileList",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: ProfileList,
          },
          {
            path: links.profileChecks,
            name: "ProfileChecksList",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: ProfileChecksList,
          },
          {
            path: links.adminCommon,
            name: "AdminCommon",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminCommon,
          },
          {
            path: links.adminKnowledge,
            name: "AdminKnowledge",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminKnowledge,
          },
          {
            path: links.adminEducation,
            name: "AdminEducation",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminEducation,
          },
          {
            path: links.adminDeclaration,
            name: "AdminDeclaration",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminDeclaration,
          },
          {
            path: links.adminInnerCheck,
            name: "AdminInnerCheck",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminInnerCheck,
          },
          {
            path: links.adminRisk,
            name: "AdminRisk",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminRisk,
          },
          {
            path: links.adminPartners,
            name: "AdminPartners",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: PartnersArea,
          },
          {
            path: links.adminPartnersForm + ":msg",
            name: "AdminPartnersForm",
            props: true,
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: PartnersForm,
          },
          {
            path: links.adminHotLine,
            name: "AdminHotLine",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminHotLine,
          },
          {
            path: links.adminAdmin,
            name: "AdminAdmin",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminAdmin,
          },
          {
            path: links.adminCalendar,
            name: "AdminCalendar",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminCalendar,
          },
          {
            path: links.adminWorkers,
            name: "AdminWorkers",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminWorkers,
          },
          {
            path: links.adminStatistics,
            name: "AdminStatistics",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminStatistics,
          },
          {
            path: links.adminDirectoryList + ":msg",
            name: "AdminDirectoryList",
            props: true,
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminDirectoryList,
          },
          {
            path: links.adminCpTypes,
            name: "AdminCpTypes",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminCpTypes,
          },
          {
            path: links.adminHlCategory,
            name: "AdminHlCategory",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminHlCategory,
          },
          {
            path: links.adminLnaTypes,
            name: "AdminLnaTypes",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminLnaTypes,
          },
          {
            path: links.adminCountries,
            name: "AdminCountries",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminCountries,
          },
          {
            path: links.adminDepartment,
            name: "AdminDepart",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminDepart,
          },
          {
            path: links.adminRoles,
            name: "AdminRoles",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: AdminRoles,
          },
          // ProfileSheetSettings
          {
            path: links.profileSheetSettings,
            name: "ProfileSheetSettings",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: ProfileSheetSettings,
          },
          // security
          {
            path: links.securityEvents,
            name: "SecurityEvents",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: SecurityEvents,
          },
          {
            path: links.securitySettings,
            name: "SecuritySettings",
            meta: {
              title: titles.administration,
              metaTags: [
                {
                  name: "description",
                  content: titles.administration,
                },
                {
                  property: "og:description",
                  content: titles.administration,
                },
              ],
            },
            component: SecuritySettings,
          },
          // ProfileSettings
          {
            path: links.profileSettings,
            name: "ProfileSettings",
            meta: {
              title: titles.profile,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: ProfileSettings,
          },
          // adminNotification
          {
            path: links.adminNotification,
            name: "AdminNotification",
            meta: {
              title: titles.profile,
              metaTags: [
                {
                  name: "description",
                  content: titles.profile,
                },
                {
                  property: "og:description",
                  content: titles.profile,
                },
              ],
            },
            component: AdminNotification,
          },
          // автосверка
          {
            path: links.autochecks,
            name: "AutochecksArea",
            meta: {
              title: titles.autochecks,
              metaTags: [
                {
                  name: "description",
                  content: titles.autochecks,
                },
                {
                  property: "og:description",
                  content: titles.autochecks,
                },
              ],
            },
            component: AutochecksArea,
          },
          {
            path: links.autocheckForm + ":msg",
            name: "AutocheckForm",
            props: true,
            meta: {
              title: titles.autochecks,
              metaTags: [
                {
                  name: "description",
                  content: titles.autochecks,
                },
                {
                  property: "og:description",
                  content: titles.autochecks,
                },
              ],
            },
            component: AutocheckForm,
          },
        ],
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "Not Found", component: thePage404 },
];
