import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.map.js";
import { formatName, fmtNameShort, limitBound, fmtNameFull } from "@/helpers/text";
import UserSelector from "@/components/Helpers/UserSelector";
import Calendar from "@/components/Helpers/Calendar.vue";
import { mapActions, mapGetters } from "vuex";
import { formatDate, fmtDateShort, formatDateObj, formatObjDate, fmtDate, dateObjInit } from "@/helpers/date";
import { invalidMinMaxDate } from "@/helpers/validate";
export default {
  name: "WorkersToAssign",
  components: {
    UserSelector,
    Calendar
  },
  data: () => ({
    isChange: false,
    fmtNameFull,
    currentPage: 1,
    perPage: 10,
    gotoPage: 1,
    tableFields: [{
      key: "FIOT",
      label: "ФИО работника",
      class: "text-nowrap"
    }, {
      key: "student_department_name",
      label: "Подразделение"
    }, {
      key: "deadlineT",
      label: "Срок ознакомления"
      //formatter: (value) => formatDate(value, fmtDateShort),
    }, {
      key: "btnChange",
      label: "Измененить",
      class: "text-center"
    }, {
      key: "btnRemove",
      label: "Удалить",
      class: "text-center"
    }],
    worker: null,
    deadline: {
      ...dateObjInit
    },
    showModalAdd: false,
    showModalRemove: false,
    titleModalRemove: "",
    item2Remove: {},
    alertTitle: "",
    showAlert: false
  }),
  methods: {
    ...mapActions("knowledge", ["assignDocument", "unassignDocument", "confirmDocument", "updateWorkersToAss", "updateWorkersList", "reassignDocument"]),
    formatName,
    changePage() {
      this.currentPage = limitBound(Number(this.gotoPage), 1, this.workersToAssCount / this.perPage, this.currentPage);
      this.updateWorkersToAss({
        page: this.currentPage,
        libmat_id: this.knowlegeDocument.id,
        confirmed: false
      });
    },
    async handlerOk() {
      const deadline = formatDateObj(this.deadline, fmtDate, null);
      if (this.isChange) {
        if (deadline !== this.worker.deadline) await this.reassignDocument({
          id: this.worker.id,
          deadline
        });
      } else await this.assignDocument({
        id: this.knowlegeDocument.id,
        allOrg: false,
        depart: [],
        workers: [{
          id: this.worker.id
        }],
        deadline
      });
      await this.updateWorkersToAss({
        page: this.currentPage,
        libmat_id: this.knowlegeDocument.id,
        confirmed: false
      });
    },
    async clickRemove(item) {
      this.titleModalRemove = `Удалить
      ${formatName({
        first_name: item.student_first_name,
        middle_name: item.student_middle_name,
        last_name: item.student_last_name
      }, fmtNameFull)} из списка?`;
      this.item2Remove = item;
      this.showModalRemove = true;
    },
    async doRemove() {
      await this.unassignDocument(this.item2Remove.id);
      this.currentPage = 1;
      await this.updateWorkersToAss({
        page: 1,
        libmat_id: this.knowlegeDocument.id,
        confirmed: false
      });
    },
    selectedWorker(worker) {
      this.worker = worker;
    },
    clickChange(item) {
      this.deadline = formatObjDate(item.deadline);
      this.isChange = true;
      this.worker = item;
      this.showModalAdd = true;
    },
    clickAddWorker() {
      this.isChange = false;
      this.worker = null;
      this.deadline = formatObjDate(this.knowlegeDocument.deadline);
      this.showModalAdd = true;
    },
    // утвердить назначение
    async clickConfirm() {
      // валидация дат
      this.showAlert = false;
      const arr = [];
      // поля «Срок ознакомления» страницы «Назначить ознакомление» не ранее даты внесения сведений
      this.items4table.forEach((el, index) => {
        const invalid = invalidMinMaxDate(el.deadline, formatDate(new Date(), fmtDate), null, `строка ${index + 1} «Срок ознакомления» должен быть не ранее даты внесения сведений`);
        if (invalid) arr.push(invalid);
      });
      if (arr.length > 0) {
        this.showAlert = true;
        this.alertTitle = arr.join("\n");
        return;
      }
      await this.confirmDocument(this.knowlegeDocument.id);
      // обновим списки
      this.updateWorkersList({
        libmat_id: this.knowlegeDocument.id,
        confirmed: true,
        is_signed: true
      });
      if (this.currentPage === 1) this.updateWorkersToAss({
        page: 1,
        libmat_id: this.knowlegeDocument.id,
        confirmed: false
      });else this.currentPage = 1;
    }
  },
  computed: {
    ...mapGetters("knowledge", ["workersToAss", "workersToAssCount", "workersToAssLoading", "knowlegeDocument", "knowlegeDocumentLoading", "sectionFormLoading"]),
    okModalDisabled() {
      return !formatDateObj(this.deadline, fmtDate, null) || !this.worker;
    },
    currentPageModel: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = value;
        this.updateWorkersToAss({
          page: value,
          libmat_id: this.knowlegeDocument.id,
          confirmed: false
        });
      }
    },
    readOnly() {
      return this.knowlegeDocumentLoading || this.sectionFormLoading;
    },
    items4table() {
      return Array.isArray(this.workersToAss) ? this.workersToAss.map(el => ({
        ...el,
        deadlineT: formatDate(el.deadline, fmtDateShort),
        FIOT: formatName({
          first_name: el.student_first_name,
          middle_name: el.student_middle_name,
          last_name: el.student_last_name
        }, fmtNameShort)
      })) : [];
    }
  }
};