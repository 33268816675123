import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  key: 0,
  class: "table-responsive"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_BTable = _resolveComponent("BTable");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_container = _resolveComponent("b-container");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  return _openBlock(), _createElementBlock("div", null, [_cache[3] || (_cache[3] = _createElementVNode("h5", null, "Отчет по КП", -1)), !$options.isEmpty ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BTable, {
    borderless: "",
    items: _ctx.cp,
    fields: _ctx.tableFields,
    stacked: "md",
    busy: _ctx.cpLoading,
    small: ""
  }, {
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    "cell(index)": _withCtx(data => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + data.index + 1), 1)]),
    _: 1
  }, 8, ["items", "fields", "busy"])])) : (_openBlock(), _createBlock(_component_b_alert, {
    key: 1,
    show: "",
    variant: "light",
    class: "text-center"
  }, {
    default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
      src: _imports_0,
      alt: "empty",
      class: "mb-4"
    }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
    _: 1
  })), _createVNode(_component_b_card_footer, {
    "footer-bg-variant": "white",
    "footer-class": "px-4 py-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_container, null, {
      default: _withCtx(() => [_createVNode(_component_b_row, null, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto",
          class: "ms-0 me-auto mb-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_pagination, {
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.cpCount,
            "per-page": _ctx.perPage,
            pills: "",
            class: "mb-0"
          }, null, 8, ["modelValue", "total-rows", "per-page"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}