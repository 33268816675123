import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Тип контрольной процедуры *"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.cpForm.cp_type,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.cpForm.cp_type = $event),
          options: $options.option4type,
          onChange: _cache[1] || (_cache[1] = $event => $options.changeData('cp_type')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Не выбран")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Периодичность контроля *"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.cpForm.period,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.cpForm.period = $event),
          options: $options.option4period,
          onChange: _cache[3] || (_cache[3] = $event => $options.changeData('period')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Не выбрана")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Задание *"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.cpForm.task,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.cpForm.task = $event),
      rows: "3",
      "max-rows": "3",
      trim: "",
      onChange: _cache[5] || (_cache[5] = $event => $options.changeData('task')),
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _cache[8] || (_cache[8] = _createElementVNode("p", {
    class: "text-muted"
  }, "* обязательные к заполнению поля", -1))]);
}