import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapGetters } from "vuex";
import { formatName, fmtNameFull, NONAME_SHORT } from "@/helpers/text";
import { mapStateToText, canValidate, canApprove, canCheckProfile } from "@/helpers/profile";
import { arrayEqual, deepEqual } from "@/helpers/global";
import { addСhildRoles, listСhildRoles } from "@/helpers/roles";
import { mapCheckToText } from "@/helpers/profile";
import { formatDateObj, formatObjDate, fmtDate, dateObjInit } from "@/helpers/date";
import Calendar from "@/components/Helpers/Calendar.vue";
export default {
  name: "ProfileListAction",
  components: {
    Calendar
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    idModal: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showModal: false,
    canValidate,
    canApprove,
    canCheckProfile,
    mapStateToText,
    mapCheckToText,
    department: null,
    selectedRoles: [],
    oldSelectedRoles: [],
    // роли которые были выбраны до изменения (нужно для проверки дочерних)
    position: "",
    code: "",
    // ТН
    positionState: null,
    codeState: null,
    roleState: null,
    startDate: {
      ...dateObjInit
    },
    NONAME_SHORT
  }),
  methods: {
    isActionableState(state) {
      return this.canValidate(state) || this.canApprove(state) || this.canCheckProfile(state);
    },
    async clickAction() {
      this.position = this.item.position || "";
      this.code = this.item.worker_code;
      this.department = this.item.department_id;
      this.selectedRoles = this.item.user_roles || [];
      this.oldSelectedRoles = this.selectedRoles.concat([]);
      this.startDate = formatObjDate(this.item.hiring_date);
      this.showModal = true;
    },
    approve() {
      if (!this.validatePosition() || !this.validateCode() || !this.validateRole() || !formatDateObj(this.startDate, fmtDate, null)) return;
      const itemStartDate = formatObjDate(this.item.hiring_date);
      const data = {
        params: {
          position: this.position !== this.item.position ? this.position : null,
          worker_code: this.code !== this.item.worker_code ? this.code : null,
          roles: arrayEqual(this.selectedRoles, this.item.user_roles, false) ? null : this.selectedRoles,
          department: this.department !== this.item.department_id ? this.department : null,
          hiring_date: deepEqual(itemStartDate, this.startDate) ? null : formatDateObj(this.startDate, fmtDate, null)
        },
        item: this.item
      };
      this.$emit("profileApprove", data);
      this.showModal = false;
    },
    validateProfile() {
      this.$emit("profileValidate", this.item.id);
      this.showModal = false;
    },
    check() {
      this.$emit("profileCheck", this.item.id);
      this.showModal = false;
    },
    handlerReject() {
      this.$emit("actionReject", this.item.id);
      this.showModal = false;
    },
    validatePosition() {
      const validPosition = this.position.trim() !== "";
      this.positionState = validPosition ? null : false;
      return validPosition;
    },
    validateCode() {
      const validCode = this.code && this.code.trim() !== "";
      this.codeState = validCode ? null : false;
      return validCode;
    },
    changeRoles() {
      const newRole = this.selectedRoles.find(role => !this.oldSelectedRoles.includes(role));
      if (newRole) {
        this.selectedRoles = addСhildRoles(this.selectedRoles, newRole, this.adminGroups);
      }
      this.oldSelectedRoles = this.selectedRoles.concat([]); // обновим old
      this.validateRole(); // проверим статус
    },
    validateRole() {
      const validRole = this.selectedRoles.length > 0;
      this.roleState = validRole ? null : false;
      return validRole;
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departments"]),
    ...mapGetters("administration", ["adminGroups", "adminRolesRef"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [{
        value: null,
        text: "Выберите подразделение"
      }]);
    },
    checkRoles() {
      return listСhildRoles(this.selectedRoles, this.adminGroups);
    },
    titleModal() {
      return this.canCheckProfile(this.item.state) ? "Подтверждаете проверку личного листка?" : formatName(this.item, fmtNameFull);
    },
    disabledOK() {
      return !formatDateObj(this.startDate, fmtDate, null);
    }
  }
};