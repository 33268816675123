import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "table-header"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "bi bi-exclamation-circle-fill text-danger",
  title: "У пользователя почта на небезопасном домене"
};
const _hoisted_4 = {
  class: "table-footer"
};
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { formatDate, fmtDateMonthTime, fmtDateShort } from "@/helpers/date";
import { formatName, fmtNameShort, limitBound } from "@/helpers/text";
import { workerToStates, stateWorker } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth";
import { optionsDesc } from "@/helpers/text";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification.vue";
import AdminWorkersChg from "./AdminWorkersChg.vue";
const perPage = 20;
export default {
  __name: 'AdminWorkers',
  setup(__props) {
    const permissions = ref({});
    const showModalDismiss = ref(false);
    const currentItem = ref({});
    const sort = ref(null);
    const currentPage = ref(1);
    const optionsSort = [{
      value: "last_name,first_name,middle_name",
      text: "по фамилии"
    }, {
      value: "department_name",
      text: "по подразделению"
    }];
    const tableFields = [{
      key: "index",
      label: "№ п/п"
    }, {
      key: "problem",
      label: ""
    }, {
      key: "worker_code",
      label: "Табельный номер"
    }, {
      key: "FIO",
      label: "Работник"
    }, {
      key: "department_name",
      label: "Подразделение"
    }, {
      key: "position",
      label: "Должность"
    }, {
      key: "created_at",
      label: "Дата трудоустройства",
      formatter: value => formatDate(value, fmtDateMonthTime),
      tdAttr: value => ({
        "data-created": formatDate(value, fmtDateShort)
      }),
      class: "text-nowrap worker-date-list"
    }, {
      key: "state",
      label: "Статус",
      formatter: value => stateWorker[value]
    }, {
      key: "btnChange",
      label: "",
      class: "text-center p-0"
    }, {
      key: "btnDismiss",
      label: "",
      class: "text-center p-0"
    }];
    const breadcrumbs = [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }];
    const settingsChange = {
      title: "Редактировать работника",
      iconType: "pencil-square"
    };
    const store = useStore();
    const adminWorkers = computed(() => store.getters["administration/adminWorkers"]);
    const adminWorkersLoading = computed(() => store.getters["administration/adminWorkersLoading"]);
    const adminWorkersCount = computed(() => store.getters["administration/adminWorkersCount"]);
    const departmentsLoading = computed(() => store.getters["referenceBooks/departmentsLoading"]);
    const updateDepartments = () => store.dispatch("referenceBooks/updateDepartments");
    async function sortChanged({
      sortBy,
      sortDesc
    }) {
      sort.value = sortDesc ? `-${sortBy}` : sortBy;
      await loadData();
    }
    const clickDismiss = item => {
      currentItem.value = item;
      showModalDismiss.value = true;
    };
    async function doDismiss() {
      const fio = formatName(currentItem.value, fmtNameShort);
      await store.dispatch("administration/stateToAdminWorkers", {
        id: currentItem.value.id,
        stateTo: workerToStates.toResigned,
        msg: `Работник ${fio} уволен`
      });
      currentPage.value = 1;
      await loadData();
    }
    async function clickChange(data) {
      await store.dispatch("administration/changeAdminWorkers", data);
      await loadData();
    }
    async function changePage(newPage) {
      currentPage.value = limitBound(Number(newPage), 1, adminWorkersCount.value / perPage, currentPage.value);
      await loadData();
    }
    async function searchChanged(string) {
      store.dispatch("administration/updateAdminWorkers", {
        page: 1,
        search_string: string,
        page_size: perPage
      });
    }
    async function loadData() {
      store.dispatch("administration/updateAdminWorkers", {
        page: currentPage.value,
        sort: sort.value,
        page_size: perPage
      });
    }
    onMounted(async () => {
      const instance = getCurrentInstance();
      permissions.value = instance?.appContext.config.globalProperties.$perm;
      await loadData();
      updateDepartments();
    });
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_table = _resolveComponent("b-table");
      const _component_b_card = _resolveComponent("b-card");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_modal = _resolveComponent("b-modal");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(theNotification), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs
      }), _cache[4] || (_cache[4] = _createElementVNode("h1", {
        class: "mb-4"
      }, "Работники", -1)), _createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0 shadow-sm"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_row, {
          class: "d-flex flex-wrap m-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "6",
            xl: "2"
          }, {
            default: _withCtx(() => [_createVNode(_component_CustomSearch, {
              onOnSearch: searchChanged
            })]),
            _: 1
          }), _createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "5",
            xl: "4",
            class: "me-auto mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_CustomSort, {
              optionsSort: optionsSort,
              optionsDesc: _unref(optionsDesc),
              onOnSort: sortChanged
            }, null, 8, ["optionsDesc"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(_component_b_table, {
          class: "table-custom",
          responsive: "",
          hover: "",
          small: "",
          "show-empty": "",
          stacked: "md",
          items: adminWorkers.value,
          fields: tableFields,
          busy: adminWorkersLoading.value || departmentsLoading.value
        }, {
          "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
          "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
          empty: _withCtx(() => [_createVNode(_component_b_alert, {
            show: "",
            variant: "light",
            class: "text-center"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
              src: _imports_0,
              alt: "empty",
              class: "mb-4"
            }, null, -1), _createElementVNode("h4", null, "Работников нет", -1)])),
            _: 1
          })]),
          "cell(problem)": _withCtx(row => [_withDirectives(_createElementVNode("i", _hoisted_3, null, 512), [[_vShow, !row.item.is_safe_email]])]),
          "cell(FIO)": _withCtx(row => [_createTextVNode(_toDisplayString(_unref(formatName)(row.item, _unref(fmtNameShort))), 1)]),
          "cell(btnChange)": _withCtx(row => [_unref(stateWorker)[row.item.state] !== _unref(stateWorker).resigned ? (_openBlock(), _createBlock(AdminWorkersChg, {
            key: 0,
            idModal: `changeWorker${row.item.id}`,
            settings: settingsChange,
            item: row.item,
            onUpdateWorker: clickChange
          }, null, 8, ["idModal", "item"])) : _createCommentVNode("", true)]),
          "cell(btnDismiss)": _withCtx(row => [_unref(stateWorker)[row.item.state] !== _unref(stateWorker).resigned ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            variant: "link",
            onClick: $event => clickDismiss(row.item),
            "data-hook": "iconDismissWorker",
            disabled: _unref(checkPermissions)(permissions.value.view_wa_autocheck)
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
              class: "bi bi-person-x"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick", "disabled"])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["items", "busy"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "4"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            totalRows: adminWorkersCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, adminWorkersCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_modal, {
        title: `Уволить работника ${_unref(formatName)(currentItem.value, _unref(fmtNameShort))} ?`,
        modelValue: showModalDismiss.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => showModalDismiss.value = $event),
        "ok-variant": "outline-danger",
        "ok-title": "Уволить",
        "cancel-variant": "outline-primary",
        "cancel-title": "Отменить",
        centered: "",
        size: "sm",
        "button-size": "sm",
        "footer-class": "p-2",
        "hide-header-close": "",
        "body-class": "d-none",
        onOk: doDismiss
      }, null, 8, ["title", "modelValue"])]);
    };
  }
};