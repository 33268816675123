import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "introduce-wrap"
};
const _hoisted_2 = {
  class: "table-header"
};
const _hoisted_3 = {
  key: 2
};
const _hoisted_4 = {
  class: "text-center text-primary my-2"
};
const _hoisted_5 = {
  class: "table-footer"
};
const _hoisted_6 = {
  class: "mb-2"
};
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { states } from "@/helpers/states";
import { formatDate, fmtDateShort, formatDateObj, fmtDate } from "@/helpers/date";
import { limitBound, optionsDesc, formatName, fmtNameShort } from "@/helpers/text";
import COISidebar from "./COISidebar";
import links from "@/router/links";
export default {
  __name: 'COI',
  setup(__props) {
    const store = useStore();
    const showSidebar = ref(false);
    const currentPage = ref(1);
    const perPage = ref(10);
    // const gotoPage = ref(1);
    const sortBy = ref(null);
    const sortDesc = ref(true);
    const filters = ref({
      declaration: null,
      state: null,
      state_modified_lte: null,
      state_modified_gte: null,
      user_search: null,
      coi_type: null,
      assignee_search: null
    });
    const optionsSort = [{
      value: "user__last_name,user__first_name,user__middle_name",
      text: "по сотруднику"
    }, {
      value: "coi_type",
      text: "по типу конфликта"
    }, {
      value: "state",
      text: "по статусу"
    }, {
      value: "state_modified_at",
      text: "по дате изменения"
    }, {
      value: "assignee__last_name",
      text: "по ответственному"
    }];
    const coiFields = [{
      key: "index",
      label: "№п/п"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "user_detailT",
      label: "Автор",
      class: "text-nowrap"
    }, {
      key: "coi_type_name",
      label: "Тип"
    }, {
      key: "source",
      label: "Источник"
    }, {
      key: "assignee_detailT",
      label: "Исполнитель",
      class: "text-nowrap"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "state_modified_atT",
      label: "Дата изменения"
    }];
    const coi = computed(() => store.getters["coi/coi"]);
    const coiCount = computed(() => store.getters["coi/coiCount"]);
    const coiLoading = computed(() => store.getters["coi/coiLoading"]);
    const sortChanged = ({
      sortBy,
      sortDesc
    }) => {
      currentPage.value = 1;
      store.dispatch("coi/fetchCoi", {
        sortBy,
        sortDesc: sortDesc ? "-" : "",
        filters: filters.value,
        page: 1,
        pageSize: 20
      });
    };
    const applyFilters = newFilters => {
      filters.value.state = newFilters.state;
      filters.value.state_modified_gte = formatDateObj(newFilters.dateFrom, fmtDate, null);
      filters.value.state_modified_lte = formatDateObj(newFilters.dateUntill, fmtDate, null);
      filters.value.assignee_search = newFilters.assignee;
      filters.value.decl_id = newFilters.number;
      filters.value.user_search = newFilters.author;
      filters.value.coi_type = newFilters.coi;
      if (currentPage.value === 1) store.dispatch("coi/fetchCoi", {
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        pageSize: 20,
        page: 1
      });else currentPage.value = 1;
    };
    const changePage = newPage => {
      currentPage.value = limitBound(Number(newPage), 1, coiCount.value / perPage.value, currentPage.value);
      store.dispatch("coi/fetchCoi", {
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        page: currentPage.value,
        pageSize: 20
      });
    };
    const searchChanged = string => {
      console.log(string);
      store.dispatch("coi/fetchCoi", {
        sortBy: "",
        sortDesc: {},
        filters: {},
        page: 1,
        pageSize: 20,
        searchString: string
      });
    };
    const items4table = computed(() => {
      return Array.isArray(coi.value) ? coi.value.map(el => ({
        ...el,
        user_detailT: formatName(el.user_detail, fmtNameShort),
        stateT: states[el.state],
        state_modified_atT: formatDate(el.state_modified_at, fmtDateShort),
        assignee_detailT: formatName(el.assignee_detail, fmtNameShort)
      })) : [];
    });
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_FilterButton = _resolveComponent("FilterButton");
      const _component_b_row = _resolveComponent("b-row");
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_BTable = _resolveComponent("BTable");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_container = _resolveComponent("b-container");
      const _component_b_offcanvas = _resolveComponent("b-offcanvas");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSearch, {
            onOnSearch: searchChanged
          })]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "4"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSort, {
            optionsSort: optionsSort,
            optionsDesc: _unref(optionsDesc),
            onOnSort: sortChanged
          }, null, 8, ["optionsDesc"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "4",
          xl: "2"
        }, {
          default: _withCtx(() => [_createVNode(_component_FilterButton, {
            onClick: _cache[0] || (_cache[0] = $event => showSidebar.value = true)
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_BTable, {
        class: "table-custom",
        hover: "",
        small: "",
        responsive: "",
        "show-empty": "",
        stacked: "md",
        items: items4table.value,
        fields: coiFields,
        busy: coiLoading.value
      }, {
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage.value + row.index + 1), 1)]),
        "cell()": _withCtx(row => [_createVNode(_component_router_link, {
          to: _unref(links).coiForm + row.item.id,
          class: _normalizeClass(row.item.is_new ? 'font-weight-bold' : '')
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
          _: 2
        }, 1032, ["to", "class"])]),
        "cell(source)": _withCtx(row => [row.item.declaration ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _unref(links).declarationForm + row.item.declaration
        }, {
          default: _withCtx(() => [_createTextVNode("ЭДКИ #" + _toDisplayString(row.item.declaration), 1)]),
          _: 2
        }, 1032, ["to"])) : row.item.innercheck ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: _unref(links).innerCheckForm + row.item.innercheck
        }, {
          default: _withCtx(() => [_createTextVNode("ВП #" + _toDisplayString(row.item.innercheck), 1)]),
          _: 2
        }, 1032, ["to"])) : (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))]),
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Конфликтов нет", -1)])),
          _: 1
        })]),
        _: 1
      }, 8, ["items", "busy"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            totalRows: coiCount.value,
            onOnChangePage: changePage
          }, null, 8, ["totalRows"]), [[_vShow, coiCount.value > perPage.value]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_offcanvas, {
        modelValue: showSidebar.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showSidebar.value = $event),
        placement: "end",
        "no-header": "",
        backdrop: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-2"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_cache[5] || (_cache[5] = _createElementVNode("h5", {
            class: "d-inline"
          }, "Фильтровать конфликты", -1)), _createElementVNode("button", {
            type: "button",
            class: "btn-close float-end",
            "aria-label": "Close",
            onClick: _cache[1] || (_cache[1] = $event => showSidebar.value = false)
          })]), _createVNode(_unref(COISidebar), {
            onEmitApplyFilters: applyFilters
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};