import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/icons/searchIcon.svg';
const _hoisted_1 = {
  class: "custom-search"
};
import { watch } from "vue";
import { useDebouncedRef } from "@/helpers/useDebouncedRef";
export default {
  __name: 'CustomSearch',
  emits: ["onSearch"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const search = useDebouncedRef("");
    watch(search, newSearch => {
      emit("onSearch", newSearch);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("input", {
        type: "search",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(search) ? search.value = $event : null),
        placeholder: "Найти...",
        class: "search-input"
      }, null, 512), [[_vModelText, _unref(search)]]), _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Search Icon",
        class: "icon"
      }, null, -1))]);
    };
  }
};