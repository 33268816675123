import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.find.js";
import "core-js/modules/es.iterator.reduce.js";
import ReportStatsEDKI from "./ReportStatsEDKI";
import ReportTimingControl from "./ReportTimingControl";
import ReportCoiEDKI from "./ReportCoiEDKI";
import ReportCpInfo from "./ReportCpInfo";
import ReportCpReport from "./ReportCpReport";
import { mapActions, mapGetters } from "vuex";
import { formatDate, fmtDate, fmtDateTime } from "@/helpers/date";
import { typeOptionsDecl, selectAll } from "@/helpers/text";
export default {
  name: "ReportHeader",
  data: () => ({
    filters: {},
    periodSelected: "month",
    periodOptions: [{
      value: "day",
      text: "День",
      day: 0,
      month: 0,
      year: 0
    }, {
      value: "week",
      text: "Неделя",
      day: 7,
      month: 0,
      year: 0
    }, {
      value: "month",
      text: "Месяц",
      day: 0,
      month: 1,
      year: 0
    }, {
      value: "quarter",
      text: "Квартал",
      day: 0,
      month: 3,
      year: 0
    }, {
      value: "year",
      text: "Год",
      day: 0,
      month: 0,
      year: 1
    }, {
      value: "all",
      text: "Всё время"
    }],
    typeSelected: "statsEDKI",
    typeOptionsDecl,
    departmentSelected: null,
    selected: {
      period: null,
      type: null,
      department: null
    } // параметры формирования отчета
  }),
  components: {
    ReportStatsEDKI,
    ReportTimingControl,
    ReportCoiEDKI,
    ReportCpInfo,
    ReportCpReport
  },
  methods: {
    ...mapActions("declarationsReports", ["updateStatsEDKI", "updateTimingControl"]),
    ...mapActions("coi", ["fetchCoi", "dropCoi"]),
    ...mapActions("cp", ["fetchCp"]),
    ...mapActions("referenceBooks", ["updateDepartments"]),
    async updateReport() {
      this.selected.period = this.periodSelected;
      this.selected.type = this.typeSelected;
      this.selected.department = this.departmentSelected;
      if (this.periodSelected !== "all") {
        const now = new Date();
        const period = this.periodOptions.find(el => el["value"] === this.periodSelected);
        const start = new Date(now.getFullYear() - period["year"], now.getMonth() - period["month"], now.getDate() - period["day"]);
        const nowDateString = formatDate(now, fmtDate);
        const nowString = formatDate(now, fmtDateTime);
        const startDateString = formatDate(start, fmtDate);
        const startString = formatDate(start, fmtDateTime);
        this.filters.date_range = `${startDateString},${nowDateString}`;
        this.filters.decl_submitted_gte = startString;
        this.filters.decl_submitted_lte = nowString;
      } else {
        this.filters.date_range = null;
        this.filters.decl_submitted_gte = null;
        this.filters.decl_submitted_lte = null;
      }
      this.filters.department_id = this.selected.department;
      switch (this.selected.type) {
        case "statsEDKI":
          this.filters.decl_submitted_gte = null;
          this.filters.decl_submitted_lte = null;
          this.updateStatsEDKI(this.filters);
          break;
        case "coiEDKI":
          this.dropCoi(); // обнулимся и начнем все заново
          this.filters.date_range = null;
          await this.fetchCoi({
            sortBy: "id",
            sortDesc: true,
            page: 1,
            filters: this.filters
          });
          break;
        case "timingControl":
          this.updateTimingControl();
          break;
        case "cpReport":
          this.fetchCp();
          break;
        case "cpInfo":
          break;
        default:
      }
    }
  },
  computed: {
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [...selectAll]);
    }
  },
  // в момент создания компоненты обновим справочники
  created() {
    this.updateDepartments();
  }
};