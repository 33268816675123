import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AutochecksList from "./AutochecksList.vue";
import TableSortAndFilter from "./TableSortAndFilter.vue";
import TablePagination from "./TablePagination.vue";
import { useRoute } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { typeBreadcrumbs } from "./autochecks";
const perPage = 20;
export default {
  __name: 'AutochecksArea',
  setup(__props) {
    const route = useRoute();

    // store
    const store = useStore();
    const autochecksCount = computed(() => store.getters["administration2/autochecksCount"]);
    const breadcrumbs = computed(() => typeBreadcrumbs(route?.query?.type));
    const currentPage = ref(1);
    onMounted(() => {
      updateData();
    });

    // updateData
    function updateData() {
      const data = {
        page: currentPage.value,
        page_size: perPage
      };
      if (route?.query?.type) data[route.query.type] = true;
      store.dispatch("administration2/updateAutochecks", data);
    }
    watch(currentPage, () => {
      setTimeout(updateData, 0);
    });
    return (_ctx, _cache) => {
      const _directive_can = _resolveDirective("can");
      return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs.value
      }, null, 8, ["breadCrumb"]), _cache[1] || (_cache[1] = _createElementVNode("h1", {
        class: "mb-4 me-auto"
      }, "Автосверка", -1)), false ? (_openBlock(), _createBlock(TableSortAndFilter, {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(AutochecksList, {
        currentPage: currentPage.value,
        perPage: perPage
      }, null, 8, ["currentPage"]), _createVNode(TablePagination, {
        currentPage: currentPage.value,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => currentPage.value = $event),
        perPage: perPage,
        count: autochecksCount.value
      }, null, 8, ["currentPage", "count"])])), [[_directive_can, _ctx.$perm.view_wa_autocheck]]);
    };
  }
};