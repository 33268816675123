import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "sites"
};
const _hoisted_2 = {
  class: "index"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  colspan: "5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "26. Сведения об адресах сайтов и (или) страниц сайтов в\n          информационно-телекоммуникационной сети «Интернет», на которых Вами\n          размещались общедоступная информация, а также данные, позволяющие Вас\n          идентифицировать"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_website_exposure,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_website_exposure = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: $options.submitData,
          state: _ctx.hasWebsiteState,
          class: "w-auto mt-2"
        }, null, 8, ["modelValue", "options", "disabled", "onChange", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _withDirectives(_createVNode(_component_b_form_row, {
    class: "mt-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "№"), _createElementVNode("th", null, " Адрес сайта и (или) страницы сайта в информационно-телекоммуникационной сети «Интернет» ")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sites, (site, index) => {
          return _openBlock(), _createElementBlock("tr", {
            key: index,
            class: "site-record"
          }, [_createElementVNode("td", _hoisted_2, _toDisplayString(index + 1), 1), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
            modelValue: site.name,
            "onUpdate:modelValue": $event => site.name = $event,
            type: "url",
            onChange: $event => $options.submitSitesUpdate(index),
            state: site.nameState,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete",
            variant: "outline",
            onClick: $event => $options.removeRow(index)
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_4, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_5, [_createVNode(_component_AddRow, {
          onClicked: $options.addSite
        }, null, 8, ["onClicked"])])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.profileObj.has_website_exposure]])]);
}