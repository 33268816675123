import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_container = _resolveComponent("b-container");
  const _component_b_spinner = _resolveComponent("b-spinner");
  return _openBlock(), _createElementBlock("div", null, [_cache[18] || (_cache[18] = _createElementVNode("h5", null, "Статистика по ЭДКИ", -1)), !_ctx.statsEDKILoading ? (_openBlock(), _createBlock(_component_b_container, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "text-muted"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        col: "",
        lg: "2"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("№ п/п")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Статус")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Количество")])),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        col: "",
        lg: "2"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("1.")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Принято")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.statsEDKI.submitted_completed), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        col: "",
        lg: "2"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("2.")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("На рассмотрении")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.statsEDKI.submitted), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        col: "",
        lg: "2"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("3.")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Рассмотрено")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.statsEDKI.completed), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        col: "",
        lg: "2"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("3.1.")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("Из них: проведена комиссия")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.statsEDKI.with_commission), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        col: "",
        lg: "2"
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("3.2.")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("Из них: выявлено КИ")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("-")])),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        col: "",
        lg: "2"
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode("3.3.")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("Из них: назначено КП")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode("-")])),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_b_spinner, {
    variant: "primary",
    label: "Загрузка..."
  }), _cache[17] || (_cache[17] = _createElementVNode("p", null, "Загрузка данных...", -1))], 64))]);
}