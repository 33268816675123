import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "introduce-wrap"
};
const _hoisted_2 = {
  class: "table-header"
};
const _hoisted_3 = {
  class: "text-center text-primary my-2"
};
const _hoisted_4 = {
  class: "table-footer"
};
const _hoisted_5 = {
  class: "mb-2"
};
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { statesCp } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
import { limitBound, optionsDesc, formatName, fmtNameShort } from "@/helpers/text";
import CPSidebar from "./CPSidebar";
import links from "@/router/links";
const perPage = 20;
export default {
  __name: 'CP',
  setup(__props) {
    const store = useStore();
    const permissions = ref({});
    const showSidebar = ref(false);
    const currentPage = ref(1);
    const sortBy = ref(null);
    const sortDesc = ref(true);
    const filters = ref({
      state: null,
      state_modified_lte: null,
      state_modified_gte: null,
      date_planned_lte: null,
      date_planned_gte: null,
      assignee_search: null,
      cp_type: null
    });
    const optionsSort = [{
      value: "state",
      text: "по статусу"
    }, {
      value: "cp_type",
      text: "по субъекту КП"
    }, {
      value: "date_planned",
      text: "по сроку КП"
    }, {
      value: "state_modified_at",
      text: "по дате изменения"
    }, {
      value: "assignee__last_name,assignee__first_name,assignee__middle_name",
      text: "по ответственному"
    }];
    const cpFields = [{
      key: "index",
      label: "№ п/п",
      class: "text-nowrap"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "foundation",
      label: "Источник"
    }, {
      key: "target_usersT",
      label: "Работник"
    }, {
      key: "assignee_detailT",
      label: "Исполнитель"
    }, {
      key: "cp_type_name",
      label: "Субъект КП"
    }, {
      key: "date_plannedT",
      label: "Срок КП"
    }, {
      key: "stateT",
      label: "Статус"
    }, {
      key: "state_modified_atT",
      label: "Дата изменения"
    }];
    // const cpForm = computed(() => store.getters["cp/cpForm"]);
    const cpCount = computed(() => store.getters["cp/cpCount"]);
    const cpLoading = computed(() => store.getters["cp/cpLoading"]);
    const cp = computed(() => store.getters["cp/cp"]);
    const updateSort = ({
      sortBy: newSortBy,
      sortDesc: newSortDesc
    }) => {
      currentPage.value = 1;
      sortBy.value = newSortBy;
      sortDesc.value = newSortDesc;
      loadData();
    };
    const changePage = newPage => {
      currentPage.value = limitBound(Number(newPage), 1, cpCount.value / perPage, currentPage.value);
      loadData();
    };
    const applyFilters = newFilters => {
      filters.value = {
        ...newFilters
      };
      currentPage.value = 1;
      loadData();
    };
    const clickRemove = async id => {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Удалить КП #${id}?`, {
        size: "sm",
        buttonSize: "sm",
        okVariant: "outline-danger",
        cancelVariant: "outline-primary",
        okTitle: "Удалить",
        cancelTitle: "Отменить",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      });
      if (isConfirmed) {
        loadData();
      }
    };
    const DateNow = computed(() => formatDate(Date.now()));
    const items4table = computed(() => {
      return Array.isArray(cp.value) ? cp.value.map(el => ({
        ...el,
        target_usersT: el.target_users.map(x => `${formatName(x, fmtNameShort)} (${x.worker_code})`).join(", "),
        stateT: statesCp[el.state],
        date_plannedT: formatDate(el.date_planned, fmtDateShort),
        state_modified_atT: formatDate(el.state_modified_at, fmtDateShort),
        assignee_detailT: formatName(el.assignee_detail, fmtNameShort)
      })) : [];
    });
    function loadData() {
      store.dispatch("cp/fetchCp", {
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        page: currentPage.value,
        pageSize: perPage
      });
    }
    onMounted(async () => {
      const instance = getCurrentInstance();
      permissions.value = instance?.appContext.config.globalProperties.$perm;
      loadData();
    });
    function searchChanged(search) {
      store.dispatch("cp/fetchCp", {
        sortBy: sortBy.value,
        sortDesc: sortDesc.value,
        filters: filters.value,
        page: currentPage.value,
        pageSize: perPage,
        search_string: search
      });
    }
    return (_ctx, _cache) => {
      const _component_CustomSearch = _resolveComponent("CustomSearch");
      const _component_b_col = _resolveComponent("b-col");
      const _component_CustomSort = _resolveComponent("CustomSort");
      const _component_FilterButton = _resolveComponent("FilterButton");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_router_link = _resolveComponent("router-link");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_container = _resolveComponent("b-container");
      const _component_b_offcanvas = _resolveComponent("b-offcanvas");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSearch, {
            onOnSearch: searchChanged
          })]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "8",
          lg: "6",
          xl: "5",
          class: "me-auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_CustomSort, {
            optionsSort: optionsSort,
            optionsDesc: _unref(optionsDesc),
            onOnSort: updateSort
          }, null, 8, ["optionsDesc"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "12",
          md: "4",
          xl: "2",
          class: "ps-0 mb-3"
        }, {
          default: _withCtx(() => [_createVNode(_component_FilterButton, {
            onClick: _cache[0] || (_cache[0] = $event => showSidebar.value = true)
          })]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_table, {
        class: "table-custom",
        hover: "",
        items: items4table.value,
        fields: cpFields,
        stacked: "md",
        busy: cpLoading.value.value,
        small: "",
        responsive: "",
        "show-empty": ""
      }, {
        "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
        "cell()": _withCtx(row => [_createVNode(_component_router_link, {
          to: _unref(links).cpForm + row.item.id,
          class: _normalizeClass(row.item.is_new || row.item.date_planned < DateNow.value ? 'font-weight-bold' : '')
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(row.value), 1)]),
          _: 2
        }, 1032, ["to", "class"])]),
        "cell(foundation)": _withCtx(row => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.foundations_coi, (el, index) => {
          return _openBlock(), _createBlock(_component_router_link, {
            key: 'КИ' + index,
            to: "#",
            class: "me-2"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString("КИ#" + el), 1)]),
            _: 2
          }, 1024);
        }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.foundations_message, (el, index) => {
          return _openBlock(), _createBlock(_component_router_link, {
            key: 'ГЛ' + index,
            to: _unref(links).hotlineForm + el,
            class: "me-2"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString("ГЛ#" + el), 1)]),
            _: 2
          }, 1032, ["to"]);
        }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.foundations_innercheck, (el, index) => {
          return _openBlock(), _createBlock(_component_router_link, {
            key: 'ВП' + index,
            to: _unref(links).innerCheckForm + el,
            class: "me-2"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString("ВП#" + el), 1)]),
            _: 2
          }, 1032, ["to"]);
        }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.foundations_coid, (el, index) => {
          return _openBlock(), _createBlock(_component_router_link, {
            key: 'ЭДКИ' + index,
            to: _unref(links).declarationForm + el,
            class: "me-2"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString("ЭДКИ#" + el), 1)]),
            _: 2
          }, 1032, ["to"]);
        }), 128))]),
        empty: _withCtx(() => [_createVNode(_component_b_alert, {
          show: "",
          variant: "light",
          class: "text-center"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("img", {
            src: _imports_0,
            alt: "empty",
            class: "mb-4"
          }, null, -1), _createElementVNode("h4", null, "Процедур нет", -1)])),
          _: 1
        })]),
        "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
        "cell(btnRemove)": _withCtx(row => [_unref(statesCp)[row.item.state] === _unref(statesCp).draft ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          variant: "link",
          onClick: $event => clickRemove(row.item.id),
          class: "p-0"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
            class: "bi bi-trash"
          }, null, -1)])),
          _: 2
        }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.add_wa_cp]]) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["items", "busy"]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_b_row, {
        class: "d-flex flex-wrap m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
            onOnChangePage: changePage,
            totalRows: cpCount.value
          }, null, 8, ["totalRows"]), [[_vShow, cpCount.value > perPage]])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(_component_b_offcanvas, {
        modelValue: showSidebar.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => showSidebar.value = $event),
        placement: "end",
        "no-header": "",
        backdrop: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_container, {
          class: "p-2"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_cache[6] || (_cache[6] = _createElementVNode("h5", {
            class: "d-inline"
          }, "Фильтровать процедуры", -1)), _createElementVNode("button", {
            type: "button",
            class: "btn-close float-end",
            "aria-label": "Close",
            onClick: _cache[1] || (_cache[1] = $event => showSidebar.value = false)
          })]), _createVNode(_unref(CPSidebar), {
            onEmitApplyFilters: applyFilters
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};