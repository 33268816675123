import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "mb-4 me-auto"
};
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AutocheckFormList from "./AutocheckFormList.vue";
import TablePagination from "../TablePagination.vue";
import TableSortAndFilter from "../TableSortAndFilter.vue";
import { useRoute } from "vue-router";
import { computed, onMounted, toRefs, ref, watch } from "vue";
import { useStore } from "vuex";
import { typeBreadcrumbs, entityTitle } from "../autochecks";
const perPage = 20;

// store

export default {
  __name: 'AutocheckFormBase',
  props: {
    msg: String
  },
  setup(__props) {
    const props = __props;
    const {
      msg
    } = toRefs(props);
    const route = useRoute();
    const currentPage = ref(1);
    const store = useStore();
    const autochecksEntity = computed(() => store.getters["administration2/autochecksEntity"]);
    const autochecksEntityLoading = computed(() => store.getters["administration2/autochecksEntityLoading"]);
    const autochecksHitsCount = computed(() => store.getters["administration2/autochecksHitsCount"]);
    const breadcrumbs = computed(() => [].concat(typeBreadcrumbs(route?.query?.type), [{
      text: "Автосверка",
      to: {
        name: "AutochecksArea",
        query: route.query
      }
    }]));
    onMounted(() => {
      updateData();
      updateTable();
    });

    // updateData
    async function updateData() {
      await store.dispatch("administration2/updateAutochecksEntity", {
        id: msg.value
      });
    }
    async function updateTable() {
      const params = {
        page: currentPage.value,
        page_size: perPage
      };
      await store.dispatch("administration2/updateAutochecksHits", {
        id: msg.value,
        params
      });
    }
    watch(currentPage, () => {
      setTimeout(updateTable, 0);
    });
    return (_ctx, _cache) => {
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _directive_can = _resolveDirective("can");
      return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs.value
      }, null, 8, ["breadCrumb"]), autochecksEntityLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createElementVNode("h1", _hoisted_2, _toDisplayString(_unref(entityTitle)(autochecksEntity.value)), 1), false ? (_openBlock(), _createBlock(TableSortAndFilter, {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(AutocheckFormList, {
        currentPage: currentPage.value,
        perPage: perPage
      }, null, 8, ["currentPage"]), _createVNode(TablePagination, {
        currentPage: currentPage.value,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => currentPage.value = $event),
        perPage: perPage,
        count: autochecksHitsCount.value
      }, null, 8, ["currentPage", "count"])], 64))])), [[_directive_can, _ctx.$perm.view_wa_autocheck]]);
    };
  }
};