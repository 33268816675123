import "core-js/modules/es.array.push.js";
import links from "@/router/links";
import HotLineList from "./HotLineList";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { mapActions, mapGetters } from "vuex";
import theNotification from "@/components/theNotification";
import HotLineReport from "./Reports/HotLineReport.vue";
import { hlineDefaultSort } from "@/helpers/text";
export default {
  name: "HotLineArea",
  components: {
    HotLineList,
    BreadCrumbToHome,
    theNotification,
    HotLineReport
  },
  async created() {
    const params = {
      page: 1,
      sort: hlineDefaultSort,
      page_size: 20
    };
    if (this.onlyNew) params.is_new = true;
    await this.updateHlines(params);
    this.setOnlyNew(null); // обнулимся
  },
  data: () => ({}),
  methods: {
    ...mapActions("hotline", ["updateHlines", "createHlineMsg"]),
    ...mapActions("homeStats", ["setOnlyNew"]),
    async clickNew() {
      await this.createHlineMsg();
      if (this.hlineMsg.id) this.$router.push(links.hotlineForm + this.hlineMsg.id);
    }
  },
  computed: {
    ...mapGetters("hotline", ["hlinesCount", "hlineMsg"]),
    ...mapGetters("homeStats", ["onlyNew"])
  }
};