import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "other-citizenships"
};
const _hoisted_3 = {
  class: "other-citizenships-country"
};
const _hoisted_4 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_5 = {
  class: "other-citizenships-document"
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  colspan: "4"
};
const _hoisted_8 = {
  key: 1,
  class: "text-danger"
};
const _hoisted_9 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "2",
      xl: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Пол"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.sex,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.sex = $event),
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('sex')),
          options: _ctx.sexOptions,
          state: _ctx.sex_state,
          required: "",
          disabled: $options.readonly
        }, null, 8, ["modelValue", "options", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "4",
      xl: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Число, месяц, год рождения",
        date: _ctx.birth_date,
        disabled: $options.readonly,
        classCustom: "mt-2 no-gutters",
        state: _ctx.birth_date_state,
        onChangeValue: _cache[2] || (_cache[2] = $event => $options.submitData('birth_date', $event))
      }, null, 8, ["date", "disabled", "state"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "6",
      xl: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Место рождения"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.birth_place,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.profileObj.birth_place = $event),
          onChange: _cache[4] || (_cache[4] = $event => $options.submitData('birth_place')),
          state: _ctx.birth_place_state,
          disabled: $options.readonly,
          type: "text",
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "6"
    }, {
      default: _withCtx(() => [!_ctx.countriesLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Гражданство"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          options: $options.optionsCountry,
          disabled: $options.readonly,
          modelValue: _ctx.profileObj.citizenship,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.profileObj.citizenship = $event),
          onChange: _cache[6] || (_cache[6] = $event => $options.submitData('citizenship')),
          state: _ctx.citizenship_state
        }, null, 8, ["options", "disabled", "modelValue", "state"])]),
        _: 1
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Наличие гражданства иностранного государства либо вида на жительство на территории иностранного государства (подтверждающий документ)"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          class: "w-auto",
          modelValue: _ctx.profileObj.has_other_citizenships,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.profileObj.has_other_citizenships = $event),
          disabled: $options.readonly,
          state: _ctx.has_other_citizenships_state,
          options: _ctx.optionYesNo,
          onChange: _cache[8] || (_cache[8] = $event => $options.submitData('has_other_citizenships'))
        }, null, 8, ["modelValue", "disabled", "state", "options"])]),
        _: 1
      })]),
      _: 1
    }), _withDirectives(_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_2, [_cache[17] || (_cache[17] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Страна"), _createElementVNode("th", null, "Подтверждение иностранного гражданства")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherCitizenshipInfo, el => {
          return _openBlock(), _createElementBlock("tr", {
            key: el.id,
            class: "other-citizenships-info"
          }, [_createElementVNode("td", _hoisted_3, [!_ctx.countriesLoading ? (_openBlock(), _createBlock(_component_b_form_select, {
            key: 0,
            options: $options.optionsCountry,
            disabled: $options.readonly,
            modelValue: el.country,
            "onUpdate:modelValue": $event => el.country = $event,
            onChange: $event => $options.submitDataEndpoint(el, 'country', 'otherCitizenshipInfo', _ctx.endpointOtherCitizenships),
            state: el.country_state
          }, null, 8, ["options", "disabled", "modelValue", "onUpdate:modelValue", "onChange", "state"])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Загрузка...", -1))]))]), _createElementVNode("td", _hoisted_5, [_createVNode(_component_b_form_input, {
            modelValue: el.citizenship_document,
            "onUpdate:modelValue": $event => el.citizenship_document = $event,
            onChange: $event => $options.submitDataEndpoint(el, 'citizenship_document', 'otherCitizenshipInfo', _ctx.endpointOtherCitizenships),
            state: el.citizenship_document_state,
            disabled: $options.readonly,
            placeholder: "Укажите документ",
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.otherCitizenshipInfo, el.id, 'otherCitizenshipInfo', _ctx.endpointOtherCitizenships)
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_6, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_7, [$options.firstCountry !== undefined ? (_openBlock(), _createBlock(_component_AddRow, {
          key: 0,
          onClicked: _cache[9] || (_cache[9] = $event => $options.addDataEndpoint(_ctx.otherCitizenshipInfo, 'otherCitizenshipInfo', _ctx.endpointOtherCitizenships, $options.templateOtherCitizenships, _ctx.statesOtherCitizenshipInfo))
        })) : (_openBlock(), _createElementBlock("span", _hoisted_8, " Справочник стран пуст, невозможно добавить гражданство "))])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    }, 512), [[_vShow, _ctx.profileObj.has_other_citizenships]])]),
    _: 1
  }), _createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "3",
      class: "tin"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "ИНН"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.profileObj.inn,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.profileObj.inn = $event),
          onChange: _cache[11] || (_cache[11] = $event => $options.submitData('inn')),
          state: _ctx.inn_state,
          disabled: $options.readonly,
          trim: ""
        }, null, 8, ["modelValue", "state", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "",
      lg: "3",
      class: "snils"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "СНИЛС"
      }, {
        default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.snils = $event),
          type: "text",
          disabled: $options.readonly,
          maxlength: "14",
          class: _normalizeClass(["form-control", (_ctx.snils_state === false ? 'is-invalid' : '', _ctx.snils_state === true ? 'is-valid' : '')]),
          onChange: _cache[13] || (_cache[13] = $event => $options.submitData('snils'))
        }, null, 42, _hoisted_9), [[_vModelText, _ctx.snils]])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}