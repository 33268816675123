import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "profile-photo"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: _ctx.previewImage,
    class: "uploading-image",
    alt: "Фотография"
  }, null, 8, _hoisted_2), !$options.readonly ? (_openBlock(), _createElementBlock("input", {
    key: 0,
    type: "file",
    id: "profile-photo",
    ref: "photo",
    accept: "image/jpeg",
    onChange: _cache[0] || (_cache[0] = (...args) => $options.uploadImage && $options.uploadImage(...args))
  }, null, 544)) : _createCommentVNode("", true)]);
}