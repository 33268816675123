import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import Calendar from "@/components/Helpers/Calendar.vue";
import { formatDateObj, fmtDate, formatObjDate, formatDate } from "@/helpers/date";
import { validate, invalidMinMaxDate } from "@/helpers/validate";
import { option4select } from "@/helpers/global";
import AddRow from "@/components/common/AddRow";
import { optionYesNo } from "@/helpers/text";
import { snilsMask } from "@/helpers/masks";
export default {
  name: "GeneralData",
  components: {
    Calendar,
    AddRow
  },
  data: () => ({
    snilsMask,
    optionYesNo,
    isGeneralVisible: true,
    sexOptions: [{
      value: "Мужской",
      text: "Мужской"
    }, {
      value: "Женский",
      text: "Женский"
    }],
    birth_date: null,
    birth_date_state: null,
    birth_place_state: null,
    citizenship_state: null,
    inn_state: null,
    snils: "",
    snils_state: null,
    sex_state: null,
    has_other_citizenships_state: null,
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    otherCitizenshipInfo: [],
    endpointOtherCitizenships: "other-citizenships",
    statesOtherCitizenshipInfo: {
      country_state: null,
      citizenship_document_state: null
    }
  }),
  created() {
    this.birth_date = formatObjDate(this.profileObj.birth_date);
    this.snils = snilsMask(this.profileObj.snils); // т.к. ввод по маске, нужно отдельно хранить
    this.profileObjSaved.birth_place = this.profileObj.birth_place;
    this.otherCitizenshipInfo = this.profileObj.other_citizenship_info ? this.profileObj.other_citizenship_info.map(el => ({
      ...el,
      ...this.statesOtherCitizenshipInfo
    })) : [];
    this.profileObjSaved.otherCitizenshipInfo = this.profileObj.other_citizenship_info ? this.profileObj.other_citizenship_info.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
    this.profileObjSaved.birth_date = this.profileObj.birth_date;
    this.profileObjSaved.citizenship = this.profileObj.citizenship;
    this.profileObjSaved.inn = this.profileObj.inn;
    this.profileObjSaved.snils = this.profileObj.snils;
    this.profileObjSaved.sex = this.profileObj.sex;
    this.profileObjSaved.has_other_citizenships = this.profileObj.has_other_citizenships;
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    ...mapActions(["showNotify"]),
    submitData(key, date) {
      let value = this.profileObjSaved[key];
      let valid = true;
      if (key === "snils") {
        value = this[key].replace(/[-\s]/g, "");
        valid = validate({
          data: value,
          dataRequired: "snils"
        });
      } else if (["birth_date"].includes(key)) {
        value = formatDateObj(date, fmtDate, null);
        const invalid = invalidMinMaxDate(value, null, formatDate(new Date(), fmtDate), "Некорректная дата рождения");
        if (invalid) {
          this.showNotify({
            msg: invalid,
            title: "Ошибка пользователя",
            variant: "danger"
          });
          return;
        }
      } else value = this.profileObj[key];
      if (this.profileObjSaved[key] === value) return; // изменений не было

      const state = `${key}_state`;
      this[state] = false;
      if (!valid) {
        this.showNotify({
          msg: "Неверное значение",
          title: "Ошибка пользователя",
          variant: "danger"
        });
        return;
      }
      this.updateProfile({
        id: this.profileObj.id,
        [key]: value
      }).then(() => {
        this.profileObjSaved[key] = value;
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    },
    submitDataEndpoint(obj, key, savekey, endpoint) {
      const value = obj[key];
      if (this.profileObjSaved[savekey][obj.id][key] === value) return; // изменений не было
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: value
        },
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint(obj, savekey, endpoint, template, states) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint,
        data: template
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = {
          ...template
        };
        obj.push({
          id: response.id,
          ...states,
          ...template
        });
      });
    },
    removeDataEndpoint(obj, id, savekey, endpoint) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved[savekey][id];
        obj.splice(index, 1);
      });
    }
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    ...mapGetters("referenceBooks", ["countries", "countriesLoading"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    },
    optionsCountry() {
      return option4select(this.countries, false);
    },
    firstCountry() {
      const countries = this.optionsCountry;
      if (countries.length > 0) return countries[0].value;else return undefined;
    },
    templateOtherCitizenships() {
      return {
        country: this.firstCountry,
        citizenship_document: null
      };
    }
  },
  watch: {
    snils(newVal) {
      this.snils = snilsMask(newVal);
    }
  }
};