import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "table-footer"
};
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import AdminElementAdd from "./AdminElementAdd.vue";
import { limitBound, relevantHlText } from "@/helpers/text.js";
import { typeMessageHotline } from "@/helpers/text";
import { option4select } from "@/helpers/global";
const perPage = 20;
export default {
  __name: 'AdminHlCategory',
  setup(__props) {
    const store = useStore();
    const currentPage = ref(1);
    const tableFields = [{
      key: "index",
      label: "№ п/п",
      class: "text-start"
    }, {
      key: "id",
      label: "UID"
    }, {
      key: "message_type",
      label: "Наименование"
    }, {
      key: "message_category",
      label: "Подразделение",
      formatter: value => typeMessageHotline[value] || "-"
    }, {
      key: "is_relevant",
      label: "Релевантность",
      formatter: value => relevantHlText(value),
      class: "text-nowrap"
    }, {
      key: "btnChange",
      label: "",
      class: "text-center"
    }];
    const breadcrumbs = [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }];
    const settingsAdd = {
      title: "Создать тип",
      btnTitle: "Создать",
      buttonIcon: false,
      iconType: null,
      texts: {
        message_type: {
          required: true,
          label: "Название"
        }
      },
      selectors: {
        message_category: {
          required: true,
          label: "Уполномоченное подразделение",
          options: option4select(typeMessageHotline)
        }
      },
      switchName: "is_relevant",
      switchText: relevantHlText
    };
    const settingsChange = {
      title: "Редактировать тип",
      btnTitle: null,
      buttonIcon: true,
      iconType: "pencil-square",
      texts: {
        message_type: {
          required: true,
          label: "Название"
        }
      },
      selectors: {
        message_category: {
          required: true,
          label: "Уполномоченное подразделение",
          options: option4select(typeMessageHotline)
        }
      },
      switchName: "is_relevant",
      switchText: relevantHlText
    };
    const adminHlCategory = computed(() => store.getters["administration/adminHlCategory"]);
    const adminHlCategoryLoading = computed(() => store.getters["administration/adminHlCategoryLoading"]);
    const adminHlCategoryCount = computed(() => store.getters["administration/adminHlCategoryCount"]);
    const updateAdminHlCategory = payload => store.dispatch("administration/updateAdminHlCategory", payload);
    const createAdminHlCategory = payload => store.dispatch("administration/createAdminHlCategory", payload);
    const changeAdminHlCategory = payload => store.dispatch("administration/changeAdminHlCategory", payload);
    async function changePage(newPage) {
      currentPage.value = limitBound(Number(newPage), 1, adminHlCategoryCount.value / perPage, currentPage.value);
      await loadData();
    }
    async function clickCreate(data) {
      await createAdminHlCategory(data);
      currentPage.value = 1;
      await loadData();
    }
    async function clickChange(data) {
      await changeAdminHlCategory(data);
      await loadData();
    }
    async function loadData() {
      await updateAdminHlCategory({
        page: currentPage.value,
        pageSize: perPage
      });
    }
    onMounted(async () => {
      await loadData();
    });
    return (_ctx, _cache) => {
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_alert = _resolveComponent("b-alert");
      const _component_b_table = _resolveComponent("b-table");
      const _component_CustomPagination = _resolveComponent("CustomPagination");
      const _component_b_card = _resolveComponent("b-card");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs
      }), _createVNode(_component_b_row, {
        class: "mb-4"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h1", null, "Типы сообщений на горячую линию", -1)])),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(AdminElementAdd, {
            idModal: "createNewHl",
            settings: settingsAdd,
            onUpdate: clickCreate
          }, null, 512), [[_directive_can, _ctx.$perm.add_admin_hotmsgcat]])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0 shadow-sm py-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_table, {
          class: "table-custom",
          responsive: "",
          hover: "",
          small: "",
          "show-empty": "",
          stacked: "md",
          items: adminHlCategory.value,
          fields: tableFields,
          busy: adminHlCategoryLoading.value
        }, {
          "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
          "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((currentPage.value - 1) * perPage + row.index + 1), 1)]),
          empty: _withCtx(() => [_createVNode(_component_b_alert, {
            show: "",
            variant: "light",
            class: "text-center"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("img", {
              src: _imports_0,
              alt: "empty",
              class: "mb-4"
            }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
            _: 1
          })]),
          "cell(btnChange)": _withCtx(row => [_withDirectives(_createVNode(AdminElementAdd, {
            idModal: `changeHl${row.item.id}`,
            settings: settingsChange,
            item: row.item,
            onUpdate: clickChange
          }, null, 8, ["idModal", "item"]), [[_directive_can, _ctx.$perm.change_admin_hotmsgcat]])]),
          _: 1
        }, 8, ["items", "busy"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_row, {
          class: "d-flex flex-wrap m-0 p-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_col, {
            cols: "12",
            md: "8",
            lg: "6",
            xl: "4"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_CustomPagination, {
              totalRows: adminHlCategoryCount.value,
              onOnChangePage: changePage
            }, null, 8, ["totalRows"]), [[_vShow, adminHlCategoryCount.value > perPage]])]),
            _: 1
          })]),
          _: 1
        })])]),
        _: 1
      })]);
    };
  }
};