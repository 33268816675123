import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_checkbox, {
    modelValue: _ctx.hlineMsg.is_anonymous,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.hlineMsg.is_anonymous = $event),
    onChange: _cache[1] || (_cache[1] = $event => $options.changeData('is_anonymous')),
    disabled: $options.readOnly
  }, {
    default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode(" Анонимно ")])),
    _: 1
  }, 8, ["modelValue", "disabled"]), !_ctx.hlineMsg.is_anonymous ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_b_form_group, {
    label: "Заявитель (фамилия, имя, отчество) *",
    class: "mt-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.hlineMsg.applicant_full_name,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.hlineMsg.applicant_full_name = $event),
      onChange: _cache[3] || (_cache[3] = $event => $options.changeData('applicant_full_name')),
      trim: "",
      disabled: $options.readOnly
    }, null, 8, ["modelValue", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Адрес (почтовый или электронный) заявителя"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.hlineMsg.applicant_address,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.hlineMsg.applicant_address = $event),
          onChange: _cache[5] || (_cache[5] = $event => $options.changeData('applicant_address')),
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Телефон заявителя"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.hlineMsg.applicant_phone,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.hlineMsg.applicant_phone = $event),
          onChange: _cache[7] || (_cache[7] = $event => $options.changeData('applicant_phone')),
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Тип заявителя *"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.hlineMsg.applicant_type,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.hlineMsg.applicant_type = $event),
          options: _ctx.typeAuthorHotline,
          onChange: _cache[9] || (_cache[9] = $event => $options.changeData('applicant_type')),
          disabled: $options.readOnly
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode("Не выбран")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Организационно-правовая форма"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.hlineMsg.organization_form,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.hlineMsg.organization_form = $event),
          onChange: _cache[11] || (_cache[11] = $event => $options.changeData('organization_form')),
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: `Место работы заявителя ${$options.star}`
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.hlineMsg.applicant_job,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.hlineMsg.applicant_job = $event),
          onChange: _cache[13] || (_cache[13] = $event => $options.changeData('applicant_job')),
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      }, 8, ["label"])]),
      _: 1
    }), _createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "ИНН места работы заявителя"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.hlineMsg.applicant_job_inn,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _ctx.hlineMsg.applicant_job_inn = $event),
          onChange: _cache[15] || (_cache[15] = $event => $options.changeData('applicant_job_inn')),
          trim: "",
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })], 64)) : _createCommentVNode("", true), _cache[18] || (_cache[18] = _createElementVNode("p", {
    class: "text-muted"
  }, "* обязательные к заполнению поля", -1))]);
}