import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { innerCheckMeasures, MEASURES_NO } from "@/helpers/text";
export default {
  name: "InnerCheckListDetails",
  data: () => ({}),
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    measures() {
      return Array.isArray(this.item.measures) && this.item.measures.length > 0 ? this.item.measures.map(key => innerCheckMeasures[key]).join(", ") : MEASURES_NO;
    }
  }
};