// import PersonalArea from "@/components/PersonalArea/PersonalArea.vue";
import theHeader from "@/components/theHeader.vue";
import theSidebar from "@/components/theSidebar.vue";
import { VMarkdownView } from "vue3-markdown";
import links from "@/router/links";
import { SITE_NAME, OWNER_NAME } from "@/helpers/text.js";
import { agreements } from "@/helpers/auth";
import { mapGetters } from "vuex";
export default {
  name: "AppLayout",
  components: {
    theHeader,
    theSidebar,
    VMarkdownView
  },
  data: () => ({
    breadcrumbs: [{
      text: "",
      active: true
    }],
    links,
    SITE_NAME,
    OWNER_NAME,
    docKey: "pers_data_conscent",
    showModal: false,
    agreements
  }),
  methods: {
    // показываем текст соглашения
    showText(key) {
      this.docKey = key;
      this.showModal = true;
    }
  },
  computed: {
    ...mapGetters("administration", ["adminCommon"])
  }
};