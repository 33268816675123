import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mb-3"
};
const _hoisted_2 = {
  class: "ms-2"
};
const _hoisted_3 = {
  class: "ms-2"
};
const _hoisted_4 = {
  class: "ms-2"
};
const _hoisted_5 = {
  class: "ms-2"
};
const _hoisted_6 = {
  class: "ms-2"
};
const _hoisted_7 = {
  class: "ms-2"
};
const _hoisted_8 = {
  class: "ms-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_link = _resolveComponent("b-link");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_modal = _resolveComponent("b-modal");
  const _component_Calendar = _resolveComponent("Calendar");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", _hoisted_1, "Внутренняя проверка #" + _toDisplayString(_ctx.innerCheckForm.id), 1), _cache[10] || (_cache[10] = _createElementVNode("span", {
        class: "text-muted"
      }, "Статус", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.statesInnerCheck[_ctx.innerCheckForm.state]), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [$options.viewBtnAssign ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: "primary",
        onClick: $options.clickBtnAssign
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("Назначить проверку ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.assign_wa_innercheck]]) : _createCommentVNode("", true), $options.viewBtnReturn ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 1,
        variant: "outline-primary",
        class: "ms-3",
        onClick: $options.clickBtnReturn
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("Вернуть на доработку ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.complete_wa_innercheck]]) : _createCommentVNode("", true), false ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 2,
        variant: "outline-danger",
        class: "ms-3"
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("Отклонить проверку ")])),
        _: 1
      })), [[_directive_can, _ctx.$perm.complete_wa_innercheck]]) : _createCommentVNode("", true), $options.viewBtnPerform ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 3,
        variant: "primary",
        class: "ms-3",
        onClick: $options.clickBtnPerform
      }, {
        default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode("Провести проверку ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.perform_wa_innercheck]]) : _createCommentVNode("", true), $options.viewBtnComplete ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 4,
        class: "ms-3",
        variant: "primary",
        onClick: $options.clickBtnComplete
      }, {
        default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("Утвердить проверку ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.complete_wa_innercheck]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_cache[16] || (_cache[16] = _createElementVNode("span", {
        class: "text-muted"
      }, "Назначено", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString($options.formatDate(_ctx.innerCheckForm.assigned_at, _ctx.fmtDateShort)), 1), _cache[17] || (_cache[17] = _createElementVNode("span", {
        class: "text-muted ms-4"
      }, "Проведено", -1)), _createElementVNode("span", _hoisted_4, _toDisplayString($options.formatDate(_ctx.innerCheckForm.date_actual, _ctx.fmtDateShort)), 1), _cache[18] || (_cache[18] = _createElementVNode("span", {
        class: "text-muted ms-4"
      }, "Утверждено", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString($options.formatDate(_ctx.innerCheckForm.date_confirmed, _ctx.fmtDateShort)), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_cache[22] || (_cache[22] = _createElementVNode("span", {
        class: "text-muted"
      }, "Нарушение", -1)), _createElementVNode("span", _hoisted_6, _toDisplayString($options.violationDetected), 1), _ctx.innerCheckForm.report ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_cache[21] || (_cache[21] = _createElementVNode("span", {
        class: "text-muted ms-4"
      }, "Приложение", -1)), _createElementVNode("span", _hoisted_7, [_cache[20] || (_cache[20] = _createTextVNode("Файл отчета ")), _createVNode(_component_b_link, {
        class: "ms-4 border-0",
        href: _ctx.innerCheckForm.report,
        download: decodeURI(_ctx.innerCheckForm.report),
        title: "Скачать отчет",
        variant: "outline-primary"
      }, {
        default: _withCtx(() => _cache[19] || (_cache[19] = [_createElementVNode("i", {
          class: "bi bi-download"
        }, null, -1)])),
        _: 1
      }, 8, ["href", "download"])])], 64)) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_cache[23] || (_cache[23] = _createElementVNode("span", {
        class: "text-muted"
      }, "Принятые меры", -1)), _createElementVNode("span", _hoisted_8, _toDisplayString($options.measures), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_modal, {
    modelValue: _ctx.modalPerformShow,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.modalPerformShow = $event),
    id: "modalPerform",
    title: "Проверка",
    centered: "",
    onOk: $options.handlerPerform,
    "ok-title": "Завершить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalPerformDisabled
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      "align-v": "baseline"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => _cache[24] || (_cache[24] = [_createTextVNode("Нарушение выявлено")])),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.modalPerformDefection,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.modalPerformDefection = $event),
          options: _ctx.optionYesNo
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Отчет",
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_row, null, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.modalPerformFile ? _ctx.modalPerformFile.name : _ctx.NONAME_SHORT), 1)]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            class: "pe-0",
            variant: "link",
            title: "Загрузить файл",
            onClick: $options.clickAddFile,
            disabled: !!_ctx.modalPerformFile
          }, {
            default: _withCtx(() => _cache[25] || (_cache[25] = [_createElementVNode("i", {
              class: "bi bi-file-earmark-plus"
            }, null, -1)])),
            _: 1
          }, 8, ["onClick", "disabled"])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            class: "pe-0",
            variant: "link",
            disabled: !_ctx.modalPerformFile,
            onClick: _cache[1] || (_cache[1] = $event => _ctx.modalPerformFile = null)
          }, {
            default: _withCtx(() => _cache[26] || (_cache[26] = [_createElementVNode("i", {
              class: "bi bi-trash"
            }, null, -1)])),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Комментарий"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
        modelValue: _ctx.modalPerformComment,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.modalPerformComment = $event),
        placeholder: "Введите комментарий",
        rows: "3",
        "max-rows": "3",
        trim: ""
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onOk", "ok-disabled"]), _createVNode(_component_b_modal, {
    modelValue: _ctx.modalCompleteShow,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.modalCompleteShow = $event),
    id: "modalComplete",
    title: "Утверждение уполномоченным лицом",
    centered: "",
    onOk: $options.handlerComplete,
    "ok-title": "Утвердить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalCompleteDisabled
  }, {
    default: _withCtx(() => [_createVNode(_component_Calendar, {
      title: "Дата утверждения",
      date: _ctx.modalCompleteDate,
      classCustom: "mt-2",
      onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.modalCompleteDate = $event)
    }, null, 8, ["date"]), _createVNode(_component_b_form_group, {
      label: "Принятые меры",
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_select, {
        modelValue: _ctx.modalTakenMeasures,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.modalTakenMeasures = $event),
        options: $options.optionsMeasures
      }, null, 8, ["modelValue", "options"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onOk", "ok-disabled"]), _createVNode(_component_b_modal, {
    id: "modalReturn",
    modelValue: _ctx.modalReturnShow,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.modalReturnShow = $event),
    title: "Вернуть на доработку",
    centered: "",
    onOk: $options.handlerReturn,
    "ok-title": "На доработку",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "ok-disabled": $options.okModalPerformDisabled
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_textarea, {
      modelValue: _ctx.modalPerformComment,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.modalPerformComment = $event),
      placeholder: "Введите комментарий",
      rows: "3",
      "max-rows": "3",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }, 8, ["modelValue", "onOk", "ok-disabled"]), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[9] || (_cache[9] = (...args) => $options.handlerAdd && $options.handlerAdd(...args))
  }, null, 544)]);
}