import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import { yearsGenerator, monthsOptions } from "@/helpers/date";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { option4select } from "@/helpers/global";
export default {
  name: "theEighth",
  data: () => ({
    yeras: 5,
    monthsOptions,
    hasBeenAboard: false,
    visits: [],
    endpoint: "travels-abroad",
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    template: {
      start_month: null,
      start_year: null,
      end_month: null,
      end_year: null,
      country: null,
      purpose: null
    },
    states: {
      start_month_state: null,
      start_year_state: null,
      end_month_state: null,
      end_year_state: null,
      country_state: null,
      purpose_state: null
    }
  }),
  created() {
    const yearOfInterest = new Date().getFullYear() - this.yeras;
    this.hasBeenAboard = this.profileObj.travels_abroad && this.profileObj.travels_abroad.length > 0;
    this.visits = this.profileObj.travels_abroad ? this.profileObj.travels_abroad.filter(visit => visit.end_year > yearOfInterest || visit.end_year === null).map(visit => ({
      ...visit,
      ...this.states
    })) : [];
    this.profileObjSaved = this.profileObj.travels_abroad ? this.profileObj.travels_abroad.filter(visit => visit.end_year > yearOfInterest || visit.end_year === null).reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfileData", "addProfileData", "delProfileData"]),
    submitDataEndpoint(obj, key) {
      if (this.profileObjSaved[obj.id][key] === obj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: obj[key]
        },
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved[response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint() {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved[response.id] = {
          ...this.template
        };
        this.visits.push({
          id: response.id,
          ...this.states,
          ...this.template
        });
      });
    },
    removeDataEndpoint(id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = this.visits.findIndex(el => el.id === id);
        delete this.profileObjSaved[id];
        this.visits.splice(index, 1);
      });
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    ...mapGetters("referenceBooks", ["countries", "countriesLoading"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    },
    visitingYear() {
      return yearsGenerator({
        offset: 0,
        amount: this.yeras
      });
    },
    optionsCountry() {
      return option4select(this.countries, false);
    }
  }
};