import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "introduce-wrap"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_BTable = _resolveComponent("BTable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_b_card_header, {
    "header-class": "p-0 m-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto ms-auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
          "total-rows": _ctx.declarationFormAttachmentsCount,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.declarationFormAttachmentsCount > _ctx.perPage]]), _createVNode(_component_BTable, {
    hover: "",
    class: "table-custom",
    items: _ctx.declarationFormAttachments,
    fields: _ctx.tableFields,
    stacked: "md",
    busy: _ctx.declarationFormAttachmentsLoading,
    small: "",
    "show-empty": "",
    responsive: ""
  }, {
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    empty: _withCtx(() => [_createVNode(_component_b_alert, {
      show: "",
      variant: "light",
      class: "text-center"
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
        src: _imports_0,
        alt: "empty",
        class: "mb-4"
      }, null, -1), _createElementVNode("h4", null, "Вложений нет", -1)])),
      _: 1
    })]),
    "cell(download)": _withCtx(row => [_createVNode(_component_b_button, {
      class: "p-0 button-wrap",
      variant: "link",
      href: row.item['document'],
      download: row.item['file_name']
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
        class: "bi bi-download"
      }, null, -1)])),
      _: 2
    }, 1032, ["href", "download"])]),
    _: 1
  }, 8, ["items", "fields", "busy"])]);
}