import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import "core-js/modules/es.iterator.reduce.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "d-flex flex-row align-items-center"
};
const _hoisted_2 = {
  class: "col me-3"
};
const _hoisted_3 = {
  class: "col"
};
const _hoisted_4 = {
  class: "d-flex flex-row align-items-center"
};
const _hoisted_5 = {
  class: "col me-3"
};
const _hoisted_6 = {
  class: "col"
};
const _hoisted_7 = {
  class: "footer mt-5"
};
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { selectAll, riskLevelPartners, typeRiskLevel } from "@/helpers/text.js";
export default {
  __name: 'MatrixRiskListSidebar',
  emits: ["emitApplyFilters"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;

    // store
    const store = useStore();
    const departments = computed(() => store.getters["referenceBooks/departments"]);
    const departmentsLoading = computed(() => store.getters["referenceBooks/departmentsLoading"]);
    const matrixForm = computed(() => store.getters["risk/matrixForm"]);
    const matrixFormLoading = computed(() => store.getters["risk/matrixFormLoading"]);
    const filters = ref({
      risk_num: null,
      activity_num: null,
      risk_owner_id: null,
      financial_costs: null,
      risk_probability_gte: null,
      risk_probability_lte: null,
      risk_impact_gte: null,
      risk_impact_lte: null,
      risk_level: []
    });
    const departmentOptions = computed(() => Object.keys(departments.value).reduce((acc, curr) => {
      acc.push({
        value: departments.value[curr],
        text: curr
      });
      return acc;
    }, [...selectAll]));
    const financialCoststOptions = computed(() => matrixForm.value?.criteria_reference?.financial_costs ? [...selectAll].concat(matrixForm.value.criteria_reference.financial_costs) : [...selectAll]);
    function clearFilters() {
      Object.keys(filters.value).forEach(key => {
        if (["risk_level"].includes(key)) filters.value[key] = [];else filters.value[key] = null;
      });
    }
    function clickApplyFilters() {
      emit("emitApplyFilters", filters.value);
    }
    return (_ctx, _cache) => {
      const _component_b_form_input = _resolveComponent("b-form-input");
      const _component_b_form_group = _resolveComponent("b-form-group");
      const _component_b_form_select = _resolveComponent("b-form-select");
      const _component_b_input_group = _resolveComponent("b-input-group");
      const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
      const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group");
      const _component_b_button = _resolveComponent("b-button");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
        label: "№ риска",
        class: "mt-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          size: "sm",
          modelValue: filters.value.risk_num,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => filters.value.risk_num = $event),
          type: "search",
          placeholder: "Номер",
          trim: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_b_form_group, {
        label: "№ вида деятельности",
        class: "mt-3"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          size: "sm",
          modelValue: filters.value.activity_num,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => filters.value.activity_num = $event),
          type: "search",
          placeholder: "Номер",
          trim: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }), !departmentsLoading.value ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Владелец риска",
        class: "mt-3"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          size: "sm",
          modelValue: filters.value.risk_owner_id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => filters.value.risk_owner_id = $event),
          options: departmentOptions.value
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_b_form_group, {
        label: "Вероятность наступления %",
        class: "mt-3"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_input_group, {
          prepend: "от",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            type: "number",
            modelValue: filters.value.risk_probability_gte,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => filters.value.risk_probability_gte = $event),
            style: {
              "width": "3em"
            }
          }, null, 8, ["modelValue"])]),
          _: 1
        })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_input_group, {
          prepend: "до",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            type: "number",
            modelValue: filters.value.risk_probability_lte,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => filters.value.risk_probability_lte = $event),
            style: {
              "width": "3em"
            }
          }, null, 8, ["modelValue"])]),
          _: 1
        })])])]),
        _: 1
      }), !matrixFormLoading.value ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 1,
        label: matrixForm.value?.terms_map?.financial_costs,
        class: "mt-3"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          size: "sm",
          modelValue: filters.value.financial_costs,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => filters.value.financial_costs = $event),
          options: financialCoststOptions.value
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _createVNode(_component_b_form_group, {
        label: "Степень влияния %",
        class: "mt-3"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_b_input_group, {
          prepend: "от",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            type: "number",
            modelValue: filters.value.risk_impact_gte,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => filters.value.risk_impact_gte = $event),
            style: {
              "width": "3em"
            }
          }, null, 8, ["modelValue"])]),
          _: 1
        })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_b_input_group, {
          prepend: "до",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            type: "number",
            modelValue: filters.value.risk_impact_lte,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => filters.value.risk_impact_lte = $event),
            style: {
              "width": "3em"
            }
          }, null, 8, ["modelValue"])]),
          _: 1
        })])])]),
        _: 1
      }), _createVNode(_component_b_form_group, {
        label: "Уровень риска",
        class: "mt-3"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_checkbox_group, {
          modelValue: filters.value.risk_level,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => filters.value.risk_level = $event)
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_unref(riskLevelPartners)), ([k, v]) => {
            return _openBlock(), _createBlock(_component_b_form_checkbox, {
              value: k,
              key: k
            }, {
              default: _withCtx(() => [_createElementVNode("span", {
                class: _normalizeClass(`text-${_unref(typeRiskLevel)(Number(k))}`)
              }, _toDisplayString(v), 3)]),
              _: 2
            }, 1032, ["value"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_7, [_createVNode(_component_b_button, {
        block: "",
        variant: "primary",
        onClick: clickApplyFilters,
        size: "sm"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Применить")])),
        _: 1
      }), _createVNode(_component_b_button, {
        size: "sm",
        block: "",
        variant: "outline-secondary",
        class: "ms-3",
        onClick: clearFilters
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("Сбросить фильтры")])),
        _: 1
      })])]);
    };
  }
};