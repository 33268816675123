import { mapActions, mapGetters } from "vuex";
import Calendar from "@/components/Helpers/Calendar.vue";
import { option4select } from "@/helpers/global";
import { formatDateObj, fmtDate } from "@/helpers/date";
import links from "@/router/links";
import { checkPermissions } from "@/auth/auth.js";
import { statesKnowledge } from "@/helpers/states";
import { knowledgeFormat, needAppoint } from "@/helpers/knowledge";
export default {
  name: "KnowledgeFormDescription",
  components: {
    Calendar
  },
  data: () => ({
    links,
    knowledgeFormat
  }),
  methods: {
    ...mapActions("knowledge", ["changeDocument"]),
    async changeData(key, data) {
      let value = this.knowlegeDocument[key];
      if (["document_date"].includes(key)) {
        value = formatDateObj(data, fmtDate, null);
        if (!value) return;
      }
      await this.changeDocument({
        id: this.knowlegeDocument.id,
        params: {
          [key]: value
        }
      });
    },
    changeDate(data) {
      this.knowlegeDocument.documentDate = data;
      this.changeData("document_date", data);
    },
    async handlerDelFile() {
      this.knowlegeDocument.attachment = null;
      await this.changeDocument({
        id: this.knowlegeDocument.id,
        params: {
          attachment: null
        }
      });
    },
    clickAddFile() {
      this.$refs.fileInput.click();
    },
    async handlerAddFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.knowlegeDocument.attachment = files[0].name;
      const formData = new FormData();
      formData.append("attachment", files[0]);
      await this.changeDocument({
        id: this.knowlegeDocument.id,
        params: formData,
        fileUpload: true
      });
      //this.$refs.fileInput.value = null;
    }
  },
  computed: {
    ...mapGetters("knowledge", ["subsectionsDocuments", "subsectionsDocumentsLoading", "knowlegeDocument", "knowlegeDocumentLoading", "sectionFormLoading"]),
    ...mapGetters("referenceBooks", ["departments", "departmentsLoading", "lnaTypes", "lnaTypesLoading"]),
    optionsType() {
      return option4select(this.lnaTypes, false);
    },
    optionsOwner() {
      return option4select(this.departments, false);
    },
    readOnly() {
      return this.knowlegeDocumentLoading || this.sectionFormLoading || statesKnowledge[this.knowlegeDocument.state] !== statesKnowledge.draft || !checkPermissions(this.$perm.change_wa_libmaterial);
    },
    useAppoint() {
      return this.knowlegeDocument.visibility_level === needAppoint;
    }
  }
};