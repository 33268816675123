import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1
};
const _hoisted_2 = {
  class: "need-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SignPEP = _resolveComponent("SignPEP");
  const _component_b_link = _resolveComponent("b-link");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_modal = _resolveComponent("b-modal");
  const _component_b_row = _resolveComponent("b-row");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createBlock(_component_b_row, {
    class: "align-items-center"
  }, {
    default: _withCtx(() => [_createVNode(_component_SignPEP, {
      ref: "btnPEP",
      type: _ctx.signTypes.profile,
      id: $props.profileId
    }, null, 8, ["type", "id"]), _createVNode(_component_b_col, {
      class: "text-md-right"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_link, {
        variant: "outline-secondary",
        class: "border-bottom-0 mb-2 mb-md-0 me-3",
        href: _ctx.links.profileCard + `${JSON.stringify({
          profileId: $props.profileId,
          myProfile: true
        })}/print`,
        target: "_blank"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
          class: "bi bi-printer me-2"
        }, null, -1), _createElementVNode("span", {
          class: "link"
        }, "Подготовить к печати", -1)])),
        _: 1
      }, 8, ["href"]), !$options.readonly && !_ctx.btnSendDisabled ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: "primary",
        onClick: $options.updateStatus
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Отправить на проверку ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.process_my_profile]]) : _createCommentVNode("", true), $options.readonly ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_cache[6] || (_cache[6] = _createTextVNode(" Отправлено на проверку ")), _createElementVNode("time", null, _toDisplayString($options.formatDate(_ctx.profileObj.submitted_at, _ctx.fmtDateTime)), 1)])) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_b_modal, {
      title: _ctx.confirms[_ctx.stepConfirm].title,
      modelValue: _ctx.showModalConfirm,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showModalConfirm = $event),
      "ok-variant": "primary",
      "ok-title": _ctx.confirms[_ctx.stepConfirm].btnOk,
      "cancel-variant": "danger",
      "cancel-title": "Нет",
      centered: "",
      "footer-class": "p-2",
      "hide-header-close": "",
      noCloseOnBackdrop: "",
      onOk: $options.doConfirm,
      onCancel: _cache[1] || (_cache[1] = $event => _ctx.btnSendDisabled = false)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.confirms[_ctx.stepConfirm].text), 1)]),
      _: 1
    }, 8, ["title", "modelValue", "ok-title", "onOk"]), _createVNode(_component_b_modal, {
      title: "Не заполнены обязательные поля",
      modelValue: _ctx.showModalRequiredFields,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModalRequiredFields = $event),
      centered: "",
      okOnly: "",
      "ok-variant": "outline-primary",
      "ok-title": "Закрыть",
      "footer-class": "p-2",
      "hide-header-close": "",
      noCloseOnBackdrop: ""
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.requiredFieldsText), 1)]),
      _: 1
    }, 8, ["modelValue"]), _createVNode(_component_b_modal, {
      title: "Некорректно заполнены данные",
      modelValue: _ctx.showModalInvalidDate,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModalInvalidDate = $event),
      centered: "",
      okOnly: "",
      "ok-variant": "outline-primary",
      "ok-title": "Закрыть",
      "footer-class": "p-2",
      "hide-header-close": "",
      noCloseOnBackdrop: "",
      size: "lg"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.invalidDateText), 1)]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  });
}