import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
import { debounce } from "@/helpers/global";
export default {
  name: "NPOParticipation",
  data: () => ({
    optionYesNo,
    has_ngo_state: null,
    ngos: [],
    endpoint: "ngos",
    template: {
      position: null,
      is_paid: null,
      org_name: null,
      inn: null,
      address: null
    },
    states: {
      position_state: null,
      is_paid_state: null,
      org_name_state: null,
      inn_state: null,
      address_state: null
    },
    profileObjSaved: {},
    currentAddress: null
  }),
  created() {
    this.ngos = this.profileObj.ngos ? this.profileObj.ngos.map(ngo => ({
      ...ngo,
      ...this.states
    })) : [];
    this.profileObjSaved = this.profileObj.ngos ? this.profileObj.ngos.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    ...mapActions("administration2", ["updateDataAdress"]),
    submitData() {
      this.has_ngo_state = false;
      this.updateProfile({
        id: this.profileObj.id,
        has_ngo: this.profileObj.has_ngo
      }).then(() => {
        setValueTimeout(this, "has_ngo_state");
      });
    },
    submitDataEndpoint(obj, key) {
      if (this.profileObjSaved[obj.id][key] === obj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: obj[key]
        },
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved[response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint() {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved[response.id] = {
          ...this.template
        };
        this.ngos.push({
          id: response.id,
          ...this.states,
          ...this.template
        });
      });
    },
    removeDataEndpoint(id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = this.ngos.findIndex(el => el.id === id);
        delete this.profileObjSaved[id];
        this.ngos.splice(index, 1);
      });
    },
    inputData(value) {
      this.currentAddress = value;
      debounce(this.updateDataAddress, 1000);
    },
    updateDataAddress() {
      this.updateDataAdress(this.currentAddress);
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    ...mapGetters("administration2", ["dataAddress"]),
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};