import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_StringsWithX = _resolveComponent("StringsWithX");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_button, {
    variant: "outline-primary",
    onClick: $options.clickAssign,
    disabled: $options.readOnly
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Сформировать список для ознакомления")])),
    _: 1
  }, 8, ["onClick", "disabled"]), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.showModal = $event),
    id: "modalLnaAssign",
    title: "Назначить ознакомление",
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("form", {
      ref: "form-login",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers((...args) => $options.handlerOk && $options.handlerOk(...args), ["stop", "prevent"]))
    }, [!_ctx.departmentsLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_form_group, {
      label: "Срок ознакомления"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "",
        date: _ctx.deadline,
        onChangeValue: _cache[0] || (_cache[0] = $event => _ctx.deadline = $event)
      }, null, 8, ["date"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Организация"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: _ctx.allOrg,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.allOrg = $event),
        switch: ""
      }, {
        default: _withCtx(() => [_createTextVNode("в целом по организации " + _toDisplayString(_ctx.orgName), 1)]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Подразделения"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_select, {
        modelValue: _ctx.depart,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.depart = $event),
        disabled: $options.departOptions.length === 0 || _ctx.allOrg,
        options: $options.departOptions,
        onChange: $options.changeDepart
      }, {
        first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
          value: null,
          disabled: ""
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Выберите подразделение")])),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue", "disabled", "options", "onChange"])]),
      _: 1
    }), _createVNode(_component_b_form_group, {
      label: "Работники"
    }, {
      default: _withCtx(() => [_createVNode(_component_UserSelector, {
        onSelected: $options.selectedWorker,
        disabled: _ctx.allOrg,
        fmtName: _ctx.fmtNameFull,
        showInfo: true
      }, null, 8, ["onSelected", "disabled", "fmtName"])]),
      _: 1
    })])) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Загрузка...", -1))])), _createVNode(_component_b_card, null, {
      default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_StringsWithX, {
          items: _ctx.departsToAssign,
          onRemoveItem: $options.removeDeparts
        }, null, 8, ["items", "onRemoveItem"]), [[_vShow, !_ctx.allOrg]]), _withDirectives(_createVNode(_component_StringsWithX, {
          items: _ctx.workersToAssign,
          onRemoveItem: $options.removeWorkers
        }, null, 8, ["items", "onRemoveItem"]), [[_vShow, !_ctx.allOrg]]), _withDirectives(_createElementVNode("p", {
          class: "mb-0"
        }, "Все работники " + _toDisplayString(_ctx.orgName), 513), [[_vShow, _ctx.allOrg]])]),
        _: 1
      })]),
      _: 1
    })], 544)]),
    _: 1
  }, 8, ["modelValue", "onOk"])]);
}