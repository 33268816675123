import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата начала",
        date: _ctx.startDate,
        classCustom: "mt-2 no-gutters",
        onChangeValue: _cache[0] || (_cache[0] = $event => _ctx.startDate = $event)
      }, null, 8, ["date"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      lg: "3"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: "Дата окончания",
        date: _ctx.endDate,
        classCustom: "mt-2 no-gutters",
        onChangeValue: _cache[1] || (_cache[1] = $event => _ctx.endDate = $event)
      }, null, 8, ["date"])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    class: "mt-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "6",
      md: "6",
      lg: "6"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Тип отчета"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.reportType,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.reportType = $event),
          options: _ctx.reportTypeOptions
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "6",
      md: "6",
      lg: "6"
    }, {
      default: _withCtx(() => [!_ctx.departmentsLoading && _ctx.reportType === 'department' ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 0,
        label: "Подразделение"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.departmentSelected,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.departmentSelected = $event),
          options: $options.departmentOptions
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      })) : _ctx.departmentsLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _ctx.reportType === 'worker' ? (_openBlock(), _createBlock(_component_b_form_group, {
        key: 2,
        label: "Работник"
      }, {
        default: _withCtx(() => [_createVNode(_component_UserSelector, {
          onSelected: $options.selectedWorker,
          fmtName: _ctx.fmtNameFull,
          showInfo: true
        }, null, 8, ["onSelected", "fmtName"])]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    "align-h": "start",
    class: "mt-4 my-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-primary",
        onClick: $options.clickReport,
        disabled: _ctx.reportLoading
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Сформировать отчёт ")])),
        _: 1
      }, 8, ["onClick", "disabled"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "outline-primary",
        onClick: $options.clickDownload
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Скачать файл ")])),
        _: 1
      }, 8, ["onClick"])), [[_directive_can, _ctx.$perm.download_report_wa_innercheck]])]),
      _: 1
    })]),
    _: 1
  }), _withDirectives(_createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-right-0 border-bottom-0 border-left-0 py-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      "header-class": "py-0 m-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.reportCount,
            "per-page": _ctx.perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.reportCount > _ctx.perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.gotoPage,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.gotoPage = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: $options.changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 512), [[_vShow, _ctx.reportCount > _ctx.perPage]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_table, {
      class: "table-custom",
      hover: "",
      responsive: "",
      "show-empty": "",
      items: _ctx.reportData,
      fields: $options.tableFields,
      stacked: "md",
      busy: _ctx.reportLoading,
      small: ""
    }, {
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      empty: _withCtx(() => [_createVNode(_component_b_alert, {
        show: "",
        variant: "light",
        class: "text-center"
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "empty",
          class: "mb-4"
        }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
        _: 1
      })]),
      "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + row.index + 1), 1)]),
      "cell(source)": _withCtx(row => [_createTextVNode(_toDisplayString([].concat(row.item.foundations_coi.map(coi => _ctx.innerCheckReason.foundations_coi + " #" + coi), row.item.foundations_message.map(msg => _ctx.innerCheckReason.foundations_message + " #" + msg), row.item.foundations_cp.map(cp => _ctx.innerCheckReason.foundations_cp + " #" + cp)).join(", ")), 1)]),
      _: 1
    }, 8, ["items", "fields", "busy"])]),
    _: 1
  }, 512), [[_vShow, _ctx.reportShow]]), _createElementVNode("a", {
    href: _ctx.reportFile,
    ref: "fileDownload"
  }, null, 8, _hoisted_3)]);
}