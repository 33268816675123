import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "mb-4"
};
const _hoisted_3 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_icon = _resolveComponent("b-icon");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_BModal = _resolveComponent("BModal");
  const _component_thePage404 = _resolveComponent("thePage404");
  return _openBlock(), _createElementBlock("div", null, [_ctx.types.includes($props.msg) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", _hoisted_2, "Справочник «" + _toDisplayString(_ctx.adminDirectoryTitle) + "»", 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "primary",
        onClick: $options.clickAdd
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Добавить элемент")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }), !_ctx.adminDirectoryLoading ? (_openBlock(), _createBlock(_component_b_card, {
    key: 0,
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm p-4"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminDirectory, (item, index) => {
      return _openBlock(), _createBlock(_component_b_row, {
        key: index,
        class: "mb-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto",
          class: "me-auto"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(index + 1), 1)]),
          _: 2
        }, 1024), _createVNode(_component_b_col, null, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item), 1)]),
          _: 2
        }, 1024), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_icon, {
            icon: "pencil-square",
            class: "icon-wrapper",
            onClick: $event => $options.clickChange({
              item,
              index
            })
          }, null, 8, ["onClick"])]),
          _: 2
        }, 1024), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_icon, {
            icon: "trash",
            class: "icon-wrapper",
            onClick: $event => $options.clickRemove({
              item,
              index
            })
          }, null, 8, ["onClick"])]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])), _createVNode(_component_BModal, {
    id: "modalChangeItem",
    title: _ctx.modalTitle,
    onOk: $options.handlerModalOk,
    "ok-title": "Сохранить",
    "ok-disabled": $options.modalOkDisabled,
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    centered: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_group, {
      label: "Наименование"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.labelItem,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.labelItem = $event),
        placeholder: "Введите наименование",
        trim: ""
      }, null, 8, ["modelValue"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["title", "onOk", "ok-disabled"])])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_thePage404)]))]);
}