import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_withDirectives(_createVNode(_component_b_card_header, {
    "header-class": "p-0 m-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "m-0 p-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto ms-auto"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
          "total-rows": $props.count,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": ""
        }, null, 8, ["modelValue", "total-rows", "per-page"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, $props.count > _ctx.perPage]]), _createVNode(_component_b_table, {
    class: "table-custom",
    hover: "",
    items: $props.items,
    fields: $options.tableFields,
    stacked: "md",
    small: "",
    "show-empty": "",
    responsive: "",
    busy: $props.busy,
    "empty-text": "Данных нет"
  }, {
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + row.index + 1), 1)]),
    "cell(name)": _withCtx(row => [$props.disabledActions ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(row.value), 1)) : (_openBlock(), _createBlock(_component_b_form_input, {
      key: 1,
      modelValue: row.item.name,
      "onUpdate:modelValue": $event => row.item.name = $event,
      onChange: $event => $options.changeComment(row.item.id, row.item.name),
      trim: ""
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))]),
    "cell(remove)": _withCtx(row => [_createVNode(_component_b_button, {
      variant: "link",
      onClick: $event => $options.handlerDel(row.item.name, row.item.id),
      class: "p-0",
      disabled: $props.disabledActions
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
        class: "bi bi-trash"
      }, null, -1)])),
      _: 2
    }, 1032, ["onClick", "disabled"])]),
    _: 1
  }, 8, ["items", "fields", "busy"]), _createVNode(_component_b_card_footer, {
    "footer-bg-variant": "white",
    "footer-class": "py-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_button, {
      variant: "outline-primary",
      onClick: $options.clickAdd,
      disabled: $props.disabledActions
    }, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode(" Добавить комментарий ")])),
      _: 1
    }, 8, ["onClick", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_modal, {
    title: `Удалить комментарий ${_ctx.currentName}?`,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"])]);
}