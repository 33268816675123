import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_InnerCheckViewHeader = _resolveComponent("InnerCheckViewHeader");
  const _component_InnerCheckFormInfo = _resolveComponent("InnerCheckFormInfo");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_InnerCheckFormSource = _resolveComponent("InnerCheckFormSource");
  const _component_InnerCheckFormAttachments = _resolveComponent("InnerCheckFormAttachments");
  const _component_InnerCheckViewChanges = _resolveComponent("InnerCheckViewChanges");
  const _component_InnerCheckFormComments = _resolveComponent("InnerCheckFormComments");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _ctx.innerCheckFormLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createVNode(_component_InnerCheckViewHeader), _createVNode(_component_b_card, {
    "no-body": "",
    class: "mt-3 border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        title: "О проверке"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_InnerCheckFormInfo)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Повод"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_InnerCheckFormSource)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Основание"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_InnerCheckFormAttachments, {
            msg: $props.msg
          }, null, 8, ["msg"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Журнал изменений"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_InnerCheckViewChanges, {
            msg: $props.msg
          }, null, 8, ["msg"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Комментарии"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_InnerCheckFormComments, {
            msg: $props.msg
          }, null, 8, ["msg"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])), [[_directive_can, _ctx.$perm.change_wa_innercheck]]);
}