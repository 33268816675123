import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import theNotification from "@/components/theNotification";
import theAttachments from "@/components/Helpers/theAttachments.vue";
import theComments from "@/components/Helpers/theComments.vue";
import theHistory from "@/components/Helpers/theHistory.vue";
import CPFormInfo from "./CPFormInfo.vue";
import CPFormHeader from "./CPFormHeader.vue";
import CPFormSource from "./CPFormSource.vue";
import { mapActions, mapGetters } from "vuex";
import { statesCp } from "@/helpers/states";
import { checkPermissions } from "@/auth/auth.js";
import { formatName, fmtNameShort } from "@/helpers/text";
import { formatDate, fmtDateShort } from "@/helpers/date";
export default {
  name: "CPFormBase",
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  // сюда получаем id cp

  components: {
    BreadCrumbToHome,
    theNotification,
    CPFormInfo,
    CPFormHeader,
    theAttachments,
    theComments,
    theHistory,
    CPFormSource
  },
  data: () => ({
    breadcrumbs: [{
      text: "Контрольные процедуры",
      to: {
        name: "CPBase"
      }
    }],
    fieldsAttach: [{
      key: "user",
      label: "Работник",
      formatter: value => formatName(value, fmtNameShort)
    }],
    eventFields: [{
      key: "index",
      label: "№"
    }, {
      key: "event",
      label: "Описание"
    }, {
      key: "last_name",
      label: "Работник"
    }, {
      key: "timestamp",
      label: "Дата",
      formatter: value => formatDate(value, fmtDateShort)
    }]
  }),
  async created() {
    this.updateCPForm(this.msg);
    this.updateCpDirectory();
    this.updateCpAttach({
      id: this.msg,
      page: 1
    });
    this.updateCpComments({
      id: this.msg,
      page: 1
    });
    this.updateCpEvents({
      cp_id: this.msg,
      page: 1
    });
  },
  methods: {
    ...mapActions("cp", ["updateCPForm", "updateCpDirectory", "updateCpAttach", "updateCpComments", "createCpAttach", "deleteCpAttach", "createCpComments", "changeCpComments", "deleteCpComments", "updateCpEvents"]),
    async addAttach(file) {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("file_name", file.name);
      await this.createCpAttach({
        id: this.msg,
        formData
      });
      this.updateCpAttach({
        id: this.msg,
        page: 1
      });
    },
    async delAttach(idAttach) {
      await this.deleteCpAttach({
        id: this.msg,
        idAttach
      });
      this.updateCpAttach({
        id: this.msg,
        page: 1
      });
    },
    setPageAttach(page) {
      this.updateCpAttach({
        id: this.msg,
        page
      });
    },
    async addComment() {
      await this.createCpComments({
        id: this.msg,
        body: "новый комментарий"
      });
      this.updateCpComments({
        id: this.msg,
        page: 1
      });
    },
    changeComment(data) {
      this.changeCpComments({
        id: this.msg,
        idComment: data.id,
        body: data.name
      });
    },
    async delComment(idComment) {
      await this.deleteCpComments({
        id: this.msg,
        idComment
      });
      this.updateCpComments({
        id: this.msg,
        page: 1
      });
    },
    async setPageComment(page) {
      this.updateCpComments({
        id: this.msg,
        page
      });
    },
    setPageHistory(page) {
      this.updateCpEvents({
        cp_id: this.msg,
        page
      });
    }
  },
  computed: {
    ...mapGetters("cp", ["cpForm", "cpFormLoading", "cpDirectoryLoading", "cpAttach", "cpAttachCount", "cpAttachLoading", "cpComments", "cpCommentsCount", "cpCommentsLoading", "cpEvents", "cpEventsCount", "cpEventsLoading"]),
    readOnly() {
      return statesCp[this.cpForm.state] !== statesCp.draft || !checkPermissions(this.$perm.add_wa_cp) || this.cpFormLoading;
    },
    getAttachments() {
      return this.cpAttach.map(el => ({
        name: el.file_name,
        id: el.id,
        date: el.created_at,
        file: el.document,
        user: el.user_detail
      }));
    },
    getComments() {
      return this.cpComments.map(el => ({
        name: el.body,
        id: el.id,
        date: el.created_at,
        user: el.user_detail
      }));
    },
    sourceCount() {
      return this.cpForm.foundations_coi.length + this.cpForm.foundations_message.length + this.cpForm.foundations_innercheck.length + this.cpForm.foundations_coid.length;
    }
  }
};