import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "education"
};
const _hoisted_2 = {
  class: "school"
};
const _hoisted_3 = {
  class: "department"
};
const _hoisted_4 = {
  class: "date"
};
const _hoisted_5 = {
  class: "date"
};
const _hoisted_6 = {
  class: "date"
};
const _hoisted_7 = {
  class: "major"
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  colspan: "6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "2. Образование, в том числе полученное за пределами Российской Федерации"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.edu_level,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.edu_level = $event),
          options: _ctx.optionsEduLevel,
          onChange: _cache[1] || (_cache[1] = $event => $options.submitData('edu_level')),
          disabled: _ctx.readonly,
          state: _ctx.edu_level_state
        }, {
          first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
            value: null,
            disabled: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Выберите образование")])),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "options", "disabled", "state"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[5] || (_cache[5] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Наименование учебного заведения, его местонахождение"), _createElementVNode("th", null, "Факультет или отделение"), _createElementVNode("th", null, "Год поступления"), _createElementVNode("th", null, "Год окончания или ухода"), _createElementVNode("th", null, "Если не окончил, то с какого курса ушел"), _createElementVNode("th", null, "Специальность, номер диплома или удостоверения")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formalEducation, educ => {
          return _openBlock(), _createElementBlock("tr", {
            key: educ.id,
            class: "school-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_input, {
            modelValue: educ.college_name,
            "onUpdate:modelValue": $event => educ.college_name = $event,
            onChange: $event => $options.submitDataEndpoint(educ, 'college_name', 'education', _ctx.endpointEducation),
            disabled: _ctx.readonly,
            state: educ.college_name_state,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled", "state"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
            modelValue: educ.department_name,
            "onUpdate:modelValue": $event => educ.department_name = $event,
            onChange: $event => $options.submitDataEndpoint(educ, 'department_name', 'education', _ctx.endpointEducation),
            disabled: _ctx.readonly,
            state: educ.department_name_state,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled", "state"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_select, {
            modelValue: educ.start_year,
            "onUpdate:modelValue": $event => educ.start_year = $event,
            options: $options.startYearsGenerator,
            onChange: $event => $options.submitDataEndpoint(educ, 'start_year', 'education', _ctx.endpointEducation),
            disabled: _ctx.readonly,
            state: educ.start_year_state
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "disabled", "state"])]), _createElementVNode("td", _hoisted_5, [_createVNode(_component_b_form_select, {
            modelValue: educ.end_year,
            "onUpdate:modelValue": $event => educ.end_year = $event,
            options: $options.endYearsGenerator,
            onChange: $event => $options.submitDataEndpoint(educ, 'end_year', 'education', _ctx.endpointEducation),
            disabled: _ctx.readonly,
            state: educ.end_year_state
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "disabled", "state"])]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_b_form_input, {
            modelValue: educ.break_stage,
            "onUpdate:modelValue": $event => educ.break_stage = $event,
            onChange: $event => $options.submitDataEndpoint(educ, 'break_stage', 'education', _ctx.endpointEducation),
            disabled: _ctx.readonly,
            state: educ.break_stage_state,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled", "state"])]), _createElementVNode("td", _hoisted_7, [_createVNode(_component_b_form_input, {
            modelValue: educ.specialization,
            "onUpdate:modelValue": $event => educ.specialization = $event,
            onChange: $event => $options.submitDataEndpoint(educ, 'specialization', 'education', _ctx.endpointEducation),
            disabled: _ctx.readonly,
            state: educ.specialization_state,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "disabled", "state"]), !_ctx.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete double-height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.formalEducation, educ.id, 'education', _ctx.endpointEducation)
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !_ctx.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_8, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_9, [_createVNode(_component_AddRow, {
          onClicked: _cache[2] || (_cache[2] = $event => $options.addDataEndpoint(_ctx.formalEducation, 'education', _ctx.endpointEducation, _ctx.templateEducation, _ctx.statesEducation))
        })])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}