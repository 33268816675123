import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import { riskLevelPartners, formatName, fmtNameFull } from "@/helpers/text";
import { mapActions, mapGetters } from "vuex";
import TaskButton from "@/components/Declaration/Tasks/TaskButton.vue";
import { statesPartnerChecks } from "@/helpers/states";
import { formatDate, fmtDateShort } from "@/helpers/date";
export default {
  name: "PartnersSingleRisk",
  props: {
    riskObject: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    checkId: null,
    fmtNameFull,
    fmtDateShort,
    riskLevelPartners,
    item: {},
    comments: [],
    attachments: [],
    statesPartnerChecks,
    settingsStart: {
      buttonTitle: "Начать проверку",
      buttonType: "outline-primary",
      modalTitle: "Начать проверку",
      addFiles: true,
      addAssignee: true,
      addComment: true,
      limitFiles: 1
    },
    settingsDone: {
      buttonTitle: "Выполнить проверку",
      buttonType: "outline-primary",
      modalTitle: "Выполнить проверку",
      addFiles: true,
      addComment: true,
      limitFiles: 1,
      addSelects: true,
      selects: [{
        title: "Уровень риска *",
        key: "risk_level",
        options: Object.keys(riskLevelPartners).map(key => ({
          value: key,
          text: riskLevelPartners[key]
        })),
        value: null
      }]
    }
  }),
  components: {
    TaskButton
  },
  methods: {
    ...mapActions("partners", ["updatePartnerChecks", "updatePartnerChecksComment", "updatePartnerChecksAttach", "changeStatePartnerCheck", "changePartnerCheck", "createPartnerChecksComment", "createPartnerChecksAttach", "updatePartnerById"]),
    formatDate,
    formatName,
    async handlerComment(comment) {
      if (String(comment).trim().length) await this.createPartnerChecksComment({
        id: this.checkId,
        params: {
          body: comment
        }
      });
    },
    async handlerAttachments(files) {
      for (const file of files) {
        const formData = new FormData();
        formData.append("document", file);
        formData.append("file_name", file.name);
        await this.createPartnerChecksAttach({
          id: this.checkId,
          params: formData
        });
      }
    },
    async handlerSelects(selects) {
      for (const el of selects) {
        await this.changePartnerCheck({
          id: this.checkId,
          params: {
            [el.key]: el.value
          }
        });
      }
    },
    async clickStart(data) {
      await this.changePartnerCheck({
        id: this.checkId,
        params: {
          assignee: data.worker
        }
      });
      await this.handlerComment(data.comment);
      await this.handlerAttachments(data.files);
      await this.changeStatePartnerCheck({
        id: this.checkId,
        state: "assigned",
        msg: "Проверка назначена"
      });
      await this.updateCheck();
    },
    async clickCheck(data) {
      await this.handlerSelects(data.selects);
      await this.handlerComment(data.comment);
      await this.handlerAttachments(data.files);
      await this.changeStatePartnerCheck({
        id: this.checkId,
        state: "checked",
        msg: "Проверка выполнена"
      });
      await this.updateCheck();
    },
    async clickComplete() {
      await this.changeStatePartnerCheck({
        id: this.checkId,
        state: "completed",
        msg: "Проверка завершена"
      });
      await this.updatePartnerById(this.partnerForm.id);
    },
    async updateCheck() {
      await this.updatePartnerChecks(this.checkId);
      await this.updatePartnerChecksComment(this.checkId);
      await this.updatePartnerChecksAttach(this.checkId);
      this.item = this.partnerChecks[this.checkId];
      this.comments = this.partnerChecksComment[this.checkId];
      this.attachments = this.partnerChecksAttach[this.checkId];
    }
  },
  async created() {
    this.checkId = this.partnerForm[this.riskObject.key].id;
    await this.updateCheck();
  },
  computed: {
    ...mapGetters("partners", ["partnerForm", "partnerChecks", "partnerChecksLoading", "partnerChecksComment", "partnerChecksCommentLoading", "partnerChecksAttach", "partnerChecksAttachLoading"])
  }
};