import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.some.js";
import DeclarationFormPartIIItem from "./DeclarationFormPartIIItem";
import Calendar from "@/components/Helpers/Calendar.vue";
import { setCurrentData } from "@/helpers/declaration.render";
import { validate } from "@/helpers/validate";
import { switchText } from "@/helpers/text";
import { formatDateObj, fmtDate, formatObjDate } from "@/helpers/date";
export default {
  name: "DeclarationFormPartII",
  data: () => ({
    currentItem: {},
    showModalRemove: false,
    showModal: false,
    sectionId: null,
    // номер секции(вопроса)
    sectionName: "",
    // название секции(вопроса)
    itemIndex: null,
    // индекс элемента в секции
    addNewItem: false,
    // переключатель добавления нового элемента
    bottomSectionRenderItem: {},
    // данные элемента
    okDisabled: true // кнопка не доступна
  }),
  props: {
    sectionData: {
      type: Object,
      required: true
    },
    disabledDeclActions: {
      type: Boolean,
      default: true
    } // недоступность действий с декларацией
  },
  components: {
    DeclarationFormPartIIItem,
    Calendar
  },
  methods: {
    formatObjDate,
    // валидация модальной формы
    handleSubmit() {
      const valid = this.$refs["form-modal"].checkValidity();
      return valid;
    },
    //доступность кнопки
    setOkDisabled() {
      this.okDisabled = Boolean(Object.values(this.bottomSectionRenderItem).some(el => el.valid === false));
    },
    // обаботка закрытия модального окна
    // нажали Ок в модальном окне
    changeItemOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      const isOkModal = this.handleSubmit();
      if (!isOkModal) return;
      // только через глубокое копирование! иначе передается ссылка
      const newItem = JSON.parse(JSON.stringify(this.bottomSectionRenderItem));
      if (this.addNewItem) {
        // this.addItemToSectionPartII({
        //   sectionId: this.sectionId,
        //   newItem: newItem,
        // });
        this.$emit("emitAddCOI", {
          sectionId: this.sectionId,
          newItem: newItem
        });
      } else {
        // this.changeItemFromSectionPartII({
        //   sectionId: this.sectionId,
        //   itemIndex: this.itemIndex,
        //   newItem: newItem,
        // });
        this.$emit("emitChangeCOI", {
          sectionId: this.sectionId,
          itemIndex: this.itemIndex,
          newItem: newItem
        });
      }
      setTimeout(() => {
        this.showModal = false;
      }, 0);
    },
    // нажали на кнопку "Редактировать" в потомке
    onChangeItem({
      itemIndex,
      sectionId
    }) {
      // запишим текущие параметры
      // потом открываем модальное окно для ввода данных
      this.sectionId = sectionId;
      this.sectionName = sectionId ? this.sectionData[sectionId]["text"] : "";
      this.itemIndex = itemIndex;
      this.addNewItem = false;
      const bottomSectionRenderItem = setCurrentData(itemIndex, sectionId, this.sectionData);
      this.bottomSectionRenderItem = bottomSectionRenderItem;
      this.setOkDisabled();
      this.showModal = true;
    },
    // нажали на кнопку "Добавить"
    onAddItem(sectionId) {
      // запишим текущие параметры
      // потом открываем модальное окно для ввода данных
      this.addNewItem = true;
      this.sectionId = sectionId;
      this.itemIndex = -1; // элемента еще нет
      this.sectionName = sectionId ? this.sectionData[sectionId]["text"] : "";
      const bottomSectionRenderItem = setCurrentData(-1, sectionId, this.sectionData);
      this.bottomSectionRenderItem = bottomSectionRenderItem;
      this.setOkDisabled();
      this.showModal = true;
    },
    // удаление объекта
    // нажали на кнопку "Удалить" в потомке
    onRemoveItem({
      itemIndex,
      title,
      sectionId
    }) {
      this.currentItem.sectionId = sectionId;
      this.currentItem.itemIndex = itemIndex;
      this.currentItem.title = title;
      this.showModalRemove = true;
    },
    doRemove() {
      this.$emit("emitDelCOI", {
        sectionId: this.currentItem.sectionId,
        itemIndex: this.currentItem.itemIndex
      });
    },
    // проверим валидность
    checkValid(index) {
      const item = this.bottomSectionRenderItem[index];
      const validResult = item["dataRequired"] ? validate(item) : Boolean(item["data"]);
      this.bottomSectionRenderItem[index]["valid"] = validResult;
      this.bottomSectionRenderItem[index]["state"] = validResult ? null : false;
      return validResult;
    },
    // состояние валидации Input
    stateInput(index) {
      if (this.bottomSectionRenderItem[index]["state"] === false) {
        const validResult = this.checkValid(index);
        return validResult ? null : validResult;
      } else {
        return null;
      }
    },
    // смена фокуса
    blurMethod(index) {
      this.checkValid(index);
      this.setOkDisabled();
      this.$forceUpdate();
    },
    // изменение значения фокуса
    changeMethod(index) {
      this.checkValid(index);
      this.setOkDisabled();
    },
    // вывод текста переключателя
    switchTextRender(value) {
      return switchText(value);
    },
    changeDate(index, date) {
      this.bottomSectionRenderItem[index].data = formatDateObj(date, fmtDate, null);
      this.checkValid(index);
      this.setOkDisabled();
    }
  },
  computed: {}
};