import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("span", null, [_createVNode(_component_b_button, {
    variant: "outline-primary",
    onClick: $options.handlerClick,
    size: "sm"
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Добавить риск ")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_modal, {
    id: "addRiskToMatrix",
    size: "lg",
    title: "Добавить риск",
    onOk: $options.handlerOk,
    "ok-disabled": !_ctx.risksToAdd.length,
    "ok-title": "Добавить в матрицу",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    centered: "",
    "no-close-on-backdrop": "",
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.showModal = $event)
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [!_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
          key: 0,
          label: "Владелец риска"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.risk_owner_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.risk_owner_id = $event),
            options: $options.departmentOptions
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
          class: "align-middle"
        })]))]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [!_ctx.matrixFormLoading ? (_openBlock(), _createBlock(_component_b_form_group, {
          key: 0,
          label: "Вид деятельности"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.activity_num,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.activity_num = $event),
            options: $options.vdOptions
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "mt-5"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_button, {
          variant: "outline-primary",
          onClick: $options.clickAvailable
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Фильтровать")])),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    }), _cache[14] || (_cache[14] = _createElementVNode("hr", null, null, -1)), _createVNode(_component_b_card_header, {
      "header-class": "py-0 m-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.availableRiskCount,
            "per-page": _ctx.perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.availableRiskCount > _ctx.perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.gotoPage,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.gotoPage = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: $options.changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 512), [[_vShow, _ctx.availableRiskCount > _ctx.perPage]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_table, {
      borderless: "",
      items: _ctx.availableRisk,
      fields: _ctx.tableFields,
      stacked: "md",
      small: "",
      responsive: "",
      busy: _ctx.availableRiskLoading,
      selectable: "",
      "select-mode": "multi",
      onSelection: $options.onSelection,
      "selected-variant": "info",
      "show-empty": "",
      "empty-text": "Рисков нет"
    }, {
      "cell(selected)": _withCtx(row => [_ctx.risksToAdd.includes(row.item) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_cache[7] || (_cache[7] = _createElementVNode("span", {
        "aria-hidden": "true"
      }, "☑", -1)), _cache[8] || (_cache[8] = _createElementVNode("span", {
        class: "sr-only"
      }, "Selected", -1))], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_cache[9] || (_cache[9] = _createElementVNode("span", {
        "aria-hidden": "true"
      }, "☐", -1)), _cache[10] || (_cache[10] = _createElementVNode("span", {
        class: "sr-only"
      }, "Not selected", -1))], 64))]),
      "select-cell": _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode(_toDisplayString(""))])),
      "select-head": _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode(_toDisplayString(""))])),
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      empty: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.emptyText), 1)]),
      _: 1
    }, 8, ["items", "fields", "busy", "onSelection"])]),
    _: 1
  }, 8, ["onOk", "ok-disabled", "modelValue"])]);
}