import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import { yearsGenerator, monthsOptions } from "@/helpers/date";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly } from "@/helpers/profile";
import { UNTIL_NOW } from "@/helpers/text";
import { debounce } from "@/helpers/global";
export default {
  name: "theSeventh",
  data: () => ({
    monthsOptions,
    jobs: [],
    endpointJobs: "job-records",
    templateJobs: {
      start_month: null,
      start_year: null,
      end_month: null,
      end_year: null,
      till_present: null,
      org_name: null,
      organization_form: null,
      inn: null,
      position: null,
      location: null,
      activity_domain: null,
      quit_reason: null
    },
    statesJobs: {
      start_month_state: null,
      start_year_state: null,
      end_month_state: null,
      end_year_state: null,
      org_name_state: null,
      organization_form_state: null,
      inn_state: null,
      position_state: null,
      location_state: null,
      activity_domain_state: null,
      quit_reason_state: null
    },
    UNTIL_NOW,
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    currentAddress: null
  }),
  created() {
    this.jobs = this.profileObj.job_records ? this.profileObj.job_records.map(job => ({
      ...job,
      ...this.statesJobs
    })) : [];
    this.profileObjSaved.jobs = this.profileObj.job_records ? this.profileObj.job_records.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfileData", "addProfileData", "delProfileData"]),
    ...mapActions("administration2", ["updateDataAdress"]),
    async resetTillPresent(job) {
      const response = await this.updateProfileData({
        id: this.profileObj.id,
        dataId: job.id,
        params: {
          till_present: false
        },
        endpoint: this.endpointJobs
      });
      this.profileObjSaved.jobs[response.id] = response;
      job.till_present = false;
    },
    submitDataEndpoint(obj, key, savekey, endpoint) {
      if (this.profileObjSaved[savekey][obj.id][key] === obj[key]) {
        // изменений не было
        return;
      }
      if (key === "end_year" && obj[key] == UNTIL_NOW) {
        const params = {
          till_present: true,
          end_month: null,
          end_year: null
        };
        obj.till_present = true;
        obj.end_month = null;
        obj.end_year = null;
        this.updateProfileData({
          id: this.profileObj.id,
          dataId: obj.id,
          params: params,
          endpoint
        }).then(response => {
          this.profileObjSaved[savekey][response.id] = response;
        });
      } else {
        const state = `${key}_state`;
        obj[state] = false;
        this.updateProfileData({
          id: this.profileObj.id,
          dataId: obj.id,
          params: {
            [key]: obj[key]
          },
          endpoint
        }).then(response => {
          this.profileObjSaved[savekey][response.id] = response;
          setValueTimeout(obj, state);
        });
      }
    },
    addDataEndpoint(obj, savekey, endpoint, template, states) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = {
          ...template
        };
        obj.push({
          id: response.id,
          ...states,
          ...template
        });
      });
    },
    removeDataEndpoint(obj, id, savekey, endpoint) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved[savekey][id];
        obj.splice(index, 1);
      });
    },
    inputData(value) {
      this.currentAddress = value;
      debounce(this.updateDataAddress, 1000);
    },
    updateDataAddress() {
      this.updateDataAdress(this.currentAddress);
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    ...mapGetters("administration2", ["dataAddress"]),
    workingYearsEnd() {
      return [UNTIL_NOW].concat(yearsGenerator({
        offset: 0,
        amount: 50
      }));
    },
    workingYearsStart() {
      return yearsGenerator({
        offset: 0,
        amount: 50
      });
    },
    readonly() {
      return profileReadonly(this.profileObj.state);
    }
  }
};