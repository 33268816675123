import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "px-4"
};
const _hoisted_2 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_3 = {
  class: "mt-3 ms-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome), _cache[14] || (_cache[14] = _createElementVNode("h1", null, "Изменить пароль", -1)), _ctx.profilSettingsLoading ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createElementVNode("div", null, [_cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "text-muted me-2"
  }, "ТН", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.profilSettings[0].worker_code), 1), _cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "text-muted ms-4 me-2"
  }, "ФИО", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.profilSettings[0].last_name) + " " + _toDisplayString(_ctx.profilSettings[0].first_name) + " " + _toDisplayString(_ctx.profilSettings[0].middle_name), 1)]), _createElementVNode("div", null, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "text-muted me-2"
  }, "Организация", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.profilSettings[0].organization_name), 1), _cache[8] || (_cache[8] = _createElementVNode("span", {
    class: "text-muted ms-4 me-2"
  }, "Подразделение", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.profilSettings[0].department_name), 1), _cache[9] || (_cache[9] = _createElementVNode("span", {
    class: "text-muted ms-4 me-2"
  }, "Должность", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.profilSettings[0].position), 1)]), _createElementVNode("div", null, [_cache[10] || (_cache[10] = _createElementVNode("span", {
    class: "text-muted me-2"
  }, "Нужно сменить пароль", -1)), _createElementVNode("span", null, _toDisplayString($options.switchText(_ctx.profilSettings[0].needs_change_password)), 1), _cache[11] || (_cache[11] = _createElementVNode("span", {
    class: "text-muted ms-4 me-2"
  }, " Следующая дата плановой смены пароля ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.profilSettings[0].next_regular_password_update || $options.formatDate(new Date())), 1)]), _createVNode(_component_b_card, {
    "no-body": "",
    class: "mt-3 border-0 shadow-sm p-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "ms-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Старый пароль"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "password",
          modelValue: _ctx.passwordOld,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.passwordOld = $event),
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "ms-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Новый пароль",
        "invalid-feedback": "Пароль задан неверно (мин 10 знаков, заглавные латинские буквы, строчные латинские буквы, цифры)"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "password",
          modelValue: _ctx.passwordNew,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.passwordNew = $event),
          state: _ctx.passwordNewState,
          onInput: $options.validatePasswordNew,
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "state", "onInput", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "ms-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "Новый пароль (еще раз)",
        "invalid-feedback": "Новые пароли не совпадают"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "password",
          modelValue: _ctx.passwordNewAgain,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.passwordNewAgain = $event),
          state: _ctx.passwordNewAgainState,
          onInput: $options.validatePasswordNewAgain,
          disabled: $options.readOnly
        }, null, 8, ["modelValue", "state", "onInput", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_b_button, {
      variant: "primary",
      onClick: $options.changePassword,
      disabled: $options.disabledChange
    }, {
      default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode(" Изменить пароль ")])),
      _: 1
    }, 8, ["onClick", "disabled"])])]),
    _: 1
  }), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModalLogout,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModalLogout = $event),
    "ok-variant": "primary",
    "ok-title": "Выйти из приложения",
    "ok-only": "",
    centered: "",
    size: "sm",
    "hide-header": "",
    "footer-class": "border-top-0",
    "body-class": "text-center",
    "no-close-on-backdrop": "",
    "no-close-on-esc": "",
    "header-class": "border-bottom-0",
    onOk: $options.doLogout
  }, {
    default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("Пароль успешно изменен ")])),
    _: 1
  }, 8, ["modelValue", "onOk"])]);
}