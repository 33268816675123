import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "mt-2"
};
const _hoisted_2 = {
  class: "mt-2"
};
const _hoisted_3 = {
  class: "mt-2 mb-0"
};
const _hoisted_4 = {
  key: 0,
  class: "text-muted"
};
const _hoisted_5 = {
  class: "mt-2 mb-0"
};
const _hoisted_6 = {
  key: 0,
  class: "text-muted"
};
const _hoisted_7 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_TaskButton = _resolveComponent("TaskButton");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_spinner = _resolveComponent("b-spinner");
  return _openBlock(), _createElementBlock("div", null, [!_ctx.partnerChecksLoading && !_ctx.partnerChecksCommentLoading && !_ctx.partnerChecksAttachLoading ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h5", null, _toDisplayString(_ctx.item.department_name), 1)]),
      _: 1
    }), _ctx.statesPartnerChecks[_ctx.item.state] === _ctx.statesPartnerChecks.new ? (_openBlock(), _createBlock(_component_b_col, {
      key: 0,
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_TaskButton, {
        idModal: `modalStart${$props.riskObject.key}`,
        settings: _ctx.settingsStart,
        onAddData: $options.clickStart
      }, null, 8, ["idModal", "settings", "onAddData"])]),
      _: 1
    })) : _createCommentVNode("", true), _ctx.statesPartnerChecks[_ctx.item.state] === _ctx.statesPartnerChecks.assigned ? (_openBlock(), _createBlock(_component_b_col, {
      key: 1,
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_TaskButton, {
        idModal: `modalCheck${$props.riskObject.key}`,
        settings: _ctx.settingsDone,
        onAddData: $options.clickCheck
      }, null, 8, ["idModal", "settings", "onAddData"])]),
      _: 1
    })) : _createCommentVNode("", true), _ctx.statesPartnerChecks[_ctx.item.state] === _ctx.statesPartnerChecks.checked ? (_openBlock(), _createBlock(_component_b_col, {
      key: 2,
      cols: "auto",
      onClick: $options.clickComplete
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-primary"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("Завершить проверку")])),
        _: 1
      })]),
      _: 1
    }, 8, ["onClick"])) : _createCommentVNode("", true)]),
    _: 1
  }), _createElementVNode("div", null, [_cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "text-muted"
  }, "Статус ", -1)), _createTextVNode(" " + _toDisplayString(_ctx.statesPartnerChecks[_ctx.item.state]), 1)]), _createElementVNode("div", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "text-muted"
  }, "Дата создания ", -1)), _createTextVNode(" " + _toDisplayString($options.formatDate(_ctx.item.created_at, _ctx.fmtDateShort)) + " ", 1), _cache[3] || (_cache[3] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Дата начала ", -1)), _createTextVNode(" " + _toDisplayString($options.formatDate(_ctx.item.assigned_at, _ctx.fmtDateShort)) + " ", 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Дата выполнения ", -1)), _createTextVNode(" " + _toDisplayString($options.formatDate(_ctx.item.checked_at, _ctx.fmtDateShort)) + " ", 1), _cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Дата завершения ", -1)), _createTextVNode(" " + _toDisplayString($options.formatDate(_ctx.item.completed_at, _ctx.fmtDateShort)), 1)]), _createElementVNode("div", _hoisted_2, [_cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "text-muted"
  }, "Уровень риска ", -1)), _createTextVNode(" " + _toDisplayString(_ctx.riskLevelPartners[_ctx.item.risk_level]) + " ", 1), _cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Ответственный ", -1)), _createTextVNode(" " + _toDisplayString($options.formatName(_ctx.item.assignee_detail, _ctx.fmtNameFull)), 1)]), _createElementVNode("dl", _hoisted_3, [_ctx.comments.length ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Комментарии")) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, comment => {
    return _openBlock(), _createElementBlock("dd", {
      key: comment.id
    }, _toDisplayString(comment.body), 1);
  }), 128))]), _createElementVNode("dl", _hoisted_5, [_ctx.attachments.length ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Вложения")) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, file => {
    return _openBlock(), _createElementBlock("dd", {
      key: file.id
    }, [_createVNode(_component_b_button, {
      class: "p-0 button-wrap me-2",
      variant: "link",
      href: file.document,
      download: decodeURI(file.document)
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("i", {
        class: "bi bi-download"
      }, null, -1)])),
      _: 2
    }, 1032, ["href", "download"]), _createTextVNode(" " + _toDisplayString(file.file_name), 1)]);
  }), 128))])], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Загрузка...", -1))]))]);
}