import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "work"
};
const _hoisted_2 = {
  class: "date"
};
const _hoisted_3 = {
  class: "date"
};
const _hoisted_4 = {
  class: "place"
};
const _hoisted_5 = {
  class: "tin"
};
const _hoisted_6 = {
  class: "position"
};
const _hoisted_7 = {
  class: "location"
};
const _hoisted_8 = {
  id: "input-list-job-location"
};
const _hoisted_9 = ["value"];
const _hoisted_10 = {
  class: "industry"
};
const _hoisted_11 = {
  class: "reason-to-leave"
};
const _hoisted_12 = {
  key: 0
};
const _hoisted_13 = {
  colspan: "8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_AddRow = _resolveComponent("AddRow");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  return _openBlock(), _createBlock(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "7. Выполняемая работа с начала трудовой деятельности, в том числе за\n        пределами Российской Федерации (включая учебу в высших и средних\n        специальных учебных заведениях, военную службу и работу по\n        совместительству). При заполнении данного пункта учреждения, организации\n        и предприятия необходимо именовать так, как они назывались в свое время,\n        военную службу записывать с указанием должности."
      }, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[6] || (_cache[6] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", {
          colspan: "2",
          style: {
            "text-align": "center"
          }
        }, "Месяц и год"), _createElementVNode("th", {
          rowspan: "2"
        }, " Наименование учреждения, организации, предприятия, министерства (ведомства) "), _createElementVNode("th", {
          rowspan: "2"
        }, "ИНН"), _createElementVNode("th", {
          rowspan: "2"
        }, "Должность"), _createElementVNode("th", {
          rowspan: "2"
        }, " Местонахождение учреждения, организации, предприятия, ведомства "), _createElementVNode("th", {
          rowspan: "2"
        }, "Сфера деятельности"), _createElementVNode("th", {
          rowspan: "2"
        }, "Причина увольнения (согласно ТК РФ)")]), _createElementVNode("tr", null, [_createElementVNode("th", {
          colspan: "1"
        }, "поступления"), _createElementVNode("th", {
          colspan: "1"
        }, "ухода")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, job => {
          return _openBlock(), _createElementBlock("tr", {
            key: job.id,
            class: "job-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_select, {
            class: "month",
            modelValue: job.start_month,
            "onUpdate:modelValue": $event => job.start_month = $event,
            options: _ctx.monthsOptions,
            onChange: $event => $options.submitDataEndpoint(job, 'start_month', 'jobs', _ctx.endpointJobs),
            state: job.start_month_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"]), _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)), _createVNode(_component_b_form_select, {
            modelValue: job.start_year,
            "onUpdate:modelValue": $event => job.start_year = $event,
            class: "year",
            options: $options.workingYearsStart,
            onChange: $event => $options.submitDataEndpoint(job, 'start_year', 'jobs', _ctx.endpointJobs),
            state: job.start_year_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_withDirectives(_createVNode(_component_b_button, {
            disabled: $options.readonly,
            variant: "outline-primary",
            onClick: $event => $options.resetTillPresent(job)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.UNTIL_NOW), 1)]),
            _: 2
          }, 1032, ["disabled", "onClick"]), [[_vShow, job.till_present]]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_b_form_select, {
            class: "month",
            modelValue: job.end_month,
            "onUpdate:modelValue": $event => job.end_month = $event,
            options: _ctx.monthsOptions,
            onChange: $event => $options.submitDataEndpoint(job, 'end_month', 'jobs', _ctx.endpointJobs),
            state: job.end_month_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"]), _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)), _createVNode(_component_b_form_select, {
            modelValue: job.end_year,
            "onUpdate:modelValue": $event => job.end_year = $event,
            options: $options.workingYearsEnd,
            onChange: $event => $options.submitDataEndpoint(job, 'end_year', 'jobs', _ctx.endpointJobs),
            state: job.end_year_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"])], 512), [[_vShow, !job.till_present]])]), _createElementVNode("td", _hoisted_4, [_cache[3] || (_cache[3] = _createElementVNode("small", null, "Организационно-правовая форма", -1)), _createVNode(_component_b_form_input, {
            modelValue: job.organization_form,
            "onUpdate:modelValue": $event => job.organization_form = $event,
            onChange: $event => $options.submitDataEndpoint(job, 'organization_form', 'jobs', _ctx.endpointJobs),
            state: job.organization_form_state,
            disabled: $options.readonly,
            type: "text",
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), _cache[4] || (_cache[4] = _createElementVNode("small", null, "Наименование", -1)), _createVNode(_component_b_form_textarea, {
            rows: "2",
            "no-resize": "",
            modelValue: job.org_name,
            "onUpdate:modelValue": $event => job.org_name = $event,
            onChange: $event => $options.submitDataEndpoint(job, 'org_name', 'jobs', _ctx.endpointJobs),
            state: job.org_name_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_5, [_createVNode(_component_b_form_input, {
            modelValue: job.inn,
            "onUpdate:modelValue": $event => job.inn = $event,
            onChange: $event => $options.submitDataEndpoint(job, 'inn', 'jobs', _ctx.endpointJobs),
            state: job.inn_state,
            disabled: $options.readonly,
            trim: "",
            type: "number"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_b_form_textarea, {
            rows: "3",
            "no-resize": "",
            modelValue: job.position,
            "onUpdate:modelValue": $event => job.position = $event,
            onChange: $event => $options.submitDataEndpoint(job, 'position', 'jobs', _ctx.endpointJobs),
            state: job.position_state,
            trim: "",
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_7, [_createVNode(_component_b_form_group, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: job.location,
              "onUpdate:modelValue": $event => job.location = $event,
              onChange: $event => $options.submitDataEndpoint(job, 'location', 'jobs', _ctx.endpointJobs),
              state: job.location_state,
              disabled: $options.readonly,
              trim: "",
              list: "input-list-job-location",
              onInput: $event => $options.inputData(job.location)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled", "onInput"]), _createElementVNode("datalist", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataAddress, (address, index) => {
              return _openBlock(), _createElementBlock("option", {
                key: index,
                value: address
              }, _toDisplayString(address), 9, _hoisted_9);
            }), 128))])]),
            _: 2
          }, 1024)]), _createElementVNode("td", _hoisted_10, [_createVNode(_component_b_form_textarea, {
            rows: "3",
            "no-resize": "",
            modelValue: job.activity_domain,
            "onUpdate:modelValue": $event => job.activity_domain = $event,
            onChange: $event => $options.submitDataEndpoint(job, 'activity_domain', 'jobs', _ctx.endpointJobs),
            state: job.activity_domain_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_11, [_createVNode(_component_b_form_textarea, {
            rows: "3",
            "no-resize": "",
            modelValue: job.quit_reason,
            "onUpdate:modelValue": $event => job.quit_reason = $event,
            onChange: $event => $options.submitDataEndpoint(job, 'quit_reason', 'jobs', _ctx.endpointJobs),
            state: job.quit_reason_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete double-height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(_ctx.jobs, job.id, 'jobs', _ctx.endpointJobs)
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_12, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_13, [_createVNode(_component_AddRow, {
          onClicked: _cache[0] || (_cache[0] = $event => $options.addDataEndpoint(_ctx.jobs, 'jobs', _ctx.endpointJobs, _ctx.templateJobs, _ctx.statesJobs))
        })])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}