import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "mb-2 text-danger"
};
const _hoisted_4 = {
  class: "text-muted mb-2"
};
const _hoisted_5 = {
  class: "text-danger m-0"
};
const _hoisted_6 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_list_group_item = _resolveComponent("b-list-group-item");
  const _component_b_list_group = _resolveComponent("b-list-group");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [$options.isActionableState($props.item.state) ? (_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    variant: "link",
    onClick: _cache[0] || (_cache[0] = $event => $options.clickAction()),
    class: "border-0 p-0"
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("i", {
      class: "bi bi-check2-square"
    }, null, -1)])),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.showModal = $event),
    id: $props.idModal,
    title: $options.titleModal,
    centered: "",
    scrollable: "",
    "no-close-on-backdrop": "",
    "hide-footer": "",
    size: "lg"
  }, {
    default: _withCtx(() => [_ctx.canCheckProfile($props.item.state) ? (_openBlock(), _createElementBlock("form", _hoisted_1, [_createVNode(_component_b_button, {
      variant: "primary",
      onClick: $options.check
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Подтвердить")])),
      _: 1
    }, 8, ["onClick"])])) : _createCommentVNode("", true), _ctx.canApprove($props.item.state) ? (_openBlock(), _createElementBlock("form", _hoisted_2, [_withDirectives(_createElementVNode("div", _hoisted_3, [_cache[9] || (_cache[9] = _createElementVNode("i", {
      class: "bi bi-exclamation-circle-fill me-2"
    }, null, -1)), _cache[10] || (_cache[10] = _createTextVNode(" У пользователя почта на небезопасном домене ")), _createElementVNode("div", _hoisted_4, _toDisplayString($props.item.email), 1)], 512), [[_vShow, !$props.item.is_safe_email]]), _createVNode(_component_b_list_group, {
      class: "mb-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_list_group_item, null, {
        default: _withCtx(() => [_createTextVNode(" Проверка УК №" + _toDisplayString($props.item.check_compliance.id) + ": " + _toDisplayString(_ctx.mapCheckToText[$props.item.check_compliance.state]), 1)]),
        _: 1
      }), _createVNode(_component_b_list_group_item, null, {
        default: _withCtx(() => [_createTextVNode(" Проверка ОБ № " + _toDisplayString($props.item.check_security.id) + ": " + _toDisplayString(_ctx.mapCheckToText[$props.item.check_security.state]), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Должность"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.position,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.position = $event),
            required: "",
            type: "text",
            placeholder: "Укажите должность",
            state: _ctx.positionState,
            onInput: $options.validatePosition,
            trim: ""
          }, null, 8, ["modelValue", "state", "onInput"])]),
          _: 1
        }), _createVNode(_component_b_form_group, {
          label: "Табельный номер"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.code,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.code = $event),
            required: "",
            type: "text",
            placeholder: "Укажите табельный номер",
            state: _ctx.codeState,
            onInput: $options.validateCode,
            trim: ""
          }, null, 8, ["modelValue", "state", "onInput"])]),
          _: 1
        }), _createVNode(_component_b_form_group, {
          label: "Структурное подразделение"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.department,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.department = $event),
            options: $options.departmentOptions
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        }), _createVNode(_component_Calendar, {
          title: "Дата приема на работу *",
          date: _ctx.startDate,
          classCustom: "mt-2",
          onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.startDate = $event)
        }, null, 8, ["date"]), _cache[11] || (_cache[11] = _createElementVNode("p", {
          class: "mt-2 text-muted"
        }, " * обязательные к заполнению поля для принятия документа ", -1))]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Роль"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_checkbox_group, {
            modelValue: _ctx.selectedRoles,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.selectedRoles = $event),
            state: _ctx.roleState,
            onChange: $options.changeRoles,
            stacked: "",
            required: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminGroups, (role, name) => {
              return _openBlock(), _createBlock(_component_b_form_checkbox, {
                key: name,
                value: name,
                class: _normalizeClass({
                  'text-danger': $options.checkRoles.has(name)
                })
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.adminRolesRef[name] || _ctx.NONAME_SHORT), 1)]),
                _: 2
              }, 1032, ["value", "class"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue", "state", "onChange"]), _withDirectives(_createElementVNode("p", _hoisted_5, " Не все подчиненные роли выбраны ", 512), [[_vShow, $options.checkRoles.size]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_button, {
      variant: "primary",
      onClick: $options.approve,
      disabled: $options.disabledOK
    }, {
      default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("Принять")])),
      _: 1
    }, 8, ["onClick", "disabled"]), _createVNode(_component_b_button, {
      class: "ms-2 float-right",
      variant: "danger",
      onClick: $options.handlerReject
    }, {
      default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("Отклонить ")])),
      _: 1
    }, 8, ["onClick"])])) : _createCommentVNode("", true), _ctx.canValidate($props.item.state) ? (_openBlock(), _createElementBlock("form", _hoisted_6, [_createVNode(_component_b_button, {
      variant: "primary",
      onClick: $options.validateProfile
    }, {
      default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode("Отправить на проверку")])),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_b_button, {
      class: "ms-2 float-right",
      variant: "danger",
      onClick: $options.handlerReject
    }, {
      default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("Отклонить ")])),
      _: 1
    }, 8, ["onClick"])])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue", "id", "title"])]);
}