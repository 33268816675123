import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "px-4 pb-6"
};
const _hoisted_2 = {
  class: "px-4"
};
const _hoisted_3 = {
  class: "mb-0"
};
const _hoisted_4 = {
  class: "mb-0"
};
const _hoisted_5 = {
  class: "mb-0 text-muted"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theSidebar = _resolveComponent("theSidebar");
  const _component_theHeader = _resolveComponent("theHeader");
  const _component_b_breadcrumb = _resolveComponent("b-breadcrumb");
  const _component_router_view = _resolveComponent("router-view");
  const _component_b_container = _resolveComponent("b-container");
  const _component_router_link = _resolveComponent("router-link");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_VMarkdownView = _resolveComponent("VMarkdownView");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theSidebar), _createElementVNode("main", _hoisted_1, [_createVNode(_component_theHeader), _createVNode(_component_b_container, {
    fluid: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_breadcrumb, {
      items: _ctx.breadcrumbs,
      class: "p-0 bg-transparent"
    }, null, 8, ["items"]), _createVNode(_component_router_view)]),
    _: 1
  }), _createVNode(_component_b_container, {
    fluid: "",
    class: "pt-5 align-self-end"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "align-self-end"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, [_createTextVNode(_toDisplayString(new Date().getFullYear()) + " © ", 1), _createVNode(_component_router_link, {
          to: _ctx.links.about
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Сведения о Платформе")])),
          _: 1
        }, 8, ["to"])]), _createElementVNode("p", _hoisted_4, [_createElementVNode("span", {
          class: "span-link",
          onClick: _cache[0] || (_cache[0] = $event => $options.showText('pers_data_conscent'))
        }, "Согласие на обработку персональных данных"), _createElementVNode("span", {
          class: "span-link ms-3",
          onClick: _cache[1] || (_cache[1] = $event => $options.showText('user_agreement'))
        }, "Пользовательское соглашение сервисов сайта \"Комплаенс-платформа.рф\""), _createElementVNode("span", {
          class: "span-link ms-3",
          onClick: _cache[2] || (_cache[2] = $event => $options.showText('pers_data_policy'))
        }, "Политика обработки персональных данных")]), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.OWNER_NAME || ""), 1)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_modal, {
    title: _ctx.agreements[_ctx.docKey],
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.showModal = $event),
    size: "lg",
    "ok-only": "",
    "ok-title": "Закрыть",
    centered: "",
    scrollable: "",
    "no-close-on-backdrop": "",
    "ok-variant": "outline-primary"
  }, {
    default: _withCtx(() => [_createVNode(_component_VMarkdownView, {
      content: _ctx.adminCommon?.[_ctx.docKey]
    }, null, 8, ["content"])]),
    _: 1
  }, 8, ["title", "modelValue"])])]);
}