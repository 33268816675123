import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "mb-1 h5 text-nowrap"
};
const _hoisted_2 = {
  class: "h5 text-muted"
};
const _hoisted_3 = {
  class: "progress-wrap mb-1"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "text-center text-primary my-2"
};
const _hoisted_6 = {
  class: "mb-2"
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_progress_bar = _resolveComponent("b-progress-bar");
  const _component_b_progress = _resolveComponent("b-progress");
  const _component_router_link = _resolveComponent("router-link");
  const _component_EducationCourseItem = _resolveComponent("EducationCourseItem");
  const _component_b_card = _resolveComponent("b-card");
  const _component_CoursePreview = _resolveComponent("CoursePreview");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createElementVNode("h1", null, _toDisplayString(_ctx.getCourse.title), 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_ctx.statesEducUser[_ctx.getCourse.assignment.state] !== _ctx.statesEducUser.new ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_ctx.getCourse.format === 'offline' && _ctx.getCourse.assignment.state !== 'finished' ? (_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        class: "mt-2",
        variant: "primary",
        onClick: $options.uploadCertificate
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Загрузить сертификат")])),
        _: 1
      }, 8, ["onClick"])) : _ctx.getCourse.format !== 'offline' && Number(_ctx.getCourse.assignment.percent) >= 1 ? (_openBlock(), _createBlock(_component_b_button, {
        key: 1,
        class: "mt-2",
        variant: "primary",
        onClick: $options.clickTest
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Тестирование ")])),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), _ctx.statesEducUser[_ctx.getCourse.assignment.state] === _ctx.statesEducUser.new ? (_openBlock(), _createBlock(_component_b_button, {
        key: 1,
        class: "mt-2",
        variant: "primary",
        onClick: $options.clickStart
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("Начать обучение")])),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _ctx.statesEducUser[_ctx.getCourse.assignment.state] !== _ctx.statesEducUser.new ? (_openBlock(), _createBlock(_component_b_row, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => [_createVNode(_component_b_row, {
        class: "mb-2"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => [_createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.getCourse.instructor), 1), _createElementVNode("p", _hoisted_2, " С " + _toDisplayString($options.formatDate(_ctx.getCourse.assignment.date_start_formal, _ctx.fmtDateShort)) + " по " + _toDisplayString($options.formatDate(_ctx.getCourse.assignment.date_end_formal, _ctx.fmtDateShort)), 1)]),
          _: 1
        })]),
        _: 1
      }), _ctx.getCourse.format !== 'offline' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("p", _hoisted_3, "Пройдено " + _toDisplayString($options.progress) + " %", 1), _createVNode(_component_b_progress, {
        max: "100",
        height: "0.2rem"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_progress_bar, {
          value: $options.progress
        }, null, 8, ["value"])]),
        _: 1
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCourse.materials, (item, index) => {
        return _openBlock(), _createElementBlock("p", {
          class: "mb-0",
          key: index
        }, [_createVNode(_component_b_button, {
          class: _normalizeClass(["text-left ps-0", {
            'text-muted': index > _ctx.currentSchemeItem
          }]),
          variant: "link",
          onClick: $event => $options.onClickScheme(index)
        }, {
          default: _withCtx(() => [_createElementVNode("i", {
            class: _normalizeClass(["bi bi-record-circle me-2", index > _ctx.currentSchemeItem ? 'text-muted' : 'text-primary'])
          }, null, 2), _createTextVNode(" " + _toDisplayString(index + 1) + ". " + _toDisplayString(item.title), 1)]),
          _: 2
        }, 1032, ["onClick", "class"])]);
      }), 128)), _ctx.getCourse.assignment.state === 'finished' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)), _createElementVNode("h6", null, [_createVNode(_component_router_link, {
        to: `${_ctx.links.educationCourseCertificate}?id=${_ctx.getCourse.assignment.id}`
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("div", {
          class: "fs-5"
        }, "Сертификат", -1)])),
        _: 1
      }, 8, ["to"])])])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)]),
      _: 1
    }), _ctx.getCourse.format !== 'offline' ? (_openBlock(), _createBlock(_component_b_col, {
      key: 0,
      cols: "9"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, null, {
        default: _withCtx(() => [_ctx.currentSchemeItem < _ctx.getCourse.materials.length ? (_openBlock(), _createBlock(_component_EducationCourseItem, {
          key: 0,
          items: _ctx.getCourse.materials,
          currentItem: _ctx.currentSchemeItem,
          onChangeCurrent: $options.changeCurrent
        }, null, 8, ["items", "currentItem", "onChangeCurrent"])) : _createCommentVNode("", true)]),
        _: 1
      })]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_CoursePreview, {
    key: 1,
    item: _ctx.getCourse
  }, null, 8, ["item"])), _withDirectives(_createElementVNode("div", _hoisted_5, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Загрузка...", -1))], 512), [[_vShow, _ctx.courseLoading]]), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showModal = $event),
    id: "showTestDetails",
    title: "Информация о тестировании",
    centered: "",
    "ok-only": "",
    "ok-title": "Закрыть",
    "ok-variant": "outline-secondary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, [!_ctx.educationTestLoading ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("div", _hoisted_6, " Максимальное число попыток прохождения = " + _toDisplayString(_ctx.getCourse.assignment.course_max_attempts), 1), _createVNode(_component_b_table, {
      class: "table-custom",
      responsive: "",
      hover: "",
      small: "",
      "show-empty": "",
      "empty-text": "Вы еще не начинали ни одного тестирования",
      stacked: "md",
      items: _ctx.getCourse.assignment.test_detail,
      fields: _ctx.testFields
    }, {
      "cell(state)": _withCtx(row => [_ctx.statesTestUser[row.item.state] === _ctx.statesTestUser.started ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.links.educationCourseTest + JSON.stringify({
          assignment: $props.msg,
          attempt: row.item.id
        })
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.statesTestUser[row.value]), 1)]),
        _: 2
      }, 1032, ["to"])) : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.statesTestUser[row.value]), 1))]),
      _: 1
    }, 8, ["items", "fields"]), $options.showBtnStartTest ? (_openBlock(), _createBlock(_component_b_button, {
      key: 0,
      class: "mt-2",
      variant: "primary",
      onClick: $options.clickNewTest
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Начать новое тестирование ")])),
      _: 1
    }, 8, ["onClick"])) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Загрузка...", -1))]))])]),
    _: 1
  }, 8, ["modelValue"]), _createElementVNode("input", {
    type: "file",
    ref: "fileInput",
    class: "d-none",
    onChange: _cache[1] || (_cache[1] = (...args) => $options.handlerAdd && $options.handlerAdd(...args))
  }, null, 544)]);
}