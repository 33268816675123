import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/icons/filterIcon.svg';
const _hoisted_1 = {
  class: "filter-button"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("button", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("img", {
    src: _imports_0,
    alt: "Filter Icon",
    class: "icon"
  }, null, -1), _createElementVNode("span", null, "Фильтровать", -1)]));
}