import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_modal = _resolveComponent("b-modal");
  const _component_b_card = _resolveComponent("b-card");
  const _directive_b_tooltip = _resolveDirective("b-tooltip");
  return _openBlock(), _createBlock(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      xl: "8",
      class: "pb-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_header, {
          class: "px-4 pt-4"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("h4", null, "Обучающие материалы", -1)])),
          _: 1
        }), _createVNode(_component_b_card_text, {
          class: "mb-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_table, {
            class: "table-custom mb-0",
            hover: "",
            items: _ctx.propItem.materials,
            fields: _ctx.tableFields,
            stacked: "md",
            small: "",
            responsive: ""
          }, {
            "cell()": _withCtx(row => [_createElementVNode("span", {
              class: _normalizeClass(row.value ? '' : 'text-gray-400')
            }, _toDisplayString(row.value ? row.value : "—"), 3)]),
            "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString(row.index + 1), 1)]),
            "cell(material_type)": _withCtx(row => [_createTextVNode(_toDisplayString(_ctx.optionsEducMaterials[row.value]), 1)]),
            "cell(btnChange)": _withCtx(row => [_createVNode(_component_b_button, {
              variant: "link",
              onClick: $event => $options.clickisChangeMaterial(row.index),
              disabled: _ctx.propItem.readonly,
              class: "p-0 border-0"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: "edit",
                "fixed-width": ""
              })]),
              _: 2
            }, 1032, ["onClick", "disabled"])]),
            "cell(btnRemove)": _withCtx(row => [_createVNode(_component_b_button, {
              variant: "link",
              onClick: $event => $options.removeMaterial(row.index),
              disabled: _ctx.propItem.readonly,
              class: "p-0 border-0"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: "trash-alt",
                "fixed-width": ""
              })]),
              _: 2
            }, 1032, ["onClick", "disabled"])]),
            _: 1
          }, 8, ["items", "fields"])]),
          _: 1
        }), _createVNode(_component_b_card_footer, {
          class: "bg-white"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            variant: "link",
            class: "p-0",
            onClick: $options.addMaterial,
            disabled: _ctx.propItem.readonly
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("+ Добавить материал ")])),
            _: 1
          }, 8, ["onClick", "disabled"])]),
          _: 1
        }), _createVNode(_component_b_modal, {
          modelValue: _ctx.showModal,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.showModal = $event),
          id: "modalAddMaterial",
          title: "Материал",
          centered: "",
          onOk: $options.handlerOk,
          "ok-title": "Сохранить",
          "cancel-title": "Отменить",
          "cancel-variant": "light",
          scrollable: "",
          "ok-disabled": $options.okModalDisabled
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_group, {
            label: "Наименование",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.modalMaterial,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.modalMaterial = $event),
              placeholder: "Укажите название",
              trim: ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Тип",
            class: "mb-2"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.modalType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.modalType = $event),
              options: $options.optionsTypes
            }, null, 8, ["modelValue", "options"])]),
            _: 1
          }), _createVNode(_component_b_form_group, null, {
            default: _withCtx(() => [_ctx.optionsEducMaterials[_ctx.modalType] === _ctx.optionsEducMaterials.attachment || _ctx.optionsEducMaterials[_ctx.modalType] === _ctx.optionsEducMaterials.presentation || _ctx.optionsEducMaterials[_ctx.modalType] === _ctx.optionsEducMaterials.video ? (_openBlock(), _createBlock(_component_b_row, {
              key: 0,
              class: "mt-2 border border-gray rounded mx-0",
              style: {
                "border-style": "dashed !important"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_b_col, {
                class: "text-muted text-nowrap text-truncate pt-2"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString($options.modalFileName), 1)]),
                _: 1
              }), _createVNode(_component_b_col, {
                cols: "auto"
              }, {
                default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
                  class: "pe-0",
                  variant: "link",
                  title: "Загрузить файл",
                  onClick: $options.clickAddFile
                }, {
                  default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                    icon: "file-circle-plus",
                    "fixed-width": ""
                  })]),
                  _: 1
                }, 8, ["onClick"])), [[_directive_b_tooltip, void 0, void 0, {
                  hover: true
                }]]), _createVNode(_component_b_button, {
                  class: "pe-0",
                  variant: "link",
                  disabled: !_ctx.modalFile,
                  onClick: _cache[2] || (_cache[2] = $event => _ctx.modalFile = null)
                }, {
                  default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                    icon: "trash-can",
                    "fixed-width": ""
                  })]),
                  _: 1
                }, 8, ["disabled"])]),
                _: 1
              })]),
              _: 1
            })) : _ctx.optionsEducMaterials[_ctx.modalType] === _ctx.optionsEducMaterials.text ? (_openBlock(), _createBlock(_component_b_form_input, {
              key: 1,
              modelValue: _ctx.description,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.description = $event),
              placeholder: "Укажите описание",
              trim: ""
            }, null, 8, ["modelValue"])) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "onOk", "ok-disabled"]), _createElementVNode("input", {
          type: "file",
          ref: "fileInput",
          class: "d-none",
          onChange: _cache[5] || (_cache[5] = (...args) => $options.handlerAddFile && $options.handlerAddFile(...args))
        }, null, 544)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_modal, {
      title: `Удалить ${_ctx.currentTitle2Remove}?`,
      modelValue: _ctx.showModalRemove,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.showModalRemove = $event),
      "ok-variant": "outline-danger",
      "ok-title": "Удалить",
      "cancel-variant": "outline-primary",
      "cancel-title": "Отменить",
      centered: "",
      size: "sm",
      "button-size": "sm",
      "footer-class": "p-2",
      "hide-header-close": "",
      "body-class": "d-none",
      onOk: $options.doRemove
    }, null, 8, ["title", "modelValue", "onOk"])]),
    _: 1
  });
}