import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "mt-2"
};
const _hoisted_2 = {
  class: "text-muted mb-2"
};
const _hoisted_3 = {
  class: "text-danger m-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_form_checkbox_group = _resolveComponent("b-form-checkbox-group");
  const _component_b_collapse = _resolveComponent("b-collapse");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [$props.settings.buttonIcon ? (_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    variant: "link",
    onClick: $options.changeUser,
    class: "border-0 p-0"
  }, {
    default: _withCtx(() => [_createElementVNode("i", {
      class: _normalizeClass(`bi bi-${$props.settings.iconType}`)
    }, null, 2)]),
    _: 1
  }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_b_button, {
    key: 1,
    variant: "primary",
    onClick: $options.changeUser,
    "data-hook": "buttonAddUser"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.settings.btnTitle), 1)]),
    _: 1
  }, 8, ["onClick"])), _createVNode(_component_b_modal, {
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _ctx.showModal = $event),
    id: $props.idModal,
    title: $props.settings.title,
    centered: "",
    onOk: $options.handlerOk,
    "ok-title": "Сохранить",
    "cancel-title": "Отменить",
    "cancel-variant": "outline-primary",
    scrollable: "",
    "no-close-on-backdrop": ""
  }, {
    default: _withCtx(() => [_createElementVNode("form", {
      ref: "form-login",
      onSubmit: _cache[12] || (_cache[12] = _withModifiers((...args) => $options.handlerOk && $options.handlerOk(...args), ["stop", "prevent"]))
    }, [!_ctx.isAnalyst ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Организация"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_select, {
        modelValue: _ctx.selectedOrg,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectedOrg = $event),
        options: $options.optionsOrg,
        state: _ctx.orgState,
        onChange: $options.validateOrg,
        disabled: $options.disableOrg
      }, null, 8, ["modelValue", "options", "state", "onChange", "disabled"])]),
      _: 1
    })) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_1, [_cache[14] || (_cache[14] = _createElementVNode("div", {
      class: "text-danger"
    }, [_createElementVNode("i", {
      class: "bi bi-exclamation-circle-fill me-2"
    }), _createTextVNode(" У пользователя почта на небезопасном домене ")], -1)), _createElementVNode("div", _hoisted_2, _toDisplayString($props.item.login), 1)], 512), [[_vShow, !$props.item.is_safe_email]]), _createVNode(_component_b_form_group, {
      label: "Логин",
      "invalid-feedback": "Поле должно быть заполнено по шаблону example@example.com",
      class: "mt-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.login,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.login = $event),
        required: "",
        placeholder: "Укажите логин",
        state: _ctx.emailState,
        onInput: $options.validateEmail,
        trim: ""
      }, null, 8, ["modelValue", "state", "onInput"])]),
      _: 1
    }), $props.settings.new ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 1,
      label: "Пароль",
      "invalid-feedback": "Пароль задан неверно (мин 10 знаков, заглавные латинские буквы, строчные латинские буквы, цифры)",
      class: "mt-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.password = $event),
        required: "",
        trim: "",
        type: "password",
        state: _ctx.passwordState,
        onInput: _cache[3] || (_cache[3] = $event => $options.validatePassword('password', 'passwordState'))
      }, null, 8, ["modelValue", "state"])]),
      _: 1
    })) : _createCommentVNode("", true), !$props.settings.new ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 2,
      label: "Должность",
      class: "mt-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_input, {
        modelValue: _ctx.position,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.position = $event),
        required: "",
        type: "text",
        placeholder: "Укажите должность",
        trim: ""
      }, null, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), !$props.settings.new ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 3,
      label: "Роль",
      class: "mt-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox_group, {
        modelValue: _ctx.selectedRoles,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.selectedRoles = $event),
        state: _ctx.roleState,
        onChange: $options.changeRoles,
        stacked: "",
        required: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminGroups, (role, name) => {
          return _openBlock(), _createBlock(_component_b_form_checkbox, {
            key: name,
            value: name,
            class: _normalizeClass({
              'text-danger': $options.checkRoles.has(name)
            })
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.adminRolesRef[name] || _ctx.NONAME_SHORT), 1)]),
            _: 2
          }, 1032, ["value", "class"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue", "state", "onChange"]), _withDirectives(_createElementVNode("p", _hoisted_3, " Не все подчиненные роли выбраны ", 512), [[_vShow, $options.checkRoles.size]])]),
      _: 1
    })) : _createCommentVNode("", true), !$props.settings.new ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 4,
      label: "Статус",
      class: "mt-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: _ctx.active,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.active = $event),
        switch: ""
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.active ? "Активный" : "Заблокированный"), 1)]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true), $options.canChangePassword ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 5
    }, [_createVNode(_component_b_button, {
      variant: "outline",
      class: _normalizeClass(["ps-0", _ctx.changePswVisible ? null : 'collapsed']),
      "aria-expanded": _ctx.changePswVisible ? 'true' : 'false',
      "aria-controls": "collapse-4",
      onClick: _cache[7] || (_cache[7] = $event => _ctx.changePswVisible = !_ctx.changePswVisible)
    }, {
      default: _withCtx(() => [_cache[15] || (_cache[15] = _createElementVNode("span", {
        class: "link inner"
      }, "Изменить пароль", -1)), _createElementVNode("i", {
        class: _normalizeClass(["bi text-primary pt-1 ms-1", _ctx.changePswVisible ? 'bi-chevron-up' : 'bi-chevron-down']),
        role: "button"
      }, null, 2)]),
      _: 1
    }, 8, ["class", "aria-expanded"]), _createVNode(_component_b_collapse, {
      modelValue: _ctx.changePswVisible,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.changePswVisible = $event)
    }, {
      default: _withCtx(() => [_cache[16] || (_cache[16] = _createElementVNode("div", {
        class: "text-danger"
      }, " Вы собираетесь изменить текущий пароль пользователя ", -1)), _createVNode(_component_b_form_group, {
        label: "Новый пароль",
        "invalid-feedback": "Пароль задан неверно (мин 10 знаков, заглавные латинские буквы, строчные латинские буквы, цифры)"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "password",
          modelValue: _ctx.passwordNew,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.passwordNew = $event),
          state: _ctx.passwordNewState,
          onInput: $options.validatePasswordNew
        }, null, 8, ["modelValue", "state", "onInput"])]),
        _: 1
      }), _createVNode(_component_b_form_group, {
        label: "Новый пароль (еще раз)",
        "invalid-feedback": "Новые пароли не совпадают"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          type: "password",
          modelValue: _ctx.passwordNewAgain,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.passwordNewAgain = $event),
          state: _ctx.passwordNewAgainState,
          onInput: $options.validatePasswordNewAgain
        }, null, 8, ["modelValue", "state", "onInput"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["modelValue"])], 64)) : _createCommentVNode("", true), $options.canAddAnalyst ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 6,
      class: "mt-2"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        modelValue: _ctx.isAnalyst,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _ctx.isAnalyst = $event),
        name: "check-button"
      }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode(" Пользователь - аналитик ")])),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    })) : _createCommentVNode("", true)], 544)]),
    _: 1
  }, 8, ["modelValue", "id", "title", "onOk"])]);
}