import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "elections"
};
const _hoisted_2 = {
  class: "location"
};
const _hoisted_3 = {
  class: "organization"
};
const _hoisted_4 = {
  class: "position"
};
const _hoisted_5 = {
  class: "start-year"
};
const _hoisted_6 = {
  class: "end-year"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  colspan: "5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "9. Участие в центральных, республиканских, краевых, областных, окружных,\n          городских, районных выборных органах власти"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_elected_bodies,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_elected_bodies = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: $options.submitData,
          state: _ctx.hasElectedtate,
          class: "w-auto"
        }, null, 8, ["modelValue", "options", "disabled", "onChange", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _withDirectives(_createVNode(_component_b_form_row, {
    class: "mt-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", {
          rowspan: "2"
        }, "Местонахождение выборного органа"), _createElementVNode("th", {
          rowspan: "2"
        }, "Название выборного органа"), _createElementVNode("th", {
          rowspan: "2"
        }, "В качестве кого избран"), _createElementVNode("th", {
          colspan: "2"
        }, "Год")]), _createElementVNode("tr", null, [_createElementVNode("th", null, "избрания"), _createElementVNode("th", null, "выбытия")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elections, election => {
          return _openBlock(), _createElementBlock("tr", {
            key: election.id,
            class: "election-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_input, {
            modelValue: election.location,
            "onUpdate:modelValue": $event => election.location = $event,
            onChange: $event => $options.submitDataEndpoint(election, 'location'),
            state: election.location_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_input, {
            modelValue: election.body_name,
            "onUpdate:modelValue": $event => election.body_name = $event,
            onChange: $event => $options.submitDataEndpoint(election, 'body_name'),
            state: election.body_name_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_input, {
            modelValue: election.position,
            "onUpdate:modelValue": $event => election.position = $event,
            onChange: $event => $options.submitDataEndpoint(election, 'position'),
            state: election.position_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_5, [_createVNode(_component_b_form_select, {
            modelValue: election.start_year,
            "onUpdate:modelValue": $event => election.start_year = $event,
            options: $options.yearsStart,
            onChange: $event => $options.submitDataEndpoint(election, 'start_year'),
            state: election.start_year_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_b_form_select, {
            modelValue: election.end_year,
            "onUpdate:modelValue": $event => election.end_year = $event,
            options: $options.yearsEnd,
            onChange: $event => $options.submitDataEndpoint(election, 'end_year'),
            state: election.end_year_state,
            disabled: $options.readonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "state", "disabled"]), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete height",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(election.id)
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_7, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_8, [_createVNode(_component_AddRow, {
          onClicked: $options.addDataEndpoint
        }, null, 8, ["onClicked"])])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.profileObj.has_elected_bodies]])]);
}