import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/post.svg';
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_button = _resolveComponent("b-button");
  const _component_router_link = _resolveComponent("router-link");
  const _component_VMarkdownView = _resolveComponent("VMarkdownView");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), !_ctx.magicLink ? (_openBlock(), _createElementBlock("form", {
    key: 0,
    ref: "form-login",
    onSubmit: _cache[5] || (_cache[5] = (...args) => $options.handlerConfirm && $options.handlerConfirm(...args))
  }, [_createVNode(_component_b_form_group, {
    label: "Электронная почта",
    "label-for": "email"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      id: "email",
      required: "",
      type: "email",
      name: "email",
      autofocus: "",
      modelValue: _ctx.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.email = $event),
      placeholder: _ctx.emailPlaceholder
    }, null, 8, ["modelValue", "placeholder"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Пароль",
    "label-for": "password"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      id: "password",
      required: "",
      type: "password",
      name: "password",
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.password = $event)
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createVNode(_component_b_form_row, {
    class: "mb-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_checkbox, {
        id: "agreement",
        modelValue: _ctx.agreement,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.agreement = $event),
        state: _ctx.agreement ? null : false
      }, null, 8, ["modelValue", "state"])]),
      _: 1
    }), _createVNode(_component_b_col, {
      class: _normalizeClass(!_ctx.agreement ? 'text-danger' : '')
    }, {
      default: _withCtx(() => [_cache[7] || (_cache[7] = _createTextVNode(" Даю ")), _createElementVNode("span", {
        class: "span-link",
        onClick: _cache[3] || (_cache[3] = $event => $options.showText('pers_data_conscent'))
      }, "Согласие на обработку персональных данных"), _cache[8] || (_cache[8] = _createTextVNode(", а также принимаю и безоговорочно соглашаюсь с условиями ")), _createElementVNode("span", {
        class: "span-link",
        onClick: _cache[4] || (_cache[4] = $event => $options.showText('user_agreement'))
      }, "Пользовательского соглашения сервисов сайта \"Комплаенс-платформа.рф\"")]),
      _: 1
    }, 8, ["class"])]),
    _: 1
  }), _createVNode(_component_b_button, {
    type: "submit",
    variant: "primary",
    block: "",
    disabled: !_ctx.agreement
  }, {
    default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Получить ссылку для входа")])),
    _: 1
  }, 8, ["disabled"])], 544)) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_cache[11] || (_cache[11] = _createElementVNode("img", {
    class: "mb-4 img-fluid",
    src: _imports_0,
    alt: "Проверьте почту",
    height: "160"
  }, null, -1)), _cache[12] || (_cache[12] = _createElementVNode("p", null, " На почту вам отправлена ссылка для входа. Проверьте, пожалуйста, свою почту. ", -1)), !String(_ctx.magicLink).includes(_ctx.textLink4Prod) ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: _ctx.magicLink,
    class: "text-danger"
  }, {
    default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode(" ссылка для входа ")])),
    _: 1
  }, 8, ["to"])) : _createCommentVNode("", true)])), _createVNode(_component_b_modal, {
    title: _ctx.agreements[_ctx.docKey],
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.showModal = $event),
    size: "lg",
    "ok-only": "",
    "ok-title": "Закрыть",
    centered: "",
    scrollable: "",
    "no-close-on-backdrop": "",
    "ok-variant": "outline-primary"
  }, {
    default: _withCtx(() => [_createVNode(_component_VMarkdownView, {
      content: _ctx.adminCommon?.[_ctx.docKey]
    }, null, 8, ["content"])]),
    _: 1
  }, 8, ["title", "modelValue"])]);
}