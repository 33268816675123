import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "npos"
};
const _hoisted_2 = {
  class: "position"
};
const _hoisted_3 = {
  class: "center paid"
};
const _hoisted_4 = {
  class: "name"
};
const _hoisted_5 = {
  class: "tin"
};
const _hoisted_6 = {
  class: "address"
};
const _hoisted_7 = {
  id: "input-list-ngo-address"
};
const _hoisted_8 = ["value"];
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  colspan: "5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  const _component_AddRow = _resolveComponent("AddRow");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, {
        label: "10. Участие в общественных объединениях и некоммерческих организациях"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.profileObj.has_ngo,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.profileObj.has_ngo = $event),
          options: _ctx.optionYesNo,
          disabled: $options.readonly,
          onChange: $options.submitData,
          state: _ctx.has_ngo_state,
          class: "w-auto"
        }, null, 8, ["modelValue", "options", "disabled", "onChange", "state"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _withDirectives(_createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "",
      lg: "12"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_group, null, {
        default: _withCtx(() => [_createElementVNode("table", _hoisted_1, [_cache[2] || (_cache[2] = _createElementVNode("thead", null, [_createElementVNode("tr", null, [_createElementVNode("th", null, "Должность"), _createElementVNode("th", null, "Оплачиваемая должность"), _createElementVNode("th", null, " Наименование общественного объединения, некоммерческой организации "), _createElementVNode("th", null, "ИНН"), _createElementVNode("th", null, "Адрес юридического лица")])], -1)), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ngos, ngo => {
          return _openBlock(), _createElementBlock("tr", {
            key: ngo.id,
            class: "ngo-record"
          }, [_createElementVNode("td", _hoisted_2, [_createVNode(_component_b_form_input, {
            modelValue: ngo.position,
            "onUpdate:modelValue": $event => ngo.position = $event,
            onChange: $event => $options.submitDataEndpoint(ngo, 'position'),
            state: ngo.position_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_3, [_createVNode(_component_b_form_select, {
            modelValue: ngo.is_paid,
            "onUpdate:modelValue": $event => ngo.is_paid = $event,
            options: _ctx.optionYesNo,
            disabled: $options.readonly,
            onChange: $event => $options.submitDataEndpoint(ngo, 'is_paid'),
            state: ngo.is_paid_state
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "disabled", "onChange", "state"])]), _createElementVNode("td", _hoisted_4, [_createVNode(_component_b_form_input, {
            modelValue: ngo.org_name,
            "onUpdate:modelValue": $event => ngo.org_name = $event,
            onChange: $event => $options.submitDataEndpoint(ngo, 'org_name'),
            state: ngo.org_name_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_5, [_createVNode(_component_b_form_input, {
            modelValue: ngo.inn,
            "onUpdate:modelValue": $event => ngo.inn = $event,
            onChange: $event => $options.submitDataEndpoint(ngo, 'inn'),
            state: ngo.inn_state,
            disabled: $options.readonly,
            trim: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled"])]), _createElementVNode("td", _hoisted_6, [_createVNode(_component_b_form_group, null, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: ngo.address,
              "onUpdate:modelValue": $event => ngo.address = $event,
              onChange: $event => $options.submitDataEndpoint(ngo, 'address'),
              state: ngo.address_state,
              disabled: $options.readonly,
              trim: "",
              list: "input-list-ngo-address",
              onInput: $event => $options.inputData(ngo.address)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange", "state", "disabled", "onInput"]), _createElementVNode("datalist", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataAddress, (address, index) => {
              return _openBlock(), _createElementBlock("option", {
                key: index,
                value: address
              }, _toDisplayString(address), 9, _hoisted_8);
            }), 128))])]),
            _: 2
          }, 1024), !$options.readonly ? (_openBlock(), _createBlock(_component_b_button, {
            key: 0,
            size: "sm",
            class: "mb-2 delete",
            variant: "outline",
            onClick: $event => $options.removeDataEndpoint(ngo.id)
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("i", {
              class: "bi bi-trash-fill",
              "aria-hidden": "true"
            }, null, -1)])),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true)])]);
        }), 128))]), !$options.readonly ? (_openBlock(), _createElementBlock("tfoot", _hoisted_9, [_createElementVNode("tr", null, [_createElementVNode("td", _hoisted_10, [_createVNode(_component_AddRow, {
          onClicked: $options.addDataEndpoint
        }, null, 8, ["onClicked"])])])])) : _createCommentVNode("", true)])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 512), [[_vShow, _ctx.profileObj.has_ngo]])]);
}