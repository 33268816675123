import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "mx-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _cache[2] || (_cache[2] = _createElementVNode("h1", {
    class: "mb-4"
  }, "Настройки безопасности", -1)), _ctx.securityGuideLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.securityGuide.table), ([key, value]) => {
    return _openBlock(), _createBlock(_component_b_row, {
      key: key
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        class: "border py-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(key), 1)]),
        _: 2
      }, 1024), _createVNode(_component_b_col, {
        class: "border text-center py-1"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(value), 1)]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1024);
  }), 128)), _cache[1] || (_cache[1] = _createElementVNode("p", null, null, -1)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.securityGuide.guide, (el, index) => {
    return _openBlock(), _createElementBlock("p", {
      key: index
    }, _toDisplayString(index + 1) + ". " + _toDisplayString(el), 1);
  }), 128))])]);
}