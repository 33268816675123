import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "card-title mb-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_SectionAdd = _resolveComponent("SectionAdd");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_link = _resolveComponent("b-link");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome), _createVNode(_component_b_row, {
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("h1", null, "Библиотека знаний", -1)])),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto",
      class: "text-md-right"
    }, {
      default: _withCtx(() => [!_ctx.sectionsLoading && !$options.isAltUI ? _withDirectives((_openBlock(), _createBlock(_component_SectionAdd, {
        key: 0,
        setting: _ctx.setting,
        onAddSection: $options.addSection
      }, null, 8, ["setting", "onAddSection"])), [[_directive_can, _ctx.$perm.add_wa_libsection]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), !_ctx.sectionsLoading ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_ctx.sectionsCount > 0 ? (_openBlock(), _createBlock(_component_b_row, {
    key: 0
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (item, index) => {
      return _openBlock(), _createBlock(_component_b_col, {
        cols: "4",
        key: index,
        class: "d-flex align-self-stretch"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card, {
          "no-body": "",
          class: "mb-4 border-0 shadow-sm w-100"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_card_body, {
            class: "position-relative p-4"
          }, {
            default: _withCtx(() => [_createElementVNode("h5", _hoisted_1, [_createVNode(_component_b_link, {
              class: "card-link stretched-link",
              onClick: $event => $options.onMouseClick(item)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
              _: 2
            }, 1032, ["onClick"])])]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_b_alert, {
    key: 1,
    show: "",
    variant: "light",
    class: "text-center"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
      src: _imports_0,
      alt: "empty",
      class: "mb-4"
    }, null, -1), _createElementVNode("h4", null, "Пусто", -1)])),
    _: 1
  }))], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_b_spinner, {
    class: "align-middle text-center text-primary my-2"
  }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))], 64)), _createVNode(_component_b_card_header, {
    "header-class": "py-0 m-0 bg-light border-0"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_row, {
      class: "mt-0 mb-2 ms-0 me-0 py-2 px-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "auto"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
          modelValue: $options.currentPageModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
          "total-rows": _ctx.sectionsCount,
          "per-page": _ctx.perPage,
          size: "sm",
          "first-number": "",
          "last-number": "",
          "hide-goto-end-buttons": "",
          class: "mb-0"
        }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.sectionsCount > _ctx.perPage]])]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "auto",
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
          prepend: "Перейти к",
          size: "sm"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.gotoPage,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
            style: {
              "width": "3em"
            },
            oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
          }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
            default: _withCtx(() => [_createVNode(_component_b_button, {
              variant: "outline-primary-muted",
              onClick: $options.changePage,
              size: "sm"
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: ['far', 'arrow-alt-circle-right'],
                "fixed-width": ""
              })]),
              _: 1
            }, 8, ["onClick"])]),
            _: 1
          })]),
          _: 1
        }, 512), [[_vShow, _ctx.sectionsCount > _ctx.perPage]])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}