import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  key: 1,
  class: "table-responsive"
};
const _hoisted_3 = {
  class: "text-center text-primary my-2"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_COIDetails = _resolveComponent("COIDetails");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  return _openBlock(), _createElementBlock("div", null, [_cache[4] || (_cache[4] = _createElementVNode("h5", null, "ЭДКИ с КИ", -1)), _ctx.coiLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), !$options.isEmpty ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_table, {
    borderless: "",
    items: _ctx.coi,
    fields: _ctx.tableFields,
    stacked: "md",
    busy: _ctx.coiLoading,
    small: ""
  }, {
    "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_b_spinner, {
      class: "align-middle"
    }), _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
    "cell(index)": _withCtx(data => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + data.index + 1), 1)]),
    "cell()": _withCtx(row => [_createElementVNode("div", {
      class: "coi-type-wrap",
      onClick: row.toggleDetails
    }, _toDisplayString(row.value), 9, _hoisted_4)]),
    "row-details": _withCtx(row => [_createVNode(_component_COIDetails, {
      item: row.item
    }, null, 8, ["item"])]),
    _: 1
  }, 8, ["items", "fields", "busy"])])) : (_openBlock(), _createBlock(_component_b_alert, {
    key: 2,
    show: "",
    variant: "light",
    class: "text-center"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("img", {
      src: _imports_0,
      alt: "empty",
      class: "mb-4"
    }, null, -1), _createElementVNode("h4", null, "Данных нет", -1)])),
    _: 1
  })), _createVNode(_component_b_card_footer, {
    "footer-bg-variant": "white",
    class: "px-4 py-3 text-center"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_pagination, {
      modelValue: $options.currentPageModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
      "total-rows": _ctx.coiCount,
      "per-page": _ctx.perPage,
      pills: "",
      class: "mb-0"
    }, null, 8, ["modelValue", "total-rows", "per-page"])]),
    _: 1
  })]);
}