import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import theNotification from "@/components/theNotification";
import BreadCrumbToHome from "@/components/BreadCrumbToHome";
import { checkPermissions } from "@/auth/auth";
import CP from "./CP.vue";
import links from "@/router/links";
import { useRouter } from "vue-router";
export default {
  __name: 'CPBase',
  setup(__props) {
    const permissions = ref({});
    const store = useStore();
    const router = useRouter();
    const onlyNew = computed(() => store.getters["homeStats/onlyNew"]);
    // const cpCount = computed(() => store.getters["cp/cpCount"]);

    const setOnlyNew = value => store.dispatch("homeStats/setOnlyNew", value);
    const fetchCp = params => store.dispatch("cp/fetchCp", params);
    const updateCpDirectory = () => store.dispatch("cp/updateCpDirectory");
    const cpForm = computed(() => store.getters["cp/cpForm"]);
    onMounted(async () => {
      const instance = getCurrentInstance();
      permissions.value = instance?.appContext.config.globalProperties.$perm;
      const params = {
        filters: {}
      };
      if (onlyNew.value) params.filters.is_new = true;
      if (checkPermissions(permissions.view_wa_cp)) {
        await fetchCp(params);
        await updateCpDirectory();
      }
      setOnlyNew(null); // обнулимся
    });
    const clickCreateCp = async () => {
      await store.dispatch("cp/createCPForm");
      if (cpForm.value.id) router.push(links.cpForm + cpForm.value.id);
    };
    return (_ctx, _cache) => {
      const _component_b_col = _resolveComponent("b-col");
      const _component_b_button = _resolveComponent("b-button");
      const _component_b_row = _resolveComponent("b-row");
      const _component_b_card_text = _resolveComponent("b-card-text");
      const _component_b_card = _resolveComponent("b-card");
      const _directive_can = _resolveDirective("can");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(_unref(BreadCrumbToHome)), _unref(checkPermissions)(_ctx.$perm.view_wa_cp) ? (_openBlock(), _createBlock(_component_b_row, {
        key: 0,
        class: "sticky-top align-items-center px-4 mb-2 bg-light title-shadow"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("h1", null, "Контрольные процедуры", -1)])),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
            variant: "primary",
            onClick: clickCreateCp
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode(" Создать ")])),
            _: 1
          })), [[_directive_can, _ctx.$perm.add_wa_cp]])]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0 shadow-sm p-3"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(CP)]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};