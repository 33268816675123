import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select_option = _resolveComponent("b-form-select-option");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_StringsWithX = _resolveComponent("StringsWithX");
  const _component_TaskButton = _resolveComponent("TaskButton");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_form_group, {
    label: "Тип конфликта интересов"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.coiForm.coi_type_code,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.coiForm.coi_type_code = $event),
      options: $options.option4type,
      onChange: _cache[1] || (_cache[1] = $event => $options.changeData('coi_type_code')),
      disabled: $options.readOnly
    }, {
      first: _withCtx(() => [_createVNode(_component_b_form_select_option, {
        value: null,
        disabled: ""
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Не выбран")])),
        _: 1
      })]),
      _: 1
    }, 8, ["modelValue", "options", "disabled"])]),
    _: 1
  }), _createVNode(_component_b_form_row, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries($options.coiRenderTemplate), ([key, el]) => {
      return _openBlock(), _createBlock(_component_b_col, {
        key: key,
        cols: "12",
        class: "my-2",
        lg: el.gridLg || 12
      }, {
        default: _withCtx(() => [el.hidden ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [], 64)) : el.type === 'checkbox' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [_createTextVNode(_toDisplayString(_ctx.coiTemplate?.[el.keyForDB]?.title) + " ", 1), _createVNode(_component_b_form_checkbox, {
          modelValue: _ctx.coiForm[el.keyForDB],
          "onUpdate:modelValue": $event => _ctx.coiForm[el.keyForDB] = $event,
          onChange: $event => $options.changeData(el.keyForDB),
          switch: "",
          class: "mb-2",
          disabled: $options.readOnly
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($options.switchText(_ctx.coiForm[el.keyForDB])), 1)]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "disabled"])], 64)) : el.type === 'date' ? (_openBlock(), _createBlock(_component_Calendar, {
          key: 2,
          title: _ctx.coiTemplate?.[el.keyForDB]?.title,
          date: $options.formatObjDate(_ctx.coiForm[el.keyForDB]),
          classCustom: "mt-2 no-gutters",
          disabled: $options.readOnly,
          onChangeValue: $event => $options.changeDataDate(el.keyForDB, $event)
        }, null, 8, ["title", "date", "disabled", "onChangeValue"])) : (_openBlock(), _createBlock(_component_b_form_group, {
          key: 3,
          label: _ctx.coiTemplate?.[el.keyForDB]?.title,
          disabled: $options.readOnly
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.coiForm[el.keyForDB],
            "onUpdate:modelValue": $event => _ctx.coiForm[el.keyForDB] = $event,
            type: el.type,
            trim: "",
            onChange: $event => $options.changeData(el.keyForDB)
          }, null, 8, ["modelValue", "onUpdate:modelValue", "type", "onChange"])]),
          _: 2
        }, 1032, ["label", "disabled"]))]),
        _: 2
      }, 1032, ["lg"]);
    }), 128))]),
    _: 1
  }), _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)), _cache[4] || (_cache[4] = _createElementVNode("h6", null, "Работники", -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_StringsWithX, {
    items: $options.workersArray,
    onRemoveItem: $options.clickRemoveWorker
  }, null, 8, ["items", "onRemoveItem"])]), _createVNode(_component_TaskButton, {
    idModal: "addWorker2COI",
    disabled: $options.readOnly,
    settings: _ctx.settingsBtn,
    onAddData: $options.clickAddWorker
  }, null, 8, ["disabled", "settings", "onAddData"])]);
}