import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "filter-wrap"
};
const _hoisted_2 = {
  class: "footer mt-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_form_group, {
    label: "Статус"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.state,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filters.state = $event),
      options: $options.stateOptions
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Субъект КП"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.cp_type,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.filters.cp_type = $event),
      options: $options.cpOptions
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  }), _cache[9] || (_cache[9] = _createElementVNode("div", null, "Дата изменения статуса", -1)), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "С",
      class: "mb-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.state_modified_gte,
        classCustom: "no-gutters",
        onChangeValue: _cache[2] || (_cache[2] = $event => _ctx.state_modified_gte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "По"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.state_modified_lte,
        classCustom: "no-gutters",
        onChangeValue: _cache[3] || (_cache[3] = $event => _ctx.state_modified_lte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1)), _cache[11] || (_cache[11] = _createElementVNode("div", null, "Плановая дата", -1)), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "С",
      class: "mb-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.date_planned_gte,
        classCustom: "no-gutters",
        onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.date_planned_gte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "По"
    }, {
      default: _withCtx(() => [_createVNode(_component_Calendar, {
        title: null,
        date: _ctx.date_planned_lte,
        classCustom: "no-gutters",
        onChangeValue: _cache[5] || (_cache[5] = $event => _ctx.date_planned_lte = $event)
      }, null, 8, ["date"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_b_form_group, {
    label: "Ответственный"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.assignee_search,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.filters.assignee_search = $event),
      type: "search",
      placeholder: "Фамилия",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_button, {
    block: "",
    variant: "primary",
    onClick: $options.clickApplyFilters
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Применить")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_button, {
    block: "",
    variant: "outline-secondary",
    onClick: $options.clearFilters,
    class: "ms-3"
  }, {
    default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Сбросить фильтры")])),
    _: 1
  }, 8, ["onClick"])])]);
}