import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "text-danger"
};
const _hoisted_3 = {
  key: 0,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_card_footer = _resolveComponent("b-card-footer");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_radio = _resolveComponent("b-form-radio");
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_b_modal = _resolveComponent("b-modal");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createBlock(_component_b_row, null, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      xl: "8",
      class: "pb-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "overflow-hidden border-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_header, {
          class: "px-4 pt-4"
        }, {
          default: _withCtx(() => [_createElementVNode("h4", null, [_cache[11] || (_cache[11] = _createTextVNode(" Тестирование")), _createVNode(_component_b_badge, {
            variant: "secondary",
            class: "text-white ms-1"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.educationQuestCount), 1)]),
            _: 1
          })]), _createVNode(_component_b_row, {
            class: "my-3"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_col, null, {
              default: _withCtx(() => [_createVNode(_component_b_form_group, {
                label: "Минимальный балл для прохождения",
                "label-cols": "12",
                "label-cols-md": "7",
                "content-cols": "12",
                "content-cols-md": "4",
                "invalid-feedback": "Балл должен быть >0 и <=100",
                state: _ctx.getCourse.grade_passing > 0 && _ctx.getCourse.grade_passing <= 100 ? null : false
              }, {
                default: _withCtx(() => [_createVNode(_component_b_input_group, {
                  append: "%"
                }, {
                  default: _withCtx(() => [_createVNode(_component_b_form_input, {
                    modelValue: _ctx.getCourse.grade_passing,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.getCourse.grade_passing = $event),
                    disabled: _ctx.getCourse.readonly,
                    type: "number",
                    onChange: _cache[1] || (_cache[1] = $event => $options.changeValue('grade_passing')),
                    state: _ctx.getCourse.grade_passing > 0 && _ctx.getCourse.grade_passing <= 100 ? null : false
                  }, null, 8, ["modelValue", "disabled", "state"])]),
                  _: 1
                })]),
                _: 1
              }, 8, ["state"])]),
              _: 1
            }), _createVNode(_component_b_col, null, {
              default: _withCtx(() => [_createVNode(_component_b_form_group, {
                label: "Максимальное число попыток прохождения",
                "label-cols": "12",
                "label-cols-md": "8",
                "content-cols": "12",
                "content-cols-md": "4",
                "invalid-feedback": "Число должно быть >0"
              }, {
                default: _withCtx(() => [_createVNode(_component_b_form_input, {
                  modelValue: _ctx.getCourse.max_attempts,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.getCourse.max_attempts = $event),
                  onChange: _cache[3] || (_cache[3] = $event => $options.changeValue('max_attempts')),
                  disabled: _ctx.getCourse.readonly,
                  type: "number",
                  state: _ctx.getCourse.max_attempts > 0 ? null : false
                }, null, 8, ["modelValue", "disabled", "state"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_b_form_row, {
            class: "m-0 p-0"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_col, {
              cols: "auto"
            }, {
              default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
                modelValue: $options.currentPageModelQuest,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $options.currentPageModelQuest = $event),
                "total-rows": _ctx.educationQuestCount,
                "per-page": _ctx.perPage,
                size: "sm",
                "first-number": "",
                "last-number": "",
                "hide-goto-end-buttons": ""
              }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.educationQuestCount > _ctx.perPage]])]),
              _: 1
            }), _createVNode(_component_b_col, {
              cols: "auto",
              class: "m-0 p-0"
            }, {
              default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
                prepend: "Перейти к",
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_b_form_input, {
                  modelValue: _ctx.gotoPageQuest,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.gotoPageQuest = $event),
                  style: {
                    "width": "3em"
                  },
                  oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
                }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
                  default: _withCtx(() => [_createVNode(_component_b_button, {
                    variant: "outline-primary-muted",
                    onClick: $options.changePageQuest,
                    size: "sm"
                  }, {
                    default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'arrow-alt-circle-right'],
                      "fixed-width": ""
                    })]),
                    _: 1
                  }, 8, ["onClick"])]),
                  _: 1
                })]),
                _: 1
              }, 512), [[_vShow, _ctx.educationQuestCount > _ctx.perPage]])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_b_card_text, {
          class: "mb-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_table, {
            responsive: "",
            hover: "",
            small: "",
            stacked: "md",
            items: _ctx.educationQuest,
            fields: _ctx.fieldsQuest,
            busy: _ctx.educationQuestLoading,
            class: "table-custom mb-0"
          }, {
            "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
              class: "align-middle"
            }), _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
            "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((_ctx.currentPageQuest - 1) * _ctx.perPage + row.index + 1), 1)]),
            "cell(btnChange)": _withCtx(row => [_createVNode(_component_b_button, {
              class: "p-0",
              variant: "link",
              onClick: $event => $options.changeQuestion(row.item)
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: "edit",
                "fixed-width": ""
              })]),
              _: 2
            }, 1032, ["onClick"])]),
            "cell(btnRemove)": _withCtx(row => [_createVNode(_component_b_button, {
              class: "p-0",
              variant: "link",
              disabled: _ctx.getCourse.readonly,
              onClick: $event => $options.removeQuestion(row.item)
            }, {
              default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                icon: "trash-alt",
                "fixed-width": ""
              })]),
              _: 2
            }, 1032, ["disabled", "onClick"])]),
            _: 1
          }, 8, ["items", "fields", "busy"])]),
          _: 1
        }), _createVNode(_component_b_card_footer, {
          class: "bg-white"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_button, {
            variant: "link",
            class: "p-0",
            onClick: $options.addQuestion,
            disabled: _ctx.getCourse.readonly
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("+ Добавить вопрос ")])),
            _: 1
          }, 8, ["onClick", "disabled"])]),
          _: 1
        }), _createVNode(_component_b_modal, {
          id: "modalChangeQuestion",
          modelValue: _ctx.showModal,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.showModal = $event),
          title: _ctx.modalTitle,
          centered: "",
          onOk: $options.handlerOk,
          "ok-title": _ctx.modalButton,
          "cancel-title": _ctx.modalCansel,
          "cancel-variant": "outline-secondary",
          scrollable: "",
          size: "lg",
          "ok-disabled": $options.okModalDisabled
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_group, {
            label: "Вопрос",
            "label-for": "modal-description"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              id: "modal-description",
              modelValue: _ctx.modalDescription,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.modalDescription = $event),
              placeholder: "Опишите вопрос",
              disabled: _ctx.getCourse.readonly,
              trim: ""
            }, null, 8, ["modelValue", "disabled"])]),
            _: 1
          }), _createVNode(_component_b_form_group, {
            label: "Верный вариант ответа"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_select, {
              modelValue: _ctx.answerTypeSelected,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _ctx.answerTypeSelected = $event),
              options: $options.options4answerType,
              disabled: _ctx.getCourse.readonly || !_ctx.addNewQuest
            }, null, 8, ["modelValue", "options", "disabled"])]),
            _: 1
          }), _ctx.answerType[_ctx.answerTypeSelected] !== _ctx.answerType.open && !_ctx.addNewQuest ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_cache[17] || (_cache[17] = _createElementVNode("p", null, "Варианты ответа (не более 10)", -1)), _withDirectives(_createElementVNode("p", _hoisted_2, " Неверно выбраны правильные ответы ", 512), [[_vShow, $options.showErrorCheckAnswer && !_ctx.educationAnswerLoading]]), _ctx.educationAnswerLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Загрузка...", -1))])) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(_ctx.educationAnswer, (answer, index) => {
            return _openBlock(), _createElementBlock("div", {
              key: index
            }, [_createVNode(_component_b_row, {
              class: "mb-2"
            }, {
              default: _withCtx(() => [_createVNode(_component_b_col, {
                cols: "auto",
                class: "pt-2"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(index + 1), 1)]),
                _: 2
              }, 1024), _createVNode(_component_b_col, null, {
                default: _withCtx(() => [_createVNode(_component_b_input_group, null, {
                  default: _withCtx(() => [_createVNode(_component_b_form_input, {
                    modelValue: answer.content,
                    "onUpdate:modelValue": $event => answer.content = $event,
                    placeholder: "Укажите вариант ответа",
                    disabled: _ctx.getCourse.readonly,
                    onChange: $event => $options.changeAnswer(answer, 'content'),
                    trim: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"]), _createVNode(_component_b_input_group_append, {
                    "is-text": ""
                  }, {
                    default: _withCtx(() => [_ctx.answerType[_ctx.answerTypeSelected] === _ctx.answerType.singlechoice ? (_openBlock(), _createBlock(_component_b_form_radio, {
                      key: 0,
                      modelValue: answer.is_correct,
                      "onUpdate:modelValue": $event => answer.is_correct = $event,
                      name: "modal-radios",
                      disabled: _ctx.getCourse.readonly,
                      value: true,
                      onChange: $event => $options.changeAnswer(answer, 'is_correct')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])) : (_openBlock(), _createBlock(_component_b_form_checkbox, {
                      key: 1,
                      modelValue: answer.is_correct,
                      "onUpdate:modelValue": $event => answer.is_correct = $event,
                      name: 'modal-checkbox-' + index,
                      disabled: _ctx.getCourse.readonly,
                      onChange: $event => $options.changeAnswer(answer, 'is_correct')
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "disabled", "onChange"]))]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024), _createVNode(_component_b_col, {
                cols: "auto",
                class: "p-0"
              }, {
                default: _withCtx(() => [_createVNode(_component_b_button, {
                  variant: "link",
                  disabled: _ctx.getCourse.readonly,
                  onClick: $event => $options.removeAnswer(answer)
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [_createElementVNode("i", {
                    class: "bi bi-trash"
                  }, null, -1)])),
                  _: 2
                }, 1032, ["disabled", "onClick"])]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024)]);
          }), 128)), _createVNode(_component_b_button, {
            variant: "link",
            class: "p-0",
            onClick: $options.addAnswer,
            disabled: $options.addAnswerDisabled
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode(" + Добавить вариант ответа")])),
            _: 1
          }, 8, ["onClick", "disabled"])], 64)) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["modelValue", "title", "onOk", "ok-title", "cancel-title", "ok-disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_modal, {
      title: `Удалить вопрос ${_ctx.currentItem.text} ?`,
      modelValue: _ctx.showModalRemoveQuest,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _ctx.showModalRemoveQuest = $event),
      "ok-variant": "outline-danger",
      "ok-title": "Удалить",
      "cancel-variant": "outline-primary",
      "cancel-title": "Отменить",
      centered: "",
      size: "sm",
      "button-size": "sm",
      "footer-class": "p-2",
      "hide-header-close": "",
      "body-class": "d-none",
      onOk: $options.doRemoveQuest
    }, null, 8, ["title", "modelValue", "onOk"]), _createVNode(_component_b_modal, {
      title: `Удалить ответ ${_ctx.currentItem.content} ?`,
      modelValue: _ctx.showModalRemoveAnswer,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.showModalRemoveAnswer = $event),
      "ok-variant": "outline-danger",
      "ok-title": "Удалить",
      "cancel-variant": "outline-primary",
      "cancel-title": "Отменить",
      centered: "",
      size: "sm",
      "button-size": "sm",
      "footer-class": "p-2",
      "hide-header-close": "",
      "body-class": "d-none",
      onOk: $options.doRemoveAnswer
    }, null, 8, ["title", "modelValue", "onOk"])]),
    _: 1
  });
}