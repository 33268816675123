import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly, languageLevelOptions } from "@/helpers/profile";
export default {
  name: "theFourth",
  data: () => ({
    languageLevelOptions,
    languages: [],
    readonly: true,
    profileObjSaved: {},
    // для проверки на изменения, чтобы не отправлять лишние запросы в бэк
    endpointLanguages: "foreign-languages",
    templateLanguages: {
      language: null,
      level: null
    },
    statesLanguages: {
      language_state: null,
      level_state: null
    }
  }),
  created() {
    this.readonly = profileReadonly(this.profileObj.state);
    this.languages = this.profileObj.foreign_languages ? this.profileObj.foreign_languages.map(lang => ({
      ...lang,
      ...this.statesLanguages
    })) : [];
    this.profileObjSaved.languages = this.profileObj.foreign_languages ? this.profileObj.foreign_languages.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitDataEndpoint(obj, key, savekey, endpoint) {
      if (this.profileObjSaved[savekey][obj.id][key] === obj[key]) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: obj[key]
        },
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint(obj, savekey, endpoint, template, states) {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint
      }).then(response => {
        this.profileObjSaved[savekey][response.id] = {
          ...template
        };
        obj.push({
          id: response.id,
          ...states,
          ...template
        });
      });
    },
    removeDataEndpoint(obj, id, savekey, endpoint) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint
      }).then(() => {
        const index = obj.findIndex(el => el.id === id);
        delete this.profileObjSaved[savekey][id];
        obj.splice(index, 1);
      });
    },
    submitData(key) {
      const value = this[key];
      if (this.profileObjSaved[key] === value) {
        // изменений не было
        return;
      }
      const state = `${key}_state`;
      this[state] = false;
      this.updateProfile({
        id: this.profileObj.id,
        [key]: value
      }).then(() => {
        this.profileObjSaved[key] = value;
        setValueTimeout(this, state);
      }).catch(err => console.log("submitData", err));
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"])
  }
};