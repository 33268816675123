import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "text-center text-primary my-2"
};
const _hoisted_2 = {
  class: "shadow-sm p-3 my-3 bg-white rounded"
};
const _hoisted_3 = {
  class: "mt-2 text-muted"
};
const _hoisted_4 = {
  class: "row mt-1"
};
const _hoisted_5 = ["onUpdate:modelValue"];
const _hoisted_6 = {
  class: "mt-2 text-muted"
};
const _hoisted_7 = {
  class: "row mt-1"
};
const _hoisted_8 = ["onUpdate:modelValue"];
const _hoisted_9 = {
  class: "row mt-1"
};
const _hoisted_10 = {
  class: "col-sm-5 col-form-label"
};
const _hoisted_11 = {
  class: "col-sm-1"
};
const _hoisted_12 = {
  class: "col-sm-5 col-form-label text-end"
};
const _hoisted_13 = {
  class: "col-sm-1"
};
const _hoisted_14 = {
  class: "row mt-1"
};
const _hoisted_15 = {
  class: "col-sm-5 col-form-label"
};
const _hoisted_16 = {
  class: "col-sm-1"
};
const _hoisted_17 = {
  class: "col-sm-5 col-form-label text-end"
};
const _hoisted_18 = {
  class: "col-sm-1"
};
const _hoisted_19 = {
  class: "row mt-1"
};
const _hoisted_20 = {
  class: "col-sm-5 col-form-label"
};
const _hoisted_21 = {
  class: "col-sm-1"
};
const _hoisted_22 = {
  class: "col-sm-5 col-form-label text-end"
};
const _hoisted_23 = {
  class: "col-sm-1"
};
const _hoisted_24 = {
  class: "row mt-3"
};
const _hoisted_25 = {
  class: "col-sm-3 col-form-label"
};
const _hoisted_26 = {
  class: "col-sm-1"
};
const _hoisted_27 = {
  class: "col-sm-2 col-form-label text-end"
};
const _hoisted_28 = {
  class: "col-sm-1"
};
const _hoisted_29 = {
  class: "col-sm-4 col-form-label text-end"
};
const _hoisted_30 = {
  class: "col-sm-1"
};
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import AdminRiskCritNameSet from "./AdminRiskCritNameSet.vue";
import theNotification from "@/components/theNotification";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'AdminRisk',
  setup(__props) {
    const breadcrumbs = [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }];

    // store
    const store = useStore();
    const adminRisk = computed(() => store.getters["administration/adminRisk"]);
    const adminRiskLoading = computed(() => store.getters["administration/adminRiskLoading"]);
    onMounted(() => {
      store.dispatch("administration/updateRisk");
    });
    function changeData(key) {
      store.dispatch("administration/changeRisk", {
        id: adminRisk.value.id,
        body: {
          [key]: adminRisk.value[key]
        }
      });
    }
    return (_ctx, _cache) => {
      const _component_b_spinner = _resolveComponent("b-spinner");
      const _component_b_tab = _resolveComponent("b-tab");
      const _component_b_tabs = _resolveComponent("b-tabs");
      const _directive_can = _resolveDirective("can");
      return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_unref(theNotification)), _createVNode(BreadCrumbToHome, {
        breadCrumb: breadcrumbs
      }), _cache[21] || (_cache[21] = _createElementVNode("h1", {
        class: "mb-4 me-auto"
      }, "Настройка оценки рисков", -1)), adminRiskLoading.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Загрузка...", -1))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_tabs, {
        pills: "",
        card: "",
        class: "tabs-custom"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_tab, {
          active: "",
          title: "Настройки критериев вероятности",
          class: "py-3"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(adminRisk.value.values_probability_map), vpmk => {
            return _openBlock(), _createElementBlock("div", {
              key: vpmk,
              class: "pb-3 border-top mb-2"
            }, [_createElementVNode("div", _hoisted_3, _toDisplayString(adminRisk.value.terms_map[vpmk]), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(adminRisk.value.values_probability_map[vpmk]), (k, ki) => {
              return _openBlock(), _createElementBlock("div", {
                key: k,
                class: "col-3 d-flex align-items-center"
              }, [_createElementVNode("div", {
                class: _normalizeClass(["me-3 label-wrap", {
                  'text-end': ki
                }])
              }, _toDisplayString(k), 3), _withDirectives(_createElementVNode("input", {
                type: "number",
                class: "form-control input-wrap",
                onChange: _cache[0] || (_cache[0] = $event => changeData('values_probability_map')),
                "onUpdate:modelValue": $event => adminRisk.value.values_probability_map[vpmk][k] = $event
              }, null, 40, _hoisted_5), [[_vModelText, adminRisk.value.values_probability_map[vpmk][k]]])]);
            }), 128))])]);
          }), 128))]),
          _: 1
        }), _createVNode(_component_b_tab, {
          title: "Настройки критериев влияния",
          class: "py-3"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(adminRisk.value.values_impact_map), vimk => {
            return _openBlock(), _createElementBlock("div", {
              key: vimk,
              class: "pb-3 border-top mb-2"
            }, [_createElementVNode("div", _hoisted_6, _toDisplayString(adminRisk.value.terms_map[vimk]), 1), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(adminRisk.value.values_impact_map[vimk]), (k, ki) => {
              return _openBlock(), _createElementBlock("div", {
                key: k,
                class: "col-3 d-flex align-items-center"
              }, [_createElementVNode("div", {
                class: _normalizeClass(["me-3 label-wrap", {
                  'text-end': ki
                }])
              }, _toDisplayString(k), 3), _withDirectives(_createElementVNode("input", {
                type: "number",
                class: "form-control input-wrap",
                onChange: _cache[1] || (_cache[1] = $event => changeData('values_impact_map')),
                "onUpdate:modelValue": $event => adminRisk.value.values_impact_map[vimk][k] = $event
              }, null, 40, _hoisted_8), [[_vModelText, adminRisk.value.values_impact_map[vimk][k]]])]);
            }), 128))])]);
          }), 128))]),
          _: 1
        }), _createVNode(_component_b_tab, {
          title: "Настройки весов вероятности",
          class: "py-3"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("label", _hoisted_10, _toDisplayString(adminRisk.value.terms_map.signing_act), 1), _createElementVNode("div", _hoisted_11, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => adminRisk.value.weight_probability_map.signing_act = $event),
            onChange: _cache[3] || (_cache[3] = $event => changeData('weight_probability_map'))
          }, null, 544), [[_vModelText, adminRisk.value.weight_probability_map.signing_act]])]), _createElementVNode("label", _hoisted_12, _toDisplayString(adminRisk.value.terms_map.activity_regulation), 1), _createElementVNode("div", _hoisted_13, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[4] || (_cache[4] = $event => changeData('weight_probability_map')),
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => adminRisk.value.weight_probability_map.activity_regulation = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_probability_map.activity_regulation]])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("label", _hoisted_15, _toDisplayString(adminRisk.value.terms_map.confirming_document), 1), _createElementVNode("div", _hoisted_16, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[6] || (_cache[6] = $event => changeData('weight_probability_map')),
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => adminRisk.value.weight_probability_map.confirming_document = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_probability_map.confirming_document]])]), _createElementVNode("label", _hoisted_17, _toDisplayString(adminRisk.value.terms_map.control_procedures), 1), _createElementVNode("div", _hoisted_18, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[8] || (_cache[8] = $event => changeData('weight_probability_map')),
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => adminRisk.value.weight_probability_map.control_procedures = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_probability_map.control_procedures]])])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("label", _hoisted_20, _toDisplayString(adminRisk.value.terms_map.violations_internal), 1), _createElementVNode("div", _hoisted_21, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[10] || (_cache[10] = $event => changeData('weight_probability_map')),
            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => adminRisk.value.weight_probability_map.violations_internal = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_probability_map.violations_internal]])]), _createElementVNode("label", _hoisted_22, _toDisplayString(adminRisk.value.terms_map.violations_partners), 1), _createElementVNode("div", _hoisted_23, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[12] || (_cache[12] = $event => changeData('weight_probability_map')),
            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => adminRisk.value.weight_probability_map.violations_partners = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_probability_map.violations_partners]])])])]),
          _: 1
        }), _createVNode(_component_b_tab, {
          title: "Настройки весов влияния",
          class: "py-3"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_24, [_createElementVNode("label", _hoisted_25, _toDisplayString(adminRisk.value.terms_map.reputation_impact), 1), _createElementVNode("div", _hoisted_26, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[14] || (_cache[14] = $event => changeData('weight_impact_map')),
            "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => adminRisk.value.weight_impact_map.reputation_impact = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_impact_map.reputation_impact]])]), _createElementVNode("label", _hoisted_27, _toDisplayString(adminRisk.value.terms_map.responsibility), 1), _createElementVNode("div", _hoisted_28, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[16] || (_cache[16] = $event => changeData('weight_impact_map')),
            "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => adminRisk.value.weight_impact_map.responsibility = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_impact_map.responsibility]])]), _createElementVNode("label", _hoisted_29, _toDisplayString(adminRisk.value?.terms_map?.financial_costs), 1), _createElementVNode("div", _hoisted_30, [_withDirectives(_createElementVNode("input", {
            type: "number",
            class: "form-control",
            onChange: _cache[18] || (_cache[18] = $event => changeData('weight_impact_map')),
            "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => adminRisk.value.weight_impact_map.financial_costs = $event)
          }, null, 544), [[_vModelText, adminRisk.value.weight_impact_map.financial_costs]])])])]),
          _: 1
        }), _createVNode(_component_b_tab, {
          title: "Настройки названий критериев",
          class: "py-3"
        }, {
          default: _withCtx(() => [_createVNode(AdminRiskCritNameSet)]),
          _: 1
        })]),
        _: 1
      })])])), [[_directive_can, _ctx.$perm.change_risk_calc_settings]]);
    };
  }
};