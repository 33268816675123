import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.map.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapActions, mapGetters } from "vuex";
import AddRow from "@/components/common/AddRow";
import { setValueTimeout } from "@/helpers/timeout";
import { profileReadonly, relativeDegree } from "@/helpers/profile";
import { optionYesNo } from "@/helpers/text";
import { yearsOptions } from "@/helpers/date";
export default {
  name: "ThirtyFourth",
  data: () => ({
    optionYesNo,
    yearsOptions,
    relatives: [],
    readonly: true,
    endpoint: "relatives",
    has_relatives_agreement_state: null,
    template: {
      relation_degree: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      birth_year: null,
      org_name: null,
      organization_form: null,
      inn: null,
      position: null,
      address: null
    },
    states: {
      relation_degree_state: null,
      first_name_state: null,
      last_name_state: null,
      middle_name_state: null,
      birth_year_state: null,
      org_name_state: null,
      organization_form_state: null,
      inn_state: null,
      position_state: null,
      address_state: null
    },
    profileObjSaved: {}
  }),
  created() {
    this.readonly = profileReadonly(this.profileObj.state);
    this.relatives = this.profileObj.relatives.map(relative => ({
      ...relative,
      ...this.states
    }));
    this.profileObjSaved = this.profileObj.relatives ? this.profileObj.relatives.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}) : {};
  },
  methods: {
    ...mapActions("profile", ["updateProfile", "updateProfileData", "addProfileData", "delProfileData"]),
    submitDataEndpoint(obj, key) {
      const value = obj[key];
      if (this.profileObjSaved[obj.id][key] === value) {
        return;
      }
      const state = `${key}_state`;
      obj[state] = false;
      this.updateProfileData({
        id: this.profileObj.id,
        dataId: obj.id,
        params: {
          [key]: value
        },
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved[response.id] = response;
        setValueTimeout(obj, state);
      });
    },
    addDataEndpoint() {
      this.addProfileData({
        id: this.profileObj.id,
        endpoint: this.endpoint
      }).then(response => {
        this.profileObjSaved[response.id] = {
          ...this.template
        };
        this.relatives.push({
          id: response.id,
          ...this.states,
          ...this.template
        });
      });
    },
    removeDataEndpoint(id) {
      this.delProfileData({
        id: this.profileObj.id,
        dataId: id,
        endpoint: this.endpoint
      }).then(() => {
        const index = this.relatives.findIndex(el => el.id === id);
        delete this.profileObjSaved[id];
        this.relatives.splice(index, 1);
      });
    },
    submitData(key) {
      this.updateProfile({
        id: this.profileObj.id,
        [key]: this.profileObj[key]
      }).then(() => {
        setValueTimeout(this, [key] + "_state");
      });
    }
  },
  components: {
    AddRow
  },
  computed: {
    ...mapGetters("profile", ["profileObj"]),
    relativeOptions() {
      return Object.entries(relativeDegree).map(([value, text]) => ({
        value,
        text
      }));
    }
  }
};