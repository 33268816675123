import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _cache[8] || (_cache[8] = _createElementVNode("h1", {
    class: "mb-4"
  }, "Настройки модуля «Обучение»", -1)), !_ctx.adminEducationLoading && !_ctx.organizationsLoading && !_ctx.departmentsLoading ? (_openBlock(), _createBlock(_component_b_card, {
    key: 0,
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm p-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "6"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Количество дней для прохождения внутреннего обучения по умолчанию"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.form.daysInner,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.form.daysInner = $event),
            type: "number"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "6"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Количество дней для прохождения внешнего обучения по умолчанию"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_input, {
            modelValue: _ctx.form.daysOuter,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.form.daysOuter = $event),
            type: "number"
          }, null, 8, ["modelValue"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Вид отчета по умолчанию"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.form.type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.form.type = $event),
            options: _ctx.typeOptions
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Период отчета по умолчанию"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.form.period,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.form.period = $event),
            options: _ctx.periodOptions
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Организация по умолчанию"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.form.organisation,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.form.organisation = $event),
            options: $options.orgOptions
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_group, {
          label: "Подразделение по умолчанию"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_form_select, {
            modelValue: _ctx.form.department,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.form.department = $event),
            options: $options.departmentOptions
          }, null, 8, ["modelValue", "options"])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_button, {
          variant: "primary",
          onClick: $options.clickSave
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("Сохранить")])),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Загрузка...", -1))]))]);
}