import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_AdminDepartAdd = _resolveComponent("AdminDepartAdd");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_pagination = _resolveComponent("b-pagination");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_row = _resolveComponent("b-form-row");
  const _component_b_card_header = _resolveComponent("b-card-header");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_table = _resolveComponent("b-table");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_modal = _resolveComponent("b-modal");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), _createVNode(_component_b_row, {
    class: "mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, null, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("h1", null, "Подразделения", -1)])),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [_withDirectives(_createVNode(_component_AdminDepartAdd, {
        idModal: "createNewDepart",
        settings: _ctx.settingsAdd,
        onUpdateDepart: $options.clickCreateDepart
      }, null, 8, ["settings", "onUpdateDepart"]), [[_directive_can, _ctx.$perm.add_admin_department]])]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_card, {
    "no-body": "",
    class: "overflow-hidden border-0 shadow-sm py-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card_header, {
      "header-class": "py-0 m-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_form_row, {
        class: "m-0 p-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_pagination, {
            modelValue: $options.currentPageModel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentPageModel = $event),
            "total-rows": _ctx.adminDepartCount,
            "per-page": _ctx.perPage,
            size: "sm",
            "first-number": "",
            "last-number": "",
            "hide-goto-end-buttons": ""
          }, null, 8, ["modelValue", "total-rows", "per-page"]), [[_vShow, _ctx.adminDepartCount > _ctx.perPage]])]),
          _: 1
        }), _createVNode(_component_b_col, {
          cols: "auto",
          class: "m-0 p-0"
        }, {
          default: _withCtx(() => [_withDirectives(_createVNode(_component_b_input_group, {
            prepend: "Перейти к",
            size: "sm"
          }, {
            default: _withCtx(() => [_createVNode(_component_b_form_input, {
              modelValue: _ctx.gotoPage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.gotoPage = $event),
              style: {
                "width": "3em"
              },
              oninput: "this.value = this.value.replace(/[^0-9]/g, '');"
            }, null, 8, ["modelValue"]), _createVNode(_component_b_input_group_append, null, {
              default: _withCtx(() => [_createVNode(_component_b_button, {
                variant: "outline-primary-muted",
                onClick: $options.changePage,
                size: "sm"
              }, {
                default: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
                  icon: ['far', 'arrow-alt-circle-right'],
                  "fixed-width": ""
                })]),
                _: 1
              }, 8, ["onClick"])]),
              _: 1
            })]),
            _: 1
          }, 512), [[_vShow, _ctx.adminDepartCount > _ctx.perPage]])]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_table, {
      class: "table-custom",
      responsive: "",
      hover: "",
      small: "",
      "show-empty": "",
      stacked: "md",
      items: _ctx.adminDepart,
      fields: _ctx.tableFields,
      busy: _ctx.adminDepartLoading
    }, {
      "table-busy": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_spinner, {
        class: "align-middle"
      }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))])]),
      "cell(index)": _withCtx(row => [_createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.perPage + row.index + 1), 1)]),
      empty: _withCtx(() => [_createVNode(_component_b_alert, {
        show: "",
        variant: "light",
        class: "text-center"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "empty",
          class: "mb-4"
        }, null, -1), _createElementVNode("h4", null, "Подразделений нет", -1)])),
        _: 1
      })]),
      "cell(btnChange)": _withCtx(row => [_withDirectives(_createVNode(_component_AdminDepartAdd, {
        idModal: `changeDepart${row.item.id}`,
        settings: _ctx.settingsChange,
        item: row.item,
        onUpdateDepart: $options.clickChange
      }, null, 8, ["idModal", "settings", "item", "onUpdateDepart"]), [[_directive_can, _ctx.$perm.change_admin_department]])]),
      "cell(btnRemove)": _withCtx(row => [_withDirectives((_openBlock(), _createBlock(_component_b_button, {
        variant: "link",
        onClick: $event => $options.clickRemove(row.item),
        "data-hook": "iconRemoveDepart"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("i", {
          class: "bi bi-trash"
        }, null, -1)])),
        _: 2
      }, 1032, ["onClick"])), [[_directive_can, _ctx.$perm.delete_admin_department]])]),
      _: 1
    }, 8, ["items", "fields", "busy"])]),
    _: 1
  }), _createVNode(_component_b_modal, {
    title: `Удалить подразделение ${_ctx.currentItem.department_name} ?`,
    modelValue: _ctx.showModalRemove,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.showModalRemove = $event),
    "ok-variant": "outline-danger",
    "ok-title": "Удалить",
    "cancel-variant": "outline-primary",
    "cancel-title": "Отменить",
    centered: "",
    size: "sm",
    "button-size": "sm",
    "footer-class": "p-2",
    "hide-header-close": "",
    "body-class": "d-none",
    onOk: $options.doRemove
  }, null, 8, ["title", "modelValue", "onOk"])]);
}