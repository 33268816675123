import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "about"
};
const _hoisted_2 = {
  class: "p-4 bg-white"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "px-4 py-3"
};
const _hoisted_5 = {
  class: "div-info-wrap"
};
const _hoisted_6 = {
  class: "div-info-wrap"
};
const _hoisted_7 = {
  key: 0,
  class: "div-info-wrap"
};
const _hoisted_8 = ["href"];
const _hoisted_9 = {
  class: "div-info-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_container = _resolveComponent("b-container");
  const _component_b_link = _resolveComponent("b-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_container, {
    fluid: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "px-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_ctx.adminCommonLoading ? (_openBlock(), _createBlock(_component_b_spinner, {
          key: 0,
          class: "align-middle text-center text-primary"
        })) : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.adminCommon.logo_min,
          class: "d-inline-block align-top",
          style: {
            "max-height": "2.5rem"
          }
        }, null, 8, _hoisted_3))]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_b_container, {
    fluid: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "px-4"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "12",
        class: "pb-3"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_link, {
          to: _ctx.links.auth,
          class: "border-0"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("small", null, [_createElementVNode("i", {
            class: "fas fa-arrow-left me-2"
          })], -1), _createElementVNode("span", {
            class: "border-bottom border-gray"
          }, "Назад к платформе", -1)])),
          _: 1
        }, 8, ["to"]), _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Сведения о платформе", -1))]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "lead"
      }, {
        default: _withCtx(() => [_cache[2] || (_cache[2] = _createElementVNode("h4", null, "Информация о правообладателе", -1)), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.adminCommon.copyright_holder), 1), _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Информация о разработчике", -1)), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.adminCommon.developer), 1), _cache[4] || (_cache[4] = _createElementVNode("h4", null, "Версия и дата последнего релиза", -1)), _cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "div-info-wrap"
        }, "Версия 1.0.2 от 10 июля 2024 г.", -1)), _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Документация", -1)), Array.isArray(_ctx.adminCommon.docs) ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminCommon.docs, doc => {
          return _openBlock(), _createElementBlock("div", {
            key: doc.id
          }, [_createElementVNode("a", {
            target: "_blank",
            href: doc.document
          }, _toDisplayString(doc.document), 9, _hoisted_8)]);
        }), 128))])) : _createCommentVNode("", true), _cache[7] || (_cache[7] = _createElementVNode("h4", null, "Контакты технической поддержки", -1)), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.adminCommon.tech_support_contacts), 1)]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])]);
}