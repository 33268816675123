import "core-js/modules/es.array.push.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.reduce.js";
import { mapGetters, mapActions } from "vuex";
import BreadCrumbToHome from "@/components/BreadCrumbToHome.vue";
import theNotification from "@/components/theNotification";
import { periodOptions } from "@/helpers/date";
import { typeOptionsHline } from "@/helpers/text";
export default {
  name: "AdminHotLine",
  data: () => ({
    form: {
      days: 10,
      notifyNew: true,
      phone: "8(999) 123-45-67",
      email: "hotline@mdto.ru",
      period: "month",
      organisation: "all",
      department: "all",
      type: "report_stats"
    },
    typeOptionsHline,
    periodOptions,
    breadcrumbs: [{
      text: "Администрирование",
      to: {
        name: "AdminArea"
      }
    }]
  }),
  components: {
    BreadCrumbToHome,
    theNotification
  },
  async created() {
    await this.updateHotLine();
    await this.updateOrganizations();
    await this.updateDepartments();
    this.form.days = this.adminHotLine.days;
    this.form.notifyNew = this.adminHotLine.notifyNew;
    this.form.phone = this.adminHotLine.phone;
    this.form.email = this.adminHotLine.email;
    this.form.type = this.adminHotLine.type;
    this.form.period = this.adminHotLine.period;
    this.form.organisation = this.adminHotLine.organisation;
    this.form.department = this.adminHotLine.department;
  },
  methods: {
    ...mapActions("administration", ["updateHotLine", "changeHotLine"]),
    ...mapActions("referenceBooks", ["updateOrganizations", "updateDepartments"]),
    async clickSave() {
      this.changeHotLine(this.form);
    }
  },
  computed: {
    ...mapGetters("administration", ["adminHotLine", "adminHotLineLoading"]),
    ...mapGetters("referenceBooks", ["organizations", "organizationsLoading", "departments", "departmentsLoading"]),
    departmentOptions() {
      return Object.keys(this.departments).reduce((acc, curr) => {
        acc.push({
          value: this.departments[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    },
    orgOptions() {
      return Object.keys(this.organizations).reduce((acc, curr) => {
        acc.push({
          value: this.organizations[curr],
          text: curr
        });
        return acc;
      }, [{
        value: "all",
        text: "Все"
      }]);
    }
  }
};