import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 2
};
const _hoisted_4 = {
  class: "card-title"
};
const _hoisted_5 = {
  key: 0,
  class: "list-unstyled mt-4 mb-0"
};
const _hoisted_6 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_7 = {
  class: "card-title"
};
const _hoisted_8 = {
  key: 0,
  class: "list-unstyled mt-4 mb-0"
};
const _hoisted_9 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_10 = {
  class: "card-title"
};
const _hoisted_11 = {
  key: 0,
  class: "list-unstyled mt-4 mb-0"
};
const _hoisted_12 = {
  class: "text-muted text-nowrap me-2"
};
const _hoisted_13 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_14 = {
  class: "card-title"
};
const _hoisted_15 = {
  key: 0,
  class: "list-unstyled mt-4 mb-0"
};
const _hoisted_16 = {
  key: 1,
  class: "text-center text-primary my-2"
};
const _hoisted_17 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_router_link = _resolveComponent("router-link");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_card_body = _resolveComponent("b-card-body");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_avatar = _resolveComponent("b-avatar");
  const _component_PersonalAreaCard = _resolveComponent("PersonalAreaCard");
  const _directive_can = _resolveDirective("can");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_b_row, {
    class: "align-items-center px-4 mb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("h1", null, "Панель управления", -1)])),
      _: 1
    })]),
    _: 1
  }), _withDirectives((_openBlock(), _createBlock(_component_b_alert, {
    modelValue: _ctx.showAlertAutochecksMessage,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.showAlertAutochecksMessage = $event),
    variant: "warning",
    dismissible: ""
  }, {
    default: _withCtx(() => [_ctx.autochecksMessage.bpartner ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_link, {
      class: "need-wrap",
      to: {
        name: 'AutochecksArea',
        query: {
          type: 'bp_only'
        }
      }
    }, {
      default: _withCtx(() => [_createTextVNode(" Выявлены потенциальные конфликты интересов в деловых партнерах: " + _toDisplayString(_ctx.autochecksMessage.bpartner), 1)]),
      _: 1
    })])) : _createCommentVNode("", true), _ctx.autochecksMessage.declaration ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_router_link, {
      to: {
        name: 'AutochecksArea',
        query: {
          type: 'coid_only'
        }
      },
      class: "need-wrap"
    }, {
      default: _withCtx(() => [_createTextVNode(" Выявлены потенциальные конфликты интересов в декларациях: " + _toDisplayString(_ctx.autochecksMessage.declaration), 1)]),
      _: 1
    })])) : _createCommentVNode("", true), _ctx.autochecksMessage.profile ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_router_link, {
      class: "need-wrap",
      to: {
        name: 'AutochecksArea',
        query: {
          type: 'profile_only'
        }
      }
    }, {
      default: _withCtx(() => [_createTextVNode(" Выявлены потенциальные конфликты интересов в личных листках: " + _toDisplayString(_ctx.autochecksMessage.profile), 1)]),
      _: 1
    })])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue"])), [[_directive_can, _ctx.$perm.view_autocheck_notification_message]]), _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_row, {
    class: "align-items-center px-4 pb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("h3", {
        class: "mb-0 text-muted"
      }, "Личный кабинет", -1)])),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    class: "mb-2"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "12",
      md: "3",
      xl: "3",
      class: "d-flex align-self-stretch"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "mb-4 border-0 w-100 shadow"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_body, {
          class: "position-relative p-4"
        }, {
          default: _withCtx(() => [_createElementVNode("h5", _hoisted_4, [_createVNode(_component_router_link, {
            to: _ctx.links.profileEducation
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("Обучение")])),
            _: 1
          }, 8, ["to"])]), !_ctx.coursUserLoading ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coursUser.slice(0, 5), course => {
            return _openBlock(), _createElementBlock("li", {
              key: course.id,
              class: "mb-2"
            }, [_createVNode(_component_router_link, {
              to: _ctx.links.educationCourse + course.id
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(course.title), 1)]),
              _: 2
            }, 1032, ["to"])]);
          }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "3",
      xl: "3",
      class: "d-flex align-self-stretch"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "mb-4 border-0 w-100 shadow"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_body, {
          class: "position-relative p-4"
        }, {
          default: _withCtx(() => [_createElementVNode("h5", _hoisted_7, [_createVNode(_component_router_link, {
            to: _ctx.links.profileAcquaintance
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Ознакомление")])),
            _: 1
          }, 8, ["to"])]), !_ctx.lnaUserListLoading ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lnaUserList.slice(0, 5), lna => {
            return _openBlock(), _createElementBlock("li", {
              key: lna.id,
              class: "mb-2"
            }, [_createVNode(_component_router_link, {
              to: _ctx.links.profileAcquaintanceFormView + lna.id
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(lna.title), 1)]),
              _: 2
            }, 1032, ["to"])]);
          }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "3",
      xl: "3",
      class: "d-flex align-self-stretch"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "mb-4 border-0 w-100 shadow"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_body, {
          class: "position-relative p-4"
        }, {
          default: _withCtx(() => [_createElementVNode("h5", _hoisted_10, [_createVNode(_component_router_link, {
            to: _ctx.links.profileDeclarations
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Декларации")])),
            _: 1
          }, 8, ["to"])]), !_ctx.declarationsLoading ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.declarationsObj.slice(0, 5), declaration => {
            return _openBlock(), _createElementBlock("li", {
              key: declaration.id,
              class: "mb-2 position-relative"
            }, [_createVNode(_component_router_link, {
              to: _ctx.links.profileDeclarationForm + declaration.id,
              class: "stretched-link me-2"
            }, {
              default: _withCtx(() => [_createTextVNode(" № " + _toDisplayString(declaration.id), 1)]),
              _: 2
            }, 1032, ["to"]), _createElementVNode("span", _hoisted_12, " от " + _toDisplayString(_ctx.formatDate(declaration.created_at, _ctx.fmtDateMonth)), 1)]);
          }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[8] || (_cache[8] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "12",
      md: "6",
      xl: "3",
      class: "d-flex align-self-stretch"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_card, {
        "no-body": "",
        class: "mb-4 border-0 w-100 overflow-hidden shadow"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_body, {
          class: "position-relative p-4"
        }, {
          default: _withCtx(() => [_createElementVNode("h5", _hoisted_14, [_createVNode(_component_router_link, {
            to: _ctx.links.profileSheets
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("Личные листки")])),
            _: 1
          }, 8, ["to"])]), !_ctx.profilesKadrLoading ? (_openBlock(), _createElementBlock("ul", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profilesList.slice(0, 5), user => {
            return _openBlock(), _createElementBlock("li", {
              key: user.id,
              class: "mb-2 position-relative"
            }, [_createVNode(_component_b_avatar, {
              class: "me-1",
              size: 20,
              variant: "primary",
              text: _ctx.userInitials(user),
              src: user.photo
            }, null, 8, ["text", "src"]), _createVNode(_component_router_link, {
              to: _ctx.links.profileCard + user.id,
              class: "stretched-link"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.formatName(user, _ctx.fmtNameShort)), 1)]),
              _: 2
            }, 1032, ["to"])]);
          }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_b_spinner, {
            class: "align-middle"
          }), _cache[10] || (_cache[10] = _createElementVNode("strong", null, "Загрузка...", -1))]))]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])), [[_directive_can, _ctx.$perm.access_account]]), !_ctx.statsLoading ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_b_row, {
    class: "align-items-center px-4 pb-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => _cache[11] || (_cache[11] = [_createElementVNode("h3", {
        class: "mb-0 text-muted"
      }, "Сводка", -1)])),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_b_row, {
    class: "mb-2"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.personalAreaCards, (item, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_PersonalAreaCard, {
        item: item,
        key: index
      }, null, 8, ["item"])), [[_directive_can, item.perm]]);
    }), 128))]),
    _: 1
  })], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Загрузка...", -1))]))]);
}