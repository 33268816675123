import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/empty.svg';
const _hoisted_1 = {
  class: "text-center text-muted m-4"
};
const _hoisted_2 = {
  class: "text-right small mt-4 mb-0"
};
const _hoisted_3 = {
  class: "text-right small mt-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("img", {
    src: _imports_0,
    alt: "empty",
    class: "mb-4 img-wrap"
  }, null, -1)), _cache[2] || (_cache[2] = _createElementVNode("h4", {
    class: "mb-4"
  }, "Такой страницы нет", -1)), _createVNode(_component_b_button, {
    class: "mb-4",
    variant: "primary",
    to: _ctx.links.auth
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("Домашняя страница")])),
    _: 1
  }, 8, ["to"]), _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.SITE_NAME), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.OWNER_NAME), 1)]);
}