import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "mt-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_Calendar = _resolveComponent("Calendar");
  const _component_UserSelector = _resolveComponent("UserSelector");
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", null, [!_ctx.departmentsLoading && $props.key4filters.includes('owner_department_id') ? (_openBlock(), _createBlock(_component_b_form_group, {
    key: 0,
    label: "Владелец"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.owner_department_id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filters.owner_department_id = $event),
      options: $options.optionsOwner
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_b_spinner, {
    key: 1,
    class: "align-middle text-center text-primary my-2"
  })), $props.key4filters.includes('title_search') ? (_openBlock(), _createBlock(_component_b_form_group, {
    key: 2,
    label: "Название"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.title_search,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.filters.title_search = $event),
      type: "text",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  })) : _createCommentVNode("", true), $props.key4filters.includes('state') ? (_openBlock(), _createBlock(_component_b_form_group, {
    key: 3,
    label: "Статус"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: _ctx.filters.state,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.filters.state = $event),
      options: $options.sourceState
    }, null, 8, ["modelValue", "options"])]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Дата документа от... до..."
    }, {
      default: _withCtx(() => [$props.key4filters.includes('document_date_gte') ? (_openBlock(), _createBlock(_component_Calendar, {
        key: 0,
        title: null,
        date: _ctx.filters.document_date_gte,
        onChangeValue: _cache[3] || (_cache[3] = $event => _ctx.filters.document_date_gte = $event),
        classCustom: "no-gutters"
      }, null, 8, ["date"])) : _createCommentVNode("", true), $props.key4filters.includes('document_date_lte') ? (_openBlock(), _createBlock(_component_Calendar, {
        key: 1,
        title: null,
        date: _ctx.filters.document_date_lte,
        onChangeValue: _cache[4] || (_cache[4] = $event => _ctx.filters.document_date_lte = $event),
        classCustom: "no-gutters"
      }, null, 8, ["date"])) : _createCommentVNode("", true)]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), $props.key4filters.includes('document_number_search') ? (_openBlock(), _createBlock(_component_b_form_group, {
    key: 4,
    label: "Номер документа"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_input, {
      modelValue: _ctx.filters.document_number_search,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.filters.document_number_search = $event),
      type: "text",
      trim: ""
    }, null, 8, ["modelValue"])]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.showItem && $props.key4filters.includes('responsible_id') ? (_openBlock(), _createBlock(_component_b_form_group, {
      key: 0,
      label: "Ответственный"
    }, {
      default: _withCtx(() => [_createVNode(_component_UserSelector, {
        onSelected: _cache[6] || (_cache[6] = $event => $options.setFilter('responsible_id', $event)),
        name: _ctx.assignee,
        fmtName: _ctx.fmtNameFull,
        showInfo: true
      }, null, 8, ["name", "fmtName"])]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_b_button, {
    block: "",
    variant: "primary",
    onClick: $options.clickApplyFilters
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Применить")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_b_button, {
    block: "",
    variant: "outline-secondary",
    class: "ms-3",
    onClick: $options.clearFilters
  }, {
    default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Сбросить фильтры")])),
    _: 1
  }, 8, ["onClick"])])]);
}