import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_card = _resolveComponent("b-card");
  return _openBlock(), _createBlock(_component_b_card, null, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "mt-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[20] || (_cache[20] = [_createTextVNode("Вид деятельности")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10",
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.propItem.activity_description), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[21] || (_cache[21] = [_createTextVNode("Описание риска")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10",
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.propItem.risk_description), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-1"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[22] || (_cache[22] = [_createTextVNode("Рисковые должности")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10",
        "align-self": "center"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.propItem.risk_positions), 1)]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.activity_regulation), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.activity_regulation,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.propItem.activity_regulation = $event),
          options: _ctx.matrixForm.criteria_reference.activity_regulation,
          disabled: $options.readOnly,
          onChange: _cache[1] || (_cache[1] = $event => $options.changeData('activity_regulation'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.signing_act), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.signing_act,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.propItem.signing_act = $event),
          options: _ctx.matrixForm.criteria_reference.signing_act,
          disabled: $options.readOnly,
          onChange: _cache[3] || (_cache[3] = $event => $options.changeData('signing_act'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.confirming_document), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.confirming_document,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.propItem.confirming_document = $event),
          options: _ctx.matrixForm.criteria_reference.confirming_document,
          disabled: $options.readOnly,
          onChange: _cache[5] || (_cache[5] = $event => $options.changeData('confirming_document'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.control_procedures), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.control_procedures,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.propItem.control_procedures = $event),
          options: _ctx.matrixForm.criteria_reference.control_procedures,
          disabled: $options.readOnly,
          onChange: _cache[7] || (_cache[7] = $event => $options.changeData('control_procedures'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.violations_internal), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.violations_internal,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.propItem.violations_internal = $event),
          options: _ctx.matrixForm.criteria_reference.violations_internal,
          disabled: $options.readOnly,
          onChange: _cache[9] || (_cache[9] = $event => $options.changeData('violations_internal'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.violations_partners), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.violations_partners,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _ctx.propItem.violations_partners = $event),
          options: _ctx.matrixForm.criteria_reference.violations_partners,
          disabled: $options.readOnly,
          onChange: _cache[11] || (_cache[11] = $event => $options.changeData('violations_partners'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.reputation_impact), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.reputation_impact,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _ctx.propItem.reputation_impact = $event),
          options: _ctx.matrixForm.criteria_reference.reputation_impact,
          disabled: $options.readOnly,
          onChange: _cache[13] || (_cache[13] = $event => $options.changeData('reputation_impact'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.responsibility), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.responsibility,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _ctx.propItem.responsibility = $event),
          options: _ctx.matrixForm.criteria_reference.responsibility,
          disabled: $options.readOnly,
          onChange: _cache[15] || (_cache[15] = $event => $options.changeData('responsibility'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      }), _createVNode(_component_b_col, {
        class: "text-muted"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.matrixForm?.terms_map?.financial_costs), 1)]),
        _: 1
      }), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_b_form_select, {
          modelValue: _ctx.propItem.financial_costs,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => _ctx.propItem.financial_costs = $event),
          options: _ctx.matrixForm.criteria_reference.financial_costs,
          disabled: $options.readOnly,
          onChange: _cache[17] || (_cache[17] = $event => $options.changeData('financial_costs'))
        }, null, 8, ["modelValue", "options", "disabled"])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_row, {
      class: "mt-3"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "2",
        class: "text-muted"
      }, {
        default: _withCtx(() => _cache[23] || (_cache[23] = [_createTextVNode("Рекомендации")])),
        _: 1
      }), _createVNode(_component_b_col, {
        cols: "10"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_form_input, {
          modelValue: _ctx.propItem.recommendations,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => _ctx.propItem.recommendations = $event),
          placeholder: "Введите рекомендации Управление комплаенса",
          trim: "",
          disabled: $options.readOnly,
          onChange: _cache[19] || (_cache[19] = $event => $options.changeData('recommendations'))
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}