import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "mb-0 mt-0"
};
const _hoisted_2 = {
  class: "me-3"
};
const _hoisted_3 = {
  class: "mb-0"
};
const _hoisted_4 = {
  class: "mb-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [$props.user.last_name || $props.user.first_name || $props.user.middle_name ? (_openBlock(), _createElementBlock("p", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.user.last_name) + " " + _toDisplayString($props.user.first_name) + " " + _toDisplayString($props.user.middle_name), 1)])) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_3, [$props.user.position ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_cache[0] || (_cache[0] = _createElementVNode("span", {
    class: "text-muted"
  }, "Должность: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.position), 1)], 64)) : _createCommentVNode("", true), $props.user.organization ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_cache[1] || (_cache[1] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "Организация: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.organization), 1)], 64)) : _createCommentVNode("", true), $props.user.worker_status ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 2
  }, [_cache[2] || (_cache[2] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "Статус: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.worker_status), 1)], 64)) : _createCommentVNode("", true), $props.user.worker_code ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 3
  }, [_cache[3] || (_cache[3] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "Табельный номер: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.worker_code), 1)], 64)) : _createCommentVNode("", true), $props.user.inn ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 4
  }, [_cache[4] || (_cache[4] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "ИНН: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.inn), 1)], 64)) : _createCommentVNode("", true), $props.user.snils ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 5
  }, [_cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "СНИЛС: ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.snilsMask($props.user.snils)), 1)], 64)) : _createCommentVNode("", true), $props.user.birth_date ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 6
  }, [_cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "Дата рождения: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.birth_date), 1)], 64)) : _createCommentVNode("", true), $props.user.sex ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 7
  }, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "Пол: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.sex), 1)], 64)) : _createCommentVNode("", true), $props.user.email ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 8
  }, [_cache[8] || (_cache[8] = _createElementVNode("span", {
    class: "ms-3 text-muted"
  }, "email: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.email), 1)], 64)) : _createCommentVNode("", true)]), _createElementVNode("p", _hoisted_4, [$props.user.senior ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_cache[9] || (_cache[9] = _createElementVNode("span", {
    class: "text-muted"
  }, "Руководитель: ", -1)), _createElementVNode("span", null, _toDisplayString($props.user.senior), 1)], 64)) : _createCommentVNode("", true)])]);
}