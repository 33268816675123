import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "button-back"
};
const _hoisted_3 = {
  class: "ms-2"
};
const _hoisted_4 = {
  class: "ms-2"
};
const _hoisted_5 = {
  class: "mb-2"
};
const _hoisted_6 = {
  class: "ms-2"
};
const _hoisted_7 = {
  class: "ms-2"
};
const _hoisted_8 = {
  class: "ms-2"
};
const _hoisted_9 = {
  class: "ms-2"
};
const _hoisted_10 = {
  class: "ms-2"
};
const _hoisted_11 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_button = _resolveComponent("b-button");
  const _component_TaskButton = _resolveComponent("TaskButton");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_alert = _resolveComponent("b-alert");
  const _component_HotlineFormInfo = _resolveComponent("HotlineFormInfo");
  const _component_HotlineFormAuthor = _resolveComponent("HotlineFormAuthor");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_HotlineFormResult = _resolveComponent("HotlineFormResult");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_HotlineFormSource = _resolveComponent("HotlineFormSource");
  const _component_HotlineFormAttach = _resolveComponent("HotlineFormAttach");
  const _component_HotlineFormComments = _resolveComponent("HotlineFormComments");
  const _component_HotlineFormLog = _resolveComponent("HotlineFormLog");
  const _component_b_tabs = _resolveComponent("b-tabs");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _directive_can = _resolveDirective("can");
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: _ctx.breadcrumbs
  }, null, 8, ["breadCrumb"]), !_ctx.hlineMsgLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_row, {
    class: "mb-3"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_col, {
      class: "h1"
    }, {
      default: _withCtx(() => [_createTextVNode(" Сообщение #" + _toDisplayString(_ctx.hlineMsg.message_number || _ctx.NONUMBER) + " (" + _toDisplayString(_ctx.hlineMsg.id) + ") ", 1)]),
      _: 1
    }), _createVNode(_component_b_col, {
      cols: "auto"
    }, {
      default: _withCtx(() => [$options.stateBtn.type === 'simple' ? _withDirectives((_openBlock(), _createBlock(_component_b_button, {
        key: 0,
        variant: $options.stateBtn.variant,
        onClick: _cache[0] || (_cache[0] = $event => $options.clickAction(false, {}))
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($options.stateBtn.text), 1)]),
        _: 1
      }, 8, ["variant"])), [[_directive_can, $options.stateBtn.perm]]) : _createCommentVNode("", true), $options.stateBtn.type === 'taskBtn' ? _withDirectives((_openBlock(), _createBlock(_component_TaskButton, {
        key: 1,
        idModal: $options.stateBtn.modal,
        settings: $options.stateBtn.settings,
        onAddData: _cache[1] || (_cache[1] = $event => $options.clickAction(false, $event))
      }, null, 8, ["idModal", "settings"])), [[_directive_can, $options.stateBtn.perm]]) : _createCommentVNode("", true), _ctx.statesHotline[_ctx.hlineMsg.state] === _ctx.statesHotline.checked ? _withDirectives((_openBlock(), _createBlock(_component_TaskButton, {
        key: 2,
        class: "ms-3",
        idModal: "taskModalChecked2Complete",
        onAddData: _cache[2] || (_cache[2] = $event => $options.clickAction('prochecked', $event)),
        settings: _ctx.btn4transitHline.prochecked.settings
      }, null, 8, ["settings"])), [[_directive_can, _ctx.$perm.complete_wa_hotmsg]]) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_b_button, {
    variant: "primary",
    onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.back())
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("Назад")])),
    _: 1
  })]), _createElementVNode("div", null, [_cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "text-muted"
  }, "Статус", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.statesHotline[_ctx.hlineMsg.state]), 1), _cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Оператор горячей линии", -1)), _createElementVNode("span", _hoisted_4, _toDisplayString($options.formatName(_ctx.hlineMsg.user_detail, _ctx.fmtNameShort)) + " (" + _toDisplayString(_ctx.hlineMsg.user_detail.position) + ")", 1)]), _createElementVNode("div", _hoisted_5, [_cache[8] || (_cache[8] = _createElementVNode("span", {
    class: "text-muted"
  }, "Создано", -1)), _createElementVNode("span", _hoisted_6, _toDisplayString($options.formatDate(_ctx.hlineMsg.created_at, _ctx.fmtDateShort)), 1), _cache[9] || (_cache[9] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Дата регистрации", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString($options.formatDate(_ctx.hlineMsg.submitted_at, _ctx.fmtDateShort)), 1), _cache[10] || (_cache[10] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Назначено на рассмотрение", -1)), _createElementVNode("span", _hoisted_8, _toDisplayString($options.formatDate(_ctx.hlineMsg.assigned_at, _ctx.fmtDateShort)), 1), _cache[11] || (_cache[11] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Рассмотрено ответственным", -1)), _createElementVNode("span", _hoisted_9, _toDisplayString($options.formatDate(_ctx.hlineMsg.checked_at, _ctx.fmtDateShort)), 1), _cache[12] || (_cache[12] = _createElementVNode("span", {
    class: "text-muted ms-3"
  }, "Завершено", -1)), _createElementVNode("span", _hoisted_10, _toDisplayString($options.formatDate(_ctx.hlineMsg.completed_at, _ctx.fmtDateShort)), 1)]), _createVNode(_component_b_alert, {
    modelValue: _ctx.showAlert,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.showAlert = $event),
    variant: "danger",
    dismissible: ""
  }, {
    default: _withCtx(() => [_cache[13] || (_cache[13] = _createElementVNode("div", null, "Неверно заполнены обязательные поля", -1)), _createElementVNode("div", null, _toDisplayString(_ctx.alertTitle), 1)]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_b_card, {
    "no-body": "",
    class: "border-0 shadow-sm"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tabs, {
      pills: "",
      card: "",
      class: "tabs-custom"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_tab, {
        title: "Сообщение"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_HotlineFormInfo), _createVNode(_component_HotlineFormAuthor)]),
          _: 1
        })]),
        _: 1
      }), _ctx.statesHotline[_ctx.hlineMsg.state] !== _ctx.statesHotline.draft ? (_openBlock(), _createBlock(_component_b_tab, {
        key: 0,
        title: "Рассмотрение"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_HotlineFormResult)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[14] || (_cache[14] = _createTextVNode(" Источник ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($options.sourceCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_HotlineFormSource)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[15] || (_cache[15] = _createTextVNode(" Вложения ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineAttachCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_HotlineFormAttach)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, null, {
        title: _withCtx(() => [_cache[16] || (_cache[16] = _createTextVNode(" Комментарии УК ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineCommentsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_HotlineFormComments)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_b_tab, {
        title: "Журнал изменений"
      }, {
        title: _withCtx(() => [_cache[17] || (_cache[17] = _createTextVNode(" Журнал изменений ")), _createVNode(_component_b_badge, {
          variant: "secondary",
          class: "text-white"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.hlineEventsCount), 1)]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_b_card_text, null, {
          default: _withCtx(() => [_createVNode(_component_HotlineFormLog)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])) : (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Загрузка...", -1))]))])), [[_directive_can, _ctx.$perm.view_wa_hotmsg]]);
}