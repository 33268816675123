import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "mb-3"
};
const _hoisted_2 = {
  class: "mb-1"
};
const _hoisted_3 = {
  class: "mb-1"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "mb-1"
};
const _hoisted_7 = {
  class: "mb-1"
};
const _hoisted_8 = {
  class: "mb-3"
};
const _hoisted_9 = {
  key: 1,
  class: "text-center text-primary my-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_theNotification = _resolveComponent("theNotification");
  const _component_SignPEP = _resolveComponent("SignPEP");
  const _component_BreadCrumbToHome = _resolveComponent("BreadCrumbToHome");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_card_text = _resolveComponent("b-card-text");
  const _component_b_spinner = _resolveComponent("b-spinner");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_theNotification), _createVNode(_component_SignPEP, {
    ref: "btnPEP",
    type: _ctx.signTypes.knowledge,
    id: $props.msg
  }, null, 8, ["type", "id"]), _createVNode(_component_BreadCrumbToHome, {
    breadCrumb: $options.breadcrumbs
  }, null, 8, ["breadCrumb"]), !_ctx.lnaUserFormLoading ? (_openBlock(), _createBlock(_component_b_card_text, {
    key: 0
  }, {
    default: _withCtx(() => [_createElementVNode("h1", _hoisted_1, "Документ #" + _toDisplayString(_ctx.lnaUserForm.id), 1), _createVNode(_component_b_row, null, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.lnaUserForm.title), 1), _createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("span", {
          class: "text-muted me-2"
        }, "Статус", -1)), _ctx.lnaUserForm.assignment && !$options.isAltUI ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.statesKnowledgeUser[_ctx.lnaUserForm.assignment.state]), 1)) : _createCommentVNode("", true), $options.isAltUI ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.statesKnowledge[_ctx.lnaUserForm.state]), 1)) : _createCommentVNode("", true), _cache[1] || (_cache[1] = _createElementVNode("span", {
          class: "text-muted ms-3 me-2"
        }, "Номер/дата", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.lnaUserForm.document_number) + "/" + _toDisplayString($options.formatDate(_ctx.lnaUserForm.document_date, _ctx.fmtDateShort)), 1), _cache[2] || (_cache[2] = _createElementVNode("span", {
          class: "text-muted ms-3 me-2"
        }, "Ознакомиться до", -1)), _createElementVNode("span", null, _toDisplayString($options.formatDate(_ctx.lnaUserForm.deadline, _ctx.fmtDateShort)), 1)]), _createElementVNode("div", _hoisted_6, [_cache[3] || (_cache[3] = _createElementVNode("span", {
          class: "text-muted me-2"
        }, "Владелец", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.lnaUserForm.owner_department_name), 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
          class: "text-muted ms-3 me-2"
        }, "Ответственный", -1)), _createElementVNode("span", null, _toDisplayString($options.formatName(_ctx.lnaUserForm.responsible_detail, _ctx.fmtNameShort)), 1)]), _createElementVNode("div", _hoisted_7, [_cache[5] || (_cache[5] = _createElementVNode("span", {
          class: "text-muted me-2"
        }, "Основание", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.lnaUserForm.reason), 1)]), _createElementVNode("div", _hoisted_8, [_cache[6] || (_cache[6] = _createElementVNode("span", {
          class: "text-muted me-2"
        }, "Ссылка на видео", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.lnaUserForm.video), 1)])]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_b_button, {
      href: _ctx.lnaUserForm.attachment,
      download: decodeURI(_ctx.lnaUserForm.attachment),
      variant: "primary",
      class: "me-3"
    }, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("Скачать документ ")])),
      _: 1
    }, 8, ["href", "download"]), $options.showBtnSign ? (_openBlock(), _createBlock(_component_b_button, {
      key: 0,
      variant: "primary",
      onClick: $options.signPEP
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("Подтвердить")])),
      _: 1
    }, 8, ["onClick"])) : _createCommentVNode("", true)]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_b_spinner, {
    class: "align-middle"
  }), _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Загрузка...", -1))]))]);
}